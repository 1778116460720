import React, {Component, useState} from 'react'
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Link,
  Paper,
  Container,
  CardMedia,
  Snackbar,
  Collapse,
  Alert,
  AlertTitle,
} from '@mui/material';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
//import Button from '@mui/material/Button'
//import Grid from '@mui/material/Grid'
//import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
//import Typography from '@mui/material/Typography'
import { withTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom'

import '../login/LoginPageStyles.css';
import { AddBox } from '@mui/icons-material';

require('dotenv').config();

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')
const url = window.location.href;
const parts = url.split('/')
const entityName = parts[parts.length -1]
const baseUrl = process.env.REACT_APP_URL_FRONTEND

class LoginPage extends Component {
  constructor(props) {
    super(props)

    this.tagEntidad = ""

    this.state = {
      entidad: null,
      mensajeError: "",
      mostrarAlerta: true,
      mensajeAlerta: '',
      severidadAlerta: '',
    }

    if(props.match && props.match.params.tag) {
      this.tagEntidad = props.match.params.tag
    }

    this.login = this.login.bind(this)

    this.txtUser = React.createRef()
    this.txtPassword = React.createRef()
  }

  showAlert = (mensaje, severidad) => {
    this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensaje,
        severidadAlerta: severidad,
    });
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  componentDidMount() {
	  session.endSession()
	  this.queryEntidad()
  }

  queryEntidad() {

    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {tag_entidad: this.tagEntidad},
      url: process.env.REACT_APP_URL_BACKEND + '/login/tag'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando entidad")
        this.showAlert('Error en loggin', 'error')
        this.setState({
          entidad: null
        })
      } else {
        this.setState({
          entidad: data
        })
      }
    })

  }

  login = () => {

    var user = this.txtUser.current.value
    var password = this.txtPassword.current.value


    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
          id_entidad: this.state.entidad.id,
          usuario: user,
          password: password
      },
      url: process.env.REACT_APP_URL_BACKEND + '/login/'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        this.setState({
          mensajeError: data.message
        })
      } else {
        session.startSession(data.session)
        this.props.history.push('/dashboard')
      }
    })

  }

  render() {
    const {t} = this.props

    const welcomeBox = () => {
      return (
        <Grid item container xs={8}>
          <Grid item container xs={12} justifyContent="center" sx={{backgroundColor: "#3a3a3a"}}>
          {//<Typography gutterBottom variant="h4" component="div" sx={{color: "#ffffff"}}>
              //{t('window_login_lbl_bienvenida')}
            //</Typography>}
          }
          </Grid>

          <Grid item container xs={12} justifyContent="center" sx={{backgroundColor: "#eeeeee"}}>
            <img alt='logo' src={`/entidades/${entityName}.png`} width={200}/> 
          </Grid>
        </Grid>
      )
    }

    const loginBox = () => {
      return (
        <div
            style = {{
              height: '100vh',       // Ocupa el 100% de la altura de la ventana
              width: '100vw',        // Ocupa el 100% del ancho de la ventana
              m: 0,
              p: 0,
              backgroundImage: 'url("/ejMaquina.png")',
              backgroundSize: 'cover',        // La imagen cubrirá el contenedor completo
              backgroundPosition: 'center',   // Centra la imagen
              backgroundRepeat: 'no-repeat', 
              display: 'flex', 
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
               
            <Paper elevation={24}
                  sx= {{
                    marginTop: '110px',
                    marginRight: '285px',
                    borderRadius: 10,
                    maxWidth: '600px',
                  }}
                >
              <Box
                      component="form"
                      className="form"
                      noValidate
                      autoComplete="off"
                      sx={{
                        height: 'auto',
                        p: '40px',
                        paddingLeft: '60px',
                        paddingRight: '60px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '1rem',
                        width: 'auto',
                        maxWidth: '500px',
                        //maxHeight: '750px',
                        //margin: '0 auto', 
                      }}
                    >
                      <Grid item container xs={12} justifyContent="center" >
                        <img alt='logo' item src={`/entidades/${entityName}.png`} width={200}/>
                      </Grid>

                      <Box className="input-span" sx={{ width: '100%' }}>
                        <label htmlFor="email" className="label">
                          Usuario
                        </label>
                        <TextField
                          type="email"
                          name="email"
                          id="email"
                          variant="outlined"
                          fullWidth
                          inputRef={this.txtUser}
                          InputProps={{
                            sx: {
                              borderRadius: '0.5rem',
                              backgroundColor: 'var(--clr-alpha)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            },
                          }}
                        />
                      </Box>
                      <Box className="input-span" sx={{ width: '100%' }}>
                        <label htmlFor="password" className="label">
                          Contraseña
                        </label>
                        <TextField
                          type="password"
                          name="password"
                          id="password"
                          variant="outlined"
                          fullWidth
                          inputRef={this.txtPassword}
                          InputProps={{
                            sx: {
                              borderRadius: '0.5rem',
                              backgroundColor: 'var(--clr-alpha)',
                              '& .MuiOutlinedInput-notchedOutline': {
                                border: 'none',
                              },
                            },
                          }}
                          />
                      </Box>
                      
                      <Button
                        variant="contained"
                        fullWidth
                        sx={{
                          padding: '1rem 0.75rem',
                          borderRadius: '3rem',
                          backgroundColor: 'var(--bg-dark)',
                          color: 'var(--bg-light)',
                          fontWeight: 600,
                          fontSize: '0.9rem',
                          '&:hover': {
                            backgroundColor: '#8CC63E',
                            color: 'var(--bg-dark)',
                          },
                        }}
                        onClick={this.login}
                        >
                        Ingresar
                      </Button>
                      <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                      </Snackbar> 

                      {this.state.mensajeError && (
                        <Typography color="error">{this.state.mensajeError}</Typography>
                      )}
              </Box>
            </Paper>

            <Box
               sx={{
                backgroundColor: '#353535',  
                width: '100vw',
                textAlign: 'center',
                //padding: 1
                //marginTop: 80,
                //height: '50vh',
                
              }}
              >
                <Typography
                  variant="body4"
                  sx={{
                    textAlign: 'center',
                    color: '#FFFFFF',
                    //padding: 2,
                    //width: '100vw',
                    //height: '50vh',
                  }}
                >
                  © 2024 Selfia. All rights reserved.
                </Typography>

            </Box>
        </div>
      )
    }


    var contenido = null

    if(this.state.entidad == null) {
      contenido = welcomeBox
    } else {
      contenido = loginBox
    }

    return (
      <Box sx={{display: "flex"}} justifyContent="center">
          {contenido()}
      </Box>
    )
  }

}

export default withTranslation()(LoginPage)
