import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import luxon from '@date-io/luxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/lab/Alert'


import EventosTable from './EventosTable'
import { AlertTitle, Collapse, Grid, Paper, Snackbar } from '@mui/material'

const {DateTime} = require('luxon')
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class EventosPage extends Component { 
 
  constructor() {
    super()

    //Definir fechas de inicio para los filtros de fecha
    var fechaInicioTemp = DateTime.local().set({hour: 0, minute: 0, second: 0})
    var fechaFinTemp = DateTime.local().set({hour: 23, minute: 59, second: 59})

    this.state = {
      eventos: [],
      numRegistros: 0,
      grupos: [],
      cajeros: [],
      tipoEventos: [],
      filtroFechaInicio: fechaInicioTemp,
      filtroFechaFin: fechaFinTemp,

      mostrarAlerta: false,
      mensajeAlerta: '',
      severidadAlerta: ''
    };

    this.btnBuscarEventos = this.btnBuscarEventos.bind(this)
    this.tablaCambiarNumRegistros = this.tablaCambiarNumRegistros.bind(this)
    this.tablaCambiarPagina = this.tablaCambiarPagina.bind(this)

    this.cmbGrupos = React.createRef()
    this.cmbCajeros = React.createRef()
    this.cmbTipoEventos = React.createRef()
    this.cmbNumRegistros = React.createRef()

    this.filtroFechaInicio = ""
    this.filtroFechaFin = ""
    this.numRegistrosPagina = 50
    this.numPagina = 1
  }

  fechaFiltroChanged(origen, date) {
    if (date == null) return

    var formatoFechaInicio = "yyyy-LL-dd HH:mm:00"
    var formatoFechaFin = "yyyy-LL-dd HH:mm:59"

    switch (origen) {
      case 'inicio':
        this.setState({
          filtroFechaInicio: date
        })
        this.filtroFechaInicio = date.toFormat(formatoFechaInicio)
        break;
      case 'fin':
        this.setState({
          filtroFechaFin: date
        })
        this.filtroFechaFin = date.toFormat(formatoFechaFin)
        break;
      default:

    }
  }

  btnBuscarEventos() {
    this.numPagina = 1
    this.queryEventos()
  }

  tablaCambiarNumRegistros(value) {
    this.numRegistrosPagina = value

    this.queryEventos()
  }

  tablaCambiarPagina(value) {
    this.numPagina = value
    this.queryEventos()
  }

  queryGrupos() {
    console.log('Consultando grupos....')
        
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando grupos")
        //console.log('Error respuesta consultando grupos')
        this.setState({
          grupos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando grupos',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Grupos consultados')
      	this.setState({
        	grupos: data
        })
      }
    })
  }

 queryCajeros() {

    //console.log('Consultando cajeros....')
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosconsultas/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando cajeros")
        //console.log('Error respuesta consultando cajeros')
        this.setState({
          cajeros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando cajeros',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
    
   }


  queryTipoEventos() {
    //console.log('Consultando tipo eventos....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/tiposevento/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando tipo eventos")
        //console.log('Error respuesta consultando tipo eventos')
        this.setState({
          tipoEventos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando tipo eventos',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Tipo eventos consultados')
      	this.setState({
        	tipoEventos: data
        })
      }
    })
    
  }

  queryEventos() {
    //console.log('Consultando Eventos....')

    var filtroIdGrupo = ''
    var filtroIdCajero = ''
    var filtroIdTipoEvento = 0

    if(this.cmbGrupos.current.value !== 0){
      filtroIdGrupo = this.cmbGrupos.current.value
    }

    if(this.cmbCajeros.current.value !== 0){
      filtroIdCajero = this.cmbCajeros.current.value
    }

    if(this.cmbTipoEventos.current.value > 0){
      filtroIdTipoEvento = this.cmbTipoEventos.current.value
    }

    if(filtroIdGrupo === '' && filtroIdCajero === '') {
      //alert("Debe seleccionar un grupo o un cajero")
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: 'Debe seleccionar un grupo o un cajero',
        severidadAlerta: 'warning'
      })
      return
    }
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		filtro_id_grupo: filtroIdGrupo,
        filtro_id_cajero: filtroIdCajero,
        filtro_id_tipo_evento: filtroIdTipoEvento,
        filtro_fecha_inicio: this.filtroFechaInicio,
        filtro_fecha_fin: this.filtroFechaFin,
        num_registros: this.numRegistrosPagina,
        num_pagina: this.numPagina
		},
      url: process.env.REACT_APP_URL_BACKEND + '/eventos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando eventos")
        //console.log('Error respuesta consultando eventos')
        this.setState({
          eventos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando eventos',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Eventos consultados')
      	this.setState({
        	eventos: data.data,
        	numRegistros: data.num_registros
        })
      }
    })

   }

  componentDidMount() {
    this.queryGrupos()
    this.queryCajeros()
    this.queryTipoEventos()
    //this.queryEventos()
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }


  render() {


    return (
      <Box>
        <Typography variant='h5' component="h3" sx={{ marginBottom: 2 }}>Monitoreo de eventos</Typography>

        <Paper elevation={16}>
          <Card sx={{p: 2}}>
            <CardContent >
              
                <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 4 }}>
                  Buscar eventos:
                </Typography>
                
                <Grid container spacing={2}>
                  <Grid item ys={2} >
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DateTimePicker
                        label="Fecha inicio"
                        value={this.state.filtroFechaInicio}
                        onChange={date => this.fechaFiltroChanged('inicio', date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item ys={2}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DateTimePicker
                        label="Fecha fin"
                        value={this.state.filtroFechaFin}
                        onChange={date => this.fechaFiltroChanged('fin', date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item ys={2}>
                    <FormControl fullWidth>
                      <InputLabel>Grupo</InputLabel>
                      <Select style={{width: 200}}
                        label="Grupo"
                        inputRef={this.cmbGrupos}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Seleccione --</MenuItem>
                        {this.state.grupos.map(grupo => (
                          <MenuItem value={grupo.id}>{grupo.nombre}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item ys={2}>
                    <FormControl>
                      <InputLabel>Cajero</InputLabel>
                      <Select style={{width: 200}}
                        label="Cajero"
                        inputRef={this.cmbCajeros}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Seleccione --</MenuItem>
                        {this.state.cajeros.map(cajero => (
                          <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item ys={2}>
                    <FormControl>
                      <InputLabel>Tipo evento</InputLabel>
                      <Select style={{width: 200}}
                        label="Tipo evento"
                        inputRef={this.cmbTipoEventos}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Todos los eventos --</MenuItem>
                        {this.state.tipoEventos.map(tipoEvento => (
                          <MenuItem value={tipoEvento.id}>{tipoEvento.codigo}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item ys={2}>
                    <CardActions>
                      <Button  size="large" variant="contained" onClick={this.btnBuscarEventos}>Buscar</Button>
                      <Snackbar
                                open={this.state.mostrarAlerta}
                                onClose={this.handleCloseAlert}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                              >
                                <Collapse in={this.state.mostrarAlerta}>
                                    <Alert
                                          severity={this.state.severidadAlerta}
                                          onClose={this.handleCloseAlert}
                                          sx={{ marginTop: 4, width:'100%'}}
                                              >
                                    <AlertTitle>
                                      {this.state.severidadAlerta.toUpperCase()}
                                    </AlertTitle>
                                       {this.state.mensajeAlerta}
                                    </Alert>
                                </Collapse>
                      </Snackbar>
                    </CardActions>
                  </Grid>

                </Grid>              

            </CardContent>
          </Card>
        </Paper>

        <Divider style={{"margin-top": 30, "margin-bottom": 30}} />

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Listado Eventos:
              </Typography>
              <Box sx={{ p: 4, display: 'flex', flexWrap: 'wrap'}}>
                <EventosTable eventos={this.state.eventos} numRegistros={this.state.numRegistros} numPagina={this.state.numPagina} eventoCambiarNumRegistros={this.tablaCambiarNumRegistros} eventoCambiarPagina={this.tablaCambiarPagina}/>
              </Box>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
        </Paper>
      </Box>

    )
  }
}

export default EventosPage
