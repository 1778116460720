import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import PagoAutomaticoIcon from '@mui/icons-material/Aod';
import PagoManualIcon from '@mui/icons-material/MailOutline';

const session = require('../../config/session')

function Row(props) {

 const {deposito} = props;
 const [open, setOpen] = React.useState(false)

var estiloFila = ""
var iconoTipoTransaccion = null

switch (deposito.tipo_transaccion) {
  case 100:
    iconoTipoTransaccion = <PagoAutomaticoIcon />
    break;
  case 200:
    estiloFila = "fila_deposito_manual"
    iconoTipoTransaccion = <PagoManualIcon />
    break;
  default:
    break;
}

 var detalle = (deposito.detalle !== '' ? JSON.parse(deposito.detalle) : {})
 var filaDetalle = []


 for(var campo in detalle) {
   switch(campo) {
    case 'automatico':
      var currencies = detalle[campo].currencies
      var detalleFila = []

      // Recorrer las diferentes monedas(pais)
      // eslint-disable-next-line no-loop-func
      currencies.forEach((currency) => {
        detalleFila.push(
          <span>Moneda País: {currency.currency}<br /></span>
        )

        //Tomar los billetes y monedas de esa moneda
        var bills = currency.bills
        var coins = currency.coins

        //Recorrer todos los billetes
        detalleFila.push(
          <span>Billetes:<br /></span>
        )
        bills.forEach((bill) => {
          detalleFila.push(
            <span>${bill.denomination + " x " + bill.amount}<br /></span>
          )
        })

        //Recorrer todas las monedas
        detalleFila.push(
          <span>Monedas:<br /></span>
        )
        coins.forEach((coin) => {
            detalleFila.push(
              <span>${coin.denomination + " x " + coin.amount}<br /></span>
            )
        })

        detalleFila.push(<br />)
        
      })

      filaDetalle.push(
        <TableRow>
          <TableCell className="sub-detalle-titulo">Automático:</TableCell>
          <TableCell className="sub-detalle-valor">{detalleFila}</TableCell>
        </TableRow>
      )
      break
    case 'manual':
      /* eslint-disable no-redeclare */
      var currencies = detalle[campo].currencies
      var detalleFila = []
      /* eslint-enable no-redeclare */

      // Recorrer las diferentes monedas(pais)
      // eslint-disable-next-line no-loop-func
      currencies.forEach((currency) => {
        detalleFila.push(
          <span>Moneda País: {currency.currency}<br /></span>
        )

        //Tomar los billetes y monedas de esa moneda
        var bills = currency.bills
        var coins = currency.coins

        //Recorrer todos los billetes
        detalleFila.push(
          <span>Billetes:<br /></span>
        )
        bills.forEach((bill) => {
          detalleFila.push(
            <span>${bill.denomination + " x " + bill.amount}<br /></span>
          )
        })

        //Recorrer todas las monedas
        detalleFila.push(
          <span>Monedas:<br /></span>
        )
        coins.forEach((coin) => {
            detalleFila.push(
              <span>${coin.denomination + " x " + coin.amount}<br /></span>
            )
        })

        detalleFila.push(<br />)
        
      })

      filaDetalle.push(
        <TableRow>
          <TableCell className="sub-detalle-titulo">Manual:</TableCell>
          <TableCell className="sub-detalle-valor">{detalleFila}</TableCell>
        </TableRow>
      )
      break
    default:
      filaDetalle.push(
        <TableRow>
         <TableCell className="sub-detalle-titulo">{campo}</TableCell>
         <TableCell className="sub-detalle-valor">{JSON.stringify(detalle[campo])}</TableCell>
        </TableRow>
      )
   }
 }

 return (
   <React.Fragment>
     <TableRow class={estiloFila}>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell>
        {iconoTipoTransaccion}
       </TableCell>
       <TableCell>
         {deposito.fecha_registro}
       </TableCell>
       <TableCell>
        {
          session.checkPermiso('acceder_cajeros_resumen') ?
          <Link to={{pathname: '/dashboard/cajeros/resumen', state: {id_cajero: deposito.id_cajero}}}>{deposito.codigo_cajero}</Link>
          : <span>{deposito.codigo_cajero}</span>
        }
       </TableCell>
       <TableCell>
         {deposito.alias_cajero}
       </TableCell>
       <TableCell>
         {deposito.entidad}
       </TableCell>
       <TableCell>
         {deposito.referencia_pago}
       </TableCell>
       <TableCell>
         {deposito.sesion}
       </TableCell>
       <TableCell>
         {deposito.valor.toLocaleString()}
       </TableCell>
       <TableCell>
         {deposito.moneda}
       </TableCell>
       <TableCell>
         {deposito.finalizacion}
       </TableCell>
       <TableCell>
         {deposito.aprobacion}
       </TableCell>
       <TableCell>
         {deposito.fecha_aprobacion}
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
     <TableRow>
      <TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table className="detalle">
            <TableRow>
              <TableCell className="sub-detalle-titulo">País</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.pais_nombre}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Serial Cajero</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.serial_cajero}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Fecha Dispositivo</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.fecha_dispositivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Fecha Recaudo</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.fecha_recaudo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Tipo Transacción</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.tipo_transaccion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Consecutivo</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.consecutivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo"># Transacción</TableCell>
              <TableCell className="sub-detalle-valor">{deposito.num_transaccion}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="detalle-titulo">
                Detalle
              </TableCell>
              <TableCell className="detalle-valor">
                <Table>
                  {filaDetalle}
                </Table>
              </TableCell>
            </TableRow>
          </Table>
        </Collapse>
      </TableCell>
     </TableRow>
   </React.Fragment>
 );
}

function RowBilletes(billetes) {
  /* eslint-disable no-redeclare */
  var billetes = billetes.split(',')
  var filasBilletes = []
  /* eslint-enable no-redeclare */
  for(var billete in billetes) {
    filasBilletes.push(
      <TableRow>
       <TableCell className="sub-detalle-valor">{billetes[billete]}</TableCell>
      </TableRow>
    )
  }

  return(
    <Table>
      {filasBilletes}
    </Table>
  )
}

class DepositosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      depositos: []
    };
  }



  componentDidMount() {
    //this.queryEventos()
  }

  render() {
    const depositos = this.props.depositos
    const columns = [
      {field: "desplegable", headerName: "", width: 10},
      {field: "tipo_transaccion", headerName: '', width: 20},
      {field: "fecha_hora", headerName: 'Fecha Registro', width: 200},
      {field: "codigo_cajero", headerName: 'Codigo Cajero', width: 150},
      {field: "alias_cajero", headerName: 'Alias Cajero', width: 170},
      {field: "entidad", headerName: 'Entidad', width: 100},
      {field: "referencia_pago", headerName: 'Referencia Pago', width: 100},
      {field: "sesion", headerName: 'Sesión', width: 100},
      {field: "valor", headerName: 'Valor', width: 100},
      {field: "moneda", headerName: 'Moneda', width: 30},
      {field: "finalizacion", headerName: 'Finalizacion', width: 100},
      {field: "aprobacion", headerName: 'Aprobacion', width: 100},
      {field: "fecha_aprobacion", headerName: 'Fecha Aprobacion', width: 150},
    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {depositos.map((deposito) => {
                return(
                  <Row deposito={deposito} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default DepositosTable;
