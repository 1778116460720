import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosConfigurarMoneyManager extends Component {

    constructor(props) {
        super(props)

        this.idCajero = props.location.state.id_cajero

        this.state = {
            cajero: {},
            cmbTiposPuerto: "",
            cmbSerialVelocidades: "",
            cmbModosPoll: "",
            cmbDebugs: "",
            txtSerialPuerto: "",
            txtServidorDireccion: "",
            txtServidorPuerto: "",
            txtServidorMascara: "",
            txtServidorPuerta: "",
            tiposPuerto: [
                "Ethernet",
                "Serial"
            ],
            velocidades: [
                "9600",
                "19200",
                "115200"
            ],
            modosPoll: [
                "Poll",
                "Evento"
            ],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

    handleCloseAlert= (event, reason) => {
        if (reason === 'clickaway') {
          return; // Evitar que se cierre si el usuario hace clic fuera
        }
        this.setState({ mostrarAlerta: false })
      }

    onChangecmbTiposPuerto = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposPuerto: tipo})
    }

    onChangecmbSerialVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbSerialVelocidades: velocidad})
    }

    onChangecmbModosPoll = (event) => {
        const modo = event.target.value
        this.setState({cmbModosPoll: modo})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangetxtSerialPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtSerialPuerto: puerto})
    }

    onChangetxtServidorDireccion = (event) => {
        const direccion = event.target.value.toString()
        this.setState({txtServidorDireccion: direccion})
    }

    onChangetxtServidorPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtServidorPuerto: puerto})
    }

    onChangetxtServidorMascara = (event) => {
        const mascara = event.target.value.toString()
        this.setState({txtServidorMascara: mascara})
    }

    onChangetxtServidorPuerta = (event) => {
        const puerta = event.target.value.toString()
        this.setState({txtServidorPuerta: puerta})
    }

   
    queryCajero() {
        console.log('Consultando cajero....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // alert("Error consultando cajero")
	        // console.log('Error respuesta consultando cajero')
	        this.setState({
				cajero: {},
				mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajero',
                severidadAlerta: 'error'
	        })
	      } else {
	        // console.log('Cajero consultado')
	      	var configuracionMoneyManager = null

            try {
                configuracionMoneyManager = JSON.parse(data.configuracion_moneymanager)
                this.setState({
                    cmbTiposPuerto: configuracionMoneyManager.tipo_puerto,
                    txtSerialPuerto: configuracionMoneyManager.serial_puerto,
                    cmbSerialVelocidades: configuracionMoneyManager.serial_velocidad,
                    txtServidorDireccion: configuracionMoneyManager.servidor_direccion,
                    txtServidorPuerto: configuracionMoneyManager.servidor_puerto,
                    txtServidorMascara: configuracionMoneyManager.servidor_mascara,
                    txtServidorPuerta: configuracionMoneyManager.servidor_puerta,
                    cmbModosPoll: configuracionMoneyManager.modo_poll,
                    cmbDebugs: configuracionMoneyManager.debug
                    
                })
            } catch(error) {

            }
	      }
	    })
        
    }

    btnGuardarConfiguracionClick = () => {
        // console.log('Actualizando configuracion billetero....')

        var continuar = true
        var mensajeError = "Error:\n"

        var tipoPuerto = this.state.cmbTiposPuerto
        var serialPuerto = this.state.txtSerialPuerto
        var serialVelocidad = this.state.cmbSerialVelocidades
        var servidorDireccion = this.state.txtServidorDireccion
        var servidorPuerto = this.state.txtServidorPuerto
        var servidorMascara = this.state.txtServidorMascara
        var servidorPuerta = this.state.txtServidorPuerta
        var modoPoll = this.state.cmbModosPoll
        var debug = this.state.cmbDebugs
        

        serialPuerto = serialPuerto.trim()
        servidorDireccion = servidorDireccion.trim()
        servidorPuerto = servidorPuerto.trim()
        servidorMascara = servidorMascara.trim()
        servidorPuerta = servidorPuerta.trim()


        if(tipoPuerto === '') {
          continuar = false
          mensajeError += " * Debe seleccionar el tipo de puerto.\n"
        }
    
        if(continuar) {
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_cajero: this.idCajero,
                tipo_puerto: tipoPuerto,
                serial_puerto: serialPuerto,
                serial_velocidad: serialVelocidad,
                servidor_direccion: servidorDireccion,
                servidor_puerto: servidorPuerto,
                servidor_mascara: servidorMascara,
                servidor_puerta: servidorPuerta,
                modo_poll: modoPoll,
                debug: debug
			  },
		      url: process.env.REACT_APP_URL_BACKEND +  '/cajeros/cajero/configmoneymanager'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //alert("Error actualizando informaacion MoneyManager")
		        // console.log('Error respuesta actualizando MoneyManager')
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Error actualizando informaacion MoneyManager',
                    severidadAlerta: 'error'
                })
		      } else {
		        //console.log('Informacion MoneyManager actualizada')
		        //alert("Informacion MoneyManager actualizada")
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Informacion MoneyManager actualizada',
                    severidadAlerta: 'success'
                })
		      }
		    })
			
		}
    }

    componentDidMount() {
        this.queryCajero()
    }

    render() {
        return(
            <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    Configurar MoneyManager

                </Typography>
                
                <Paper elevation={16}>
                    <Grid container xs={12}>
                            <Grid item xs={12}>
                                <Card>
                                    <CardContent>
                                        <form>
                                            <FormLabel sx={{ marginBottom: 2 }} >Información MoneyManager</FormLabel>
                                            <br />
                                            <br />
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <InputLabel>Tipo Puerto</InputLabel>
                                                    <Select
                                                        label="Tipo Puerto"
                                                        value={this.state.cmbTiposPuerto}
                                                        defaultValue={0}
                                                        onChange={this.onChangecmbTiposPuerto}>
                                                        <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                        {this.state.tiposPuerto.map(item => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="(Serial) Puerto"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtSerialPuerto}
                                                    onChange={this.onChangetxtSerialPuerto}
                                                    fullWidth
                                                    />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <InputLabel>(Serial) Velocidad</InputLabel>
                                                    <Select
                                                        label="(Serial) Velocidad"
                                                        value={this.state.cmbSerialVelocidades}
                                                        defaultValue={0}
                                                        onChange={this.onChangecmbSerialVelocidades}>
                                                        <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                        {this.state.velocidades.map(item => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="(Servidor) Dirección"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtServidorDireccion}
                                                    onChange={this.onChangetxtServidorDireccion}
                                                    fullWidth
                                                    />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="(Servidor) Puerto"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtServidorPuerto}
                                                    onChange={this.onChangetxtServidorPuerto}
                                                    fullWidth
                                                    />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="(Servidor) Mascara"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtServidorMascara}
                                                    onChange={this.onChangetxtServidorMascara}
                                                    fullWidth
                                                    />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="(Servidor) Puerta"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtServidorPuerta}
                                                    onChange={this.onChangetxtServidorPuerta}
                                                    fullWidth
                                                    />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <InputLabel>Modo Poll</InputLabel>
                                                    <Select
                                                        label="Modo Poll"
                                                        value={this.state.cmbModosPoll}
                                                        defaultValue={0}
                                                        onChange={this.onChangecmbModosPoll}>
                                                        <MenuItem value={0}>-- Seleccione Modo --</MenuItem>
                                                        {this.state.modosPoll.map(item => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <FormControl fullWidth component="fieldset">
                                                    <InputLabel>Debug</InputLabel>
                                                    <Select
                                                        label="Debug"
                                                        value={this.state.cmbDebugs}
                                                        defaultValue={0}
                                                        onChange={this.onChangecmbDebugs}>
                                                        <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                        {this.state.debugs.map(item => (
                                                        <MenuItem value={item}>{item}</MenuItem>
                                                        ))}
                                                    </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </CardContent>
                                    <CardActions>
                                            <Button  variant="contained" onClick={this.btnGuardarConfiguracionClick}>Guardar Configuración</Button>
                                            <Snackbar
                                                open={this.state.mostrarAlerta}
                                                onClose={this.handleCloseAlert}
                                                autoHideDuration={3000}
                                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                                >
                                                <Collapse in={this.state.mostrarAlerta}>
                                                    <Alert
                                                        severity={this.state.severidadAlerta}
                                                        onClose={this.handleCloseAlert}
                                                        sx={{ marginTop: 4, width:'100%'}}
                                                            >
                                                    <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                    </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                    </Alert>
                                                </Collapse>
                                            </Snackbar>
                                        </CardActions>
                                </Card>
                            </Grid>
                    </Grid>

                </Paper>


            </Box>
        )
    }
}


export default CajerosConfigurarMoneyManager