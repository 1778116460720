import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'

function Row(props) {
 const {grupo} = props
 const {evento} = props

 return (
   <TableRow>
     <TableCell>
       {grupo.nombre}
     </TableCell>
     <TableCell>
       {grupo.entidad_nombre}
     </TableCell>
     <TableCell>
       {grupo.num_cajeros}
     </TableCell>
    <TableCell>
      <Button variant="contained" color="primary" onClick={() => {evento(grupo.id)}}><EditIcon /></Button>
    </TableCell>
  </TableRow>
 );
}


class CajerosGruposTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
    };

    this.eventoSeleccionar = null
  }

  componentDidMount() {
  }

  render() {
    const grupos = this.props.grupos
    const eventoSeleccionar = this.props.eventoSeleccionar

    const columns = [
      {field: "grupo", headerName: 'Grupo', width: 170},
      {field: "entidad", headerName: 'Entidad', width: 200},
      {field: "num_cajeros", headerName: '# Cajeros', width: 100},
      {field: "", headerName: '', width: 50}
    ]

    return (
      <Box sx={{ maxWidth: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader class="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {grupos.map((grupo) => {
                return(
                  <Row grupo={grupo} evento={eventoSeleccionar} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default CajerosGruposTable;
