import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TabBilleteros from './TabBilleteros'
import TabDispenzadorBilletes from './TabDispenzadorBilletes'
import TabImpresoras from './TabImpresoras'
import TabCerraduras from './TabCerraduras'
import TabLockManager from './TabLockManager'
import TabMoneyManager from './TabMoneyManager'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosDispositivosComplementosPage extends Component {

    constructor(props) {
        super(props)

        var idCajero = props.location.state.id_cajero

        this.state ={
            idCajero: idCajero,
            cajero : null,
            cajeros: [],
            currentTab: null,
            tipoDispositivos: [
                {id: "billeteros", nombre: "Billeteros"},
                {id: "dispenzadores_billetes", nombre: "Dispenzadores Billetes"},
                {id: "impresoras", nombre: "Impresoras"},
                {id: "cerraduras", nombre: "Cerraduras"},
                {id: "lockmanagers", nombre: "LockManagers"},
                {id: "moneymanagers", nombre: "MoneyManagers"}
            ],
            dispositivosCajero: {},
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: '',
        }

		this.cmbCajerosClonar = React.createRef()
        this.seleccionarTipo = this.seleccionarTipo.bind(this)
        this.guardarDispositivos = this.guardarDispositivos.bind(this)
        
    }

    showAlert = (mensaje, severidad) => {
        this.setState({
            mostrarAlerta: true,
            mensajeAlerta: mensaje,
            severidadAlerta: severidad,
        });
    }
    
    handleCloseAlert= (event, reason) => {
        if (reason === 'clickaway') {
          return; // Evitar que se cierre si el usuario hace clic fuera
        }
        this.setState({ mostrarAlerta: false })
    }

    seleccionarTipo(tipo) {

        var dispositivos = []
        var tab = this.state.currentTab


        if(this.state.dispositivosCajero != null && this.state.dispositivosCajero[tipo]) {
            dispositivos = this.state.dispositivosCajero[tipo]
        }

        switch(tipo) {
            case 'billeteros':
                tab = <TabBilleteros xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break
            case 'dispenzadores_billetes':
                tab = <TabDispenzadorBilletes xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break
            case 'impresoras':
                tab = <TabImpresoras xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break
            case 'cerraduras':
                tab = <TabCerraduras xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break
            case 'lockmanagers':
                tab = <TabLockManager xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break 
            case 'moneymanagers':
                tab = <TabMoneyManager xs={8} tipoDispositivo={tipo} dispositivos={dispositivos} event={this.guardarDispositivos} />
                break
            default:
                break
        }

        this.setState({
            currentTab: tab
        })
       
    }

    componentDidMount() {
        this.queryDispositivos()
        this.queryCajeros()
    }
    
    queryCajeros() {
    console.log('Consultando cajeros....')
     var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosconsultas/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando cajeros")
        //console.log('Error respuesta consultando cajeros')

        this.showAlert('Error consultando cajeros', 'error')
        this.setState({
			cajeros: [],
			mensajeError: data.message
        })
      } else {
        //console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
    
   }

    queryDispositivos() {
		this.setState({
			currentTab: null
		})

        var restArgs = {
          method: 'POST',
          headers: 'JSON',
          body: {
			  id_cajero: this.state.idCajero
			},
          url: process.env.REACT_APP_URL_BACKEND + '/cajeros/dispositivos'
        }
    
        RestTunel.makePetition(restArgs,(err, data) => {
          if(err) {
            //alert("Error consultando cajero")
            this.showAlert('Error consultando cajero', 'error')
            this.setState({
                dispositivosCajero: {}
            })
          } else {
            var dispositivosCajero = {}

            try {
                dispositivosCajero = JSON.parse(data.dispositivos)

                if(dispositivosCajero === null) {
                    dispositivosCajero = {}
                }
            } catch (error) {
                //console.log(error)
            }

            this.setState({
                cajero: data,
                dispositivosCajero: dispositivosCajero
            })
          }
        })
    
    }

    guardarDispositivos(tipo, dispositivos, confirmacionGuardado) {

        var dispositivosCajero = this.state.dispositivosCajero

        dispositivosCajero[tipo] = dispositivos

        var restArgs = {
            method: 'PATCH',
            headers: 'JSON',
            body: {
                id_cajero: this.state.idCajero,
                dispositivos: dispositivosCajero
            },
            url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/dispositivos'
          }
      
          RestTunel.makePetition(restArgs,(err, data) => {
            if(err) {
                //alert("Error actualizando dispositivos cajero")
                this.showAlert('Error actualizando dispositivos cajero', 'error')
            } else {
                confirmacionGuardado()
                //alert("Dispositivos actualizados") 
                this.showAlert('Dispositivos actualizados', 'success')
                this.queryDispositivos()
            }
          })
          
    }
    
    btnClonarClick = () => {
		//console.log('Clonar dispositivo....')

		var idCajeroOrigen = ''

		if(this.cmbCajerosClonar.current.value !== 0){
	      idCajeroOrigen = this.cmbCajerosClonar.current.value
	    } else {
			return
		}
	    
	    var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_cajero_origen: idCajeroOrigen,
			  id_cajero_destino: this.state.idCajero,
			  
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/dispositivosclonar'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error clonando dispositivos")
            this.showAlert('Error clonando dispositivos', 'error')
	        //console.log('Error respuesta clonando dispositivos')
	      } else {
	        //console.log('Dispositivos clonados')
	        //alert("Dispositivos clonados")
            this.showAlert('Dispositivos clonados', 'success')
			this.queryDispositivos()
	      }
	    })
	}

    render() {
        var dispositivos = this.state.dispositivosCajero
        
        var billeteros = []
        var dispenzadoresBilletes = []
        var impresoras = []
        var cerraduras = []
        var lockManagers = []
        var moneyManagers = []

        if(dispositivos) {
            if(dispositivos["billeteros"]) {
                billeteros = dispositivos["billeteros"]
            }
            
            if(dispositivos["dispenzadores_billetes"]) {
                dispenzadoresBilletes = dispositivos["dispenzadores_billetes"]
            }

            if(dispositivos["impresoras"]) {
                impresoras = dispositivos["impresoras"]
            }

            if(dispositivos["cerraduras"]) {
                cerraduras = dispositivos["cerraduras"]
            }

            if(dispositivos["lockmanagers"]) {
                lockManagers = dispositivos["lockmanagers"]
            }

            if(dispositivos["moneymanagers"]) {
                moneyManagers = dispositivos["moneymanagers"]
            }
        }
        

        return(
            <Box >
            
                <Paper elevation={16} sx={{ marginBottom: 2, width: '100%' }}>
                    <Card>
                        <CardContent>
                            <Box sx={{ marginBottom: 2 }}>
                                Cajero: {this.state.cajero != null ? this.state.cajero.codigo: ''}
                            </Box>
                        
                            <Box sx={{ marginBottom: 2 }}>
                                Alias: {this.state.cajero != null ? this.state.cajero.alias: ''}
                            </Box>
                            
                            <Link
                                to={{
                                            pathname: '/dashboard/cajeros/editar',
                                            state: {id_cajero: this.state.idCajero}
                                    }}
                                style={{ textDecoration: 'none', marginRight: 10  }}>
                                    <Button variant="outlined" color="primary">Volver</Button>
                                    <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                                    </Snackbar> 
                            </Link>
                        </CardContent>
                    </Card>
                </Paper>

                <Grid container xs={12}>
                	<Grid item xs={2}>
                		<Box>
                			<Box xs={12}>
                                
                				{
                                
		                            this.state.tipoDispositivos.map((tipo) => {
		                                return(
                                            <Box sx={{ marginBottom: 1, marginRight: 1}} >
		                                    
		                                        <Button sx={{ py: 1}}  variant="contained" color="success" onClick={(e) => this.seleccionarTipo(tipo.id)}>
		                                            {tipo.nombre}
		                                            ({(this.state.dispositivosCajero === null || this.state.dispositivosCajero[tipo.id] === undefined) ? 0 : this.state.dispositivosCajero[tipo.id].length})
		                                        </Button>
                                                <Snackbar
                                                    open={this.state.mostrarAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    autoHideDuration={3000}
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                                    >
                                                    <Collapse in={this.state.mostrarAlerta}>
                                                        <Alert
                                                            severity={this.state.severidadAlerta}
                                                            onClose={this.handleCloseAlert}
                                                            sx={{ marginTop: 4, width:'100%'}}
                                                            >
                                                        <AlertTitle>
                                                            {this.state.severidadAlerta.toUpperCase()}
                                                        </AlertTitle>
                                                            {this.state.mensajeAlerta}
                                                        </Alert>
                                                    </Collapse>
                                                </Snackbar> 
		                                    

                                            </Box>
		                                )
		                            })
                                
                           
	                        	}
                			</Box>
                			<Box>
                				<Card>
		                    		<CardContent>
					                	<FormLabel>Clonar de:</FormLabel>
                                            <br />
                                            <br />
					                		<form>
					                          <FormControl fullWidth>
					                            <InputLabel>Cajero</InputLabel>
					                            <Select
					                              label="Cajero"
					                              inputRef={this.cmbCajerosClonar}
					                              defaultValue={0}>
					                              <MenuItem value={0}>-- Seleccione cajero --</MenuItem>
					                              {this.state.cajeros.map(cajero => (
					                                <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
					                              ))}
					                            </Select>
					                          </FormControl>
				                          </form>
				                    </CardContent>
				                    <CardActions>
				                   		<Button variant="contained" fullWidth onClick={this.btnClonarClick}>Clonar</Button>
                                        <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                                        </Snackbar> 
				                    </CardActions>
		                   		</Card>
                			</Box>
                		</Box>
	                </Grid>
                    <Grid item xs={10}>
                        {this.state.currentTab}
                    </Grid>
                </Grid>
                <br />
                <br />

                <Card>
                    <CardContent>
                        <TableContainer>
                            <Table stickyHeader class="table-data">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Tipo</TableCell>
                                        <TableCell>Alias</TableCell>
                                        <TableCell>Estado</TableCell>
                                    </TableRow>
                                </TableHead>
                                {
                                    billeteros.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>Billeteros</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    dispenzadoresBilletes.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>Dispenzador Billetes</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    impresoras.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>Impresora</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                 {
                                    cerraduras.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>Cerradura</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    lockManagers.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>LockManager</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                                {
                                    moneyManagers.map((item) => {
                                        return(
                                            <TableRow>
                                                <TableCell>MoneyManager</TableCell>
                                                <TableCell>{item.alias}</TableCell>
                                                <TableCell>{item.estado}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                
            

            </Box>
        )
    }

}

export default CajerosDispositivosComplementosPage