import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CardActions from '@mui/material/CardActions'
import DeleteIcon from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import KeyIcon from '@mui/icons-material/VpnKey';
import { Alert, AlertTitle, Box, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Snackbar, Table, TableContainer, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosEditarPage extends Component {

    constructor(props) {
        super(props)

        this.idCajero = props.location.state.id_cajero
        this.entidadesSeleccionadas = []

        this.state = {
          cajero: {},
          txtSerial: "",
          txtCodigo: "",
          txtAlias: "",
          txtDireccion: "",
          txtEmailTransportadora: "",
          txtFranjaAutenticacion: "",
          txtContadorOffline1: 0,
          txtContadorOffline2: 0,
          txtKey1: "",
          txtKey2: "",
          txtKey3: "",
          txtKey4: "",
          txtKey5: "",
          txtKey6: "",
          txtKeyAlias: "",
          txtKeyValue: "",
          cmbCiudades: "",
          ciudades: [],
          entidades: [],
          entidadesDisponibles: [],
          entidadesCajero: [],
          keys: [],

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: '',

          mostrarDialogo: false,
          selectedAlias: ''
        }

    }

    onChangetxtSerial = (event) => {
      const serial = event.target.value.toString()
      this.setState({txtSerial: serial})
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }
  

    onChangetxtCodigo = (event) => {
        const codigo = event.target.value.toString()
        this.setState({txtCodigo: codigo})
    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangetxtDireccion = (event) => {
        const direccion = event.target.value.toString()
        this.setState({txtDireccion: direccion})
    }
    
    onChangetxtEmailTransportadora = (event) => {
      const emailTransportadora = event.target.value.toString()
      this.setState({txtEmailTransportadora: emailTransportadora})
    }

    onChangetxtFranjaAutenticacion = (event) => {
      const franjaAutenticacion = parseInt(event.target.value.toString())
      this.setState({txtFranjaAutenticacion: franjaAutenticacion})
  }
  
  onChangetxtContadorOffline1 = (event) => {
    const contadorOffline = parseInt(event.target.value.toString())
    this.setState({txtContadorOffline1: contadorOffline})
  }

  onChangetxtContadorOffline2 = (event) => {
    const contadorOffline = parseInt(event.target.value.toString())
    this.setState({txtContadorOffline2: contadorOffline})
  }

  onChangetxtKey1= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey1: key})
  }

  onChangetxtKey2= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey2: key})
  }

  onChangetxtKey3= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey3: key})
  }

  onChangetxtKey4= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey4: key})
  }

  onChangetxtKey5= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey5: key})
  }

  onChangetxtKey6= (event) => {
    const key = event.target.value.toString()
    this.setState({txtKey6: key})
  }
  
  onChangetxtKeyAlias = (event) => {
    const keyAlias = event.target.value.toString()
    this.setState({txtKeyAlias: keyAlias})
  }
  
  onChangetxtKeyValue = (event) => {
    const keyValue = event.target.value.toString()
    this.setState({txtKeyValue: keyValue})
  }

  onChangecmbCiudades = (event) => {
    const ciudad = event.target.value.toString()
    this.setState({cmbCiudades: ciudad})
  }
  
  btnAgregarKey() {
	  var keys = this.state.keys
	  var alias = this.state.txtKeyAlias
	  var key = this.state.txtKeyValue

	  keys.push({
		  alias: alias,
		  key: key
	  })		
	  
	  this.setState({
		  keys: keys
	  })
	  
	  this.actualizarKeys()
	  
  }
  
  btnRandomKey() {
	  const genRanHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
	  
	  var key = genRanHex(16).toUpperCase()
	  
	  this.setState({
		  txtKeyValue: key
	  })
	  
  }
  
  handleDialogOpen = (alias) => {
    this.setState({
      mostrarDialogo: true,
      selectedAlias: alias 
    })
   }

  handleCloseDialog = () => {
    this.setState({
      mostrarDialogo: false,
      selectedAlias: '' 
    })
  }

  btnRemoverKey= () => {
    let alias = this.state.selectedAlias
    var updateKeys = this.state.keys.filter((key)=> key.alias !==  alias)

    this.setState(
      {
        keys: updateKeys,
        mostrarDialogo: false,
        selectedAlias: ''
    },
      ()=>{
        this.actualizarKeys()
      
      }
    )
  }

  actualizarEntidadesDisponibles() {
    var entidades = this.state.entidades
    
    var entidadesDisponibles = []
    var entidadesCajero = this.state.entidadesCajero

    entidades.forEach((entidad) => {
      var blnAgregar = true

      entidadesCajero.forEach((entidadCajero) => {
        if(entidadCajero.id === entidad.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        entidadesDisponibles.push(entidad)
      }
    })
    
    /* entidades.map((entidad) => {
      var blnAgregar = true

      entidadesCajero.map((entidadCajero) => {
        if(entidadCajero.id == entidad.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        entidadesDisponibles.push(entidad)
      }
    }) */

    this.setState({
      entidadesDisponibles: entidadesDisponibles
    })
  }

  agregarEntidad(idEntidad) {
    var entidadesDisponibles = this.state.entidadesDisponibles
    var entidadesCajero = this.state.entidadesCajero

    entidadesDisponibles.forEach((entidad) => {
      if(entidad.id === idEntidad) {
        entidadesCajero.push(entidad)
      }
    })

    /* entidadesDisponibles.map((entidad) => {
      if(entidad.id == idEntidad) {
        entidadesCajero.push(entidad)
      }
    }) */

    this.setState({
      entidadesCajero: entidadesCajero
    })

    this.actualizarEntidadesDisponibles()
  }

  removerEntidad(idEntidad) {
    var entidadesCajero = this.state.entidadesCajero
    for(var x = 0; x < entidadesCajero.length; x++) {
      if(entidadesCajero[x].id === idEntidad) {
        entidadesCajero.splice(x, 1) 
        break
      }
    }

    this.setState({
      entidadesCajero: entidadesCajero
    })

    this.actualizarEntidadesDisponibles()
  }

    componentDidMount() {
        this.queryCajero()
        this.queryCiudades()
        this.queryEntidades()
        this.queryEntidadesCajero()
    }

    btnActualizarCajeroClick = () => {
        console.log('Actualizando cajero....')

        var continuar = true
        var mensajeError = "Error:\n"

        var serial = this.state.txtSerial
        var codigo = this.state.txtCodigo
        var alias = this.state.txtAlias
        var emailTransportadora = this.state.txtEmailTransportadora
        var idCiudad = this.state.cmbCiudades
        var direccion = this.state.txtDireccion
        var franjaAutenticacion = this.state.txtFranjaAutenticacion
        var contadorOffline1 = this.state.txtContadorOffline1
        var contadorOffline2 = this.state.txtContadorOffline2
        var key1 = this.state.txtKey1
        var key2 = this.state.txtKey2
        var key3 = this.state.txtKey3
        var key4 = this.state.txtKey4
        var key5 = this.state.txtKey5
        var key6 = this.state.txtKey6



        serial = serial.trim()
        codigo = codigo.trim()
        alias = alias.trim()
        emailTransportadora = emailTransportadora.trim()
        direccion = direccion.trim()
        franjaAutenticacion = parseInt(franjaAutenticacion)
        contadorOffline1 = parseInt(contadorOffline1)
        contadorOffline2 = parseInt(contadorOffline2)

        if(key1 != null) key1 = key1.trim()
        if(key2 != null) key2 = key2.trim()
        if(key3 != null) key3 = key3.trim()
        if(key4 != null) key4 = key4.trim()
        if(key5 != null) key5 = key5.trim()
        if(key6 != null) key6 = key6.trim()


        if(serial === '') {
          continuar = false
          mensajeError += " * Debe ingresar el serial.\n"
        }

        if(codigo === '') {
          continuar = false
          mensajeError += " * Debe ingresar el código.\n"
        }

        if(alias === '') {
          continuar = false
          mensajeError += " * Debe ingresar el alias.\n"
        }

        if(emailTransportadora === '') {
          continuar = false
          mensajeError += " * Debe ingresar el correo de la transportadora.\n"
        }

        if(idCiudad < 1) {
          continuar = false
          mensajeError += " * Debe seleccionar la ciudad.\n"
        }

        if(direccion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la dirección/ubicación.\n"
        }

        
        if(franjaAutenticacion < 1) {
          continuar = false
          mensajeError += " * Debe ingresar la franja de autenticación.\n"
        }

        if(contadorOffline1 < 0) {
          continuar = false
          mensajeError += " * Debe ingresar el contador offline 1.\n"
        }

        if(contadorOffline2 < 0) {
          continuar = false
          mensajeError += " * Debe ingresar el contador offline 2.\n"
        }

        if(key1 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 1.\n"
        }

        if(key2 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 2.\n"
        }

        if(key3 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 3.\n"
        }

        if(key4 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 4.\n"
        }

        if(key5 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 5.\n"
        }

        if(key6 === '') {
          continuar = false
          mensajeError += " * Debe ingresar la Key 6.\n"
        }
    
        if(continuar) {
			
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_cajero: this.state.cajero.id,
	                serial: serial,
	                codigo: codigo,
	                alias: alias,
	                email_transportadora: emailTransportadora,
	                id_ciudad: idCiudad,
	                direccion: direccion,
	                franja_autenticacion: franjaAutenticacion,
	                contador_offline1: contadorOffline1,
	                contador_offline2: contadorOffline2,
	                key1: key1,
	                key2: key2,
	                key3: key3,
	                key4: key4,
	                key5: key5,
	                key6: key6
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/edit'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Error actualizando cajero',
              severidadAlerta: 'error'
            })
          } else {
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cajero Actualizado',
              severidadAlerta: 'success'
            })
		      }
		    })
	    }
    }

   
  btnGuardarEntidadesClick = () => {
    var entidadesCajero = this.state.entidadesCajero
    var entidades = []
    var mensajeError = "Error:\n"
    var continuar = true

    if(entidadesCajero.length < 1) {
      mensajeError += "* El cajero debe pertenecer al menos a una entidad."
      continuar = false
    } else {
      entidadesCajero.forEach((entidad) => {
        entidades.push(entidad.id)
      })

      /* entidadesCajero.map((entidad) => {
        entidades.push(entidad.id)
      })*/
    }
    
    if(continuar) {
		
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_cajero: this.state.cajero.id,
              entidades: entidades
		  },
	      url: process.env.REACT_APP_URL_BACKEND +  '/cajeros/cajero/entidades'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error actualizando entidades")
	        //console.log('Error respuesta consultando grupos')
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Error actualizando entidades',
            severidadAlerta: 'error'
          })
	      } else {
	        //console.log('Entidades actualizadass')
	        //alert("Entidades actualizadas")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Entidades actualizadas',
            severidadAlerta: 'success'
          })
	      }
	    })
    }
  }

  

  cambiarEstado() {
      var estado = this.state.cajero.estado
      
      switch(estado) {
          case "I":
              estado = "A"
              break
          case "A":
              estado = "I"
              break
          default:
            break
      }

      console.log('Actualizando estado cajero....')
      
      var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_cajero: this.idCajero,
	          estado: estado
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/estado/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error actualizando estado")
	        //console.log('Error respuesta actualizando estado cajero')
	        this.setState({
            grupos: [],
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error actualizando estado',
            severidadAlerta: 'error'
            
	        })
	      } else {
	        // console.log('Estado cajero actualizado')
	      	// alert('Estado cajero actualizado')
	      	this.queryCajero()
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Entidades cajero',
            severidadAlerta: 'success'
          })
	      }
	    })
      
    }
    
    actualizarKeys() {
      var keys = {
		  	keys: this.state.keys
		  }
      
      console.log('Actualizando keys cajero....')
      
      var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_cajero: this.idCajero,
	          keys: keys
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/keys/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error actualizando keys")
	        // console.log('Error respuesta actualizando keys cajero')
	        this.setState({
				    mensajeError: data.message,

            mostrarAlerta: true,
            mensajeAlerta: 'Error actualizando key',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Keys cajero actualizadas')
	        this.setState({
            txtKeyAlias: "",
            txtKeyValue: "",
            mostrarAlerta: true,
            mensajeAlerta: 'Keys cajero actualizadas',
            severidadAlerta: 'success'
	        })
	      	//alert('Keys cajero actualizadas')
	      	this.queryCajero()
	      }
	    })
      
    }

    queryCajero() {
        console.log('Consultando cajero....')
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajero")
	        // console.log('Error respuesta consultando cajero')
	        this.setState({
            cajero: {},
            mensajeError: data.message,

            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando cajero',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Cajero consultado')
	        
	        var keys = []
	        try {
				var tempKeys = JSON.parse(data.keylist)
				keys = tempKeys.keys
			} catch(error) {
				
			}
	        
	        
	      	this.setState({
                cajero: data,
                txtSerial: data.serial,
                txtCodigo: data.codigo,
                txtAlias: data.alias,
                cmbCiudades: data.ciudad,
                txtDireccion: data.direccion,
                txtEmailTransportadora: data.email_transportadora,
                txtFranjaAutenticacion: data.franja_autenticacion,
                txtContadorOffline1: data.contador_offline1,
                txtContadorOffline2: data.contador_offline2,
                keys: keys,
                txtKey1: data.key1,
                txtKey2: data.key2,
                txtKey3: data.key3,
                txtKey4: data.key4,
                txtKey5: data.key5,
                txtKey6: data.key6
            })
	      }
	    })
    }

    queryCiudades() {
      console.log('Consultando ciudades....')
      
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/ciudades/'
	    }
	
	     RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // alert("Error consultando ciudades")
	        // console.log('Error respuesta consultando ciudades')
	        this.setState({
            ciudades: [],
            mensajeError: data.message,

            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando ciudades',
            severidadAlerta: 'error'
	        })
	      } else {
	        // console.log('Grupos consultados')
	      	this.setState({
	        	ciudades: data
	        })
	      }
	    })
      
   }

    queryEntidades() {
        //console.log('Consultando entidades....')
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // alert("Error consultando grupos")
	        // console.log('Error respuesta consultando grupos')
	        this.setState({
            entidades: [],
            mensajeError: data.message,

            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando grupos',
            severidadAlerta: 'error'
	        })
	      } else {
	        // console.log('Grupos consultados')
	      	this.setState({
	        	entidades: data
	        })
	        this.actualizarEntidadesDisponibles()
	      }
	    })
      }
	
	  queryEntidadesCajero() {
	    console.log('Consultando entidades cajero....')
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/entidades/list'
	    }
	
	     RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // alert("Error consultando entidades cajero")
	        // console.log('Error respuesta consultando entidades cajero')
	        this.setState({
            entidadesCajero: [],
            mensajeError: data.message,

            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando entidades cajero',
            severidadAlerta: 'error'
	        })
	      } else {
	        // console.log('Entidades cajero consultadas')
	      	this.setState({
	        	entidadesCajero: data
	        })
	        this.actualizarEntidadesDisponibles()
	      }
	    })
	    
	  }
  
    render() {
        var cajero = this.state.cajero
        const { mostrarDialogo, selectedAlias, keys } = this.state;
		
        return(
          <Box>
            <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
              Editar Cajero
            </Typography>
            
            <Paper elevation={16}>
              <Grid >
                <Card>
                    <CardContent>
                        <form>
                            <FormLabel> Información cajero</FormLabel>
                            <Grid container xs={12} spacing={2} rowSpacing={2} sx={{ marginTop: 1 }}>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Serial"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtSerial}
                                  onChange={this.onChangetxtSerial}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Código"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtCodigo}
                                  onChange={this.onChangetxtCodigo}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>   
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Alias"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtAlias}
                                  onChange={this.onChangetxtAlias}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <InputLabel>Ciudad</InputLabel>
                                  <Select
                                    label="Ciudad"
                                    value={this.state.cmbCiudades}
                                    defaultValue={0}
                                    onChange={this.onChangecmbCiudades}>
                                    <MenuItem value={0}>-- Seleccione ciudad --</MenuItem>
                                    {this.state.ciudades.map(ciudad => (
                                      <MenuItem value={ciudad.ciudad_id}>
                                      	<ListItemText secondary={ciudad.pais_nombre}/>
                                      	<ListItemText primary={ciudad.ciudad_nombre}/>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>     
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Dirección/Ubicación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtDireccion}
                                    onChange={this.onChangetxtDireccion}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Correo Transportadora"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtEmailTransportadora}
                                    inputProps={{ inputMode: 'numeric'}}
                                    onChange={this.onChangetxtEmailTransportadora}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Franja Autenticación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtFranjaAutenticacion}
                                    onChange={this.onChangetxtFranjaAutenticacion}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Contador Offline 1"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtContadorOffline1}
                                    onChange={this.onChangetxtContadorOffline1}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Contador Offline 2"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtContadorOffline2}
                                    onChange={this.onChangetxtContadorOffline2}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 1"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey1}
                                    onChange={this.onChangetxtKey1}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 2"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey2}
                                    onChange={this.onChangetxtKey2}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 3"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey3}
                                    onChange={this.onChangetxtKey3}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 4"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey4}
                                    onChange={this.onChangetxtKey4}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 5"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey5}
                                    onChange={this.onChangetxtKey5}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Key 6"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKey6}
                                    onChange={this.onChangetxtKey6}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                            </Grid>                   
                        </form>
                    </CardContent>
                    <CardActions>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button  variant="contained" sx={{ marginBottom: 2 }} onClick={this.btnActualizarCajeroClick}>Actualizar Informacion Cajero</Button>
                        
                        <Snackbar
                              open={this.state.mostrarAlerta}
                              onClose={this.handleCloseAlert}
                              autoHideDuration={3000}
                              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                              >
                              <Collapse in={this.state.mostrarAlerta}>
                                <Alert
                                    severity={this.state.severidadAlerta}
                                    onClose={this.handleCloseAlert}
                                    sx={{ marginTop: 4, width:'100%'}}
                                    >
                                    <AlertTitle>
                                      {this.state.severidadAlerta.toUpperCase()}
                                    </AlertTitle>
                                    {this.state.mensajeAlerta}
                                </Alert>
                              </Collapse>
                          </Snackbar>
                      </Box>
                    </CardActions>
                  </Card>
              </Grid>
            </Paper>

            <br />
              
            <Paper elevation={16}>
              <Grid xs={4}>
                      <Card>
                        <CardContent>
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                              <FormLabel>Entidades Disponibles</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.entidadesDisponibles.map((entidad) => {
                                      return(
                                        <ListItem button
                                          key={entidad.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarEntidad(entidad.id)}}>
                                            {entidad.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Entidades Cajero</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.entidadesCajero.map((entidad) => {
                                      return(
                                        <ListItem button
                                          key={entidad.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerEntidad(entidad.id)}}>
                                          {entidad.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" fullWidth onClick={this.btnGuardarEntidadesClick}>Guardar Entidades</Button>
                        </CardActions>
                      </Card>
              </Grid>
              
            </Paper>
                               
				    <br />
{/*/----------------------------------------------------------------------------------------*/}
            <Paper elevation={16}>
                   <Grid item xs={4} spacing={2}>
                   		<Card>
		                    <CardContent>
                          <TableContainer
                            sx={{
                              padding: 2, 
                              margin: 2,  
                            }}
                          >
                            <Table>
                              
                                {
                                  this.state.keys.map((item) => {
                                return(
                                  
                                  <tr>
                                    <td>{item.alias}: </td>
                                    
                                    <td>{item.key}</td>
                                    
                                    <td>
                                      <Button variant="contained" size='small' color="error" onClick={()=> this.handleDialogOpen(item.alias)}><DeleteIcon/></Button>

                                      
                                    </td>

                                  </tr>
                                )
                                  })
                                }
                              <Dialog
                                open={mostrarDialogo}
                                onClose={this.handleCloseDialog}
                                aria-labelledby="confirm-dialog-title"
                                aria-describedby="confirm-dialog-description"
                                //keepMounted
                                >
                                <DialogTitle id="confirm-dialog-title">
                                        {`¿Desea borrar la key "${selectedAlias}"? `}
                                </DialogTitle>
                      
                                <DialogContent>
                                          <DialogContentText id="alert-dialog-slide-description">
                                            Borrar esta key podra implicar perdida de informacion.
                                          </DialogContentText>
                                </DialogContent>
      
                                <DialogActions>
                                          <Button onClick={this.handleCloseDialog}>Cancelar</Button>
                                          <Button onClick={this.btnRemoverKey} autoFocus >Confirmar</Button>
                                </DialogActions>
                              </Dialog>
        
                            </Table>
                          </TableContainer>
							<CardActions>
								                  <TextField
                                    label="Alias"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKeyAlias}
                                    onChange={this.onChangetxtKeyAlias}
                                    
                                  />
                                  <TextField
                                    label="Valor"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtKeyValue}
                                    onChange={this.onChangetxtKeyValue}
                                    sx={{ marginRight: 2 }}
                                  />
                                  <br />
                                  <Button variant="contained" size='small' color="success"  onClick={() => {this.btnRandomKey()}}><KeyIcon /></Button>
                                  <br />
                                  <Button variant="contained" size='small' color="primary" onClick={() => {this.btnAgregarKey()}}><SaveIcon /></Button>
							</CardActions>
		                    </CardContent>
                      </Card>
                   </Grid>
           

            </Paper>

            <br />
            
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(12, 1fr)', gap: 2 }}>
                <Box sx={{ gridColumn: 'span 8' }}>

                    <Link to={
                      {pathname: '/dashboard/cajeros/configurarmoneymanager',
                      state: {id_cajero: cajero.id}}}
                      style={{ textDecoration: 'none', marginRight: 10 }}>
                        <Button variant="contained" color="primary">Configurar MoneyManager</Button>
                    </Link>
                  
                </Box>

                <Box sx={{ gridColumn: 'span 8' }}>
                
                    <Link to={
                      {pathname: '/dashboard/cajeros/configurarbilletero',
                      state: {id_cajero: cajero.id}}}
                      style={{ textDecoration: 'none', marginRight: 10  }}>
                        <Button variant="contained" color="primary">Configurar Billetero</Button>
                    </Link>
                 
                </Box>
                
                <Box sx={{ gridColumn: 'span 8' }}>
             
                    <Link to={
                      {pathname: '/dashboard/cajeros/configurarimpresora',
                      state: {id_cajero: cajero.id}}}
                      style={{ textDecoration: 'none', marginRight: 10  }}>
                        <Button variant="contained" color="primary">Configurar Impresora</Button>
                    </Link>

                </Box>

                <Box sx={{ gridColumn: 'span 8' }}>

                    <Link to={
                      {pathname: '/dashboard/cajeros/dispositivoscomplementos',
                      state: {id_cajero: this.idCajero}}}
                      style={{ textDecoration: 'none', marginRight: 10  }}>
                        <Button variant="contained" color="primary">Configurar Dispositivos y Complementos</Button>
                    </Link>

  
                </Box>
              </Box>
              

                <br />
                <br />
                { session.checkPermiso('opcion_desactivar_cajero') &&
                    <Button variant="contained" onClick={() => {this.cambiarEstado()}}>{cajero.estado==="A" ? "Desactivar Cajero" : "Activar Cajero"}</Button>
                }
            
          </Box>
        )
    }

}


export default CajerosEditarPage