import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import PagoAutomaticoIcon from '@mui/icons-material/Aod';
import PagoManualIcon from '@mui/icons-material/MailOutline';

function Row(props) {
  var flagWidth = 25
  const registro = props.registro
  const [open, setOpen] = React.useState(false)

  var estiloDenominaciones = {
    borderColor: '000000',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderStyle: 'solid'
  }
 return (
   <React.Fragment>
     <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
          {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
       <TableCell>
         {registro.fecha}
       </TableCell>
       <TableCell>
         {registro.cajero_alias}
       </TableCell>
       <TableCell>
         {registro.pais}
       </TableCell>
       <TableCell>
         {registro.ciudad}
       </TableCell>
       <TableCell>
        {registro.moneda}
       </TableCell>
       <TableCell>
        ${registro.valor_depositos.toLocaleString()}
       </TableCell>
       <TableCell>
        ${(registro.valor_recaudado === null) ? 0 : registro.valor_recaudado.toLocaleString()}
       </TableCell>     
       <TableCell>
        ${(registro.valor_recaudado === null) ? registro.valor_depositos : (registro.valor_depositos - registro.valor_recaudado).toLocaleString()}
       </TableCell>
     </TableRow>
     <TableRow>
        <TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className="detalle">
              <TableRow>
                <TableCell className="sub-detalle-titulo">
                  Detalle Depositos: 
                </TableCell>
                <TableCell className="sub-detalle-valor">
                  <table>
                    <tr style={estiloDenominaciones}>
                      <td style={estiloDenominaciones}>Moneda</td>
                      <td style={estiloDenominaciones}>Denominacion</td>
                      <td style={estiloDenominaciones}>Cantidad</td>
                    </tr>
                    {registro.detalle.map(itemDetalle => {
                      var iconoTipoTransaccion = null
                      var iconoMonedaBillete = ''
                      switch(itemDetalle[0]) {
                        case 'A':
                          iconoTipoTransaccion = <PagoAutomaticoIcon />
                          break
                        case 'M':
                          iconoTipoTransaccion = <PagoAutomaticoIcon />
                          break
                        default:
                          break
                      }
                      switch(itemDetalle[2]) {
                        case 'B':
                          iconoMonedaBillete = '/bill.png'
                          break
                        case 'M':
                          iconoMonedaBillete = '/coin.png'
                          break
                        default:
                          break
                      }
                      return(
                        <tr>
                          <td style={estiloDenominaciones}><img alt='x' src={iconoMonedaBillete} width={20} /><img alt='x' src={'/flags/' + itemDetalle[1].toLowerCase() + '.png'} width={flagWidth} /> {itemDetalle[1]}</td>
                          <td style={estiloDenominaciones}>${itemDetalle[3].toLocaleString()}</td>
                          <td style={estiloDenominaciones}>{itemDetalle[4]}</td>
                        </tr>
                      )
                    })}
                    
                  </table>
                </TableCell>
              </TableRow>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
   </React.Fragment>
 )

}

class CertificadoAvanceTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      registros: this.props.registros
    };
  }


  render() {
    var registros = this.props.registros

    const columns = [
      {field: "desplegable", headerName: "", width: 10},
      {field: "fecha", headerName: 'Fecha', width: 100},
      {field: "cajero_alias", headerName: 'Alias Cajero', width: 100},
      {field: "pais", headerName: 'País', width: 100},
      {field: "ciudad", headerName: 'Ciudad', width: 100},
      {field: "moneda", headerName: 'Moneda', width: 50},
      {field: "total_depositos", headerName: 'Total Depositos', width: 100},
      {field: "total_recaudado", headerName: 'Total Recaudado', width: 100},
      {field: "total_remanente", headerName: 'Total Remanente', width: 100}

    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {registros.map((registro) => {
                return(
                  <Row registro={registro} eventoVerDetalle={this.eventoVerDetalle}/>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default CertificadoAvanceTable;