import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Paper } from '@mui/material'

const session = require('../../config/session')

function ItemCategoria(props) {
	var categoria = props.categoria
	return(
		<tr>
			<th colSpan={2}>{categoria}</th>
		</tr>
	)
}

function ItemEstado(props) {
	var estado = props.estado
	return(
		<tr>
			<td><img alt='cajero state' src={'/estado_cajero/' + estado.archivo} width={20}/></td>
			<td>{estado.descripcion}</td>
		</tr>
	)
}

function ItemCajero (props) {
	var cajero = props.cajero
	var categoriasEstados = props.categoriasEstados

	
	var itemEstados = []
	
	/*categoriasEstados.map(categoria => {
		itemEstados.push(<ItemCategoria categoria={categoria.nombre} />)
		categoria.tipos.map(tipo => {
			
			//Buscar si existe un estado en el cajero para este tipo en especifico
			var itemEstado = null			
			var itemEstadoDesconocido = null
			
			tipo.estados.forEach(estado => {
				if(estado.codigo_estado === 0) {
					itemEstadoDesconocido = estado
				}
				//Buscar el estado del cajero
				cajero.estados.forEach(estadoCajero => {
					if(estado.id === estadoCajero.estado) {
						itemEstado = estado	
					}
				})
			})
			
			if(itemEstado != null) {
				itemEstados.push(<ItemEstado estado={itemEstado} />)
			} else {
				if(itemEstadoDesconocido != null) {
					itemEstados.push(<ItemEstado estado={itemEstadoDesconocido} />)	
				}
			}
			
		})
	}) */
	
	categoriasEstados.forEach(categoria => {
		itemEstados.push(<ItemCategoria categoria={categoria.nombre} />)
		categoria.tipos.forEach(tipo => {
			
			//Buscar si existe un estado en el cajero para este tipo en especifico
			var itemEstado = null			
			var itemEstadoDesconocido = null
			
			tipo.estados.forEach(estado => {
				if(estado.codigo_estado === 0) {
					itemEstadoDesconocido = estado
				}
				//Buscar el estado del cajero
				cajero.estados.forEach(estadoCajero => {
					if(estado.id === estadoCajero.estado) {
						itemEstado = estado	
					}
				})
			})
			
			if(itemEstado != null) {
				itemEstados.push(<ItemEstado estado={itemEstado} />)
			} else {
				if(itemEstadoDesconocido != null) {
					itemEstados.push(<ItemEstado estado={itemEstadoDesconocido} />)	
				}
			}
			
		})
	})
	return (
		<Paper elevation={24}
			sx= {{
				borderRadius: 10,
				display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 6
			}}
		>
			<Card item xs sx={{}}>
				<CardContent>
					<table>
						<tr>
							<td>Alias: </td>
							<td>{cajero.alias}</td>
						</tr>
						<tr>
							<td>Codigo: </td>
							<td>{cajero.codigo}</td>
						</tr>
						<tr>
							<td>Serial: </td>
							<td>{cajero.serial}</td>
						</tr>
					</table>
					<table class="TablaEstados"> 
						{
							itemEstados
						}
					</table>
				</CardContent>
				<CardActions>
					{
						session.checkPermiso('acceder_cajeros_resumen') && 
						<Link to={
							{pathname: '/dashboard/cajeros/resumen',
							state: {id_cajero: cajero.id}}}
							style={{ width: '100%', textDecoration: 'none' }}>
							<Button variant="contained" color="primary" fullWidth>Ver Cajero</Button>
						</Link>
					}
				</CardActions>
			</Card>
		</Paper>
	)
}

export default ItemCajero