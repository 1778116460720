const jwt = require('jsonwebtoken')

function startSession(session) {
    localStorage.clear()
    localStorage.setItem("isAuthenticated", "true")
    localStorage.setItem("token", session.token)
    localStorage.setItem("entidad_nombre", session.entidad_nombre)
    localStorage.setItem("entidad_tag", session.entidad_tag)
    localStorage.setItem("entidad_logo", session.entidad_logo)
    localStorage.setItem("nombres", session.nombres)
    localStorage.setItem("apellidos", session.apellidos)
    localStorage.setItem("permisos", JSON.stringify(session.permisos))
    if(session.changePassword) {
		localStorage.setItem("changePassword", true)	
	}
}

function endSession() {
    localStorage.clear()
}
 
function getPermisos() {
    var permisos = localStorage.getItem("permisos")
}

function getToken() {
    return localStorage.getItem("token")
}

function getItem(item) {
    return localStorage.getItem(item)
}

function isAuthenticated() {
    var token = localStorage.getItem("token")
    var authenticated = false

    if(!token) {
        authenticated = false
    } else {
        var decodedToken = jwt.decode(token, {complete: true});
        var dateNow = new Date();
    
        if((decodedToken.payload.exp * 1000) < dateNow.getTime()) {
            authenticated = false
            console.log("Token expiro")
            endSession()
        } else {
            authenticated = (localStorage.getItem("isAuthenticated")) === "true"
        }
    }
    
    return authenticated
}

function isAuthorized(permiso) {
    if(isAuthenticated()) {
        return checkPermiso(permiso)
    } else {
        return false
    }
}

function checkPermiso(permiso) {
    //console.log(permiso)
    var permisos = localStorage.getItem("permisos") 
    var tienePermiso = false
    if(permisos) {
        permisos = JSON.parse(permisos)
        for( var tempPermiso of permisos) {
            if(permiso === tempPermiso.identificador) {
                tienePermiso = true
                break
            }
        };
    }

    if(tienePermiso) {
        //console.log("Tiene permiso")
        return true
    } else {
        //console.log("No tiene permiso")
        return false
    }
}

export {startSession, endSession, getItem, getPermisos, getToken, isAuthenticated, isAuthorized, checkPermiso}