import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'



import CajerosGruposTable from './CajerosGruposTable'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosGruposPage extends Component {

  constructor(props) {
      super(props)

      this.state = {
          entidades: [],
          grupos: [],
          idGrupo: 0,
          txtNombre: "",
          cmbEntidades: 0,
          accion: "Agregar",
          cmbGrupos: 0,
          cajeros: [],
          cajerosDisponibles: [],
          cajerosGrupo: [],
          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
      }

    this.prepararNuevo = this.prepararNuevo.bind(this)

  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  componentDidMount() {
      this.queryGrupos()
      this.queryEntidades()
      this.queryCajeros()
  }

  actualizarCajerosDisponibles() {
    var cajeros = this.state.cajeros
    
    var cajerosDisponibles = []
    var cajerosGrupo = this.state.cajerosGrupo

    /* cajeros.map((cajero) => {
      var blnAgregar = true

      cajerosGrupo.map((cajeroGrupo) => {
        if(cajeroGrupo.id === cajero.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        cajerosDisponibles.push(cajero)
      }
    })*/

    cajeros.forEach((cajero) => {
      var blnAgregar = true

      cajerosGrupo.forEach((cajeroGrupo) => {
        if(cajeroGrupo.id === cajero.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        cajerosDisponibles.push(cajero)
      }
    })
    
    this.setState({
      cajerosDisponibles: cajerosDisponibles,
      
    })
  }

  agregarCajero(idCajero) {

    var idGrupo = this.state.cmbGrupos
    if(idGrupo === 0) {
      // alert("Debe seleccionar un grupo")
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: 'Debe seleccionar un grupo',
        severidadAlerta: 'warning'
      })
      return
    }

    var cajerosDisponibles = this.state.cajerosDisponibles
    var cajerosGrupo = this.state.cajerosGrupo

    /*cajerosDisponibles.map((cajero) => {
      if(cajero.id === idCajero) {
        cajerosGrupo.push(cajero)
      }
    })*/

    cajerosDisponibles.forEach((cajero) => {
      if(cajero.id === idCajero) {
        cajerosGrupo.push(cajero)
      }
    })

    this.setState({
      cajerosGrupo: cajerosGrupo
    })

    this.actualizarCajerosDisponibles()
  }

  removerCajero(idCajero) {
    var idGrupo = this.state.cmbGrupos
    if(idGrupo === 0) {
      //alert("Debe seleccionar un grupo")
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: 'Debe seleccionar un grupo',
        severidadAlerta: 'warning'
      })
      return
    }

    var cajerosGrupo = this.state.cajerosGrupo
    for(var x = 0; x < cajerosGrupo.length; x++) {
      if(cajerosGrupo[x].id === idCajero) {
        cajerosGrupo.splice(x, 1) 
        break
      }
    }

    this.setState({
      cajerosGrupo: cajerosGrupo
    })

    this.actualizarCajerosDisponibles()
  }

  btnSeleccionarRegistro = (id) => {
    this.queryGrupo(id)
  }

  btnCancelarClick = () => {
    this.prepararNuevo()
  }

  btnGuardarClick = () => {
      console.log('Agregando Grupo....')

      var continuar = true
      var mensajeError = "Error:\n"

      var nombre = this.state.txtNombre.trim()
      var idEntidad = this.state.cmbEntidades

      
      if(nombre === '') {
        continuar = false
        mensajeError += " * Debe ingresar el nombre.\n"
      }

      if(idEntidad === 0) {
        continuar = false
        mensajeError += " * Debe seleccionar una entidad.\n"
      }
  
      if(continuar) {
        if(this.state.accion === "Agregar") {
			var restArgs = {
		      method: 'PUT',
		      headers: 'JSON',
		      body: {
			  	nombre: nombre,
            	id_entidad: idEntidad
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/new'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		            //console.log('Error respuesta insertar grupo')
                //alert("Error creando el grupo.")
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error creando el grupo',
                  severidadAlerta: 'error'
                })
		      } else {
		            //console.log('Grupo insertado')
                // alert("Nuevo grupo creado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Nuevo grupo creado',
                  severidadAlerta: 'success'
                })
                this.prepararNuevo()
                this.queryGrupos()
		      }
		    })
			
			
        } else {
          // eslint-disable-next-line no-redeclare
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_grupo: this.state.idGrupo,
                id_entidad: idEntidad,
                nombre: nombre
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/update'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		            //console.log('Error respuesta actualizar grupo')
                //alert("Error actualizado grupo")
                this.setState({
                  mensajeError: data,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error actualizado grupo',
                  severidadAlerta: 'error'
                })
		      } else {
		            //console.log('Grupo actualizado')
                //alert("Grupo actualizado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Grupo actualizado',
                  severidadAlerta: 'success'
                })
                this.prepararNuevo()
                this.queryGrupos()
		      }
		    })
			
        }
      } else {
        //alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
      }
      
  }

  btnGuardarCajerosClick = () => {
    var idGrupo = this.state.cmbGrupos
    var nombreGrupo = ""
    var cajerosGrupos = this.state.cajerosGrupo
    var cajeros = []
    var mensajeError = "Error:\n"
    var continuar = true

  

    if(idGrupo === 0) {
      mensajeError = "* Debe seleccionar un grupo"
      continuar = false
    }
    
    
    if(continuar) {

		for(var grupo in this.state.grupos) {
			if(this.state.grupos[grupo].id === idGrupo) {
				nombreGrupo = this.state.grupos[grupo].nombre
				break
			}
		}

      /*cajerosGrupos.map((cajero) => {
          cajeros.push(cajero.id)
      })*/

      cajerosGrupos.forEach((cajero) => {
        cajeros.push(cajero.id)
      })

      
      var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
		  	 id_grupo: idGrupo,
		  	 nombre: nombreGrupo,
            cajeros: cajeros
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/grupo/cajeros'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	          //console.log('Error respuesta actualizar cajeros')
            // alert("Se produjo un error actualizando cajeros")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando cajeros',
              severidadAlerta: 'error'
            })
	      } else {
	          //console.log('Cajeros actualizados')
            //alert("Cajeros actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cajeros actualizados',
              severidadAlerta: 'success'
            })
            this.queryGrupos()
	      }
	    })
	}
  }

  prepararNuevo() {
    this.setState({
      idGrupo: 0,
      txtNombre: "",
      cmbEntidades: "",
      accion: "Agregar"
    })
  }

  queryEntidades() {
    //console.log('Consultando entidades....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando entidades")
        //console.log('Error respuesta consultando entidades')
        this.setState({
          entidades: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando entidades',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Entidades consultadas')
      	this.setState({
        	entidades: data
        })
      }
    })
    
  }

  queryGrupos() {
    console.log('Consultando grupos....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando grupos")
        // console.log('Error respuesta consultando grupos')
        this.setState({
          grupos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando grupos',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Grupos consultados')
        // console.log(data)
      	this.setState({
        	grupos: data
        })
      }
    })
}

  queryGrupo(idGrupo) {
    console.log('Consultando grupo....')
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	id_grupo: idGrupo
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/detalle'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
          //console.log('Error respuesta consultando grupo')
	      this.setState({
	        mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando grupo',
          severidadAlerta: 'error'
	      })
          //alert("Error cargando grupo")

      } else {
          console.log('Grupo consultado')
          console.log(data)
          var grupo = data
          this.setState({
			      grupo: data,
            idGrupo: grupo.id,
            txtNombre: grupo.nombre,
            cmbEntidades: grupo.id_entidad,
            accion: "Editar"
          })
      }
    })

  }

  queryCajeros() {
    console.log('Consultando cajeros....')
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando cajeros")
        // console.log('Error respuesta consultando cajeros')
        this.setState({
          cajeros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando cajeros',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
  }

  queryCajerosGrupo(idGrupo) {
    console.log('Consultando cajeros grupo....')
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	id_grupo: idGrupo
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/' + idGrupo + '/cajeros'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando cajeros grupo")
        //console.log('Error respuesta consultando cajeros grupos')
          this.setState({
			      cajerosGrupo: [],
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando cajeros grupo',
            severidadAlerta: 'error'
          })
      } else {
        console.log('Cajeros grupo consultados')
        console.log(data)
          this.setState({
            cajerosGrupo: data
            })
          this.actualizarCajerosDisponibles()
      }
    })
    

  }

  onChangetxtNombre = (event) => {
    const nombre = event.target.value.toString()
    this.setState({txtNombre: nombre})
  }

  onChangecmbEntidades = (event) => {
    const entidad = event.target.value
    this.setState({cmbEntidades: entidad})
  }

  onChangecmbGrupos = (event) => {
    const grupo = event.target.value
    this.setState({cmbGrupos: grupo})

    if(grupo === '') {
      this.setState({
        cajerosGrupo: [],
        cajerosDisponibles: []
      })

      this.actualizarCajerosDisponibles()
    } else {
      this.queryCajerosGrupo(grupo)
    }
    
  }

  render() {
      return(
        <Box>
          <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
            Grupos
          </Typography>

          <Paper elevation={16}>
            <Card>
                  <CardContent>
                    <form>
                        <FormLabel>Información Grupo:</FormLabel>
                        <Grid container xs={10} spacing={2} rowSpacing={2}>
                          <Grid item xs={4}>
                            <FormControl fullWidth component="fieldset">
                              <TextField
                              label="Nombre"
                              id="outlined-basic"
                              variant="outlined"
                              value={this.state.txtNombre}
                              onChange={this.onChangetxtNombre}
                              fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl fullWidth component="fieldset">
                            <InputLabel>Entidad</InputLabel>
                            <Select
                                label="Entidad"
                                value={this.state.cmbEntidades}
                                defaultValue={0}
                                onChange={this.onChangecmbEntidades}>
                                <MenuItem value={0}>-- Seleccione Entidad --</MenuItem>
                                {this.state.entidades.map(item => (
                                <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                    </form>
                  </CardContent>
                  <CardActions>
                          <Button  variant="contained" onClick={this.btnGuardarClick}>{this.state.accion === 'Agregar' ? "Agregar Grupo" : "Editar Grupo"}</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 


                          <Button  variant="contained" onClick={this.btnCancelarClick}>Cancelar</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                      </CardActions>
            </Card>
          </Paper>
              <br />
          
          <Paper elevation={16}>
            <Card>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Listado Grupos:
                  </Typography>
                  <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <CajerosGruposTable grupos={this.state.grupos} eventoSeleccionar={this.btnSeleccionarRegistro} />
                  </Box>
                </CardContent>
                <CardActions>

                </CardActions>
            </Card>
          </Paper>

              <br />
          <Paper elevation={16}>
            <Grid container xs={12}>
                   <Grid item xs={6}>
                      <Card>
                        <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                          Asignar Cajeros
                        </Typography>
                        <br />
                        
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                            <Grid item xs={12}>
                              <FormControl fullWidth component="fieldset">
                                <InputLabel>Grupo</InputLabel>
                                <Select
                                    label="Grupo"
                                    value={this.state.cmbGrupos}
                                    defaultValue={0}
                                    onChange={this.onChangecmbGrupos}>
                                    <MenuItem value={0}>-- Seleccione Grupo --</MenuItem>
                                    {this.state.grupos.map(item => (
                                      <MenuItem value={item.id}>{item.nombre + ' - ('  + item.entidad_nombre + ')'}</MenuItem>
                                    ))}
                                </Select>
                                <br />

                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                              <FormLabel>Cajeros Disponibles</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.cajerosDisponibles.map((cajero) => {
                                      return(
                                        <ListItem button
                                          key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero.id)}}>
                                            {cajero.alias}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Cajeros Grupo</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.cajerosGrupo.map((cajero) => {
                                      return(
                                        <ListItem button
                                          key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerCajero(cajero.id)}}>
                                          {cajero.alias}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" fullWidth onClick={this.btnGuardarCajerosClick}>Guardar Cajeros Grupo</Button>
                          
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                        </CardActions>
                      </Card>
                    </Grid>
            </Grid>

          </Paper>
          
        </Box>
      )
  }

}


export default CajerosGruposPage