import React, {Component} from 'react'
import {Link, withRouter} from 'react-router-dom'
import { styled, useTheme} from '@mui/material/styles';
import { withStyles} from '@mui/styles'

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import TransaccionesIcon from '@mui/icons-material/MonetizationOn';
import DepositoIcon from '@mui/icons-material/MoveToInbox';
import RetiroIcon from '@mui/icons-material/Outbox';
import CajeroIcon from '@mui/icons-material/PointOfSale';
import CajeroGruposIcon from '@mui/icons-material/GroupWork';
import CajeroAgregarIcon from '@mui/icons-material/AddToQueue';
import EventosCajerosIcon from '@mui/icons-material/ListAlt';
import EstadoCajerosIcon from '@mui/icons-material/FactCheck';
import UsuariosListaIcon from '@mui/icons-material/PeopleAlt';
import UsuariosAgregarIcon from '@mui/icons-material/PersonAdd';
import FuncionariosAdministrarIcon from '@mui/icons-material/Engineering';
import RolesIcon from '@mui/icons-material/SafetyDivider';
import AdministrarEntidadesIcon from '@mui/icons-material/CorporateFare';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MonitoreoIcon from '@mui/icons-material/Dvr';
import ReportesIcon from '@mui/icons-material/Print';
import AdministracionIcon from '@mui/icons-material/Settings';
import BilleteraAdministrarIcon from '@mui/icons-material/AccountBalanceWallet';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ParametrosIcon from '@mui/icons-material/Build';
import LogIcon from '@mui/icons-material/Summarize';
import MiCuentaIcon from '@mui/icons-material/ManageAccounts';
import OpcionesCuentaIcon from '@mui/icons-material/Badge';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { withTranslation } from 'react-i18next';

import '../index.css';
const session = require('../config/session')

const drawerWidth = 260;


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const styles = theme =>({
  root: {
      maxWidth: 345,
  },
})


class MainLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openDrawer: true,
      openMenuReportes: false,
      openMenuTransacciones: false,
      openMenuCajeros: false,
      openMenuMonitoreo: false,
      openMenuUsuarios: false,
      openMenuFuncionarios: false,
      openMenuClientes: false,
      openMenuBilletera: false,
      openMenuParametros: false,
      openMenuMiCuenta: false
    }

    this.theme = null;

  }

  handleDrawerOpen = () => {
    this.setState({
      openDrawer: true
    })
  };
  
  handleDrawerClose = () => {
    this.setState({
      openDrawer: false
    })
  };

  handleClickMenuReportes = () => {
    this.setState({
      openMenuReportes: !this.state.openMenuReportes
    })
  };

  handleClickMenuTransacciones = () => {
    this.setState({
      openMenuTransacciones: !this.state.openMenuTransacciones
    })
  };
  
  handleClickMenuCajeros = () => {
    this.setState({
      openMenuCajeros: !this.state.openMenuCajeros
    })
  };
  
  handleClickMenuMonitoreo = () => {
    this.setState({
      openMenuMonitoreo: !this.state.openMenuMonitoreo
    })
  };
  
  handleClickMenuUsuarios = () => {
    this.setState({
      openMenuUsuarios: !this.state.openMenuUsuarios
    })
  };

  handleClickMenuFuncionarios = () => {
    this.setState({
      openMenuFuncionarios: !this.state.openMenuFuncionarios
    })
  };

  handleClickMenuClientes = () => {
    this.setState({
      openMenuClientes: !this.state.openMenuClientes
    })
  };
  
  handleClickMenuBilletera = () => {
    this.setState({
      openMenuBilletera: !this.state.openMenuBilletera
    })
  };

  handleClickMenuParametros = () => {
    this.setState({
      openMenuParametros: !this.state.openMenuParametros
    })
  };

  handleClickMenuMiCuenta = () => {
    this.setState({
      openMenuMiCuenta: !this.state.openMenuMiCuenta
    })
  };

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    const {t} = this.props
    const currentPath = this.props.location.pathname

    return(
      <Box sx={{ display: 'flex'}}>
        <CssBaseline />
          <AppBar position="fixed" open={this.state.openDrawer} className="AppBar">
            <Toolbar className={'MainDrawerToolbar'}>
              <IconButton
                //color="black" //*! si mañana no sale negro descomentar 
                aria-label="open drawer"
                onClick={this.handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: '0px',
                  ...(this.state.openDrawer && { display: 'none' })
                }}
              >
          <MenuIcon />
              </IconButton>
              <Grid container xs={12}>
                <Grid item xs={10} justifyContent="flex-end">
                  <div style={{textAlign: "end", margin: 0, padding: 0}}><span className={"header-campo-nombre"}>Entidad: </span><span className={"header-campo-valor"}>{session.getItem('entidad_nombre')}</span></div>
                  <div style={{textAlign: "end", margin: 0, padding: 0}}><span className={"header-campo-nombre"}>Usuario: </span><span className={"header-campo-valor"}>{session.getItem('nombres') + ' ' + session.getItem('apellidos')}</span></div>
                </Grid>
              </Grid>
              
            </Toolbar>
          </AppBar>

        <Drawer variant="permanent" open={this.state.openDrawer} className="MainDrawer" classes={{paper: "MainDrawerPaper"}} PaperProps={{elevation: 9}}>
          <DrawerHeader className={"MainDrawerHeader"}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img alt='entidadLogo' src={'/entidades/logo_selfia_web.png'} width={60} marginRight= "0px"/>
                 
                {/* <img alt='entidadLogo' src={'/entidades/' + session.getItem('entidad_logo')} width={60} marginRight= "0px"/>  */ }
              </Box>
            <IconButton onClick={this.handleDrawerClose}>
            <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />

          <MenuList sx={{ border: 'none', backgroundColor: '#d9d9d9ff', paddingTop: 0, paddingBottom: 0}}>
            <MenuItem selected={currentPath === '/dashboard'} component={Link} to="/dashboard" key="home" className="MainMenuItem">
              <Tooltip title={t('main_menu_home')}>
                <ListItemIcon className='MainMenuItemIcon' justifyContent="center" >
                  <HomeIcon />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={t('main_menu_home')} />
            </MenuItem>
          </MenuList>
          {
            session.checkPermiso('acceder_conciliacion') && 
            <MenuList dense>
              <MenuItem dense selected={currentPath === "/dashboard/conciliacion"} component={Link} to="/dashboard/conciliacion" key="conciliacion" className="MainMenuItem">
                <Tooltip title="Conciliación">
                  <ListItemIcon className='MainMenuItemIcon'>
                    <HomeIcon/>
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Conciliación" />
              </MenuItem>
            </MenuList>
          }

          <MenuList sx={{borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, margin: 0, display: (session.checkPermiso('acceder_reporte_totales_dia') || session.checkPermiso('acceder_reporte_totales_recaudo') || session.checkPermiso('acceder_reporte_cajeros_saldo') || session.checkPermiso('acceder_reporte_totales_cuadre') || session.checkPermiso('acceder_reporte_certificado_avance')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuReportes} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <ReportesIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_reportes')} />
              {this.state.openMenuReportes ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuReportes} timeout="auto" unmountOnExit>
                <MenuList sx={{borderBottom:'0px solid #000000ff'}}component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_reporte_totales_dia') && 
                    <MenuItem selected={currentPath === "/dashboard/totalesdia"} component={Link} sx={{border:'none', pl: 4}} to="/dashboard/totalesdia" key="totalesdia" className="MainMenuItem">
                      <Tooltip title="Totales Día">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ReportesIcon/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Totales Día" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_reporte_totales_recaudo') && 
                    <MenuItem selected={currentPath === "/dashboard/totalesrecaudo"} component={Link} sx={{border:'none', pl: 4}} to="/dashboard/totalesrecaudo" key="totalesrecaudo" className="MainMenuItem">
                      <Tooltip title="Totales Recaudo">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ReportesIcon/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Totales Recaudo" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_reporte_cajeros_saldo') && 
                    <MenuItem selected={currentPath === "/dashboard/cajerossaldo"} component={Link} sx={{borderBottom:'0px solid #000000ff', pl: 4}} to="/dashboard/cajerossaldo" key="cajerosaldo" className="MainMenuItem">
                      <Tooltip title="Saldo Cajeros">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ReportesIcon/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Saldo Cajeros" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_reporte_totales_cuadre') && 
                    <MenuItem selected={currentPath === "/dashboard/totalescuadre"} component={Link} sx={{borderBottom:'0px solid #000000ff', pl: 4}} to="/dashboard/totalescuadre" key="totalescuadre" className="MainMenuItem">
                      <Tooltip title="Totales Cuadre">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ReportesIcon/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Totales Cuadre" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_reporte_certificado_avance') && 
                    <MenuItem selected={currentPath === "/dashboard/certificadoavance"} component={Link} sx={{borderBottom:'0px solid #000000ff',pl: 4}} to="/dashboard/certificadoavance" key="certificadoavance" className="MainMenuItem">
                      <Tooltip title="Certificado Avance">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ReportesIcon/>
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Certificado Avance" />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>

          <MenuList sx={{borderTop:'1px solid #000000ff',paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_depositos') || session.checkPermiso('acceder_retiros')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuTransacciones} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <TransaccionesIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_transacciones')} />
              {this.state.openMenuTransacciones ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuTransacciones} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_depositos') && 
                    <MenuItem selected={currentPath === "/dashboard/depositos"} component={Link} sx={{pl: 4}} to="/dashboard/depositos" key="depositos" className="MainMenuItem">
                      <Tooltip title="Depositos">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <DepositoIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Depositos" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_retiros') && 
                    <MenuItem selected={currentPath === "/dashboard/retiros"} component={Link} sx={{pl: 4}} to="/dashboard/retiros" key="retiros" className="MainMenuItem">
                      <Tooltip title="Retiros">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <RetiroIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Retiros" />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>

          <MenuList sx={{borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_eventos') || session.checkPermiso('acceder_cajeros_estados')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuMonitoreo} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <MonitoreoIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_monitoreo')} />
              {this.state.openMenuMonitoreo ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuMonitoreo} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_eventos') && 
                    <MenuItem selected={currentPath === "/dashboard/eventos"} component={Link} sx={{pl: 4}} to="/dashboard/eventos" key="eventos" className="MainMenuItem">
                      <Tooltip title="Eventos">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <EventosCajerosIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Eventos" />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_cajeros_estados') && 
                    <MenuItem selected={currentPath === "/dashboard/cajerosestados"} component={Link} sx={{pl: 4}} to="/dashboard/cajerosestados" key="cajerosestados" className="MainMenuItem">
                      <Tooltip title="Estados Cajeros">
                        <ListItemIcon className='MainMenuItemIcon'>
                          <EstadoCajerosIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary="Estados Cajeros" />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>

          <MenuList sx={{ borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_cajeros') || session.checkPermiso('acceder_cajeros_crear') || session.checkPermiso('acceder_cajeros_grupos')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuCajeros} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <CajeroIcon />
              </ListItemIcon>
              <ListItemText primary= {t('main_menu_administrar_cajeros')} />
              {this.state.openMenuCajeros ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuCajeros} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_cajeros') && 
                    <MenuItem selected={currentPath === '/dashboard/cajeros'} component={Link} sx={{pl: 4}} to="/dashboard/cajeros" key="cajeros" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_cajeros_lista')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <CajeroIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_cajeros_lista')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_cajeros_crear') && 
                      <MenuItem selected={currentPath === '/dashboard/cajeros/crear'} component={Link} sx={{pl: 4}} to="/dashboard/cajeros/crear" key="cajeros_crear" className="MainMenuItem">
                        <Tooltip title={t('main_menu_administrar_cajeros_crear')}>
                          <ListItemIcon className='MainMenuItemIcon'>
                            <CajeroAgregarIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t('main_menu_administrar_cajeros_crear')} />
                      </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_cajeros_grupos') && 
                      <MenuItem selected={currentPath === '/dashboard/cajeros/grupos'} component={Link} sx={{pl: 4}} to="/dashboard/cajeros/grupos" key="cajeros_grupos" className="MainMenuItem">
                        <Tooltip title={t('main_menu_administrar_cajeros_grupos')}>
                          <ListItemIcon className='MainMenuItemIcon'>
                            <CajeroGruposIcon />
                          </ListItemIcon>
                        </Tooltip>
                        <ListItemText primary={t('main_menu_administrar_cajeros_grupos')} />
                      </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>
          
          <MenuList sx={{ borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_usuarios') || session.checkPermiso('acceder_usuarios_crear')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuUsuarios} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_administrar_usuarios')} />
              {this.state.openMenuUsuarios ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuUsuarios} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_usuarios') && 
                    <MenuItem selected={currentPath === '/dashboard/usuarios'} component={Link} sx={{pl: 4}} to="/dashboard/usuarios" key="usuarios" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_usuarios_lista')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosListaIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_usuarios_lista')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_usuarios_crear') && 
                    <MenuItem selected={currentPath === '/dashboard/usuarios/crear'} component={Link} sx={{pl: 4}} to="/dashboard/usuarios/crear" key="usuarioscrear" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_usuarios_crear')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosAgregarIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_usuarios_crear')} />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>
          
          <MenuList sx={{ borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_funcionarios') || session.checkPermiso('acceder_funcionarios_crear')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuFuncionarios} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <FuncionariosAdministrarIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_administrar_funcionarios')} />
              {this.state.openMenuFuncionarios ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuFuncionarios} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_funcionarios') && 
                    <MenuItem selected={currentPath === '/dashboard/funcionarios'} component={Link} sx={{pl: 4}} to="/dashboard/funcionarios" key="funcionarios" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_funcionarios_lista')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosListaIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_funcionarios_lista')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_funcionarios_crear') && 
                    <MenuItem selected={currentPath === '/dashboard/funcionarios/crear'} component={Link} sx={{pl: 4}} to="/dashboard/funcionarios/crear" key="funcionarioscrear" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_funcionarios_crear')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosAgregarIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_funcionarios_crear')} />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>

          <MenuList sx={{borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_clientes') || session.checkPermiso('acceder_clientes_crear')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuClientes} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <FuncionariosAdministrarIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_administrar_clientes')} />
              {this.state.openMenuClientes ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuClientes} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_clientes') && 
                    <MenuItem selected={currentPath === '/dashboard/clientes'} component={Link} sx={{pl: 4}} to="/dashboard/clientes" key="clientes" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_clientes_lista')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosListaIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_clientes_lista')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_clientes_crear') && 
                    <MenuItem selected={currentPath === '/dashboard/clientes/crear'} component={Link} sx={{pl: 4}} to="/dashboard/clientes/crear" key="clientescrear" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_clientes_crear')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosAgregarIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_clientes_crear')} />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>
          
          <MenuList sx={{borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_billetera_clientes') || session.checkPermiso('acceder_billetera_clientes_crear')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuBilletera} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <BilleteraAdministrarIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_administrar_billetera')} />
              {this.state.openMenuBilletera ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuBilletera} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_billetera_clientes') && 
                    <MenuItem selected={currentPath === '/dashboard/billetera/clientes'} component={Link} sx={{pl: 4}} to="/dashboard/billetera/clientes" key="billetera_clientes" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_billetera_lista')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosListaIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_billetera_lista')} />
                    </MenuItem>
                  }
                </MenuList>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_billetera_clientes_crear') && 
                    <MenuItem selected={currentPath === '/dashboard/billetera/clientes/crear'} component={Link} sx={{pl: 4}} to="/dashboard/billetera/clientes/crear" key="billetera_clientes_crear" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administrar_billetera_crear')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <UsuariosListaIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administrar_billetera_crear')} />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>

          <MenuList sx={{borderTop:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0, display: (session.checkPermiso('acceder_entidades') ||  session.checkPermiso('acceder_permisos_rol') ||  session.checkPermiso('acceder_log')) ? 'block' : 'none'}}>
            <ListItemButton onClick={this.handleClickMenuParametros} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <AdministracionIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_administracion')} />
              {this.state.openMenuParametros ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuParametros} timeout="auto" unmountOnExit>
                <MenuList component="div" disablePadding>
                  {
                    session.checkPermiso('acceder_entidades') && 
                    <MenuItem selected={currentPath === '/dashboard/entidades'} component={Link} sx={{pl: 4}} to="/dashboard/entidades" key="entidades" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administracion_administrar_entidades')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <AdministrarEntidadesIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administracion_administrar_entidades')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_permisos_rol') && 
                    <MenuItem selected={currentPath === '/dashboard/permisos'} component={Link} sx={{pl: 4}} to="/dashboard/permisos" key="roles" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administracion_administrar_roles')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <RolesIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administracion_administrar_roles')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_parametros') && 
                    <MenuItem selected={currentPath === '/dashboard/parametros'} component={Link} sx={{pl: 4}} to="/dashboard/parametros" key="parametros" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administracion_parametros')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <ParametrosIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administracion_parametros')} />
                    </MenuItem>
                  }
                  {
                    session.checkPermiso('acceder_log') && 
                    <MenuItem selected={currentPath === '/dashboard/log'} component={Link} sx={{pl: 4}} to="/dashboard/log" key="roles" className="MainMenuItem">
                      <Tooltip title={t('main_menu_administracion_logs')}>
                        <ListItemIcon className='MainMenuItemIcon'>
                          <LogIcon />
                        </ListItemIcon>
                      </Tooltip>
                      <ListItemText primary={t('main_menu_administracion_logs')} />
                    </MenuItem>
                  }
                </MenuList>
            </Collapse>
          </MenuList>
          
          <MenuList sx={{borderTop:'1px solid #000000ff', borderBottom:'1px solid #000000ff', paddingTop: 0, paddingBottom: 0}}>
            <ListItemButton onClick={this.handleClickMenuMiCuenta} className="MainMenu">
              <ListItemIcon className='MainMenuItemIcon'>
                <MiCuentaIcon />
              </ListItemIcon>
              <ListItemText primary={t('main_menu_micuenta')} />
              {this.state.openMenuMiCuenta ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={this.state.openMenuMiCuenta} timeout="auto" unmountOnExit>
              <MenuList  component="div" disablePadding>
                {
                  session.checkPermiso('acceder_opciones_cuenta') && 
                  <MenuItem selected={currentPath === '/dashboard/opcionescuenta'} component={Link} sx={{pl: 4}} to="/dashboard/opcionescuenta" key="opcionescuenta" className="MainMenuItem">
                    <Tooltip title={t('main_menu_micuenta_opciones')}>
                      <ListItemIcon className='MainMenuItemIcon'>
                        <OpcionesCuentaIcon />
                      </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary={t('main_menu_micuenta_opciones')} />
                  </MenuItem>
                }
                <MenuItem selected={currentPath === '/logout'} component={Link} sx={{pl: 4}} to={"/logout/" + session.getItem('entidad_tag')} key="logout" className="MainMenuItem">
                  <Tooltip title="Logout">
                    <ListItemIcon className='MainMenuItemIcon'>
                      <LogoutIcon />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary="Logout" />
                </MenuItem>
              </MenuList> 
            </Collapse>
          </MenuList>

          <Typography gutterBottom variant="p" component="div" sx={{ marginTop: 2, marginLeft: 4 }}>
            v 1.0.0
          </Typography>  

        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          {this.props.children}
        </Box>
      </Box>
    )
  }
}

export default (withStyles(styles), withRouter)(withTranslation()(MainLayout))