import react, {Component} from 'react'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { withTranslation } from 'react-i18next';
import { Box } from '@mui/material'


const session = require('../../config/session')


class LogoutPage extends Component {

    constructor(props) {
        super(props)

        if(props.match && props.match.params.tag) {
          this.tagEntidad = props.match.params.tag
        }
        session.endSession()
    }

    componentDidMount() {
        session.endSession()
    }

    render() {
        const {t} = this.props
        return(
            <Container>
                <Card>
                    <CardContent sx={{textAlign: 'center'}}>
                        <h1>{t('window_logout_lbl_agradecimiento')}</h1>
                        <img alt='logo' item src={"/logo_main.png"} width={100}/>
                        <br />
                        {t('window_logout_lbl_sesion_cerrada')}
                    </CardContent>
                    <CardActions >
                        <Box
                        sx={{
                            width: '100vp',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        >
                            <Button variant="contained" href={"/entidad/" + this.tagEntidad} sx={{textAlign: 'center'}}>{t('window_logout_btn_login')}</Button>

                        </Box>
                    </CardActions>
                </Card>
            </Container> 
        )
    }

}

export default withTranslation()(LogoutPage)