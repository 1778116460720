import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import KeyIcon from '@mui/icons-material/VpnKey';
import EstadoOperativoIcon from '@mui/icons-material/Handyman';
import ProgramarAccionIcon from '@mui/icons-material/AddAlarm';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

var intervaloCheckLoginAperturaState = 10000

class CajerosAccionesRemotas extends Component {

  constructor(props) {
      super(props)

      this.idCajero = props.location.state.id_cajero

      this.state = {
          cajero: [], 
          accionesRemotas: [],
          btnAutorizarLoginDisabled: true,  
          btnAutorizarLoginText: "",
          btnAutorizarAperturaDisabled: true,
          btnAutorizarAperturaText: "",
          token: '',
          estadosOperativos: [
            {
              nombre: 'En Operación',
              valor: 'operacion'
            },
            {
              nombre: 'En Mantenimiento',
              valor: 'mantenimiento'
            }
          ],
          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''

      }

      this.cmbEstadosOperativos = React.createRef()
      this.cmbAcciones = React.createRef()
      this.txtParametrosAccion = React.createRef()

      this.cambiarEstadoOperativo = this.cambiarEstadoOperativo.bind(this)
      this.checkLoginAperturaState = this.checkLoginAperturaState.bind(this)
      this.queryAccionesRemotas = this.queryAccionesRemotas.bind(this)
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  componentDidMount() {
      this.queryCajero()
      this.queryAccionesRemotas()

      if(session.checkPermiso('opcion_activar_apertura_cajero')) {
		  this.checkLoginAperturaState()
          this.timerRefrescarLoginApertura = setInterval(this.checkLoginAperturaState, intervaloCheckLoginAperturaState)
      }
  }

  componentWillUnmount() {
      clearInterval(this.timerRefrescarLoginApertura)
  }

  buttonsState() {
      if(this.state.cajero.estado === "I") {
          this.setState({
            btnAutorizarLoginDisabled: true,
            btnAutorizarAperturaDisabled: true,
            //btnAutorizarLoginText: "Desactivar Login",
          })

      } else {

        if(this.state.cajero.login_autorizado === 0) {
          this.setState({
            btnAutorizarLoginDisabled: false,
            btnAutorizarLoginText: "Autorizar Login",
          })
        } else {
          this.setState({
            btnAutorizarLoginDisabled: false,
            btnAutorizarLoginText: "Desactivar Login",
          })
        }
  
        if(this.state.cajero.login_autorizado === 1 && this.state.cajero.apertura_autorizada === 1) {
          this.setState({
            btnAutorizarAperturaDisabled: false,
            btnAutorizarAperturaText: "Autorizar Apertura",
          })
        } else if(this.state.cajero.apertura_autorizada === 2) {
          this.setState({
            btnAutorizarLoginDisabled: true,
            btnAutorizarAperturaDisabled: false,
            btnAutorizarAperturaText: "Cancelar Apertura",
          })
        } else {
          this.setState({
            btnAutorizarAperturaDisabled: true,
            btnAutorizarAperturaText: "Autorizar Apertura",
          })
        }
      }
  }
  
queryCajero() {
	console.log('Consultando cajero....')
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajero")
	        //console.log('Error respuesta consultando cajero')
	        this.setState({
            cajero: {},
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando cajero',
            severidadAlerta: 'error'
	        })
	      } else {
	        console.log('Cajero consultado')
          //!----------------          
          this.setState({
            cajero: data,
            mostrarAlerta: true,
            mensajeAlerta: 'Cajero consultado',
            severidadAlerta: 'success'
          })
            //!----------------
	      }
	    })
}

queryAccionesRemotas() {
	console.log('Consultando acciones remotas....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		  id_cajero: this.idCajero
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajeroaccionesremotas'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando acciones remotas")
        //console.log('Error respuesta consultando acciones remotas')
        this.setState({
          accionesRemotas: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando acciones remotas',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Acciones remotas consultadas')
      	this.setState({
        	accionesRemotas: data,
          mostrarAlerta: true,
          mensajeAlerta: 'Acciones remotas consultadas',
          severidadAlerta: 'success'
        })
      }
    })
	
}

  cambiarEstadoOperativo() {
    //console.log('Cambiar Estado Operativo')

    var estadoOperativo = ""

    estadoOperativo = this.cmbEstadosOperativos.current.value
    
    var restArgs = {
      method: 'PATCH',
      headers: 'JSON',
      body: {
		id_cajero: this.idCajero,
        estado_operativo: estadoOperativo
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/estadooperativo'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error actualizando estado operativo")
        //console.log('Error actualizando estado operativo')
        this.setState({
			    mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error actualizando estado operativo',
          severidadAlerta: 'error'
        })
      } else {
        //alert("Estado operativo actualizado")
        this.queryCajero()
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: 'Estado operativo actualizado',
          severidadAlerta: 'success'
        })
      }
    })
    
  }

  autorizarLoginApertura(accion) {
      console.log('Autorizar login/apertura....')

      var valor = 0

      this.setState({
        btnAutorizarLoginDisabled: true,
        btnAutorizarAperturaDisabled: true
      })

      switch (accion) {
        case 'login':
          if(this.state.cajero.login_autorizado === 0) {
            valor = 1
          } else {
            valor = 0
          }
          break;
        case 'apertura':
          if(this.state.cajero.apertura_autorizada === 1) {
            valor = 2
          } else {
            valor = 0
          }
          break;
        default:
      }
      
      
      var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
          id_cajero: this.idCajero,
            accion: accion,
            valor: valor
        },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/authorize'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error respuesta Login/Apertura")
	        //console.log('Error respuesta Login/Apertura')
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Error respuesta Login/Apertura',
            severidadAlerta: 'error'
          })
	      } else {
          this.setState(prevState => ({
            cajero: {
              ...prevState.cajero,
              login_autorizado: data.login_autorizado,
              apertura_autorizada: data.apertura_autorizada
            },
            mostrarAlerta: true,
            mensajeAlerta: 'Login/Apertura actualizados',
            severidadAlerta: 'success'
          }), () => {
            this.buttonsState()
          })

          //?Cambio
            //console.log('Login/Apertura actualizados')
            /*this.setState({

              //login_autorizado: data.login_autorizado,
              //apertura_autorizada: data.apertura_autorizada,
              mostrarAlerta: true,
              mensajeAlerta: 'Login/Apertura actualizados',
              severidadAlerta: 'success'
            })
        	this.buttonsState()*/

	      }
	    })

  }


  checkLoginAperturaState() {
      //console.log('Autorizar login/apertura....')
    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/authorize/state'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando estado login/apertura")
	        //console.log('Error consultando estado login/apertura')
	        this.setState({
				    mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando estado login/apertura',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Estado login/apertura consultados')
          //?Cambio

          this.setState(prevState => ({
            cajero: {
              ...prevState.cajero,
              login_autorizado: data.login_autorizado,
              apertura_autorizada: data.apertura_autorizada
            }
          }), () => {
            this.buttonsState()
          })


          /*this.setState({
            login_autorizado: data.login_autorizado,
            apertura_autorizada: data.apertura_autorizada,

          })
	        this.buttonsState()*/

          /* this.state.cajero.login_autorizado = data.login_autorizado
	        this.state.cajero.apertura_autorizada = data.apertura_autorizada
	        this.buttonsState() */
	      }
	    })
      
  }

  getTokenContingencia(accion) {
      console.log('Solicitando token contigencia')
      
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			 id_cajero: this.idCajero,
         	 accion: accion
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/authorize/tokencontingency',
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando token")
	        //console.log('Error respuesta consultando token')
	        this.setState({
            token: "",
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando token',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Token consultado')
	        //console.log(data)
	      	this.setState({
	        	token: data.token +  ' - ' + data.accion //+ ' ' + data.data.expiration_time
	        })
	      }
	    })
      
  }

  agregarAccion = () => {

      var accion = ""
      var parametros = ""

      if(this.cmbAcciones.current.value !== 0){
        accion = this.cmbAcciones.current.value
      } else {
        //alert("Debe seleccionar una accion")
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: 'Debe seleccionar una accion',
          severidadAlerta: 'error'
        })
        return
      }

      parametros = this.txtParametrosAccion.current.value
      
      var restArgs = {
	      method: 'PUT',
	      headers: 'JSON',
	      body: {
			 id_cajero: this.idCajero,
	          accion: accion,
	          parametros: parametros
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeroaccionesremotas/new'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error creando accion remota")
	        //console.log('Error creando accion remota')
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Error creando accion remota',
            severidadAlerta: 'error'
          })
	      } else {
            this.queryAccionesRemotas()
        	//alert("Accion remota creada")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Accion remota creada',
            severidadAlerta: 'success'
          })
        	this.buttonsState()
	      }
	    })

  }

  borrarAccion = (idAccion) => {
		var restArgs = {
	      method: 'DELETE',
	      headers: 'JSON',
	      body: {
			 id_accion: idAccion
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeroaccionesremotas/delete'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error borrando accion remota")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Error borrando accion remota',
            severidadAlerta: 'error'
          })
	      } else {
            this.queryAccionesRemotas()
            //alert("Accion remota borrada")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Accion remota borrada',
              severidadAlerta: 'error'
            })
	      }
	    })
		
  }

  render() {
        var cajero = this.state.cajero

        var estadosOperativos = [
          {
            nombre: 'En Operación',
            valor: 'operacion'
          },
          {
            nombre: 'En Mantenimiento',
            valor: 'mantenimiento'
          }
        ]

        var remoteActions = [
          {
            accion: 'actualizar_entidades',
            detalle: 'Actualizar Entidades'
          },
          {
            accion: 'actualizar_funcionarios',
            detalle: 'Actualizar Funcionarios'
          },
          {
            accion: 'actualizar_clientes',
            detalle: 'Actualizar Clientes'
          },
          {
            accion: 'reboot',
            detalle: 'Reiniciar cajero'
          },
          {
            accion: 'shutdown',
            detalle: 'Apagar cajero'
          },
          {
            accion: 'launch_anydesk',
            detalle: 'Lanzar AnyDesk'
          },
          {
            accion: 'reboot_device',
            detalle: 'Reiniciar Periférico'
          },
          {
            accion: 'escribir',
            detalle: 'Escribir'
          },
          {
            accion: 'leer',
            detalle: 'Leer'
          }
        ]

        if(session.checkPermiso('opcion_cajero_apertura_mantenimiento')) {
          remoteActions.push(
            {
              accion: 'apertura_mantenimiento',
              detalle: 'Apertura Mantenimiento'
            }
          )
        }

        return (
          <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                  Acciones Remotas
                </Typography>

                <Paper elevation={16}>
                  <Grid xs={4}>
                      <Card fullWidth>
                      <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                              Datos cajero:
                          </Typography>
                          <Divider />
                          <div>Codigo: {cajero.codigo}</div>
                          <div>Serial: {cajero.serial}</div>
                          <div>Alias: {cajero.alias}</div>
                          <div>Estado Operativo: {cajero.estado_operativo}</div>
                          <div>Dirección: {cajero.direccion}</div>
                      </CardContent>
                      </Card>
                  </Grid>
                </Paper>
                <br />
                { session.checkPermiso('opcion_cajero_cambiar_estadooperativo') &&
                  <Paper elevation={16}>
                    <Grid xs={4}>
                        <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                            Estado Operativo:
                            </Typography>
                            <Divider style={{"margin-top": 10, "margin-bottom": 10}} />
                            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                                  <InputLabel>Estado</InputLabel>
                                  <Select
                                    label="Estado"
                                    value={cajero.estado_operativo}
                                    defaultValue={""}
                                    inputRef={this.cmbEstadosOperativos}
                                    >
                                    <MenuItem value={""}>-- Seleccione estado --</MenuItem>
                                    {
                                      this.state.estadosOperativos.map((estado) => {
                                        return(
                                          <MenuItem value={estado.valor}>{estado.nombre}</MenuItem>      
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" color="primary" startIcon={<EstadoOperativoIcon />} onClick={()=>{this.cambiarEstadoOperativo()}} sx={{marginBottom:1, marginLeft: 2 }}>Cambiar Estado</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>  
                        </CardActions>
                        </Card>
                    </Grid>
                  </Paper>
                }
                <br />
                { session.checkPermiso('opcion_activar_apertura_cajero') &&
                  <Paper elevation={16}>
                    <Grid xs={4}>
                        <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                            Apertura puerta:
                            </Typography>
                            <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

                        </CardContent>
                        <CardActions>

                            <Button type="submit" variant="contained" color="primary" startIcon={<KeyboardIcon />} disabled={this.state.btnAutorizarLoginDisabled} onClick={()=>{this.autorizarLoginApertura('login')}}>{this.state.btnAutorizarLoginText}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 

                            <Button type="submit" variant="contained" color="primary" startIcon={<MeetingRoomIcon />} disabled={this.state.btnAutorizarAperturaDisabled} onClick={()=>{this.autorizarLoginApertura('apertura')}}>{this.state.btnAutorizarAperturaText}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 

                        </CardActions>
                        </Card>
                    </Grid>
                  </Paper>
                }
                <br />
                { session.checkPermiso('opcion_activar_apertura_cajero_contingencia') &&

                  <Paper elevation={16}>
                    <Grid xs={4}>
                        <Card>
                          <CardContent>
                              <Typography gutterBottom variant="h6" component="div">
                              Apertura puerta contingencia:
                              </Typography>
                              <Divider style={{"margin-top": 10, "margin-bottom": 10}} />
                              <TextField
                                inputProps={
                                  {readOnly: true}
                                }
                                value={this.state.token}
                                fullWidth/>
                          </CardContent>
                          <CardActions sx={{justifyContent: 'center'}}>
                              <Button variant="contained" color="primary" startIcon={<KeyIcon />} onClick={()=>{this.getTokenContingencia('login')}}>Generar token login</Button>
                              <Button variant="contained" color="primary" startIcon={<KeyIcon />} onClick={()=>{this.getTokenContingencia('opendoor')}}>Generar token apertura</Button>
                          </CardActions>
                        </Card>
                    </Grid>
                  </Paper>
                }
                <br />
                { session.checkPermiso('opcion_cajero_acciones_remotas') &&
                  <Paper elevation={16}>
                    <Grid container xs={12}>
                        <Card>
                          <CardContent>
                            <Typography gutterBottom variant="h6" component="div">
                            Acciones remotas:
                            </Typography>
                            
                            <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

                            <br />

                            <Grid container xs={12} spacing={2}>

                              <Grid item  xs={10} spacing={2}>
                                <FormControl sx={{mx:2 ,  minWidth: 120 }} >
                                  <InputLabel>Acción a enviar</InputLabel>
                                  <Select
                                    defaultValue={0}
                                    label="Acción a enviar"
                                    inputRef={this.cmbAcciones}
                                    >
                                    <MenuItem value={0}>-- Seleccione acción --</MenuItem>
                                    {
                                      remoteActions.map((action) => {
                                        return(
                                          <MenuItem value={action.accion}>{action.detalle}</MenuItem>      
                                        )
                                      })
                                    }
                                  </Select>
                                </FormControl>
                          

                                <TextField
                                  label="Parámetros"
                                  inputRef={this.txtParametrosAccion}
                                  multiline
                                  rows={1}
                                  lines={6}
                                  
                                />

                                <br />

                                <Button variant="contained" color="primary" startIcon={<ProgramarAccionIcon />} onClick={this.agregarAccion} sx={{my:2, marginLeft: 2 }}>Programar</Button>
                              </Grid>

                              <Grid item xs={12}>

                              <Button variant="contained" color="primary" startIcon={<RefreshIcon />} onClick={this.queryAccionesRemotas} sx={{ marginLeft: 2, marginBottom: 2 }}>Actualizar</Button>

                                <Table fullWidth>
                                  <TableRow>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Accion</TableCell>
                                    <TableCell>Parámetros</TableCell>
                                    <TableCell>Fecha confirmación</TableCell>
                                    <TableCell>Respuesta</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                  {
                                    this.state.accionesRemotas.map((accion) => {
                                      return(
                                        <TableRow>
                                          <TableCell>{accion.fecha_creacion}</TableCell>
                                          <TableCell>{accion.accion}</TableCell>
                                          <TableCell>{accion.parametros}</TableCell>
                                          <TableCell>{accion.fecha_respuesta}</TableCell>
                                          <TableCell>{accion.respuesta}</TableCell>
                                          {(accion.fecha_respuesta == null) &&
                                            <TableCell><Button variant="contained" color="primary" onClick={()=>{this.borrarAccion(accion.id)}}>{<DeleteIcon />}</Button></TableCell>
                                          }
                                        </TableRow>
                                      )
                                    })
                                  }
                                </Table>
                                <Button variant="contained" color="primary" startIcon={<RefreshIcon />} onClick={this.queryAccionesRemotas} sx={{my:2, marginLeft: 2 }}>Actualizar</Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                    </Grid>
                  </Paper>
                }
            

          </Box>
        )
  }
}


export default CajerosAccionesRemotas