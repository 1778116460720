import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import DatePicker from '@mui/lab/DatePicker'
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import TotalesCuadreTable from './TotalesCuadreTable'

const session = require('../../config/session')

class TotalesCuadrePage extends Component {
    constructor() {
      super()

      this.state = {
        cajeros: [],
        registros: [],
        filtroFechaInicio: null,
        filtroFechaFin: null
      }

      this.btnBucarClick = this.btnBucarClick.bind(this)

      this.cmbCajeros = React.createRef()

      this.filtroFechaInicio = ""
      this.filtroFechaFin = ""
    }

    btnBucarClick() {
      this.queryTotales()
    }

    fechaFiltroChanged(origen, date) {
      var formatoFechaInicio = "yyyy-LL-dd 00:00:00"
      var formatoFechaFin = "yyyy-LL-dd 23:59:59"
  
      switch (origen) {
        case 'inicio':
          this.setState({
            filtroFechaInicio: date
          })
          this.filtroFechaInicio = date.toFormat(formatoFechaInicio)
          break;
        case 'fin':
          this.setState({
            filtroFechaFin: date
          })
          this.filtroFechaFin = date.toFormat(formatoFechaFin)
          break;
        default:
  
      }
    }


    queryCajeros() {
      console.log('Consultando cajeros....')
  
      const requestOptions =  {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': '' + session.getToken()
        },
        body: JSON.stringify({
        })
      }
  
     fetch(process.env.REACT_APP_URL_BACKEND + '/cajeros/list', requestOptions)
       .then(response => response.json())
       .then(serverResponse => {
         console.log('Cajeros consultados')
           if(serverResponse.status === 1) {
            this.setState({
              cajeros: serverResponse.data
              })
          } else {
            console.log('Error respuesta cajeros')
            this.setState({
              mensajeError: serverResponse.message
            })
          }
       })
       .catch(error => {
         console.log("Error cargando cajeros")
       })
     }


    queryTotales() {
        console.log('Consultando totales....')
    
        var filtroIdCajero = 0
        var filtroIdTipoEvento = 0
      
        if(this.cmbCajeros.current.value > 0){
          filtroIdCajero = this.cmbCajeros.current.value
        }
    
        
        const requestOptions =  {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': '' + session.getToken()
          },
          body: JSON.stringify({
            filtro_id_cajero: filtroIdCajero,
            filtro_fecha_inicio: this.filtroFechaInicio,
            filtro_fecha_fin: this.filtroFechaFin
          })
        }
    
       fetch(process.env.REACT_APP_URL_BACKEND + '/reportes/totalescuadre/', requestOptions)
         .then(response => response.json())
         .then(serverResponse => {
          console.log('Totales consultados')
          if(serverResponse.status === 1) {
           this.setState({
             registros: serverResponse.data
             })
         } else {
           console.log('Error respuesta totales')
           this.setState({
            registros: [],
             mensajeError: serverResponse.message
           })
         }
         })
         .catch(error => {
          this.setState({
            registros: []
          })
           console.log("Error cargando totales")
         })
    }

    componentDidMount() {
      this.queryCajeros()
      this.queryTotales()
    }

    render() {
        return(
            <Box>
              <h3>Totales Cuadre</h3>
              <Card>
                <CardContent>
                  <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container xs={10} spacing={2}>
                      <Grid item fullWidth>
                        <Typography gutterBottom variant="h6" component="div">
                          Filtrar totales:
                        </Typography>
                      </Grid>
                      <Grid item container fullWidth>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              label="Fecha inicio:"
                              value={this.state.filtroFechaInicio}
                              onChange={date => this.fechaFiltroChanged('inicio', date)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={2}>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              label="Fecha fin:"
                              value={this.state.filtroFechaFin}
                              onChange={date => this.fechaFiltroChanged('fin', date)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={3}>
                          <FormControl fullWidth>
                            <InputLabel>Cajero</InputLabel>
                            <Select
                              inputRef={this.cmbCajeros}
                              defaultValue={0}>
                              <MenuItem value={0}>-- Todos los cajeros --</MenuItem>
                              {this.state.cajeros.map(cajero => (
                                <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button variant="contained" onClick={this.btnBucarClick}>Buscar</Button>
                </CardActions>
              </Card>
              <br />
              <TotalesCuadreTable registros={this.state.registros}/>
            </Box>
        )
    }

}

export default TotalesCuadrePage