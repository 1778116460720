import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class ClientesCrearPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
          entidades: [],
          txtNombre: "",
          txtIdentificacion: "",
          txtCodigo: "",
          txtPassword: "",
          cmbEntidades: 0,
          idNuevoCliente: 0,

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
        }

    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }


    onChangetxtNombre = (event) => {
      const nombre = event.target.value.toString()
      this.setState({txtNombre: nombre})
    }

    onChangetxtIdentificacion = (event) => {
        const identificacion = event.target.value.toString()
        this.setState({txtIdentificacion: identificacion})
    }

    onChangecmbEntidades = (event) => {
      const entidad = event.target.value
      this.setState({cmbEntidades: entidad})
    }

    onChangetxtPassword = (event) => {
        const password = event.target.value.toString()
        this.setState({txtPassword: password})
    }
    
    onChangetxtCodigo = (event) => {
      const codigo = event.target.value.toString()
      this.setState({txtCodigo: codigo})
    }

    componentDidMount() {
      this.queryEntidades()
    }

    btnAgregarClienteClick = () => {
        console.log('Agregando cliente....')

        var continuar = true
        var mensajeError = "Error:\n"

        var nombre = this.state.txtNombre
        var identificacion = this.state.txtIdentificacion
        var codigo = this.state.txtCodigo
        var entidad = this.state.cmbEntidades
        var password = this.state.txtPassword


        nombre = nombre.trim()
        identificacion = identificacion.trim()
        codigo = codigo.trim()
        password = password.trim()
        
        if(nombre === '') {
          continuar = false
          mensajeError += " * Debe ingresar el nombre.\n"
        }

        if(identificacion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la identificación.\n"
        }


        if(codigo === '') {
          continuar = false
          mensajeError += " * Debe ingresar el codigo.\n"
        }

        if(entidad === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar una entidad.\n"
        }

        if(password === '') {
          continuar = false
          mensajeError += " * Debe ingresar la contraseña.\n"
        }
    
        if(continuar) {
			var restArgs = {
		      method: 'PUT',
		      headers: 'JSON',
		      body: {
				  nombre: nombre,
	              identificacion: identificacion,
	              codigo: codigo,
	              entidad: entidad,
	              password: password
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/new'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta insertar cliente')
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error: Se produjo un error creando el cliente.',
                  severidadAlerta: 'error'
                })
              //  alert("Error: Se produjo un error creando el cliente.")
		      } else {
		        //console.log('Cliente insertado')
                // alert("Nuevo Cliente creado")
                this.setState({
                  idNuevoCliente: data,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Nuevo Cliente creado',
                  severidadAlerta: 'success'
                })
		      }      
	  		})
        } else {
          // alert(mensajeError)
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: mensajeError,
            severidadAlerta: 'error'
          })
        }
        
    }

    

    btnLimpiarClick = () => {
      this.setState({
        txtNombre: "",
        txtIdentificacion: "",
        txtCodigo: "",
        txtPassword: "",
        cmbEntidades: 0,
        idNuevoCliente: 0
      })
    }

    queryEntidades() {
      // console.log('Consultando entidades....')
  
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta consultando entidades')
	        //alert("Se produjo un error consultando entidades")
	        this.setState({
	          mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Se produjo un error consultando entidades',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Entidades consultadas')
	        this.setState({
	          entidades: data
	          })
	      }      
  		})
    }

    render() {
        return(
          <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Crear Cliente
              </Typography>

              <Paper elevation={16}>
                <Card>
                    <CardContent>
                        <form>
                            <FormLabel>Información cliente</FormLabel>
                            <Grid container xs={10} spacing={2} rowSpacing={2}>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Nombre"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtNombre}
                                  onChange={this.onChangetxtNombre}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Identificación"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtIdentificacion}
                                  onChange={this.onChangetxtIdentificacion}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>   
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Código"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtCodigo}
                                  onChange={this.onChangetxtCodigo}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <InputLabel>Entidad</InputLabel>
                                  <Select
                                    label="Entidad"
                                    value={this.state.cmbEntidades}
                                    defaultValue={0}
                                    onChange={this.onChangecmbEntidades}>
                                    <MenuItem value={0}>-- Seleccione entidad --</MenuItem>
                                    {this.state.entidades.map(entidad => (
                                      <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                    label="Contraseña"
                                    id="outlined-basic"
                                    type="password"
                                    variant="outlined"
                                    value={this.state.txtPassword}
                                    onChange={this.onChangetxtPassword}
                                    fullWidth
                                  />
                                </FormControl>
                              </Grid>            
                            </Grid>                   
                        </form>
                      </CardContent>
                      <CardActions>
                          <Button  variant="contained" onClick={this.btnAgregarClienteClick}>Crear Cliente</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                          <Button  variant="contained" onClick={this.btnLimpiarClick}>Limpiar</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                      </CardActions>
                </Card>

              </Paper>
                <br />
                { this.state.idNuevoCliente !== 0 &&
                  <Link to={{pathname: '/dashboard/clientes/editar', state: {id_cliente: this.state.idNuevoCliente}}} style={{ textDecoration: 'none' }}><Button variant="contained" color="info">Configurar nuevo Cliente</Button></Link>
                }

                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 

          </Box>
        )
    }

}


export default ClientesCrearPage