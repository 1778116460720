import react, {Component, useState} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import EventosTable from './EventosTable'
import DepositosTable from './DepositosTable'
import RetirosTable from './RetirosTable'

import ItemEstadoCajero from './ItemEstadoCajero'
import { Paper } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

var intervaloUpdateEstadoCajero = 10000

class CajerosResumenPage extends Component {

  constructor(props) {
    super(props)

    this.idCajero = props.location.state.id_cajero

    this.state = {
      cajero: [],
      categoriasEstados: [],
      estadosCajero: [],
      eventos: [],
      depositos: [],
      retiros: [],
      mostrarAlerta: true,
      mensajeAlerta: '',
      severidadAlerta: '',
    }

    this.updateEstadoCajero = this.updateEstadoCajero.bind(this)

  }

  showAlert = (mensaje, severidad) => {
    this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensaje,
        severidadAlerta: severidad,
    });
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  componentDidMount() {
	  this.queryCategoriasEstados()
    this.queryCajero()
    this.updateEstadoCajero()
    this.queryEventos()
    this.queryDepositos()
    //this.queryRetiros()

    this.timerRefrescarEstadoCajero = setInterval(this.updateEstadoCajero, intervaloUpdateEstadoCajero)
  }

  componentWillUnmount() {
    console.log("Desmontado")
    clearInterval(this.timerRefrescarEstadoCajero)
  }

  queryCajero() {
	   console.log('Consultando cajero....')
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
          this.showAlert('Error consultando cajero', 'error')
	        //alert("Error consultando cajero")
	        //console.log('Error respuesta consultando cajero')
	        this.setState({
            cajero: {},
            mensajeError: data.message
	        })
	      } else {
	        //console.log('Cajero consultado')
	      	this.setState({
                cajero: data
            })
	      }
	    })
   }
   
   queryCategoriasEstados() {
        //console.log('Consultando lista estados....')
        
        var restArgs = {
          method: 'POST',
          headers: 'JSON',
          body: {},
          url: process.env.REACT_APP_URL_BACKEND + '/cajeroestados/listaestados'
        }
    
        RestTunel.makePetition(restArgs,(err, data) => {
          if(err) {
            this.showAlert('Error consultando lista estados', 'error')
            //alert("Error consultando lista estados")
            //console.log('Error respuesta consultando lista estados')
	        this.setState({
            categoriasEstados: [],
            mensajeError: data.message
	        })
	      } else {
            //console.log('Estados consultados')
          	this.setState({
            	categoriasEstados: data.categoriasEstados
            })
          }
        })
    }

  updateEstadoCajero() {
    //console.log('Consultando estado')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeroestados/estado' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
          this.showAlert('Error consultando estado', 'error')
	        //alert("Error consultando estado")
	        //console.log('Error respuesta consultando estado')
	        this.setState({
            estadosCajero: [],
            mensajeError: data.message
	        })
	      } else {
	        //console.log('Estado consultado')
	      	this.setState({
                estadosCajero: data.estados
            })
	      }
	    })
    
   }

 queryEventos() {
   //console.log('Consultando eventos')
   
   var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	filtro_id_cajero: this.idCajero
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/eventos/list' 
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        this.showAlert('Error consultando eventos', 'error')
        //alert("Error consultando eventos")
        //console.log('Error respuesta consultando eventos')
        this.setState({
          eventos: [],
          mensajeError: data.message
        })
      } else {
        //console.log('Eventos consultados')
      	this.setState({
            eventos: data.data
        })
      }
    })
   
  }

  queryDepositos() {
    //console.log('Consultando depositos....')


	var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	filtro_id_cajero: this.idCajero
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/depositos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        this.showAlert('Error consultando depositos', 'error')
        //alert("Error consultando depositos")
        //console.log('Error respuesta consultando depositos')
        this.setState({
          depositos: [],
          mensajeError: data.message
        })
      } else {
        //console.log('Depositos consultados')
      	this.setState({
            depositos: data
        })
      }
    })

  }

   queryRetiros() {
     //console.log('Consultando retiros....')


	var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	filtro_id_cajero: this.idCajero
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/retiros/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        this.showAlert('Error consultando retiros', 'error')
        //alert("Error consultando retiros")
        //console.log('Error respuesta consultando retiros')
        this.setState({
          retiros: [],
          mensajeError: data.message
        })
      } else {
        //console.log('Retiros consultados')
      	this.setState({
            retiros: data
        })
      }
    })
    }

  render() {
    var cajero = this.state.cajero

    var entidades = []
    if(cajero != null && cajero.entidades != null) {
      /* cajero.entidades.split('**-**').map((entidad) => {
       entidades.push(
         <tr><td>* {entidad}</td></tr>
       )
     })*/

      cajero.entidades.split('**-**').forEach((entidad) => {
        entidades.push(
          <tr><td>* {entidad}</td></tr>
        )
      })
    }

    return(
      <Container>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
          Resumen cajero
        </Typography>

          <Grid container xs={12} spacing={2}>
            <Grid item xs={6}>
              <Card fullWidth>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    Datos cajero:
                  </Typography>
                  
                  <Divider />
                    <div>Fecha Registro: {cajero.fecha_registro}</div>
                    <div>Codigo: {cajero.codigo}</div>
                    <div>Serial: {cajero.serial}</div>
                    <div>Alias: {cajero.alias}</div>
                    <div>Dirección: {cajero.direccion}</div>
                    <div>Email Transportadora: {cajero.email_transportadora}</div>
                    <div>Entidad: <table>{entidades}</table></div>
                    <div>{cajero.estado ==="A" ? "El cajero esta activo" : "El cajero no esta activo"}</div>
                </CardContent>
                <CardActions>
                </CardActions>
              </Card>
            </Grid>
            { session.checkPermiso('ver_estado_cajero') &&
              <ItemEstadoCajero item xs={6} categoriasEstados={this.state.categoriasEstados} estadosCajero={this.state.estadosCajero} />
            }
          </Grid>
        
        <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

        <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Ultimos eventos:
        </Typography>

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <EventosTable eventos={this.state.eventos} />
              </Box>
            </CardContent>
            <CardActions>
              
            </CardActions>
          </Card>
        </Paper>
        
        <Divider style={{"margin-top": 10, "margin-bottom": 10}} />
        
        <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Ultimos depositos:
        </Typography>

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <DepositosTable depositos={this.state.depositos} />
              </Box>
            </CardContent>
            <CardActions>
              
            </CardActions>
          </Card>
        </Paper>

        <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

        <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 2 }}>
          Ultimos retiros:
        </Typography>

        <Paper>
          <Card>
            <CardContent>
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <RetirosTable retiros={this.state.retiros} />
              </Box>
            </CardContent>
            <CardActions>
              
            </CardActions>
          </Card>
        </Paper>

      </Container>
    )
  }

}

export default CajerosResumenPage
