import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import MenuItem from '@mui/material/MenuItem'
import FormLabel from '@mui/material/FormLabel'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import RolesTable from './RolesTable'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class PermisosRolPage extends Component {

    constructor(props) { 
        super(props)

        this.state = {
            txtNombre: "",
            roles: [],
            permisos: [],
            permisosDisponibles: [],
            permisosRol: [],
            accion: "Agregar",

            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }

        this.currentRole = 0
        this.cmbRoles = React.createRef()

        this.btnGuardarPermisoslClick = this.btnGuardarPermisoslClick.bind(this)
        this.prepararNuevo = this.prepararNuevo.bind(this)
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }
  

    btnSeleccionarRegistro = (id) => {
      this.queryRol(id)
    }

    btnCancelarClick = () => {
      this.prepararNuevo()
    }

    onChangetxtNombre = (event) => {
        const nombre = event.target.value.toString()
        this.setState({txtNombre: nombre})
    }

    componentDidMount() {
        this.queryRoles()
        this.queryPermisos()
    }

    cmbRolesChange = (event) => {
        const idRol = event.target.value

        if(idRol > 0) {
            this.queryPermisosRol(idRol)
        } else {
            this.currentRole = 0
            this.setState(
                {
                    permisosRol: [],
                    permisosDisponibles: []
                }
            )
        }
    }

    agregarPermiso(idPermiso) {
        var permisosDisponibles = this.state.permisosDisponibles
        var permisosRol = this.state.permisosRol
    
        permisosDisponibles.forEach((permiso) => {
          if(permiso.id === idPermiso) {
            permisosRol.push(permiso)
          }
        })
    
        this.setState({
            permisosRol: permisosRol
        })
    
        this.actualizarPermisosDisponibles()
      }
    
      removerPermiso(idPermiso) {
        var permisosRol = this.state.permisosRol
        for(var x = 0; x < permisosRol.length; x++) {
          if(permisosRol[x].id === idPermiso) {
            permisosRol.splice(x, 1) 
            break
          }
        }
    
        this.setState({
            permisosRol: permisosRol
        })
    
        this.actualizarPermisosDisponibles()
      }

    actualizarPermisosDisponibles() {
        var permisos = this.state.permisos
        
        var permisosDisponibles = []

        if(this.currentRole > 0) {
            var permisosRol= this.state.permisosRol

            permisos.forEach((permiso) => {
            var blnAgregar = true
        
            permisosRol.forEach((permisoRol) => {
                if(permisoRol.id === permiso.id) {
              	  blnAgregar = false
                }
            })
        
            if(blnAgregar) {
                permisosDisponibles.push(permiso)
            }
            })
        }
        
        this.setState({
          permisosDisponibles: permisosDisponibles
        })
      }

    btnGuardarPermisoslClick() {
        var permisoRol = this.state.permisosRol
        var permisos = []

        var mensajeError = "Error:\n"
        var continuar = true

        if(this.currentRole < 1) {
            mensajeError += "* Debe seleccionar un rol."
            continuar = false
        } else {
            permisoRol.forEach((permiso) => {
                permisos.push(permiso.id)
            })
        }
        
        if(continuar) {
			console.log('CEditando rol s....')
		    
		    var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_rol: this.currentRole,
                permisos: permisos
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/permisos/rol/permisos'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta actualizar permisos')
              //  alert("Se produjo un error actualizando permisos")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Se produjo un error actualizando permisos',
                  severidadAlerta: 'error'
                })
		      } else {
		          //console.log('Permisos actualizados')
                // alert("Permisos actualizados")
              this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Permisos actualizados',
                  severidadAlerta: 'success'
              })
		      }
		    })
        } else {
            //alert(mensajeError)
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: mensajeError,
              severidadAlerta: 'error'
            })
        }
    }

    btnGuardarClick = () => {
        console.log('Agregando rol....')
  
        var continuar = true
        var mensajeError = "Error:\n"
  
        var nombre = this.state.txtNombre.trim()

        
        if(nombre === '') {
          continuar = false
          mensajeError += " * Debe ingresar el nombre.\n"
        }
  
        if(continuar) {
          if(this.state.accion === "Agregar") {
			  var restArgs = {
			      method: 'PUT',
			      headers: 'JSON',
			      body: {
					  nombre: nombre
				  },
			      url: process.env.REACT_APP_URL_BACKEND + '/permisos/rol/new'
		       }
		
			    RestTunel.makePetition(restArgs,(err, data) => {
			      if(err) {
			        //console.log('Error respuesta insertar rol')
	                  this.setState({
	                    mensajeError: data.message,
                      mostrarAlerta: true,
                      mensajeAlerta: 'Error respuesta insertar rol',
                      severidadAlerta: 'error'
	                  })
	              //alert("Error creando el rol")
			      } else {
			        // console.log('Rol creado')
	              //  alert("Nuevo rol creado")
	                  this.setState({
	                      txtNombre: "",
                        mostrarAlerta: true,
                        mensajeAlerta: 'Nuevo rol creado',
                        severidadAlerta: 'success'
	                  })
	                  this.prepararNuevo()
	                  this.queryRoles()
			      }
			    })
			  
          } else {

        // eslint-disable-next-line no-redeclare
			  var restArgs = {
			      method: 'PATCH',
			      headers: 'JSON',
			      body: {
					 id_rol: this.state.idRol,
                	  nombre: nombre
				  },
			      url: process.env.REACT_APP_URL_BACKEND + '/permisos/rol/update'
		       }
		
			    RestTunel.makePetition(restArgs,(err, data) => {
			      if(err) {
			         //console.log('Error respuesta actualizar rol')
	                  this.setState({
	                    mensajeError: data.message,
                      mostrarAlerta: true,
                      mensajeAlerta: 'Error respuesta actualizar rol',
                      severidadAlerta: 'error'
	                  })
	                  // alert("Error actualizando el rol")
			      } else {
			              // console.log('Rol actualizado')
	                  // alert("Rol actualizado")
	                  this.setState({
	                      txtNombre: "",
                        mostrarAlerta: true,
                        mensajeAlerta: 'Rol actualizado',
                        severidadAlerta: 'success'
	                  })
	                  this.prepararNuevo()
	                  this.queryRoles()
			      }
			    })
			  
          }
        } else {
            //alert(mensajeError)
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: mensajeError,
              severidadAlerta: 'error'
          })
        }
        
    }

    prepararNuevo() {
      this.setState({
        txtNombre: "",
        accion: "Agregar"
      })
    }

    queryRoles() {
		console.log('Consultando roles....')
	    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/roles/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando roles")
	        //console.log('Error respuesta roles')
            this.setState({
				      roles: [],
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando roles',
              severidadAlerta: 'error'
            })
	      } else {
		      //console.log('Roles consultadoss')
	       this.setState({
            roles: data
            })
	      }
	    })
   }

   queryRol(idRol) {
	    // console.log('Consultando rol....')
	    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_rol: idRol
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/rol/detalle'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
		      //alert("Error consultando rol")
		      //console.log('Error respuesta consultando rol')
	          this.setState({
				      idRol: 0,
	            mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando rol',
              severidadAlerta: 'error'
	          })
	      } else {
			      // console.log('Rol consultado')
	         
	          var rol = data
	          console.log(rol)
	
	          this.setState({
	            idRol: rol.id,
	            txtNombre: rol.nombre,
	            accion: "Editar"
	          })
	      }
	    })
  }

    queryPermisos() {
		  //console.log('Consultando permisos....')
    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando permisos")
	        //console.log('Error respuesta permisos')
            this.setState({
                permisos: [],
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando permisos',
                severidadAlerta: 'error'
            })
            this.actualizarPermisosDisponibles()
	      } else {
	        this.setState({
                permisos: data
            })
            this.actualizarPermisosDisponibles()
	      }
	    })
    }

    queryPermisosRol(idRol) {
		 //console.log('Consultando grupos....')
    
    	this.currentRole = idRol
    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_rol: idRol
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/rol/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando grupos")
	        //console.log('Error respuesta permisos')
            this.setState({
                mensajeError: data.message,
                permisosRol: [],
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando gruos',
                severidadAlerta: 'error'
            })
            this.actualizarPermisosDisponibles()
	      } else {
			    //console.log('Permisos rol consultados')
		       this.setState({
	                permisosRol: data
	            })
	            this.actualizarPermisosDisponibles()
		      }
	    })
    }

    render() {
        return(
          <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                  Administrar Roles
                </Typography>

                <Paper elevation={16}>
                  <Card>
                    <CardContent>
                      <form>
                          <FormLabel>Información rol:</FormLabel>
                          <Grid container xs={10} spacing={2} rowSpacing={2}>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Nombre"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtNombre}
                                onChange={this.onChangetxtNombre}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            
                        </Grid>
                      </form>
                    </CardContent>
                    <CardActions>
                            <Button  variant="contained" onClick={this.btnGuardarClick}>{this.state.accion=== 'Agregar' ? "Agregar Rol" : "Editar Rol"}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>     

                            <Button  variant="contained" onClick={this.btnCancelarClick}>Cancelar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>  
                        </CardActions>
                  </Card>

                </Paper>
                
                 <br />
                <Paper elevation={16}>
                  <Card>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        Listado Roles:
                      </Typography>
                      <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                        <RolesTable roles={this.state.roles} eventoSeleccionar={this.btnSeleccionarRegistro} />
                      </Box>
                    </CardContent>
                    <CardActions>

                    </CardActions>
                  </Card>
                </Paper>
                 <br />

                 <Paper elevation={16}>
                  <Card>
                      <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                              Permisos Rol:
                          </Typography>
                          <Grid item xs={3}>
                              <FormControl fullWidth>
                                  <InputLabel>Roles</InputLabel>
                                  <Select
                                      label="Roles"
                                      inputRef={this.cmbRoles}
                                      defaultValue={0}
                                      onChange={this.cmbRolesChange}>
                                      <MenuItem value={0}>-- Seleccione un rol --</MenuItem>
                                          {this.state.roles.map(rol => (
                                      <MenuItem value={rol.id}>{rol.nombre}</MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                          </Grid>
                          <br />
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                              <Grid item xs={4}>
                                <FormLabel>Permisos Disponibles</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.permisosDisponibles.map((permiso) => {
                                        return(
                                          <ListItem button
                                            key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarPermiso(permiso.id)}}>
                                              {permiso.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                              <Grid item xs={4}>
                                <FormLabel>Permisos Rol</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.permisosRol.map((permiso) => {
                                        return(
                                          <ListItem button
                                            key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerPermiso(permiso.id)}}>
                                            {permiso.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                            </Grid>
                      </CardContent>
                      <CardActions>
                          <Button  variant="contained" onClick={this.btnGuardarPermisoslClick}>Guardar Permisos</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar>  
                      </CardActions>
                  </Card>

                 </Paper>
            

          </Box>
        )
    }
}

export default PermisosRolPage