import {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

import DeleteIcon from '@mui/icons-material/DeleteForever';
import CopyIcon from '@mui/icons-material/FileCopy';
import AddIcon from '@mui/icons-material/AddBox';


function Row(props) {
    var item = props.item
    var eventSelect = props.eventSelect
    var eventDelete = props.eventDelete

    return(
        <TableRow>
            <TableCell>{item.alias}</TableCell>
            <TableCell>{item.tipoDispenzador}</TableCell>
            <TableCell>{item.protocolo}</TableCell>
            <TableCell>{item.puerto}</TableCell>
            <TableCell>{item.velocidad}</TableCell>
            <TableCell>{item.archivo}</TableCell>
            <TableCell>{item.debug}</TableCell>
            <TableCell>{item.estado}</TableCell>
            <TableCell>
                <Button variant="contained" size='small' fullWidth onClick={() => {eventSelect(item)}}>Seleccionar</Button>
                <Button variant="contained" size='small' color="error" fullWidth onClick={() => {eventDelete(item)}}>Borrar</Button>
            </TableCell>
        </TableRow>
    )
}

function RowCajon(props) {
	var item = props.item
	var removerEvent = props.removerEvent
	var copiarEvent = props.copiarEvent
	
    return(
		<TableRow>
			<TableCell>{item.codigo}</TableCell>
			<TableCell>{item.denominacion}</TableCell>
			<TableCell>{item.parametro1}</TableCell>
			<TableCell>{item.parametro2}</TableCell>
			<TableCell>{item.parametro3}</TableCell>
			<TableCell>{item.parametro4}</TableCell>
			<TableCell>
                <Button variant="contained" size='small' onClick={() => {copiarEvent(item)}}><CopyIcon /></Button>
                <Button variant="contained" size='small' color="error" onClick={() => {removerEvent(item.codigo)}}><DeleteIcon /></Button>
            </TableCell>
		</TableRow>
    )
}


class TabDispenzadorBilletes extends Component {
    constructor(props) {
        super(props)

        var tipoDispositivo = props.tipoDispositivo
        var dispositivos = props.dispositivos
        this.guardarEvent = props.event

        this.state = {
            tipoDispositivo: tipoDispositivo,
            dispositivos: dispositivos,
            txtAlias: "",
            cmbTiposDispenzador: 0,
            cmbProtocolos: 0,
            cmbModos: 0,
            cmbVelocidades: 0,
            cmbRecargas: 0,
            cmbLogicas: 0,
            txtPresentacion: "",
            txtMontos: "",
            cmbDebugs: 0,
            txtPuerto: "",
            txtArchivo: "",
            cmbEstados: 0,
            txtCajonCodigo: "",
            txtCajonDenominacion: "",
            txtCajonParametro1: "",
            txtCajonParametro2: "",
            txtCajonParametro3: "",
            txtCajonParametro4: "",
            cajones: [],
            tiposDispenzador: [
                "F53",
                "F56"
            ],
            protocolos: [
                "Fujitsu"
            ],
            modos: [
                "Simple",
                "Multiple"
            ],
            velocidades: [
                "9600",
                "19200",
                "115200"
            ],
            recargas: [
                "Frontal",
                "Trasero"
            ],
            logicas: [
                "Automatico",
                "Manual"
            ],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            estados: [
				{nombre: "Activo", valor: "A"},
				{nombre: "Inactivo", valor: "I"}
			],
            modoEdicion: false
        }
        
        this.btnCopiarCajon = this.btnCopiarCajon.bind(this)
        this.btnRemoverCajon = this.btnRemoverCajon.bind(this)
        
    }

    componentWillUnmount() {

    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangecmbTiposDispenzador = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposDispenzador: tipo})
    }

    onChangecmbProtocolos = (event) => {
        const protocolo = event.target.value
        this.setState({cmbProtocolos: protocolo})
    }

    onChangecmbModos = (event) => {
        const modo = event.target.value
        this.setState({cmbModos: modo})
    }

    onChangecmbRecargas = (event) => {
        const recarga = event.target.value
        this.setState({cmbRecargas: recarga})
    }
    
    onChangecmbLogicas = (event) => {
        const logica = event.target.value
        this.setState({cmbLogicas: logica})
    }
    
    onChangetxtPresentacion = (event) => {
        const presentacion = event.target.value.toString()
        this.setState({txtPresentacion: presentacion})
    }
    
    onChangetxtMontos= (event) => {
        const monto = event.target.value.toString()
        this.setState({txtMontos: monto})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangetxtPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtPuerto: puerto})
    }

    onChangecmbVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbVelocidades: velocidad})
    }

    onChangetxtArchivo = (event) => {
        const archivo = event.target.value.toString()
        this.setState({txtArchivo: archivo})
    }
    
    onChangecmbEstados = (event) => {
        const estado = event.target.value
        this.setState({cmbEstados: estado})
    }
    
    onChangetxtCajonCodigo = (event) => {
        const codigo = event.target.value
        this.setState({txtCajonCodigo: codigo})
    }
    
    onChangetxtCajonDenominacion = (event) => {
        const denominacion = event.target.value
        this.setState({txtCajonDenominacion: denominacion})
    }
    
    onChangetxtCajonParametro1 = (event) => {
        const parametro = event.target.value
        this.setState({txtCajonParametro1: parametro})
    }
    
    onChangetxtCajonParametro2 = (event) => {
        const parametro = event.target.value
        this.setState({txtCajonParametro2: parametro})
    }
    
    onChangetxtCajonParametro3 = (event) => {
        const parametro = event.target.value
        this.setState({txtCajonParametro3: parametro})
    }
    
    onChangetxtCajonParametro4 = (event) => {
        const parametro = event.target.value
        this.setState({txtCajonParametro4: parametro})
    }

    btnSeleccionarClick = (item) => {
		
        this.setState({
            txtAlias: item.alias,
            cmbTiposDispenzador: item.tipoDispenzador,
            cmbProtocolos: item.protocolo,
            cmbModos: item.modo,
            txtPuerto: item.puerto,
            cmbVelocidades: item.velocidad,
            cmbRecargas: item.recarga,
			cmbLogicas: item.logica,
			txtPresentacion: item.presentacion,
			txtMontos: item.montos,
            cmbDebugs: item.debug,
            txtArchivo: item.archivo,
            txtDenominaciones: item.denominaciones,
            cmbEstados: item.estado,
            cajones: item.cajones,
            modoEdicion: true
        })
        
        
    }

    btnBorrarClick = (item) => {
        
        if(window.confirm("¿Borrar dispositivo?")) {
            this.prepararNuevo()
            var dispositivos = this.state.dispositivos

            for(var x = 0; x < dispositivos.length; x++) {
                if(dispositivos[x].alias === item.alias) {
                    dispositivos.splice(x, 1)
                    break
                }
            }


            this.setState({
                dispositivos: dispositivos
            })

            this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
        }

    }

    btnGuardarClick = () => {

        var dispositivos = this.state.dispositivos

        var item = {
            alias: this.state.txtAlias,
            tipoDispenzador: this.state.cmbTiposDispenzador,
            protocolo: this.state.cmbProtocolos,
            modo: this.state.cmbModos,
            puerto: this.state.txtPuerto,
            velocidad: this.state.cmbVelocidades,
            recarga: this.state.cmbRecargas,
            logica: this.state.cmbLogicas,
            presentacion: this.state.txtPresentacion,
            montos: this.state.txtMontos,
            debug: this.state.cmbDebugs,
            archivo: this.state.txtArchivo,
            denominaciones: this.state.txtDenominaciones,
            cajones: this.state.cajones,
            estado: this.state.cmbEstados
        }

        if(this.state.modoEdicion) {
            //Buscar el dispositivo por el alias
            for(var x = 0; dispositivos.length; x++) {
                if(dispositivos[x].alias === this.state.txtAlias) {
                    dispositivos[x] = item
                    break
                }
            }
        } else {
            dispositivos.push(item)
        }

        this.setState({
            dispositivos: dispositivos
        })

        this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
    }

    prepararNuevo = () => {
        this.setState({
            txtAlias: "",
            cmbTiposDispenzador: 0,
            cmbProtocolos: 0,
            cmbModos: 0,
            txtPuerto: "",
            cmbVelocidades: 0,
            cmbRecargas: 0,
            cmbLogicas: 0,
            txtPresentacion: "",
            txtModos: "",
            txtTiempoEscrow: "",
            cmbDebugs: 0,
            txtArchivo: "",
            txtDenominaciones: "",
            cmbEstados: 0,
            cajones: [],
            txtCajonCodigo: "",
            txtCajonDenominacion: "",
            txtCajonParametro1: "",
            txtCajonParametro2: "",
            txtCajonParametro3: "",
            txtCajonParametro4: "",
            modoEdicion: false
        })
    }
    
    btnAgregarCajon() {
		var cajones = this.state.cajones
	  	var codigo = this.state.txtCajonCodigo
	  	var denominacion = this.state.txtCajonDenominacion
	  	var parametro1 = this.state.txtCajonParametro1
	  	var parametro2 = this.state.txtCajonParametro2
	  	var parametro3 = this.state.txtCajonParametro3
	  	var parametro4 = this.state.txtCajonParametro4

	  cajones.push({
		  codigo: codigo,
		  denominacion: denominacion,
		  parametro1: parametro1,
		  parametro2: parametro2,
		  parametro3: parametro3,
		  parametro4: parametro4
	  })		
	  
	  this.setState({
		  cajones: cajones,
		  txtCajonParametro1: "",
		  txtCajonParametro2: "",
		  txtCajonParametro3: "",
		  txtCajonParametro4: ""
	  })
   }
   
   btnRemoverCajon(codigo) {
	  if(window.confirm("¿Desea borrar el cajon" + codigo + "?")) {
		  var cajones = this.state.cajones

		  for(var x = 0; x < cajones.length; x++) {
			  console.log(cajones[x].codigo)
			  if(cajones[x].codigo === codigo) {
				  cajones.splice(x, 1 )
				  this.setState({
					  cajones: cajones
				  })
				  
				  break
			  }
		  }
	  }
	  
  }
  
  btnCopiarCajon(cajon) {
	  this.setState({
		  txtCajonCodigo: cajon.codigo + "-copia",
		  txtCajonDenominacion: cajon.denominacion,
		  txtCajonParametro1: cajon.parametro1,
		  txtCajonParametro2: cajon.parametro2,
		  txtCajonParametro3: cajon.parametro3,
		  txtCajonParametro4: cajon.parametro4
	  })
  }

    confirmacionGuardado = () => {
        this.prepararNuevo()
    }

    render() {
        const columns = [
            {field: 'Alias', headerName: 'Alias', width: 150},
            {field: 'Tipo', headerName: 'Tipo', width: 100},
            {field: 'Protocolo', headerName: 'Protocolo', width: 100},
            {field: 'Puerto', headerName: 'Puerto', width: 100},
            {field: 'Velocidad', headerName: 'Velocidad', width: 50},
            {field: 'Archivo', headerName: 'Archivo', width: 100},
            {field: 'Debug', headerName: 'Debug', width: 50},
            {field: 'Estado', headerName: 'Estado', width: 50},
            {field: '', headerName: '', width: 50}
          ]

        return(
            <Box>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Card fullWidth>
                            <CardContent>
                            	<h3>Dispenzador Billetes</h3>
                                <form>
                                    <FormLabel>Información dispenzador billetes</FormLabel>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Alias"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtAlias}
                                                onChange={this.onChangetxtAlias}
                                                inputProps={{disabled: this.state.modoEdicion}}
                                                disabled={this.state.modoEdicion}
                                                fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                label="Tipo"
                                                value={this.state.cmbTiposDispenzador}
                                                defaultValue={0}
                                                onChange={this.onChangecmbTiposDispenzador}>
                                                <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                {this.state.tiposDispenzador.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Protocolo</InputLabel>
                                            <Select
                                                label="Protocolo"
                                                value={this.state.cmbProtocolos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbProtocolos}>
                                                <MenuItem value={0}>-- Seleccione Protocolo --</MenuItem>
                                                {this.state.protocolos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Modo</InputLabel>
                                            <Select
                                                label="Modo"
                                                value={this.state.cmbModos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbModos}>
                                                <MenuItem value={0}>-- Seleccione Modo --</MenuItem>
                                                {this.state.modos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Puerto"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtPuerto}
                                            onChange={this.onChangetxtPuerto}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Velocidad</InputLabel>
                                            <Select
                                                label="Velocidad"
                                                value={this.state.cmbVelocidades}
                                                defaultValue={0}
                                                onChange={this.onChangecmbVelocidades}>
                                                <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                {this.state.velocidades.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Recarga</InputLabel>
                                            <Select
                                                label="Escrow"
                                                value={this.state.cmbRecargas}
                                                defaultValue={0}
                                                onChange={this.onChangecmbRecargas}>
                                                <MenuItem value={0}>-- Seleccione Recarga --</MenuItem>
                                                {this.state.recargas.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Lógica</InputLabel>
                                            <Select
                                                label="Lógica"
                                                value={this.state.cmbLogicas}
                                                defaultValue={0}
                                                onChange={this.onChangecmbLogicas}>
                                                <MenuItem value={0}>-- Seleccione Lógica --</MenuItem>
                                                {this.state.logicas.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Presentación"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtPresentacion}
                                            onChange={this.onChangetxtPresentacion}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Montos"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtMontos}
                                            onChange={this.onChangetxtMontos}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Debug</InputLabel>
                                            <Select
                                                label="Debug"
                                                value={this.state.cmbDebugs}
                                                defaultValue={0}
                                                onChange={this.onChangecmbDebugs}>
                                                <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                {this.state.debugs.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Archivo"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtArchivo}
                                            onChange={this.onChangetxtArchivo}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Estado</InputLabel>
                                            <Select
                                                label="Estado"
                                                value={this.state.cmbEstados}
                                                defaultValue={0}
                                                onChange={this.onChangecmbEstados}>
                                                <MenuItem value={0}>-- Seleccione estado --</MenuItem>
                                                {this.state.estados.map(item => (
                                                <MenuItem value={item.valor}>{item.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                                <br />
                                <FormLabel>Información cajones</FormLabel>
                                <form>
                              	  <TableContainer>
                                	<Table>
                                		<TableHead>
                                			<TableRow>
                                				<TableCell>
                                					Código
                                				</TableCell>
                                				<TableCell>
                                					Denominación
                                				</TableCell>
                                				<TableCell>
                                					Parámetro 1
                                				</TableCell>
                                				<TableCell>
                                					Parámetro 2
                                				</TableCell>
                                				<TableCell>
                                					Parámetro 3
                                				</TableCell>
                                				<TableCell>
                                					Parámetro 4
                                				</TableCell>
                                				<TableCell>
                                					
                                				</TableCell>
                                			</TableRow>
                                		</TableHead>
                                		{
											this.state.cajones.map((item) => {
												return(
													<RowCajon item = {item} copiarEvent = {this.btnCopiarCajon} removerEvent = {this.btnRemoverCajon}/>
												)
											})
										}
                                		<TableRow>
                                			<TableCell>
                            					<TextField
		                                            label="Codigo"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonCodigo}
		                                            onChange={this.onChangetxtCajonCodigo}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<TextField
		                                            label="Denominacion"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonDenominacion}
		                                            onChange={this.onChangetxtCajonDenominacion}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<TextField
		                                            label="Parametro 1"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonParametro1}
		                                            onChange={this.onChangetxtCajonParametro1}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<TextField
		                                            label="Parametro 2"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonParametro2}
		                                            onChange={this.onChangetxtCajonParametro2}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<TextField
		                                            label="Parametro 3"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonParametro3}
		                                            onChange={this.onChangetxtCajonParametro3}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<TextField
		                                            label="Parametro 4"
		                                            id="outlined-basic"
		                                            variant="outlined"
		                                            value={this.state.txtCajonParametro4}
		                                            onChange={this.onChangetxtCajonParametro4}
		                                            fullWidth
		                                            />
                            				</TableCell>
                            				<TableCell>
                            					<Button  variant="contained" onClick={() => {this.btnAgregarCajon()}}><AddIcon /></Button>
                            				</TableCell>
                                		</TableRow>
                            		</Table>
                				</TableContainer>
                            </form>
                            </CardContent>
                            <CardActions>
                                <Button  variant="contained" onClick={this.btnGuardarClick}>{ this.state.modoEdicion  === true ? "Modificar": "Agregar"}</Button>
                                <Button  variant="contained" onClick={this.prepararNuevo}>Cancelar</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Card xs={8}>
                    <CardContent xs={8}>
                        <TableContainer xs={8}>
                            <Table stickyHeader class="table-data" xs={8}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => {
                                                return(
                                                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.state.dispositivos.map((dispositivo) => {
                                        return(<Row item = {dispositivo} eventSelect={this.btnSeleccionarClick} eventDelete={this.btnBorrarClick} />)
                                    })
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

export default TabDispenzadorBilletes