import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import EditarIcon from '@mui/icons-material/Edit';
import ResumenIcon from '@mui/icons-material/Assignment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CajeroAccionesRemotasIcon from '@mui/icons-material/SettingsRemote'

const session = require('../../config/session')

function Row(props) {

  const {cajero} = props
  const [open, setOpen] = React.useState(false)

  var entidades = []
  if(cajero.entidades != null) {
    cajero.entidades.split('**-**').forEach((entidad) => {
     entidades.push(
       <span>* {entidad}<br /></span>
     )
   })

   /* cajero.entidades.split('**-**').map((entidad) => {
	entidades.push(
	  <span>* {entidad}<br /></span>
	)
  }) */
 }


  var estado = ""

  switch(cajero.estado) {
    case 'I':
      estado = 'Inactivo'
      break
    case 'A':
      estado = 'Activo'
      break
	default:
		estado = 'Inactivo'
		break
  }

  return (
	  <React.Fragment>
	    <TableRow>
	      <TableCell>
	        <IconButton
	          aria-label="expand row"
	          size="small"
	          onClick={() => setOpen(!open)}
	        >
	          {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
	        </IconButton>
	      </TableCell>
	      <TableCell>
	          {cajero.alias}
	      </TableCell>
	      <TableCell>
	        {cajero.codigo}
	      </TableCell>
	      <TableCell>
	        {entidades}
	      </TableCell>
	      <TableCell>
	        {cajero.pais}
	        <br />
	        {cajero.ciudad}
	      </TableCell>
	      <TableCell>
	        {estado}
	        <br />
	        {cajero.estado_operativo}
	      </TableCell>
	      <TableCell>
	      {
	          session.checkPermiso('acceder_cajeros_editar') && 
	          <Link to={
	            {pathname: '/dashboard/cajeros/editar',
	            state: {id_cajero: cajero.id}}}
	            style={{ textDecoration: 'none' }}>
	              <Button variant="contained" endIcon={<EditarIcon />} color="primary" size='small' fullWidth sx={{marginBottom: 1}}>Editar</Button>
	          </Link>
	        }
	        {
	          session.checkPermiso('acceder_cajeros_resumen') && 
	          <Link to={
	            {pathname: '/dashboard/cajeros/resumen',
	            state: {id_cajero: cajero.id}}}
	            style={{ textDecoration: 'none' }}>
	              <Button variant="contained" endIcon={<ResumenIcon />} color="primary" size='small' fullWidth sx={{marginBottom: 1}}>Resumen</Button>
	          </Link>
	        }
	        {
	          session.checkPermiso('acceder_cajeros_acciones_remotas') && 
	          <Link to={
	            {pathname: '/dashboard/cajeros/accionesremotas',
	            state: {id_cajero: cajero.id}}}
	            style={{ textDecoration: 'none' }}>
	              <Button variant="contained" endIcon={<CajeroAccionesRemotasIcon />} color="primary" size='small' fullWidth sx={{marginBottom: 1}}>Acciones</Button>
	          </Link>
	        }
	      </TableCell>
	    </TableRow>
	    <TableRow>
	    	<TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
		        <Collapse in={open} timeout="auto" unmountOnExit>
		        	<Table className="detalle">
		        		<TableRow>
			        		<TableCell>
						        Fecha registro:
						     </TableCell>
			        		<TableCell>
						        {cajero.fecha_registro}
						     </TableCell>
					     </TableRow>
					     <TableRow>
					     	<TableCell>
						       Serial:
						     </TableCell>
						     <TableCell>
						        {cajero.serial}
						     </TableCell>
					     </TableRow>
		          	</Table>
	          </Collapse>
	         </TableCell>
	        </TableRow>
    </React.Fragment>
  );


}


class CajerosTable extends Component {

  /* constructor(props) {
    super(props)
  } */

  componentDidMount() {
  }

  render() {
    const cajeros = this.props.cajeros

    const columns = [
	  {field: "desplegable", headerName: "", width: 10},
      {field: "alias", headerName: 'Alias', width: 220},
      {field: "codigo", headerName: 'Codigo', width: 150},
      {field: "entidades", headerName: 'Entidades', width: 250},
      {field: "pais", headerName: 'pais', width: 150},
      {field: "estado", headerName: 'Estado', width: 100},
      {field: "", headerName: '', width: 100},
    ]

    return (
      <Box sx={{ maxWidth: 1280, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader class="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {cajeros.map((cajero) => {
                return(
                  <Row cajero={cajero} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default CajerosTable;
