import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosConfigurarBilletero extends Component {

    constructor(props) {
        super(props)

        this.idCajero = props.location.state.id_cajero

        this.state = {
            cajero: {},
            cmbTiposBilletero: "",
            cmbProtocolos: "",
            cmbModos: "",
            cmbVelocidades: "",
            cmbEscrows: "",
            cmbDebugs: "",
            txtPuerto: "",
            txtArchivo: "",
            txtTiempoEscrow: "",
            txtLimiteBilletes: "",
            txtBilletesTransaccion: "",
            txtAlarmaLlenado: "",
            txtDenominaciones: "",
            tiposBilletero: [
                "Ivizion",
                "Mei",
                "Spectra",
                "CA10"
            ],
            protocolos: [
                "IDE003",
                "EBDS",
                "SSP",
                "CA10"
            ],
            modos: [
                "Simple",
                "Multiple"
            ],
            velocidades: [
                "9600",
                "19200",
                "115200"
            ],
            escrows: [
                "Activado",
                "Desactivado"
            ],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

    handleCloseAlert= (event, reason) => {
        if (reason === 'clickaway') {
          return; // Evitar que se cierre si el usuario hace clic fuera
        }
        this.setState({ mostrarAlerta: false })
      }

    onChangecmbTiposBilletero = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposBilletero: tipo})
    }

    onChangecmbProtocolos = (event) => {
        const protocolo = event.target.value
        this.setState({cmbProtocolos: protocolo})
    }

    onChangecmbModos = (event) => {
        const modo = event.target.value
        this.setState({cmbModos: modo})
    }

    onChangecmbEscrows = (event) => {
        const escrow = event.target.value
        this.setState({cmbEscrows: escrow})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangetxtPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtPuerto: puerto})
    }

    onChangecmbVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbVelocidades: velocidad})
    }

    onChangetxtArchivo = (event) => {
        const archivo = event.target.value.toString()
        this.setState({txtArchivo: archivo})
    }

    onChangetxtTiempoEscrow = (event) => {
        const tiempoEscrow = event.target.value.toString()
        this.setState({txtTiempoEscrow: tiempoEscrow})
    }

    onChangetxtAlarmaLlenado = (event) => {
        const alarmaLlenado = event.target.value.toString()
        this.setState({txtAlarmaLlenado: alarmaLlenado})
    }

    onChangetxtLimiteBilletes = (event) => {
        const limiteBilletes = event.target.value.toString()
        this.setState({txtLimiteBilletes: limiteBilletes})
    }

    onChangetxtBilletesTransaccion = (event) => {
        const billetesTransaccion = event.target.value.toString()
        this.setState({txtBilletesTransaccion: billetesTransaccion})
    }

    onChangetxtDenominaciones = (event) => {
        const denominaciones = event.target.value.toString()
        this.setState({txtDenominaciones: denominaciones})
    }

    queryCajero() {
		console.log('Consultando cajero....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajero")
	        //console.log('Error respuesta consultando cajero')
	        this.setState({
				cajero: {},
				mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajero',
                severidadAlerta: 'error'
	        })
	      } else {
	        console.log('Cajero consultado')
	      	var configuracionBilletero = null

                try {
                    configuracionBilletero = JSON.parse(data.configuracion_billetero)
                    this.setState({
                        cmbTiposBilletero: configuracionBilletero.tipo,
                        cmbProtocolos: configuracionBilletero.protocolo,
                        cmbModos: configuracionBilletero.modo,
                        cmbVelocidades: configuracionBilletero.velocidad,
                        cmbEscrows: configuracionBilletero.escrow,
                        cmbDebugs: configuracionBilletero.debug,
                        txtPuerto: configuracionBilletero.puerto,
                        txtTiempoEscrow: configuracionBilletero.tiempo_escrow,
                        txtLimiteBilletes: configuracionBilletero.limite_billetes,
                        txtBilletesTransaccion: configuracionBilletero.billetes_transaccion,
                        txtAlarmaLlenado: configuracionBilletero.alarma_llenado,
                        txtArchivo: configuracionBilletero.archivo,
                        txtDenominaciones: configuracionBilletero.denominaciones
                    })
                } catch(error) {
                }
	      }
	    })
		
    }

    btnGuardarConfiguracionClick = () => {
        console.log('Actualizando configuracion billetero....')

        var continuar = true
        var mensajeError = "Error:\n"

        var tipo = this.state.cmbTiposBilletero
        var protocolo = this.state.cmbProtocolos
        var modo = this.state.cmbModos
        var puerto = this.state.txtPuerto
        var velocidad = this.state.cmbVelocidades
        var escrow =  this.state.cmbEscrows
        var debug =  this.state.cmbDebugs
        var tiempoEscrow = this.state.txtTiempoEscrow
        var limiteBilletes = this.state.txtLimiteBilletes
        var billetesTransaccion = this.state.txtBilletesTransaccion
        var alarmaLlenado = this.state.txtAlarmaLlenado
        var archivo = this.state.txtArchivo
        var denominaciones = this.state.txtDenominaciones
        

        tipo = tipo.trim()
        protocolo = protocolo.trim()
        modo = modo.trim()
        puerto = puerto.trim()
        velocidad = velocidad.trim()
        tiempoEscrow = tiempoEscrow.trim()
        limiteBilletes = limiteBilletes.trim()
        billetesTransaccion = billetesTransaccion.trim()
        alarmaLlenado = alarmaLlenado.trim()
        archivo = archivo.trim()
        denominaciones = denominaciones.trim()


        if(tipo === '') {
          continuar = false
          mensajeError += " * Debe seleccionar el tipo de billetero.\n"
        }
    
        if(continuar) {
			
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				 id_cajero: this.idCajero,
                tipo: tipo,
                protocolo: protocolo,
                modo: modo,
                puerto: puerto,
                velocidad: velocidad,
                escrow: escrow,
                tiempo_escrow: tiempoEscrow,
                debug: debug,
                limite_billetes: limiteBilletes,
                billetes_transaccion: billetesTransaccion,
                alarma_llenado: alarmaLlenado,
                archivo: archivo,
                denominaciones: denominaciones
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/configbilletero'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //alert("Error actualizando informaacion Billetero")
		        //console.log('Error respuesta actualizando Billetero')
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Error actualizando informaacion Billetero',
                    severidadAlerta: 'error'
                })
		      } else {
		        //console.log('Informacion Billetero actualizada')
		        //alert("Informacion Billetero actualizada")
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Informacion Billetero actualizada',
                    severidadAlerta: 'success'
                })
		      }
		    })
	    }
        
    }

    componentDidMount() {
        this.queryCajero()
    }

    render() {
        return(
            <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    Configurar Billetero
                </Typography>

                <Paper elevation={16} >
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <form>
                                        <FormLabel>Información billetero</FormLabel>
                                        <br />
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Tipo</InputLabel>
                                                <Select
                                                    label="Tipo"
                                                    value={this.state.cmbTiposBilletero}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbTiposBilletero}>
                                                    <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                    {this.state.tiposBilletero.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Protocolo</InputLabel>
                                                <Select
                                                    label="Protocolo"
                                                    value={this.state.cmbProtocolos}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbProtocolos}>
                                                    <MenuItem value={0}>-- Seleccione Protocolo --</MenuItem>
                                                    {this.state.protocolos.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Modo</InputLabel>
                                                <Select
                                                    label="Modo"
                                                    value={this.state.cmbModos}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbModos}>
                                                    <MenuItem value={0}>-- Seleccione Modo --</MenuItem>
                                                    {this.state.modos.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Puerto"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtPuerto}
                                                onChange={this.onChangetxtPuerto}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Velocidad</InputLabel>
                                                <Select
                                                    label="Protocolo"
                                                    value={this.state.cmbVelocidades}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbVelocidades}>
                                                    <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                    {this.state.velocidades.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Escrow</InputLabel>
                                                <Select
                                                    label="Escrow"
                                                    value={this.state.cmbEscrows}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbEscrows}>
                                                    <MenuItem value={0}>-- Seleccione Escrow --</MenuItem>
                                                    {this.state.escrows.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Tiempo Escrow"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtTiempoEscrow}
                                                onChange={this.onChangetxtTiempoEscrow}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Debug</InputLabel>
                                                <Select
                                                    label="Debug"
                                                    value={this.state.cmbDebugs}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbDebugs}>
                                                    <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                    {this.state.debugs.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Limite Billetes"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtLimiteBilletes}
                                                onChange={this.onChangetxtLimiteBilletes}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Billetes Transacción"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtBilletesTransaccion}
                                                onChange={this.onChangetxtBilletesTransaccion}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Alarma Llenado"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtAlarmaLlenado}
                                                onChange={this.onChangetxtAlarmaLlenado}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Archivo"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtArchivo}
                                                onChange={this.onChangetxtArchivo}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Denominaciones(Billetes/Monedas)"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtDenominaciones}
                                                multiline
                                                rows={5}
                                                onChange={this.onChangetxtDenominaciones}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </CardContent>
                                <CardActions>
                                        <Button  variant="contained" onClick={this.btnGuardarConfiguracionClick}>Guardar Configuración</Button>
                                        <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                                        </Snackbar>                                    
                                    </CardActions>
                            </Card>
                        </Grid>
                    </Grid>

                </Paper>
            

            </Box>
        )
    }
}


export default CajerosConfigurarBilletero