import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'

function Row(props) {
 const {entidad} = props
 const {evento} = props

 var estado = ""

 switch(entidad.estado) {
  case "A":
    estado = "Activo"
    break
  case "I":
    estado = "Inactivo"
    break
  default:
    break
 }

 return (
   <TableRow>
     <TableCell>
       {entidad.nombre}
     </TableCell>
     <TableCell>
       {entidad.tag_url_login}
    </TableCell>
    <TableCell>
      {entidad.logo_web}
    </TableCell>
    <TableCell>
      {estado}
    </TableCell>
    <TableCell>
      <Button variant="contained" color="primary" onClick={() => {evento(entidad.id)}}><EditIcon /></Button>
    </TableCell>
  </TableRow>
 );
}


class EntidadesTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
    };

    this.eventoSeleccionar = null
  }

  componentDidMount() {
  }

  render() {
    const entidades = this.props.entidades
    const eventoSeleccionar = this.props.eventoSeleccionar

    const columns = [
      {field: "entidad", headerName: 'Entidad', width: 200},
      {field: "tag", headerName: 'Tag URL', width: 170},
      {field: "logo_web", headerName: 'Logo Web', width: 200},
      {field: "estado", headerName: 'Estado', width: 100},
      {field: "", headerName: '', width: 50}
    ]

    return (
      <Box sx={{ maxWidth: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader class="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {entidades.map((entidad) => {
                return(
                  <Row entidad={entidad} evento={eventoSeleccionar} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default EntidadesTable;
