import {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

function Row(props) {
    var item = props.item
    var eventSelect = props.eventSelect
    var eventDelete = props.eventDelete

    return(
        <TableRow>
            <TableCell>{item.alias}</TableCell>
            <TableCell>{item.tipo}</TableCell>
            <TableCell>{item.protocolo}</TableCell>
            <TableCell>{item.puerto}</TableCell>
            <TableCell>{item.velocidad}</TableCell>
            <TableCell>{item.archivo}</TableCell>
            <TableCell>{item.debug}</TableCell>
            <TableCell>{item.estado}</TableCell>
            <TableCell>
                <Button variant="contained" size='small' fullWidth onClick={() => {eventSelect(item)}}>Seleccionar</Button>
                <Button variant="contained" size='small' color="error" fullWidth onClick={() => {eventDelete(item)}}>Borrar</Button>
            </TableCell>
        </TableRow>
    )
}


class TabBilleteros extends Component {
    constructor(props) {
        super(props)

        var tipoDispositivo = props.tipoDispositivo
        var dispositivos = props.dispositivos
        this.guardarEvent = props.event

        this.state = {
            tipoDispositivo: tipoDispositivo,
            dispositivos: dispositivos,
            txtAlias: "",
            cmbTiposBilletero: 0,
            cmbProtocolos: 0,
            cmbModos: 0,
            cmbVelocidades: 0,
            cmbEscrows: 0,
            cmbDebugs: 0,
            txtPuerto: "",
            txtTiempoEscrow: "",
            txtLimiteBilletes: "",
            txtBilletesTransaccion: "",
            txtAlarmaLlenado: "",
            txtArchivo: "",
            txtDenominaciones: "",
            cmbEstados: 0,
            cmbTiposCajon: 0,
            cmbCodigoBarras: 0,
            tiposBilletero: [
                "Ivizion",
                "Mei",
                "Spectra",
                "CA10"
            ],
            protocolos: [
                "IDE003",
                "EBDS",
                "SSP",
                "CA10"
            ],
            modos: [
                "Simple",
                "Multiple"
            ],
            velocidades: [
                "9600",
                "19200",
                "115200"
            ],
            escrows: [
                "Activado",
                "Desactivado"
            ],
            tiposCajon: [
				"Tula",
				"Cashbox"
			],
			codigoBarras: [
				"Activado",
                "Desactivado"
			],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            estados: [
				{nombre: "Activo", valor: "A"},
				{nombre: "Inactivo", valor: "I"}
			],
            modoEdicion: false
        }
    }

    componentWillUnmount() {

    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangecmbTiposBilletero = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposBilletero: tipo})
    }

    onChangecmbProtocolos = (event) => {
        const protocolo = event.target.value
        this.setState({cmbProtocolos: protocolo})
    }

    onChangecmbModos = (event) => {
        const modo = event.target.value
        this.setState({cmbModos: modo})
    }

    onChangecmbEscrows = (event) => {
        const escrow = event.target.value
        this.setState({cmbEscrows: escrow})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangetxtPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtPuerto: puerto})
    }

    onChangecmbVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbVelocidades: velocidad})
    }

    onChangetxtArchivo = (event) => {
        const archivo = event.target.value.toString()
        this.setState({txtArchivo: archivo})
    }

    onChangetxtTiempoEscrow = (event) => {
        const tiempoEscrow = event.target.value.toString()
        this.setState({txtTiempoEscrow: tiempoEscrow})
    }

    onChangetxtAlarmaLlenado = (event) => {
        const alarmaLlenado = event.target.value.toString()
        this.setState({txtAlarmaLlenado: alarmaLlenado})
    }

    onChangetxtLimiteBilletes = (event) => {
        const limiteBilletes = event.target.value.toString()
        this.setState({txtLimiteBilletes: limiteBilletes})
    }

    onChangetxtBilletesTransaccion = (event) => {
        const billetesTransaccion = event.target.value.toString()
        this.setState({txtBilletesTransaccion: billetesTransaccion})
    }

    onChangetxtDenominaciones = (event) => {
        const denominaciones = event.target.value.toString()
        this.setState({txtDenominaciones: denominaciones})
    }
    
    onChangecmbEstados = (event) => {
        const estado = event.target.value
        this.setState({cmbEstados: estado})
    }
    
    onChangecmbTiposCajon = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposCajon: tipo})
    }
    
    onChangecmbCodigoBarras = (event) => {
        const estado = event.target.value
        this.setState({cmbCodigoBarras: estado})
    }

    btnSeleccionarClick = (item) => {
        

        this.setState({
            txtAlias: item.alias,
            cmbTiposBilletero: item.tipo,
            cmbProtocolos: item.protocolo,
            cmbModos: item.modo,
            txtPuerto: item.puerto,
            cmbVelocidades: item.velocidad,
            cmbEscrows: item.escrow,
            txtTiempoEscrow: item.tiempo_escrow,
            cmbDebugs: item.debug,
            txtLimiteBilletes: item.limite_billetes,
            txtBilletesTransaccion: item.billetes_transaccion,
            txtAlarmaLlenado: item.alarma_llenado,
            txtArchivo: item.archivo,
            txtDenominaciones: item.denominaciones,
            cmbEstados: item.estado,
            cmbTiposCajon: item.tipo_cajon,
            cmbCodigoBarras: item.codigo_barras,
            modoEdicion: true
        })
    }

    btnBorrarClick = (item) => {
        
        if(window.confirm("¿Borrar dispositivo?")) {
            this.pepararNuevo()
            var dispositivos = this.state.dispositivos

            for(var x = 0; x < dispositivos.length; x++) {
                if(dispositivos[x].alias === item.alias) {
                    dispositivos.splice(x, 1)
                    break
                }
            }


            this.setState({
                dispositivos: dispositivos
            })

            this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
        }

    }

    btnGuardarClick = () => {

        var dispositivos = this.state.dispositivos

        var item = {
            alias: this.state.txtAlias,
            tipo: this.state.cmbTiposBilletero,
            protocolo: this.state.cmbProtocolos,
            modo: this.state.cmbModos,
            puerto: this.state.txtPuerto,
            velocidad: this.state.cmbVelocidades,
            escrow: this.state.cmbEscrows,
            tiempo_escrow: this.state.txtTiempoEscrow,
            debug: this.state.cmbDebugs,
            limite_billetes: this.state.txtLimiteBilletes,
            billetes_transaccion: this.state.txtBilletesTransaccion,
            alarma_llenado: this.state.txtAlarmaLlenado,
            archivo: this.state.txtArchivo,
            denominaciones: this.state.txtDenominaciones,
            tipo_cajon: this.state.cmbTiposCajon,
            codigo_barras: this.state.cmbCodigoBarras,
            estado: this.state.cmbEstados
        }

        if(this.state.modoEdicion) {
            //Buscar el dispositivo por el alias
            for(var x = 0; dispositivos.length; x++) {
                if(dispositivos[x].alias === this.state.txtAlias) {
                    dispositivos[x] = item
                    break
                }
            }
        } else {
            dispositivos.push(item)
        }

        this.setState({
            dispositivos: dispositivos
        })

        this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
    }

    pepararNuevo = () => {
        this.setState({
            txtAlias: "",
            cmbTiposBilletero: 0,
            cmbProtocolos: 0,
            cmbModos: 0,
            txtPuerto: "",
            cmbVelocidades: 0,
            cmbEscrows: 0,
            txtTiempoEscrow: "",
            cmbDebugs: 0,
            txtLimiteBilletes: "",
            txtBilletesTransaccion: "",
            txtAlarmaLlenado: "",
            txtArchivo: "",
            txtDenominaciones: "",
            cmbTiposCajon: 0,
            cmbCodigoBarras: 0,
            cmbEstados: 0,
            modoEdicion: false
        })
    }

    confirmacionGuardado = () => {
        this.pepararNuevo()
    }

    render() {
        const columns = [
            {field: 'Alias', headerName: 'Alias', width: 150},
            {field: 'Tipo', headerName: 'Tipo', width: 100},
            {field: 'Protocolo', headerName: 'Protocolo', width: 100},
            {field: 'Puerto', headerName: 'Puerto', width: 100},
            {field: 'Velocidad', headerName: 'Velocidad', width: 50},
            {field: 'Archivo', headerName: 'Archivo', width: 100},
            {field: 'Debug', headerName: 'Debug', width: 50},
            {field: 'Estado', headerName: 'Estado', width: 50},
            {field: '', headerName: '', width: 50}
          ]

        return(
            <Box>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Card fullWidth>
                            <CardContent>
                            	<h3>Billeteros</h3>
                                <form>
                                    <FormLabel>Información billetero</FormLabel>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Alias"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtAlias}
                                                onChange={this.onChangetxtAlias}
                                                inputProps={{disabled: this.state.modoEdicion}}
                                                disabled={this.state.modoEdicion}
                                                fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                label="Tipo"
                                                value={this.state.cmbTiposBilletero}
                                                defaultValue={0}
                                                onChange={this.onChangecmbTiposBilletero}>
                                                <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                {this.state.tiposBilletero.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Protocolo</InputLabel>
                                            <Select
                                                label="Protocolo"
                                                value={this.state.cmbProtocolos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbProtocolos}>
                                                <MenuItem value={0}>-- Seleccione Protocolo --</MenuItem>
                                                {this.state.protocolos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Modo</InputLabel>
                                            <Select
                                                label="Modo"
                                                value={this.state.cmbModos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbModos}>
                                                <MenuItem value={0}>-- Seleccione Modo --</MenuItem>
                                                {this.state.modos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Puerto"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtPuerto}
                                            onChange={this.onChangetxtPuerto}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Velocidad</InputLabel>
                                            <Select
                                                label="Velocidad"
                                                value={this.state.cmbVelocidades}
                                                defaultValue={0}
                                                onChange={this.onChangecmbVelocidades}>
                                                <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                {this.state.velocidades.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Tipo Cajon</InputLabel>
                                            <Select
                                                label="Tipo Cajon"
                                                value={this.state.cmbTiposCajon}
                                                defaultValue={0}
                                                onChange={this.onChangecmbTiposCajon}>
                                                <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                {this.state.tiposCajon.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Codigo Barras</InputLabel>
                                            <Select
                                                label="Codigo Barras"
                                                value={this.state.cmbCodigoBarras}
                                                defaultValue={0}
                                                onChange={this.onChangecmbCodigoBarras}>
                                                <MenuItem value={0}>-- Seleccione --</MenuItem>
                                                {this.state.codigoBarras.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Escrow</InputLabel>
                                            <Select
                                                label="Escrow"
                                                value={this.state.cmbEscrows}
                                                defaultValue={0}
                                                onChange={this.onChangecmbEscrows}>
                                                <MenuItem value={0}>-- Seleccione Escrow --</MenuItem>
                                                {this.state.escrows.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Tiempo Escrow"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtTiempoEscrow}
                                            onChange={this.onChangetxtTiempoEscrow}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Debug</InputLabel>
                                            <Select
                                                label="Debug"
                                                value={this.state.cmbDebugs}
                                                defaultValue={0}
                                                onChange={this.onChangecmbDebugs}>
                                                <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                {this.state.debugs.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Limite Billetes"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtLimiteBilletes}
                                            onChange={this.onChangetxtLimiteBilletes}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Billetes Transacción"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtBilletesTransaccion}
                                            onChange={this.onChangetxtBilletesTransaccion}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Alarma Llenado"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtAlarmaLlenado}
                                            onChange={this.onChangetxtAlarmaLlenado}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Archivo"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtArchivo}
                                            onChange={this.onChangetxtArchivo}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Denominaciones(Billetes/Monedas)"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtDenominaciones}
                                            multiline
                                            rows={5}
                                            onChange={this.onChangetxtDenominaciones}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Estado</InputLabel>
                                            <Select
                                                label="Estado"
                                                value={this.state.cmbEstados}
                                                defaultValue={0}
                                                onChange={this.onChangecmbEstados}>
                                                <MenuItem value={0}>-- Seleccione estado --</MenuItem>
                                                {this.state.estados.map(item => (
                                                <MenuItem value={item.valor}>{item.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                            <CardActions>
                                <Button  variant="contained" onClick={this.btnGuardarClick}>{ this.state.modoEdicion  === true ? "Modificar": "Agregar"}</Button>
                                <Button  variant="contained" onClick={this.pepararNuevo}>Cancelar</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Card xs={8}>
                    <CardContent xs={8}>
                        <TableContainer xs={8}>
                            <Table stickyHeader class="table-data" xs={8}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => {
                                                return(
                                                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.state.dispositivos.map((dispositivo) => {
                                        return(<Row item = {dispositivo} eventSelect={this.btnSeleccionarClick} eventDelete={this.btnBorrarClick} />)
                                    })
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

export default TabBilleteros