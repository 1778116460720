import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import PagoAutomaticoIcon from '@mui/icons-material/Aod';
import PagoManualIcon from '@mui/icons-material/MailOutline';

function Row(props) {
 const {registro} = props;

 var flagWidth = 25
 var estiloFila = ""

 if(registro.tipo_transaccion === 200) {
   estiloFila = "fila_deposito_manual"
 }

 return (
   <React.Fragment>
     <TableRow class={estiloFila}>
       <TableCell>
         {registro.fecha}
       </TableCell>
       <TableCell>
         {registro.tipo_transaccion === 100 ? <PagoAutomaticoIcon /> : <PagoManualIcon />}
       </TableCell>
       <TableCell>
        {registro.total_depositos.toLocaleString()}
       </TableCell>
       <TableCell>
        <img alt='x' src={'/flags/' + registro.moneda.toLowerCase() + '.png'} width={flagWidth} /> {registro.moneda}
       </TableCell>
       <TableCell>
         {registro.num_transacciones}
       </TableCell>
       <TableCell>
         {registro.cajero_alias}
       </TableCell>
       <TableCell>
         {registro.cajero_codigo}
       </TableCell>
       <TableCell>
         {registro.pais}
       </TableCell>
       <TableCell>
         {registro.ciudad}
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
   </React.Fragment>
 );
}

class TotalesDiaTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      registros: []
    };
  }



  componentDidMount() {
  }

  render() {
    var flagWidth = 25
    const registros = this.props.registros

    var totalDepositos = []
    
    //Sumar los totales de depositos dependiendo la moneda. Se almacena en un arreglo cada tipo de moneda encontrado y se va sumando el todal de cada una
    registros.forEach((registro) => {
      
      var blnAgregarNuevaMoneda = true

      var valorTemporal = registro.total_depositos
      for(var x = 0; x < totalDepositos.length; x++) {
        
        if(totalDepositos[x].moneda === registro.moneda && totalDepositos[x].tipo === registro.tipo_transaccion) {
          totalDepositos[x].total += valorTemporal
          blnAgregarNuevaMoneda = false
          break
        }
      }

      if(blnAgregarNuevaMoneda) {
        totalDepositos.push({
          moneda: registro.moneda,
          tipo: registro.tipo_transaccion,
          total: valorTemporal
        })
      }

    })

    const columns = [
      {field: "fecha", headerName: 'Fecha', width: 80},
      {field: "tipo_transaccion", headerName: 'Tipo', width: 50},
      {field: "total_depositos", headerName: 'Total', width: 100},
      {field: "moneda", headerName: 'Moneda', width: 30},
      {field: "num_transacciones", headerName: '# Transacciones', width: 50},
      {field: "cajero_alias", headerName: 'Cajero-Alias', width: 150},
      {field: "cajero_codigo", headerName: 'Cajero-Codigo', width: 100},
      {field: "pais", headerName: 'País', width: 100},
      {field: "ciudad", headerName: 'Ciudad', width: 100}
    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {registros.map((registro) => {
                return(
                  <Row registro={registro} />
                )
              })}
            </TableBody>
          </Table>
          <Table sx={{width: '100%'}}>
            {totalDepositos.map((total) => {
              return(
                <TableRow>
                  <TableCell sx={{width: '70%'}}></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{total.tipo === 100 ? <PagoAutomaticoIcon /> : <PagoManualIcon />}</TableCell>
                  <TableCell>Total:</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right">{total.total.toLocaleString()}</TableCell>
                  <TableCell align="right"><img alt='x' src={'/flags/' + total.moneda.toLowerCase() + '.png'} width={flagWidth} />{total.moneda}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default TotalesDiaTable;
