import React, {Component} from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Alert from '@mui/lab/Alert'
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'


import RetirosTable from './RetirosTable'
import { AlertTitle, Collapse, Grid, Paper, Snackbar } from '@mui/material'

const {DateTime} = require('luxon')
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class RetirosPage extends Component {

  constructor() {
    super()

      //Definir fechas de inicio para los filtros de fecha
      var fechaInicioTemp = DateTime.local().set({hour: 0, minute: 0, second: 0})
      var fechaFinTemp = DateTime.local().set({hour: 23, minute: 59, second: 59})

    this.state = {
      paises: [],
      entidades: [],
      cajeros: [],
      grupos: [],
      filtroFechaInicio: fechaInicioTemp,
      filtroFechaFin: fechaFinTemp,
      retiros: [],

      mostrarAlerta: false,
      mensajeAlerta: '',
      severidadAlerta: ''
    };

    this.btnBucarClick = this.btnBucarClick.bind(this)

    this.cmbGrupos = React.createRef()
    this.cmbPaises = React.createRef()
    this.cmbCajeros = React.createRef()
    this.cmbEntidades = React.createRef()

    this.exportExcel = this.exportExcel.bind(this)

    this.filtroFechaInicio = ""
    this.filtroFechaFin = ""
  }

  fechaFiltroChanged(origen, date) {
    if (date == null) return

    var formatoFechaInicio = "yyyy-LL-dd HH:mm:00"
    var formatoFechaFin = "yyyy-LL-dd HH:mm:59"

    switch (origen) {
      case 'inicio':
        this.setState({
          filtroFechaInicio: date
        })
        this.filtroFechaInicio = date.toFormat(formatoFechaInicio)
        break;
      case 'fin':
        this.setState({
          filtroFechaFin: date
        })
        this.filtroFechaFin = date.toFormat(formatoFechaFin)
        break;
      default:

    }
  }

  btnBucarClick() {
    this.queryRetiros()
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  queryEntidades() {
    console.log('Consultando entidades....')
        
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando entidades")
        //console.log('Error respuesta consultando entidades')
        this.setState({
          entidades: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando entidades',
          severidadAlerta: 'error'
        })
      } else {
        console.log('Entidades consultadas')
      	this.setState({
        	entidades: data
        })
      }
    })
  }

  queryGrupos() {
    console.log('Consultando grupos....')
        
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando grupos")
        //console.log('Error respuesta consultando grupos')
        this.setState({
          grupos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando grupos',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Grupos consultados')
      	this.setState({
        	grupos: data
        })
      }
    })
  }

  queryPaises() {
    //console.log('Consultando paises....')

	var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/paises/'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando paises")
        //console.log('Error respuesta consultando paises')
        this.setState({
          paises: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando paises',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Paises consultados')
      	this.setState({
        	paises: data
        })
      }
    })
  }

  queryCajeros() {

    // console.log('Consultando cajeros....')
     var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosconsultas/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando cajeros")
        //console.log('Error respuesta consultando cajeros')
        this.setState({
          cajeros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando cajeros',
          severidadAlerta: 'error'
        })
      } else {
        //console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
    
   }

  queryRetiros() {
    //console.log('Consultando....')

    var filtroIdPais = ''
    var filtroIdGrupo = ''
    var filtroIdCajero = ''
    var filtroIdEntidad = ''

	/*
    if(this.cmbPaises.current.value > 0){
      filtroIdPais = this.cmbPaises.current.value
    }
    */

    if(this.cmbGrupos.current.value !== 0){
      filtroIdGrupo = this.cmbGrupos.current.value
    }

    if(this.cmbCajeros.current.value !== 0){
      filtroIdCajero = this.cmbCajeros.current.value
    }
    
    if(this.cmbEntidades.current.value !== 0){
      filtroIdEntidad = this.cmbEntidades.current.value
    }
    
    if(filtroIdGrupo === '' && filtroIdCajero === '') {
      //alert("Debe seleccionar un grupo o un cajero")
      this.setState({

        mostrarAlerta: true,
        mensajeAlerta: 'Debe seleccionar un grupo o un cajero',
        severidadAlerta: 'warning'
      })
      return
    }

	var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		filtro_id_pais: filtroIdPais,
        filtro_id_grupo: filtroIdGrupo,
        filtro_id_cajero: filtroIdCajero,
        filtro_id_entidad: filtroIdEntidad,
        filtro_fecha_inicio: this.filtroFechaInicio,
        filtro_fecha_fin: this.filtroFechaFin
		},
      url: process.env.REACT_APP_URL_BACKEND + '/retiros/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando retiros")
        // console.log('Error respuesta consultando retiros')
        this.setState({
          retiros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando retiros',
          severidadAlerta: 'error'
        })
      } else {
        // console.log('Retiros consultados')
      	this.setState({
        	retiros: data
        })
      }
    })

   }

  exportExcel() {
    
    var workbook = new ExcelJS.Workbook()
    var sheet = workbook.addWorksheet("Retiros")

    var nombreArchivo = 'Retiros.xlsx'

    var headers = [
      {header: 'Fecha Registro'},
      {header: 'Codigo Cajero'},
      {header: 'Alias Cajero'},
      {header: 'Entidad'},
      {header: 'Referencia Cliente'},
      {header: 'Sesion'},
      {header: 'Valor'},
      {header: 'Moneda'},
      {header: 'Finalizacion'},
      {header: 'Aprobacion'},
      {header: 'Fecha Aprobacion'},
      {header: 'Fecha Dispositivo'},
      {header: 'Fecha Recaudo'},
      {header: 'Serial Cajero'},
      {header: 'Tipo Transaccion'},
      {header: 'Consecutivo'},
      {header: 'Num Transaccion'},
      {header: 'Detalle'},

    ]
    sheet.columns = headers


    var records = this.state.retiros
    records.forEach((item) => {
      var row = [
        item.fecha_registro,
        item.codigo_cajero,
        item.alias_cajero,
        item.entidad,
        item.referencia_cliente,
        item.sesion,
        item.valor,
        item.moneda,
        item.finalizacion,
        item.aprobacion,
        item.fecha_aprobacion,
        item.fecha_dispositivo,
        item.fecha_recaudo,
        item.serial_cajero,
        item.tipo_transaccion,
        item.consecutivo,
        item.num_transaccion,
        item.detalle,
      ]
      sheet.addRow(row)
    })
    


    workbook.xlsx.writeBuffer().then(function(data) {
      FileSaver.saveAs(new Blob([data]), nombreArchivo)
    
      //alert("Archivo generado")
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: 'Archivo generado',
        severidadAlerta: 'success'
      })
    })


  }

  componentDidMount() {
    this.queryGrupos()
    this.queryPaises()
    this.queryCajeros()
    this.queryEntidades()
    //this.queryRetiros()
  }

  render() {
    return (
      <Box>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
          Retiros
        </Typography>
        
        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                  Buscar retiros:
              </Typography>

              <Grid container spacing={2} sx={{marginTop: 1}}>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <DateTimePicker
                        label="Fecha/Hora Inicio"
                        value={this.state.filtroFechaInicio}
                        onChange={date => this.fechaFiltroChanged('inicio', date)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                          label="Fecha fin"
                          value={this.state.filtroFechaFin}
                          onChange={date => this.fechaFiltroChanged('fin', date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>

                  </Grid>

                  <Grid item xs={3}>
                    <FormControl sx={{visibility: 'hidden'}}>
                      <InputLabel>Pais</InputLabel>
                      <Select style={{width: 0}}
                        label="Entidad"
                        inputRef={this.cmbPaises}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Seleccione --</MenuItem>
                        {this.state.paises.map(pais => (
                          <MenuItem value={pais.id}>{pais.nombre}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
          
                  </Grid>
              </Grid>

              <Grid container spacing={2} sx={{marginTop: 1}}>
                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel>Grupo</InputLabel>
                      <Select style={{width: 200}}
                        label="Entidad"
                        inputRef={this.cmbGrupos}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Seleccione --</MenuItem>
                        {this.state.grupos.map(grupo => (
                          <MenuItem value={grupo.id}>{grupo.nombre}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel>Cajero</InputLabel>
                      <Select style={{width: 200}}
                        label="Cajero"
                        inputRef={this.cmbCajeros}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Seleccione --</MenuItem>
                        {this.state.cajeros.map(cajero => (
                          <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </Grid>

                  <Grid item xs={3}>
                    <FormControl>
                      <InputLabel>Entidad</InputLabel>
                      <Select style={{width: 200}}
                        label="Entidad"
                        inputRef={this.cmbEntidades}
                        defaultValue={0}>
                        <MenuItem value={0}>-- Todas --</MenuItem>
                        {this.state.entidades.map(entidad => (
                          <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
          
                  </Grid>
              </Grid>

            </CardContent>
            <CardActions>
              <Button variant="contained" size="large" onClick={this.btnBucarClick}>Buscar</Button>
              <Snackbar
                                open={this.state.mostrarAlerta}
                                onClose={this.handleCloseAlert}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                              >
                                <Collapse in={this.state.mostrarAlerta}>
                                    <Alert
                                          severity={this.state.severidadAlerta}
                                          onClose={this.handleCloseAlert}
                                          sx={{ marginTop: 4, width:'100%'}}
                                              >
                                    <AlertTitle>
                                      {this.state.severidadAlerta.toUpperCase()}
                                    </AlertTitle>
                                       {this.state.mensajeAlerta}
                                    </Alert>
                                </Collapse>
              </Snackbar>
            </CardActions>
          </Card>

        </Paper>

        <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Listado retiros:
              </Typography>
              <Divider />
              { this.state.retiros.length > 0 &&
                <Button variant='contained' onClick={this.exportExcel}>Exportar</Button>
              }
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <RetirosTable retiros={this.state.retiros} />
              </Box>
            </CardContent>
            <CardActions>
              { this.state.retiros.length > 0 &&
                <Button variant='contained' onClick={this.exportExcel}>Exportar</Button>
              }
            </CardActions>
          </Card>

        </Paper>
      </Box>

    )
  }
}

export default RetirosPage
