import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import DatePicker from '@mui/lab/DatePicker'
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import TotalesRecaudoTable from './TotalesRecaudoTable'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class TotalesRecaudoPage extends Component {
    constructor() {
      super() 

      this.state = {
        grupos: [],
        cajeros: [],
        registros: [],
        filtroFechaInicio: null,
        filtroFechaFin: null,

        mostrarAlerta: false,
        mensajeAlerta: '',
        severidadAlerta: ''
      }

      this.btnBucarClick = this.btnBucarClick.bind(this)

      this.cmbGrupos = React.createRef()
      this.cmbCajeros = React.createRef()

      this.filtroFechaInicio = ""
      this.filtroFechaFin = ""
    }

    btnBucarClick() {
      this.queryTotales()
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }

    fechaFiltroChanged(origen, date) {
      if (date == null) return
      
      var formatoFechaInicio = "yyyy-LL-dd 00:00:00"
      var formatoFechaFin = "yyyy-LL-dd 23:59:59"
  
      switch (origen) {
        case 'inicio':
          this.setState({
            filtroFechaInicio: date
          })
          this.filtroFechaInicio = date.toFormat(formatoFechaInicio)
          break;
        case 'fin':
          this.setState({
            filtroFechaFin: date
          })
          this.filtroFechaFin = date.toFormat(formatoFechaFin)
          break;
        default:
  
      }
    }

 queryGrupos() {
    console.log('Consultando grupos....')
        
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando grupos")
        //console.log('Error respuesta consultando grupos')
        this.setState({
          grupos: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando grupos',
          severidadAlerta: 'error'
        })
      } else {
        console.log('Grupos consultados')
      	this.setState({
        	grupos: data
        })
      }
    })
  }


  queryCajeros() {
    console.log('Consultando cajeros....')
     var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosconsultas/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando cajeros")
        //console.log('Error respuesta consultando cajeros')
        this.setState({
          cajeros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando cajeros',
          severidadAlerta: 'error'
        })
      } else {
        console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
    
   }


queryTotales() {
    console.log('Consultando totales....')

    var filtroIdGrupo = ''
    var filtroIdCajero = ''
  
    if(this.cmbGrupos.current.value !== 0){
      filtroIdGrupo = this.cmbGrupos.current.value
    }

    if(this.cmbCajeros.current.value !== 0){
      filtroIdCajero = this.cmbCajeros.current.value
    }
    
    if(filtroIdGrupo === '') {
      // alert("Debe seleccionar un grupo")
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: "Debe seleccionar un grupo",
        severidadAlerta: "warning"
      })
      //return
    }
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		filtro_id_grupo: filtroIdGrupo,
        filtro_id_cajero: filtroIdCajero,
        filtro_fecha_inicio: this.filtroFechaInicio,
        filtro_fecha_fin: this.filtroFechaFin
		},
      url: process.env.REACT_APP_URL_BACKEND + '/reportes/totalesrecaudo/'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando totales")
        //console.log('Error respuesta consultando totales')
        this.setState({
          registros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando totales',
          severidadAlerta: 'error'
        })
      } else {
        console.log('Totales consultados')
      	this.setState({
        	registros: data
        })
      }
    })
      
}

componentDidMount() {
  this.queryGrupos()
  this.queryCajeros()
}

    render() {
        return(
            <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Totales Recaudo
              </Typography>

              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <Grid container xs={10} spacing={1}>
                        <Grid item fullWidth>
                          <Typography gutterBottom variant="h6" component="div">
                            Filtrar totales:
                          </Typography>
                        </Grid>
                        
                        <Grid item container fullWidth spacing={2}>
                          <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DatePicker
                                label="Fecha inicio"
                                value={this.state.filtroFechaInicio}
                                onChange={date => this.fechaFiltroChanged('inicio', date)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={2}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DatePicker
                                label="Fecha fin"
                                value={this.state.filtroFechaFin}
                                onChange={date => this.fechaFiltroChanged('fin', date)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={2}>
                            <FormControl fullWidth>
                              <InputLabel>Grupo</InputLabel>
                              <Select
                                label="Grupo"
                                inputRef={this.cmbGrupos}
                                defaultValue={0}>
                                <MenuItem value={0}>-- Seleccione --</MenuItem>
                                {this.state.grupos.map(grupo => (
                                  <MenuItem value={grupo.id}>{grupo.nombre}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel>Cajero</InputLabel>
                              <Select
                                label="Cajero"
                                inputRef={this.cmbCajeros}
                                defaultValue={0}>
                                <MenuItem value={0}>-- Todos los cajeros --</MenuItem>
                                {this.state.cajeros.map(cajero => (
                                  <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" size="large" onClick={this.btnBucarClick}>Buscar</Button>
                    
                    <Snackbar
                      open={this.state.mostrarAlerta}
                      onClose={this.handleCloseAlert}
                      autoHideDuration={3000}
                      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                      <Collapse in={this.state.mostrarAlerta}>
                          <Alert
                                        severity={this.state.severidadAlerta}
                                        onClose={this.handleCloseAlert}
                                        sx={{ marginTop: 4, width:'100%'}}
                                      >
                                        <AlertTitle>{this.state.severidadAlerta.toUpperCase()}</AlertTitle>
                                        {this.state.mensajeAlerta}
                          </Alert>
                      </Collapse>
                    </Snackbar>

                  </CardActions>
                </Card>
              </Paper>

              <br />

              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Totales:
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <TotalesRecaudoTable registros={this.state.registros}/>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" onClick={this.btnExportClick} sx={{visibility: 'hidden'}}>Exportar</Button>
                  </CardActions>
                </Card>
              </Paper>
            </Box>
        )
    }

}

export default TotalesRecaudoPage