import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CardActions from '@mui/material/CardActions'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class ClientesEditarPage extends Component {

    constructor(props) {
        super(props)

        this.idCliente = props.location.state.id_cliente
        this.entidadesSeleccionadas = []

        this.state = {
            cliente: {},
            txtNombre: "",
            txtIdentificacion: "",
            txtCodigo: "",
            txtPasswordNueva: "",
            txtPasswordVerificacion: "",
            txtDeuda: "",
            txtSaldo: "",
            txtLimiteDeposito: "",
            cmbEntidades: 0,
            entidades: [],
            permisos: [],
            permisosDisponibles: [],
            permisosCliente: [],
            cajeros: [],
            cajerosDisponibles: [],
            cajerosCliente: [],

            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

  handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
  }

  onChangetxtNombre = (event) => {
      const nombre = event.target.value.toString()
      this.setState({txtNombre: nombre})
  }

  onChangetxtIdentificacion = (event) => {
      const identificacion = event.target.value.toString()
      this.setState({txtIdentificacion: identificacion})
  }

  onChangetxtCodigo= (event) => {
    const codigo = event.target.value.toString()
    this.setState({txtCodigo: codigo})
  }

  onChangetxtPasswordNueva = (event) => {
    const password = event.target.value.toString()
    this.setState({txtPasswordNueva: password})
  }

  onChangetxtPasswordVerificacion = (event) => {
    const password = event.target.value.toString()
    this.setState({txtPasswordVerificacion: password})
  }

  onChangetxtDeuda = (event) => {
    const deuda = event.target.value.toString()
    this.setState({txtDeuda: deuda})
  }

  onChangetxtSaldo = (event) => {
    const saldo = event.target.value.toString()
    this.setState({txtSaldo: saldo})
  }

  onChangetxtLimiteDeposito = (event) => {
    const limite = event.target.value.toString()
    this.setState({txtLimiteDeposito: limite})
  }

  onChangecmbEntidades= (event) => {
    const entidad = event.target.value
    this.setState({cmbEntidades: entidad})
  }

  actualizarCajerosDisponibles() {
    var cajeros = this.state.cajeros
    
    var cajerosDisponibles = []
    var cajerosCliente = this.state.cajerosCliente

    cajeros.forEach((cajero) => {
      var blnAgregar = true

      cajerosCliente.forEach((cajeroCliente) => {
        if(cajeroCliente.id === cajero.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        cajerosDisponibles.push(cajero)
      }
    })
    
    this.setState({
      cajerosDisponibles: cajerosDisponibles
    })
  }

  agregarCajero(idCajero) {
    var cajerosDisponibles = this.state.cajerosDisponibles
    var cajerosCliente = this.state.cajerosCliente

    cajerosDisponibles.forEach((cajero) => {
      if(cajero.id === idCajero) {
        cajerosCliente.push(cajero)
      }
    })

    this.setState({
      cajerosCliente: cajerosCliente
    })

    this.actualizarCajerosDisponibles()
  }

  removerCajero(idCajero) {
    var cajerosCliente = this.state.cajerosCliente
    for(var x = 0; x < cajerosCliente.length; x++) {
      if(cajerosCliente[x].id === idCajero) {
        cajerosCliente.splice(x, 1) 
        break
      }
    }

    this.setState({
      cajerosCliente: cajerosCliente
    })

    this.actualizarCajerosDisponibles()
  }

  formatearPermisos(strPermisos) {
    strPermisos = JSON.parse(strPermisos)
    this.setState({
      permisos: strPermisos.permisos
    })
  }

  actualizarPermisosDisponibles() {
    var permisos = this.state.permisos
    
    var permisosDisponibles = []
    var permisosCliente = this.state.permisosCliente

    permisos.forEach((rol) => {
      var blnAgregar = true

      permisosCliente.forEach((permisoCliente) => {
        if(permisoCliente.id === rol.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        permisosDisponibles.push(rol)
      }
    })
    
    this.setState({
      permisosDisponibles: permisosDisponibles
    })
  }

  agregarPermiso(idPermiso) {
    var permisosDisponibles = this.state.permisosDisponibles
    var permisosCliente = this.state.permisosCliente

    permisosDisponibles.forEach((permiso) => {
      if(permiso.id === idPermiso) {
        permisosCliente.push(permiso)
      }
    })

    this.setState({
      permisosCliente: permisosCliente
    })

    this.actualizarPermisosDisponibles()
  }

  removerPermiso(idPermiso) {
    var permisosCliente = this.state.permisosCliente
    for(var x = 0; x < permisosCliente.length; x++) {
      if(permisosCliente[x].id === idPermiso) {
        permisosCliente.splice(x, 1) 
        break
      }
    }

    this.setState({
      permisosCliente: permisosCliente
    })

    this.actualizarPermisosDisponibles()
  }


    componentDidMount() {
        this.queryCliente()
        this.queryEntidades()
        this.queryPermisos()
        this.queryCajeros()
        this.queryCajerosCliente()
    }

    btnActualizarClienteClick = () => {
        console.log('Actualizando cliente....')

        var continuar = true
        var mensajeError = "Error:\n"

        var nombre = this.state.txtNombre
        var identificacion = this.state.txtIdentificacion
        var codigo = this.state.txtCodigo
        var deuda = this.state.txtDeuda
        var saldo = this.state.txtSaldo
        var limiteDeposito = this.state.txtLimiteDeposito
        var idEntidad = this.state.cmbEntidades
        
        nombre = nombre.trim()
        identificacion = identificacion.trim()
        codigo = codigo.trim()
        
        if(nombre === '') {
          continuar = false
          mensajeError += " * Debe ingresar el nombre.\n"
        }

        if(identificacion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la identificación.\n"
        }

        if(codigo === '') {
          continuar = false
          mensajeError += " * Debe ingresar el codigo.\n"
        }

        if(idEntidad === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar una entidad.\n"
        }
        
        if(continuar) {
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.state.cliente.id,
                nombre: nombre,
                identificacion: identificacion,
                codigo: codigo,
                deuda: deuda,
                saldo: saldo,
                limite_deposito: limiteDeposito,
                id_entidad: idEntidad
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cliente/edit'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar cliente')
            //alert("Se produjo un error actualizando el cliente")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando el cliente',
              severidadAlerta: 'error'
            })
	      } else {
            //console.log('Cliente actualizado')
            //alert("Cliente Actualizado")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cliente Actualizado',
              severidadAlerta: 'success'
            })
	      }
	    })
		    
	  } else {
	      //alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
	    }
        
    }

    btnActualizarPasswordClick = () => {
      console.log('Actualizando password....')

      var continuar = true
      var mensajeError = "Error:\n"

      var passwordNueva = this.state.txtPasswordNueva.toString()
      var passwordVerifiacion = this.state.txtPasswordVerificacion.toString()

      passwordNueva = passwordNueva.trim()
      passwordVerifiacion = passwordVerifiacion.trim()
      
      if(passwordNueva === '') {
        continuar = false
        mensajeError += " * Debe ingresar la nueva contraseña.\n"
      }

      if(passwordVerifiacion === '') {
        continuar = false
        mensajeError += " * Debe ingresar la verificación de la contraseña.\n"
      }

      if(passwordNueva !== passwordVerifiacion) {
        continuar = false
        mensajeError += " * La contraseña nueva y la verificación no coinciden.\n"
      }
  
      if(continuar) {
		  var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.state.cliente.id,
              cliente: this.state.cliente.identificacion + ' ' + this.state.cliente.codigo,
              password: passwordNueva
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cliente/password'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar contraseña')
            //alert("Se produjo un error actualizando la contraseña")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando la contraseña',
              severidadAlerta: 'error'
            })
	      } else {
            //console.log('Contraseña actualizada')
            //alert("Contraseña Actualizada")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Contraseña Actualizada',
              severidadAlerta: 'success'
            })
	      }
	    })
	} else {
      // ///alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
      
  }

  btnGuardarCajerosClick = () => {
    var cajerosCliente = this.state.cajerosCliente
    var cajeros = []
    var mensajeError = "Error:\n"
    var continuar = true

    
    cajerosCliente.forEach((cajero) => {
        cajeros.push(cajero.id)
    })
    
    
    if(continuar) {
		var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.state.cliente.id,
	            cliente: this.state.cliente.identificacion + ' ' + this.state.cliente.codigo,
	            cajeros: cajeros
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cliente/cajeros'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar cajeros')
            //alert("Se produjo un error actualizando los cajeros")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando los cajeros',
              severidadAlerta: 'error'
            })
	      } else {
            // console.log('Cajeros actualizados')
            // alert("Cajeros Actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cajeros Actualizados',
              severidadAlerta: 'success'
            })
	      }
	    })
	} else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  btnGuardarPermisosClick = () => {
    var permisosCliente = this.state.permisosCliente
    var permisos = []
    var mensajeError = "Error:\n"
    var continuar = true

    permisosCliente.forEach((permiso) => {
      permisos.push(permiso)
    })


    if(continuar) {
		var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.state.cliente.id,
	            cliente: this.state.cliente.identificacion + ' ' + this.state.cliente.codigo,
	            permisos: permisos
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cliente/permisos'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar permisos')
            //alert("Se produjo un error actualizando los permisos")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando los permisos',
              severidadAlerta: 'error'
            })
	      } else {
            //console.log('Permisos actualizados')
            //alert("Permisos Actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Permisos Actualizados',
              severidadAlerta: 'success'
            })
	      }
	    })
    } else {
      // alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  cambiarEstado() {
      var estado = this.state.cliente.estado
      
      switch(estado) {
          case "I":
              estado = "A"
              break
          case "A":
              estado = "I"
              break
          default:
              break
      }

      console.log('Actualizando cliente....')

		var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.idCliente,
	              cliente: this.state.cliente.identificacion + ' ' + this.state.cliente.codigo,
	              estado: estado
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cliente/estado/'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar cliente')
            //alert("Se produjo un error actualizando el cliente")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando el cliente',
              severidadAlerta: 'error'
            })
	      } else {
            //console.log('Cliente acutalizado')
            //alert("Cliente acutalizado")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cliente acutalizado',
              severidadAlerta: 'success'
            })
            this.queryCliente()
	      }
	    })

    }

    queryCliente() {
        //console.log('Consultando cliente....')
        
        var restArgs = {
		      method: 'POST',
		      headers: 'JSON',
		      body: {
			  	id_cliente: this.idCliente
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/clientes/detalle'
		    }
		
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta consultando cliente')
            this.setState({
            	mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error consultando el cliente',
              severidadAlerta: 'error'
            })
            // alert("Se produjo un error consultando el cliente")
	      } else {
            console.log('Cliente consultado')

            var permisos = []

			var cliente = data

            try {
              var permisosObj = JSON.parse(cliente.permisos)
              permisos = permisosObj.permisos
            } catch {

            }

            this.setState({
                cliente: cliente,
                txtNombre: cliente.nombre,
                txtIdentificacion: cliente.identificacion,
                txtCodigo: cliente.codigo,
                txtDeuda: cliente.deuda,
                txtSaldo: cliente.saldo,
                txtLimiteDeposito: cliente.limite_deposito,
                cmbEntidades: cliente.id_entidad,
                permisosCliente: permisos
            })
            this.actualizarPermisosDisponibles()
	      }
	    })
        
    }

    queryEntidades() {
        console.log('Consultando entidades....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidades")
	        //console.log('Error respuesta consultando entidades')
              this.setState({
				        entidades: [],
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando entidades',
                severidadAlerta: 'error'
              })
	      } else {
	        //console.log('Entidades consultadas')
              this.setState({
                entidades: data
                })
	      }
	    })
        
      }

      queryCajeros() {
        //console.log('Consultando cajeros....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // alert("Error consultando cajeros")
	        // console.log('Error respuesta consultando cajeros')
              this.setState({
				          cajeros: [],
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error consultando cajeros',
                  severidadAlerta: 'error'
              })
	      } else {
	          console.log('Cajeros consultados')
	          this.setState({
	            cajeros: data
	            })
	          this.actualizarCajerosDisponibles()
	      }
	    })
      }

      queryPermisos() {
        console.log('Consultando Permisos....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  codigo: 'dashboard_clientes_permisos'
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/parametros/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando permisos")
	        //console.log('Error respuesta consultando permisos')
              
              this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error consultando permisos',
                  severidadAlerta: 'error'
              })
	      } else {
	          console.log('Permisos consultados')
              this.formatearPermisos(data.valor1)
              this.actualizarPermisosDisponibles()
	      }
	    })
        
      }

      queryCajerosCliente() {
        console.log('Consultando cajeros cliente....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_cliente: this.idCliente
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/clientes/cajeros/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajeros cliente")
	        // console.log('Error respuesta consultando cajeros clientes')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajeros cliente',
                severidadAlerta: 'error'
              })
	      } else {
	          console.log('Cajeros clientes consultados')
              this.setState({
                cajerosCliente: data
              })
              this.actualizarCajerosDisponibles()
	      }
	    })

   }


    render() {
        var cliente = this.state.cliente

        return(
          <Box>

              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Editar Cliente
              </Typography>


                <Paper elevation={16}>
                  <Card>
                      <CardContent>
                          <form>
                              <FormLabel>Información Cliente</FormLabel>
                              <Grid container xs={12} spacing={2} rowSpacing={2}>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Nombre"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtNombre}
                                    onChange={this.onChangetxtNombre}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>  
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Identificación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtIdentificacion}
                                    onChange={this.onChangetxtIdentificacion}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Código"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtCodigo}
                                      onChange={this.onChangetxtCodigo}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <InputLabel>Entidad</InputLabel>
                                    <Select
                                      label="Entidad"
                                      value={this.state.cmbEntidades}
                                      defaultValue={0}
                                      onChange={this.onChangecmbEntidades}>
                                      <MenuItem value={0}>-- Seleccione entidad --</MenuItem>
                                      {this.state.entidades.map(entidad => (
                                        <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Deuda"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtDeuda}
                                      onChange={this.onChangetxtDeuda}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Saldo"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtSaldo}
                                      onChange={this.onChangetxtSaldo}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Limite Deposito"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtLimiteDeposito}
                                      onChange={this.onChangetxtLimiteDeposito}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                              </Grid>                   
                          </form>
                      </CardContent>
                      <CardActions>
                              <Button  variant="contained" onClick={this.btnActualizarClienteClick}>Actualizar Informacion Cliente</Button>
                              <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                              </Snackbar> 
                          </CardActions>
                  </Card>

                </Paper>
              
                <br />

                <Paper elevation={16}>
                    <Card>
                        <CardContent>
                          <form>
                            <FormLabel>Cambiar Contraseña</FormLabel>
                            <Grid container xs={12} spacing={2} rowSpacing={2}>
                              <Grid item xs={6}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                    label="Nueva Contraseña"
                                    id="outlined-basic"
                                    type="password"
                                    variant="outlined"
                                    onChange={this.onChangetxtPasswordNueva}
                                    fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                    label="Verificar Contraseña"
                                    id="outlined-basic"
                                    type="password"
                                    variant="outlined"
                                    onChange={this.onChangetxtPasswordVerificacion}
                                    fullWidth
                                  />
                                </FormControl>
                              </Grid>
                            </Grid>
                            </form>
                          </CardContent>
                          <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnActualizarPasswordClick}>Cambiar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                          </CardActions>
                    </Card>
                  

                </Paper>

                <br />
                <Paper elevation={16}>
                    <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Grid container xs={12} spacing={2} columnSpacing={2}>
                              <Grid item xs={6}>
                                <FormLabel>Permisos Disponibles</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.permisosDisponibles.map((permiso) => {
                                        return(
                                          <ListItem button
                                            key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarPermiso(permiso.id)}}>
                                              {permiso.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel>Permisos Cliente</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.permisosCliente.map((permiso) => {
                                        return(
                                          <ListItem button
                                            key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerPermiso(permiso.id)}}>
                                            {permiso.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnGuardarPermisosClick}>Guardar Permisos</Button>
                          </CardActions>
                        </Card>
                      </Grid>

                </Paper>
                <br />

                <Paper elevation={16}>
                    <Grid item xs={4}>
                        <Card>
                          <CardContent>
                            <Grid container xs={12} spacing={2} columnSpacing={2}>
                              <Grid item xs={6}>
                                <FormLabel>Cajeros Disponibles</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.cajerosDisponibles.map((cajero) => {
                                        return(
                                          <ListItem button
                                            key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero.id)}}>
                                              {cajero.alias}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel>Cajeros Cliente</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.cajerosCliente.map((cajero) => {
                                        return(
                                          <ListItem button
                                            key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerCajero(cajero.id)}}>
                                            {cajero.alias}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                              <Button variant="contained" fullWidth onClick={this.btnGuardarCajerosClick}>Guardar Cajeros</Button>
                                <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                                </Snackbar> 
                          </CardActions>
                        </Card>
                    </Grid>
                </Paper>
                <br />
                

                <br />
                { session.checkPermiso('opcion_desactivar_cliente') &&
                    <Button variant="contained" onClick={() => {this.cambiarEstado()}}>{cliente.estado === "A" ? "Desactivar Cliente" : "Activar Cliente"}</Button>
                }

                  <Snackbar
                                                            open={this.state.mostrarAlerta}
                                                            onClose={this.handleCloseAlert}
                                                            autoHideDuration={3000}
                                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                                            >
                                                            <Collapse in={this.state.mostrarAlerta}>
                                                                <Alert
                                                                    severity={this.state.severidadAlerta}
                                                                    onClose={this.handleCloseAlert}
                                                                    sx={{ marginTop: 4, width:'100%'}}
                                                                    >
                                                                <AlertTitle>
                                                                    {this.state.severidadAlerta.toUpperCase()}
                                                                </AlertTitle>
                                                                    {this.state.mensajeAlerta}
                                                                </Alert>
                                                            </Collapse>
                  </Snackbar> 
        

          </Box>
        )
    }

}


export default ClientesEditarPage