import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import { withTranslation } from 'react-i18next';
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'


const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class OpcionesCuentaPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        };
      

        this.txtPassword = React.createRef()
        this.txtPasswordConfirmacion = React.createRef()
    }

    handleCloseAlert= (event, reason) => {
        if (reason === 'clickaway') {
          return; // Evitar que se cierre si el usuario hace clic fuera
        }
        this.setState({ mostrarAlerta: false })
    }
    

    btnCambiarPasswordClick = () => {
        var continuar = true
        var mensajeError = "Error:\n"

        var password = this.txtPassword.current.value.trim()
        var passwordConfirmacion = this.txtPasswordConfirmacion.current.value.trim()

        if(password === '') {
            continuar = false
            mensajeError += " * Debe ingresar la contraseña.\n"
        }

        if(passwordConfirmacion === '') {
            continuar = false
            mensajeError += " * Debe ingresar la confirmacion de la contraseña.\n"
        }

        if(password !== passwordConfirmacion) {
            continuar = false
            mensajeError += " * La contraseña y la confirmacion no coinciden.\n"
        }

        if(!continuar) {
            // alert(mensajeError)
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: mensajeError,
              severidadAlerta: 'error'
	        })
            return
        }

        console.log('Actualizando password....')
        
        var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
		  	password: password
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/password'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error cambiando password')
            //alert("Se produjo un error actualizando contraseña.")
            this.setState({
                mostrarAlerta: true,
                mensajeAlerta: 'Se produjo un error actualizando contraseña',
                severidadAlerta: 'error'
              })
	      } else {
			  // console.log(data)
			  switch(data.code) {
				  case 1:
                        //console.log('Password actualizado')
                        //alert("Contraseña actualizada\nDebe iniciar sesión nuevamente.")
                        this.setState({
                            mostrarAlerta: true,
                            mensajeAlerta: 'Contraseña actualizada\nDebe iniciar sesión nuevamente',
                            severidadAlerta: 'info'
                        })
                        window.location.replace("/logout/" + session.getItem('entidad_tag'))
				    break
				  case 2:
                        //console.log('Contraseña igual a la anterior')
                        //alert(data.message)
                        this.setState({
                            mostrarAlerta: true,
                            mensajeAlerta: data.message,
                            severidadAlerta: 'info'
                        })
				    break
                    default:
                        break 
			  }
	        
	      }
	    })
    }

    render() {
        const {t} = this.props
        return(
            <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    {t('window_opciones_cuenta_lbl_titulo')}

                </Typography>
                <Paper elevation={16}>
                    <Card>
                        <CardContent>
                        <form>
                            <FormLabel sx={{marginBottom: 2}}>{t('window_opciones_cuenta_lbl_cambiar_contrasena')}</FormLabel>
                            <Grid container xs={10} spacing={2}>
                                <Grid item xs={5}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    type="password"
                                    label={t('window_opciones_cuenta_lbl_nueva_contrasena')}
                                    id="outlined-basic"
                                    variant="outlined"
                                    inputRef={this.txtPassword}
                                    fullWidth
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={5}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    type="password"
                                    label={t('window_opciones_cuenta_lbl_confirmacion_contrasena')}
                                    id="outlined-basic"
                                    variant="outlined"
                                    inputRef={this.txtPasswordConfirmacion}
                                    fullWidth
                                    />
                                </FormControl>
                                </Grid>
                            </Grid>
                        </form>
                        <CardActions>
                            <Button  variant="contained" onClick={this.btnCambiarPasswordClick}>{t('window_opciones_cuenta_btn_cambiar_contrasena')}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>
                        </CardActions>
                        </CardContent>
                    </Card>

                </Paper>
            

            </Box>
        )
    }

}

export default withTranslation()(OpcionesCuentaPage)