import React, {Component} from 'react'

const session = require('../../config/session')

class DashboardUnsupportedPage  extends Component {

    constructor(props) {
        super(props)

        session.endSession()
    }
    

    render() {
        return(
            <div style={{backgroundColor: '#ffffff'}}>
                <h2 style={{textAlign: 'center', color: '#000000'}}>Dashboard no soportado</h2>
                <div style={{textAlign: 'center'}}>
                    La versión del dashboard que está usando no está soportada.
                    <br />
                    Actualice la pagina presionando la tecla F5 o Control + F5 o borrando el cache del navegador. Después vuelva a iniciar sesión.
                    <br />
                    <br />
                    <img alt='x' src='mantenimiento.png' width={250}/>
                </div>
            </div>
        )
    }

}

export default DashboardUnsupportedPage