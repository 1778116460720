import {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

function Row(props) {
    var item = props.item
    var eventSelect = props.eventSelect
    var eventDelete = props.eventDelete

    return(
        <TableRow>
            <TableCell>{item.alias}</TableCell>
            <TableCell>{item.tipo_puerto}</TableCell>
            <TableCell>{item.archivo}</TableCell>
            <TableCell>{item.estado}</TableCell>
            <TableCell>
                <Button variant="contained" size='small' fullWidth onClick={() => {eventSelect(item)}}>Seleccionar</Button>
                <Button variant="contained" size='small' color="error" fullWidth onClick={() => {eventDelete(item)}}>Borrar</Button>
            </TableCell>
        </TableRow>
    )
}


class TabMoneyManager extends Component {
    constructor(props) {
        super(props)

        var tipoDispositivo = props.tipoDispositivo
        var dispositivos = props.dispositivos
        this.guardarEvent = props.event

        this.state = {
            tipoDispositivo: tipoDispositivo,
            dispositivos: dispositivos,
            txtAlias: "",
            cmbTiposPuerto: 0,
            txtSerialPuerto: "",
            cmbserialVelocidades: 0,
            txtServidorDireccion: "",
            txtServidorPuerto: "",
            txtServidorMascara: "",
            txtServidorPuerta: "",
            cmbModos: 0,
            txtArchivo: "",
            cmbDebugs: 0,
            cmbEstados: 0,
            tiposPuerto: [
                "Ethernet",
                "Serial"
            ],
            serialVelocidades: [
                "9600",
                "19200",
                "115200"
            ],
            modos: [
                "POLL",
                "EVENTO"
            ],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            estados: [
				{nombre: "Activo", valor: "A"},
				{nombre: "Inactivo", valor: "I"}
			],
            modoEdicion: false
        }
    }

    componentWillUnmount() {

    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangecmbTiposPuerto = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposPuerto: tipo})
    }

    onChangetxtSerialPuerto= (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtSerialPuerto: puerto})
    }

    onChangecmbserialVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbserialVelocidades: velocidad})
    }

    onChangetxtServidorDireccion = (event) => {
        const direccion = event.target.value.toString()
        this.setState({txtServidorDireccion: direccion})
    }

    onChangetxtServidorPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtServidorPuerto: puerto})
    }

    onChangetxtServidorMascara = (event) => {
        const mascara = event.target.value.toString()
        this.setState({txtServidorMascara: mascara})
    }

    onChangetxtServidorPuerta = (event) => {
        const puerta = event.target.value.toString()
        this.setState({txtServidorPuerta: puerta})
    }

    onChangecmbModos = (event) => {
        const modo = event.target.value
        this.setState({cmbModos: modo})
    }

	onChangetxtArchivo = (event) => {
        const archivo = event.target.value.toString()
        this.setState({txtArchivo: archivo})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangecmbEstados = (event) => {
        const estado = event.target.value
        this.setState({cmbEstados: estado})
    }


    btnSeleccionarClick = (item) => {
        this.setState({
            txtAlias: item.alias,
            cmbTiposPuerto: item.tipo_puerto,
            txtSerialPuerto: item.serial_puerto,
            cmbserialVelocidades: item.serial_velocidad,
            txtServidorDireccion: item.servidor_direccion,
            txtServidorPuerto: item.servidor_puerto,
            txtServidorMascara: item.servidor_mascara,
            txtServidorPuerta: item.servidor_puerta,
            cmbModos: item.modo,
            txtArchivo: item.archivo,
            cmbDebugs: item.debug,
            cmbEstados: item.estado,
            modoEdicion: true
        })
    }

    btnBorrarClick = (item) => {
        
        if(window.confirm("¿Borrar dispositivo?")) {
            this.pepararNuevo()
            var dispositivos = this.state.dispositivos

            for(var x = 0; x < dispositivos.length; x++) {
                if(dispositivos[x].alias === item.alias) {
                    dispositivos.splice(x, 1)
                    break
                }
            }


            this.setState({
                dispositivos: dispositivos
            })

            this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
        }

    }

    btnGuardarClick = () => {

        var dispositivos = this.state.dispositivos

        var item = {
            alias: this.state.txtAlias,
            tipo_puerto: this.state.cmbTiposPuerto,
            serial_puerto: this.state.txtSerialPuerto,
            serial_velocidad: this.state.cmbserialVelocidades,
            servidor_direccion: this.state.txtServidorDireccion,
            servidor_puerto: this.state.txtServidorPuerto,
            servidor_mascara: this.state.txtServidorMascara,
            servidor_puerta: this.state.txtServidorPuerta,
            modo: this.state.cmbModos,
            archivo: this.state.txtArchivo,
            debug: this.state.cmbDebugs,
            estado: this.state.cmbEstados
        }

        if(this.state.modoEdicion) {
            //Buscar el dispositivo por el alias
            for(var x = 0; dispositivos.length; x++) {
                if(dispositivos[x].alias === this.state.txtAlias) {
                    dispositivos[x] = item
                    break
                }
            }
        } else {
            dispositivos.push(item)
        }

        this.setState({
            dispositivos: dispositivos
        })

        this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
    }

    pepararNuevo = () => {
        this.setState({
            txtAlias: "",
            cmbTiposPuerto: 0,
            txtSerialPuerto: "",
            cmbserialVelocidades: 0,
            txtServidorDireccion: "",
            txtServidorPuerto: "",
            txtServidorMascara: "",
            txtServidorPuerta: "",
            cmbModos: 0,
            txtArchivo: "",
            cmbDebugs: 0,
            cmbEstados: 0,
            modoEdicion: false
        })
    }

    confirmacionGuardado = () => {
        this.pepararNuevo()
    }

    render() {
        const columns = [
            {field: 'Alias', headerName: 'Alias', width: 150},
            {field: 'Tipo', headerName: 'Tipo', width: 100},
            {field: 'Archivo', headerName: 'Archivo', width: 100},
            {field: 'Estado', headerName: 'Estado', width: 50},
            {field: '', headerName: '', width: 50}
          ]

        return(
            <Box>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Card fullWidth>
                            <CardContent>
                            	<h3>MoneyManagers</h3>
                                <form>
                                    <FormLabel>Información MoneyManager</FormLabel>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Alias"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtAlias}
                                                onChange={this.onChangetxtAlias}
                                                inputProps={{disabled: this.state.modoEdicion}}
                                                disabled={this.state.modoEdicion}
                                                fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Tipo</InputLabel>
                                            <Select
                                                label="Tipo"
                                                value={this.state.cmbTiposPuerto}
                                                defaultValue={0}
                                                onChange={this.onChangecmbTiposPuerto}>
                                                <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                {this.state.tiposPuerto.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="Puerto Serial"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtSerialPuerto}
                                                    onChange={this.onChangetxtSerialPuerto}
                                                    fullWidth
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Velocidad Serial</InputLabel>
                                            <Select
                                                label="Velocidad Serial"
                                                value={this.state.cmbserialVelocidades}
                                                defaultValue={0}
                                                onChange={this.onChangecmbserialVelocidades}>
                                                <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                {this.state.serialVelocidades.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Dirección Servidor"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtServidorDireccion}
                                            onChange={this.onChangetxtServidorDireccion}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                    <TextField
                                                    label="Puerto Servidor"
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    value={this.state.txtServidorPuerto}
                                                    onChange={this.onChangetxtServidorPuerto}
                                                    fullWidth
                                                    />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Mascara Servidor"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtServidorMascara}
                                            onChange={this.onChangetxtServidorMascara}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Puerta Servidor"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtServidorPuerta}
                                            onChange={this.onChangetxtServidorPuerta}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Modo</InputLabel>
                                            <Select
                                                label="Modo"
                                                value={this.state.cmbModos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbModos}>
                                                <MenuItem value={0}>-- Seleccione Modo --</MenuItem>
                                                {this.state.modos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <TextField
                                            label="Archivo"
                                            id="outlined-basic"
                                            variant="outlined"
                                            value={this.state.txtArchivo}
                                            onChange={this.onChangetxtArchivo}
                                            fullWidth
                                            />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Debug</InputLabel>
                                            <Select
                                                label="Debug"
                                                value={this.state.cmbDebugs}
                                                defaultValue={0}
                                                onChange={this.onChangecmbDebugs}>
                                                <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                {this.state.debugs.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Estado</InputLabel>
                                            <Select
                                                label="Estado"
                                                value={this.state.cmbEstados}
                                                defaultValue={0}
                                                onChange={this.onChangecmbEstados}>
                                                <MenuItem value={0}>-- Seleccione estado --</MenuItem>
                                                {this.state.estados.map(item => (
                                                <MenuItem value={item.valor}>{item.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                            <CardActions>
                                <Button  variant="contained" onClick={this.btnGuardarClick}>{ this.state.modoEdicion  === true ? "Modificar": "Agregar"}</Button>
                                <Button  variant="contained" onClick={this.pepararNuevo}>Cancelar</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Card xs={8}>
                    <CardContent xs={8}>
                        <TableContainer xs={8}>
                            <Table stickyHeader class="table-data" xs={8}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => {
                                                return(
                                                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.state.dispositivos.map((dispositivo) => {
                                        return(<Row item = {dispositivo} eventSelect={this.btnSeleccionarClick} eventDelete={this.btnBorrarClick} />)
                                    })
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

export default TabMoneyManager