import { createTheme } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';
import { ListItem } from '@mui/material';

export default createTheme({
    palette: {
        primary: {
            main: '#8CC63E', // Verde brillante
            contrastText: '#FFFFFF', // Texto blanco para contraste
        },
        secondary: {
            main: '#C0C0C0', // Gris plateado para el acento
        },
        background: {
            default: '#FFFFFF', // Verde oscuro para el fondo general
            paper: '#FFFFFF', // Fondo blanco para elementos "Paper"
        },
        text: {
            primary: '#434343 ', // Gris oscuro para el texto principal
            secondary: '#434343', // Gris para el texto secundario
        },
    },
    typography: {
        fontFamily: 'Exo, Arial, sans-serif', // Fuente personalizada
    },
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    border: '1px solid #000000ff',
                    '&:hover': {
                        backgroundColor: '#8CC63E', // Efecto hover con verde brillante
                    },
                },
            },
        },
        MuiMenuList: {
            styleOverrides: {
                root: {
                    borderBottom: "none",
                    padding: '0px', // Eliminar padding entre ítems
                    margin: '0px', // Eliminar márgenes entre ítems
                },
            },
        },

    },
});
