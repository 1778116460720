
import { useState } from 'react';
import {Route, Redirect} from 'react-router-dom'

require('dotenv').config();
const session = require('../config/session')

function makePetition(args, callback) {
	// const [alertaAbierta, setAlertaAbierta ] = useState(false)
    // const [mensajeAlerta, setMensajeAlerta ] = useState(false)
    // const [severidadAlerta, setSeveridadAlerta ] = useState(false)          

    args.body.dashboard_version = process.env.REACT_APP_VERSION

    if(args.headers === 'JSON') {
        args.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': '' + session.getToken()
          }
    }

    const requestOptions =
        {
            method: args.method,
            headers: args.headers,
            body: JSON.stringify(args.body)
        }

    // Realizar la peticion
    fetch(args.url, requestOptions)
        .then(response => response.json())
        .then(dataFromServer => {

            // Aqui entra cuando el servidor respondio a la solicitud. Hay que revisar si la peticion retorno algun error o si se ejecuto correctamente
            if(dataFromServer.status === 1) { 
                callback(false, dataFromServer.data)
            } else {
                erroresControlados(dataFromServer, callback)
            }
            
        })
        .catch(error => {
            alert("Error realizando la consulta")
            console.log(error)
        })
}

function erroresControlados(dataFromServer, callback) {
    switch(dataFromServer.code) {
        case 0:
            callback(true, dataFromServer)
            break
        case -100:
            servidorMantenimiento()
            break
        case -101:
            versionDashboardNoSoportada()
            break
        case -102:
            sesionCerradaPorServidor()
            break
        default:
            alert("Error no controlado.")
    }
}

function servidorMantenimiento() {
    window.location.replace(process.env.REACT_APP_URL_MANTENIMIENTO)
}

function versionDashboardNoSoportada() {
    window.location.replace(process.env.REACT_APP_URL_UNSUPPORTED)
}

function sesionCerradaPorServidor() {
    window.location.replace(process.env.REACT_APP_URL_FRONTEND + '/logout/' + session.getItem('entidad_tag'))
}

export {makePetition} 