import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class UsuariosCrearPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
          entidades: [],
          txtNombres: "",
          txtApellidos: "",
          txtIdentificacion: "",
          txtEmail: "",
          txtUsuario: "",
          txtPassword: "",
          cmbEntidades: 0,
          idNuevoUsuario: 0,

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
        }

    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }

    onChangetxtNombres = (event) => {
      const nombres = event.target.value.toString()
      this.setState({txtNombres: nombres})
    }

    onChangetxtApellidos = (event) => {
        const apellidos = event.target.value.toString()
        this.setState({txtApellidos: apellidos})
    }

    onChangetxtIdentificacion = (event) => {
        const identificacion = event.target.value.toString()
        this.setState({txtIdentificacion: identificacion})
    }

    onChangecmbEntidades = (event) => {
      const entidad = event.target.value
      this.setState({cmbEntidades: entidad})
  }

    onChangetxtEmail = (event) => {
        const email = event.target.value.toString()
        this.setState({txtEmail: email})
    }
    
    onChangetxtUsuario = (event) => {
      const usuario = event.target.value.toString()
      this.setState({txtUsuario: usuario})
    }

    onChangetxtPassword = (event) => {
      const password = event.target.value.toString()
      this.setState({txtPassword: password})
  }

    componentDidMount() {
      this.queryEntidades()
    }

    btnAgregarUsuarioClick = () => {
        console.log('Agregando usuario....')

        var continuar = true
        var mensajeError = "Error:\n"

        var nombres = this.state.txtNombres
        var apellidos = this.state.txtApellidos
        var identificacion = this.state.txtIdentificacion
        var entidad = this.state.cmbEntidades
        var email = this.state.txtEmail
        var usuario = this.state.txtUsuario
        var password = this.state.txtPassword


        nombres = nombres.trim()
        apellidos = apellidos.trim()
        identificacion = identificacion.trim()
        email = email.trim()
        usuario = usuario.trim()
        password = password.trim()
        
        if(nombres === '') {
          continuar = false
          mensajeError += " * Debe ingresar los nombres.\n"
        }

        if(apellidos === '') {
          continuar = false
          mensajeError += " * Debe ingresar los apellidos.\n"
        }

        if(identificacion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la identificación.\n"
        }

        if(email === '') {
          continuar = false
          mensajeError += " * Debe ingresar el email.\n"
        }

        if(entidad === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar una entidad.\n"
        }

        if(usuario === '') {
          continuar = false
          mensajeError += " * Debe ingresar un usuario.\n"
        }

        if(password === '') {
          continuar = false
          mensajeError += " * Debe ingresar una contraseña.\n"
        }
    
        if(continuar) {
			var restArgs = {
		      method: 'PUT',
		      headers: 'JSON',
		      body: {
				    nombres: nombres,
	                apellidos: apellidos,
	                identificacion: identificacion,
	                email: email,
	                entidad: entidad,
	                usuario: usuario,
	                password: password
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/new'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta insertar usuario')
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error: Se produjo un error creando el usuario.',
                  severidadAlerta: 'error'
                })
                //alert("Error: Se produjo un error creando el usuario.")
		      } else {
				  //No hubo error del servidor verficar lka respuesta para ver si se creo o no el usuario
				//console.log('Usuario insertado')
                
                this.setState({
                  idNuevoUsuario: data,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Nuevo usuario creado',
                  severidadAlerta: 'success'
                })
                //alert("Nuevo usuario creado")
		      }
		    })
          
        } else {
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: mensajeError,
            severidadAlerta: 'error'
          })
          //alert(mensajeError)
        }
        
    }

    

    btnLimpiarClick = () => {
      this.setState({
        txtNombres: "",
        txtApellidos: "",
        txtIdentificacion: "",
        txtEmail: "",
        txtUsuario: "",
        txtPassword: "",
        cmbEntidades: 0,
        idNuevoUsuario: 0
      })
    }

    queryEntidades() {
      console.log('Consultando entidades....')
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidades")
	        //console.log('Error respuesta consultando entidades')
            this.setState({
				      entidades: [],
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando entidades',
              severidadAlerta: 'error'
            })
	      } else {
	        console.log('Entidades consultadas')
            this.setState({
              entidades: data
              })
	      }
	    })
    }

    render() {
        return(
          <Box>

                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                  Crear Usuario
                </Typography>

                <Paper elevation={16}>
                  <Card>
                      <CardContent>
                          <form>
                              <FormLabel>Información usuario</FormLabel>
                              <Grid container xs={10} spacing={2} rowSpacing={2}>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Nombres"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtNombres}
                                    onChange={this.onChangetxtNombres}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Apellidos"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtApellidos}
                                    onChange={this.onChangetxtApellidos}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>   
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Identificación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtIdentificacion}
                                    onChange={this.onChangetxtIdentificacion}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Email"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtEmail}
                                      onChange={this.onChangetxtEmail}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <InputLabel>Entidad</InputLabel>
                                    <Select
                                      label="Entidad"
                                      value={this.state.cmbEntidades}
                                      defaultValue={0}
                                      onChange={this.onChangecmbEntidades}>
                                      <MenuItem value={0}>-- Seleccione entidad --</MenuItem>
                                      {this.state.entidades.map(entidad => (
                                        <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                      label="Usuario"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtUsuario}
                                      onChange={this.onChangetxtUsuario}
                                      fullWidth
                                    />
                                  </FormControl>
                                </Grid>    
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                      label="Password"
                                      id="outlined-basic"
                                      type="password"
                                      variant="outlined"
                                      value={this.state.txtPassword}
                                      onChange={this.onChangetxtPassword}
                                      fullWidth
                                    />
                                  </FormControl>
                                </Grid>          
                              </Grid>                   
                          </form>
                        </CardContent>
                        <CardActions>
                            <Button  variant="contained" onClick={this.btnAgregarUsuarioClick}>Crear Usuario</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                            <Button  variant="contained" onClick={this.btnLimpiarClick}>Limpiar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                        </CardActions>
                  </Card>
                </Paper>
                <br />
                { this.state.idNuevoUsuario !== 0 &&
                  <Link to={{pathname: '/dashboard/usuarios/editar', state: {id_usuario: this.state.idNuevoUsuario}}} style={{ textDecoration: 'none' }}><Button variant="contained" color="info">Configurar nuevo usuario</Button>
                  <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                  </Snackbar> 
                  </Link>
                }
           

          </Box>
        )
    }

}


export default UsuariosCrearPage