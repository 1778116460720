import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Grid'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class UsuariosCrearPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
          entidades: [], 
          txtSerial: "",
          txtCodigo: "",
          txtAlias: "",
          txtDireccion: "",
          txtEmailTransportadora: "",
          cmbEntidades: 0,
          idNuevoCajero: 0,

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
        }

    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }

    onChangetxtSerial = (event) => {
      const serial = event.target.value.toString()
      this.setState({txtSerial: serial})
    }

    onChangetxtCodigo = (event) => {
        const codigo = event.target.value.toString()
        this.setState({txtCodigo: codigo})
    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangecmbEntidades = (event) => {
      const entidad = event.target.value
      this.setState({cmbEntidades: entidad})
    }

    onChangetxtDireccion = (event) => {
        const direccion = event.target.value.toString()
        this.setState({txtDireccion: direccion})
    }
    
    onChangetxtEmailTransportadora = (event) => {
      const emailTransportadora = event.target.value.toString()
      this.setState({txtEmailTransportadora: emailTransportadora})
    }

    componentDidMount() {
      this.queryEntidades()
    }

    btnAgregarCajeroClick = () => {
        console.log('Agregando cajero....')

        var continuar = true
        var mensajeError = "Error:\n"

        var serial = this.state.txtSerial
        var codigo = this.state.txtCodigo
        var alias = this.state.txtAlias
        var entidad = this.state.cmbEntidades
        var emailTransportadora = this.state.txtEmailTransportadora
        var direccion = this.state.txtDireccion


        serial = serial.trim()
        codigo = codigo.trim()
        alias = alias.trim()
        emailTransportadora = emailTransportadora.trim()
        direccion = direccion.trim()
        
        if(serial === '') {
          continuar = false
          mensajeError += " * Debe ingresar el serial.\n"
        }

        if(codigo === '') {
          continuar = false
          mensajeError += " * Debe ingresar el código.\n"
        }

        if(alias === '') {
          continuar = false
          mensajeError += " * Debe ingresar el alias.\n"
        }

        if(emailTransportadora === '') {
          continuar = false
          mensajeError += " * Debe ingresar el correo de la transportadora.\n"
        }

        if(direccion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la dirección/ubicación.\n"
        }

        if(entidad === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar una entidad.\n"
        }

    
        if(continuar) {
			
			console.log('Consultando grupos....')
		    
		    var restArgs = {
		      method: 'PUT',
		      headers: 'JSON',
		      body: {
				    serial: serial,
	                codigo: codigo,
	                alias: alias,
	                email_transportadora: emailTransportadora,
	                direccion: direccion,
	                entidad: entidad
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/new'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta insertar cajero')
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error: Se produjo un error creando el cajero.',
                  severidadAlerta: 'error'
                })
                //alert("Error: Se produjo un error creando el cajero.")
		      } else {
		        //onsole.log('Cajero creado')
                //alert("Nuevo cajero creado")
                this.setState({
                  idNuevoCajero: data,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Nuevo cajero creado',
                  severidadAlerta: 'success'
                })
		      }
		    })
	    }
        
    }

    

    btnLimpiarClick = () => {
      this.setState({
        txtSerial: "",
        txtCodigo: "",
        txtAlias: "",
        txtDireccion: "",
        txtEmailTransportadora: "",
        cmbEntidades: 0,
        idNuevoCajero: 0
      })
    }

    queryEntidades() {
      // console.log('Consultando entidades....')
    
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidaddes")
	        //console.log('Error respuesta consultando entidades')
	        this.setState({
            entidades: [],
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando entidaddes',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Entidades consultadas')
            this.setState({
              entidades: data,
              mostrarAlerta: true,
              mensajeAlerta: 'Entidades consultadas',
              severidadAlerta: 'info'
              })
	      }
	    })
    }

    render() {
        return(

        <Box>
          <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
            Crear Cajero
          </Typography>

            <Paper elevation={16}>
                <Card>
                    <CardContent>
                        <form>
                            <FormLabel>Información cajero</FormLabel>
                            <br />
                            <Grid container xs={10} spacing={2} rowSpacing={2}>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Serial"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtSerial}
                                  onChange={this.onChangetxtSerial}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Código"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtCodigo}
                                  onChange={this.onChangetxtCodigo}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>   
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Alias"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtAlias}
                                  onChange={this.onChangetxtAlias}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Dirección/Ubicación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtDireccion}
                                    onChange={this.onChangetxtDireccion}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Correo Transportadora"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtEmailTransportadora}
                                    onChange={this.onChangetxtEmailTransportadora}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <InputLabel>Entidad</InputLabel>
                                  <Select
                                    label="Entidad"
                                    value={this.state.cmbEntidades}
                                    defaultValue={0}
                                    onChange={this.onChangecmbEntidades}>
                                    <MenuItem value={0}>-- Seleccione entidad --</MenuItem>
                                    {this.state.entidades.map(entidad => (
                                      <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>     
                            </Grid>                   
                        </form>
                      </CardContent>
                      <CardActions>
                          <Button  variant="contained" onClick={this.btnAgregarCajeroClick} sx={{marginBottom:2, marginLeft: 1 }}>Crear Cajero</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                          <Button  variant="contained" onClick={this.btnLimpiarClick} sx={{marginBottom:2, marginLeft: 1 }}>Limpiar</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                      </CardActions>
                </Card>
              
            </Paper>
                <br />
                { this.state.idNuevoCajero !== 0 &&
                  <Link to={{pathname: '/dashboard/cajeros/editar', state: {id_cajero: this.state.idNuevoCajero}}} style={{ textDecoration: 'none' }}><Button variant="contained" color="info">Configurar nuevo cajero</Button>
                  <Snackbar
                                    open={this.state.mostrarAlerta}
                                    onClose={this.handleCloseAlert}
                                    autoHideDuration={3000}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                    >
                                    <Collapse in={this.state.mostrarAlerta}>
                                      <Alert
                                          severity={this.state.severidadAlerta}
                                          onClose={this.handleCloseAlert}
                                          sx={{ marginTop: 4, width:'100%'}}
                                        >
                                          <AlertTitle>
                                            {this.state.severidadAlerta.toUpperCase()}
                                          </AlertTitle>
                                                        
                                          {this.state.mensajeAlerta}
                                      </Alert>
                                    </Collapse>
                  </Snackbar> 
                  </Link>
                }
          

        </Box>
        )
    }

}


export default UsuariosCrearPage