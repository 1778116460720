import {React, Component} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import ErrorIcon from '@mui/icons-material/Error';
import FaceSadIcon from '@mui/icons-material/SentimentDissatisfied';
import Typography from '@mui/material/Typography'

class Page404 extends Component {

    render() {
        return (
            <Box sx={{display: "flex"}} justifyContent="center">
                <Grid container xs={8}>
                    <Grid item container xs={12} justifyContent="center" sx={{backgroundColor: "#ffffff"}}>
                        <ErrorIcon />
                        <Typography gutterBottom component="div" sx={{color: "#ff0000"}}>
                            Pagina no encontrada
                        </Typography>
                        <FaceSadIcon />
                    </Grid>
                </Grid>
            </Box>
        )
    }

}

export default Page404