import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'

function Row(props) {
 const {retiro} = props;
 const [open, setOpen] = React.useState(false)

 var detalle = (retiro.detalle !== '' ? JSON.parse(retiro.detalle) : '')
 var filaDetalle = []

 for(var campo in detalle) {
  if(campo === 'billetes') {
    var filasBilletes = RowBilletes(detalle[campo])
    filaDetalle.push(
      <TableRow>
       <TableCell className="sub-detalle-titulo">{campo}</TableCell>
       <TableCell className="sub-detalle-valor">{filasBilletes}</TableCell>
      </TableRow>
    )
  } else {
    filaDetalle.push(
      <TableRow>
       <TableCell className="sub-detalle-titulo">{campo}</TableCell>
       <TableCell className="sub-detalle-valor">{detalle[campo]}</TableCell>
      </TableRow>
    )
  }
 }


 return (
   <React.Fragment>
     <TableRow>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
       <TableCell>
         {retiro.fecha_registro}
       </TableCell>
       <TableCell>
         {retiro.entidad}
       </TableCell>
       <TableCell>
         {retiro.referencia_pago}
       </TableCell>
       <TableCell>
         {retiro.valor}
       </TableCell>
       <TableCell>
         {retiro.finalizacion}
       </TableCell>
       <TableCell>
         {retiro.aprobacion}
       </TableCell>
       <TableCell>
         {retiro.fecha_aprobacion}
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
     <TableRow>
      <TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table className="detalle">
            <TableRow>
              <TableCell className="sub-detalle-titulo">Fecha Dispositivo</TableCell>
              <TableCell className="sub-detalle-valor">{retiro.fecha_dispositivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="sub-detalle-titulo">Consecutivo</TableCell>
              <TableCell className="sub-detalle-valor">{retiro.consecutivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="detalle-titulo">
                Detalle
              </TableCell>
              <TableCell className="detalle-valor">
                <Table>
                  {filaDetalle}
                </Table>
              </TableCell>
            </TableRow>
          </Table>
        </Collapse>
      </TableCell>
     </TableRow>
   </React.Fragment>
 );
}

function RowBilletes(billetes) {
  /* eslint-disable no-redeclare */
  var billetes = billetes.split(',')
  /* eslint-enable no-redeclare */

  var filasBilletes = []
  for(var billete in billetes) {
    filasBilletes.push(
      <TableRow>
       <TableCell>{billetes[billete]}</TableCell>
      </TableRow>
    )
  }

  return(
    <Table>
      {filasBilletes}
    </Table>
  )
}

class RetirosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      retiros: []
    };
  }



  componentDidMount() {
    //this.queryEventos()
  }

  render() {
    const retiros = this.props.retiros
    const columns = [
      {field: "desplegable", headerName: "", width: 10},
      {field: "fecha_hora", headerName: 'Fecha Registro', width: 150},
      {field: "entidad", headerName: 'Entidad', width: 100},
      {field: "referencia_pago", headerName: 'Referencia Pago', width: 100},
      {field: "valor", headerName: 'Valor', width: 150},
      {field: "finalizacion", headerName: 'Finalizacion', width: 150},
      {field: "aprobacion", headerName: 'Aprobacion', width: 150},
      {field: "fecha_aprobacion", headerName: 'Fecha Aprobacion', width: 150},
    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {retiros.map((retiro) => {
                return(
                  <Row retiro={retiro} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default RetirosTable;