import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'



import ParametrosTable from './ParametrosTable'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class ParametrosPage extends Component {

  constructor(props) {
      super(props)

      this.state = {
          parametros: [],
          txtCodigo: "",
          txtValor1: "",
          accion: "Agregar",

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
      }

    this.prepararNuevo = this.prepararNuevo.bind(this)
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  componentDidMount() {
      this.queryParametros()
  }

  btnSeleccionarRegistro = (codigo) => {
    this.queryParametro(codigo)
  }

  btnCancelarClick = () => {
    this.prepararNuevo()
  }

  btnGuardarClick = () => {
      console.log('Agregando parametro....')

      var continuar = true
      var mensajeError = "Error:\n"

      var codigo = this.state.txtCodigo.trim()
      var valor1 = this.state.txtValor1.trim()

      
      if(codigo === '') {
        continuar = false
        mensajeError += " * Debe ingresar el código.\n"
      }

      if(valor1 === '') {
        continuar = false
        mensajeError += " * Debe ingresar el Valor 1.\n"
      }
  
      if(continuar) {
        if(this.state.accion === "Agregar") {
			var restArgs = {
		      method: 'PUT',
		      headers: 'JSON',
		      body: {
				  codigo: codigo,
                valor1: valor1
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/parametros/new'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //alert("Error insertando parametro")
		          //console.log('Error respuesta insertar parametro')
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error insertando parametro',
                  severidadAlerta: 'error'
                })
		      } else {
		        //console.log('Parametro insertado')
                //alert("Nuevo parametro creado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Nuevo parametro agregado',
                  severidadAlerta: 'success'
                })
                this.prepararNuevo()
                this.queryParametros()
		      }
		    })
			
        } else {
      // eslint-disable-next-line no-redeclare
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  codigo_actual: this.state.parametro.codigo,
                codigo: codigo,
                valor1: valor1
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/parametros/update'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //alert("Error actualizando parametro")
		        //console.log('Error respuesta actualizar parametro')
                this.setState({
                  mensajeError: data.message,
                  mostrarAlerta: true,
                  mensajeAlerta: 'Error actualizando parametro',
                  severidadAlerta: 'error'

                })
		      } else {
		        //console.log('Parametro actualizado')
                // alert("Parámetro actualizado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Parámetro actualizado',
                  severidadAlerta: 'success'

                })
                this.prepararNuevo()
                this.queryParametros()
		      }
		    })
			
        }
      } else {
        // alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
      }
      
  }

  prepararNuevo() {
    this.setState({
      txtCodigo: "",
      txtValor1: "",
      accion: "Agregar"
    })
  }

  queryParametros() {
    // console.log('Consultando parametros....')
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/parametros/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando parametros")
        //console.log('Error respuesta consultando parametros')
          this.setState({
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando parametros',
            severidadAlerta: 'error'
          })
      } else {
        //console.log('Parameteros consultados')
	      this.setState({
	        parametros: data
	        })
      }
    })
    
  }

  queryParametro(codigo) {
    //console.log('Consultando parametro....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	codigo: codigo
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/parametros/'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando parametro")
        //console.log('Error respuesta consultando parametro')
          this.setState({
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando parametro',
            severidadAlerta: 'error'
          })
      } else {
        //console.log('Parametro consultado')
          this.setState({
            parametro: data
            })
          
          var parametro = data

          this.setState({
            txtCodigo: parametro.codigo,
            txtValor1: parametro.valor1,
            accion: "Editar"
          })
      }
    })
    
  }

  onChangetxtCodigo= (event) => {
    const codigo = event.target.value.toString()
    this.setState({txtCodigo: codigo})
  }

  onChangetxtValor1= (event) => {
    const valor1 = event.target.value.toString()
    this.setState({txtValor1: valor1})
  }

  render() {
      return(
        <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Parámetros
              </Typography>

              <Paper elevation={16}>
                <Card>
                    <CardContent>
                      <form>
                          <FormLabel>Información parámetro:</FormLabel>
                          <Grid container xs={10} spacing={2} rowSpacing={2}>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Código"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtCodigo}
                                onChange={this.onChangetxtCodigo}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <TextField
                              label="Valor 1"
                              id="outlined-basic"
                              variant="outlined"
                              value={this.state.txtValor1}
                              multiline
                              rows={5}
                              onChange={this.onChangetxtValor1}
                              fullWidth
                              />
                              </FormControl>
                          </Grid>
                          </Grid>
                      </form>
                    </CardContent>
                    <CardActions>
                            <Button  variant="contained" onClick={this.btnGuardarClick}>{this.state.accion=== 'Agregar' ? "Agregar Parámetro" : "Editar Parámetro"}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>  
                            
                            <Button  variant="contained" onClick={this.btnCancelarClick}>Cancelar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar>  
                        </CardActions>
                </Card>
              </Paper>

              <br />
              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Listado parámetros:
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <ParametrosTable parametros={this.state.parametros} eventoSeleccionar={this.btnSeleccionarRegistro} />
                    </Box>
                  </CardContent>
                  <CardActions>

                  </CardActions>
                </Card>

              </Paper>
          

        </Box>
      )
  }

}


export default ParametrosPage