import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosConfigurarImpresora extends Component {

    constructor(props) {
        super(props)

        this.idCajero = props.location.state.id_cajero

        this.state = {
            cajero: {},
            cmbTiposImpresora: "",
            cmbProtocolos: "",
            cmbVelocidades: "",
            cmbDebugs: "",
            txtPuerto: "",
            txtArchivo: "",
            tiposImpresora: [
                "Q10",
                "TECMAX",
                "Otro"
            ],
            protocolos: [
                "ESC/POS",
                "ESC/POS-USB",
                "USB"
            ],
            velocidades: [
                "9600",
                "19200",
                "115200"
            ],
            debugs: [
                "1",
                "2",
                "3",
                "4"
            ],
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

    handleCloseAlert= (event, reason) => {
        if (reason === 'clickaway') {
          return; // Evitar que se cierre si el usuario hace clic fuera
        }
        this.setState({ mostrarAlerta: false })
    }


    onChangecmbTiposImpresora = (event) => {
        const tipo = event.target.value
        this.setState({cmbTiposImpresora: tipo})
    }

    onChangecmbProtocolos = (event) => {
        const protocolo = event.target.value
        this.setState({cmbProtocolos: protocolo})
    }

    onChangecmbDebugs = (event) => {
        const debug = event.target.value
        this.setState({cmbDebugs: debug})
    }

    onChangetxtPuerto = (event) => {
        const puerto = event.target.value.toString()
        this.setState({txtPuerto: puerto})
    }

    onChangecmbVelocidades = (event) => {
        const velocidad = event.target.value
        this.setState({cmbVelocidades: velocidad})
    }

    onChangetxtArchivo = (event) => {
        const archivo = event.target.value.toString()
        this.setState({txtArchivo: archivo})
    }

    queryCajero() {
		console.log('Consultando cajero....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_cajero: this.idCajero
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/detalle' 
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajero")
	        //console.log('Error respuesta consultando cajero')
	        this.setState({
				cajero: {},
				mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajero',
                severidadAlerta: 'error'
	        })
	      } else {
	        console.log('Cajero consultado')
	      	var configuracionImpresora = null

                try {
                    var dispositivos = JSON.parse(data.dispositivos)
                    if(dispositivos !== null) {
                        if(dispositivos.impresoras !== undefined) {
                            configuracionImpresora = dispositivos.impresoras[0]
                        
                            this.setState({
                                cmbTiposImpresora: configuracionImpresora.tipo,
                                cmbProtocolos: configuracionImpresora.protocolo,
                                cmbVelocidades: configuracionImpresora.velocidad,
                                cmbDebugs: configuracionImpresora.debug,
                                txtPuerto: configuracionImpresora.puerto,
                                txtArchivo: configuracionImpresora.archivo
                            })
                        }
                    }
                } catch(error) {
                   //alert(error)
                    this.setState({
                        mostrarAlerta: true,
                        mensajeAlerta: error,
                        severidadAlerta: 'error'
                    })
                }
	      }
	    })
		
    }

    btnGuardarConfiguracionClick = () => {
        console.log('Actualizando configuracion impresora....')

        var continuar = true
        var mensajeError = "Error:\n"

        var tipo = this.state.cmbTiposImpresora
        var protocolo = this.state.cmbProtocolos
        var puerto = this.state.txtPuerto
        var velocidad = this.state.cmbVelocidades
        var debug =  this.state.cmbDebugs
        var archivo = this.state.txtArchivo
        

        tipo = tipo.trim()
        protocolo = protocolo.trim()
        puerto = puerto.trim()
        velocidad = velocidad.trim()
        archivo = archivo.trim()
        debug = debug.trim()


        if(tipo === '') {
          continuar = false
          mensajeError += " * Debe seleccionar el tipo de impresora.\n"
        }
    
        if(continuar) {
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_cajero: this.idCajero,
                tipo: tipo,
                protocolo: protocolo,
                puerto: puerto,
                velocidad: velocidad,
                debug: debug,
                archivo: archivo
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/cajero/configimpresora'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //alert("Error actualizando informaacion Impresora")
		        //console.log('Error respuesta actualizando Impresora')
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Error actualizando informaacion Impresora',
                    severidadAlerta: 'error'
                })
		      } else {
		        //console.log('Informacion Impresora actualizada')
		        //alert("Informacion Impresora actualizada")
                this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Informacion Impresora actualizada',
                    severidadAlerta: 'success'
                })
		      }
		    })
		}
        
    }

    componentDidMount() {
        this.queryCajero()
    }

    render() {
        return(
            <Box>
                <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                    Configurar Impresora
                </Typography>

                <Paper elevation={16} >
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <form>
                                        <FormLabel>Información impresora</FormLabel>
                                        <br />
                                        <br />
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Tipo</InputLabel>
                                                <Select
                                                    label="Tipo"
                                                    value={this.state.cmbTiposImpresora}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbTiposImpresora}>
                                                    <MenuItem value={0}>-- Seleccione Tipo --</MenuItem>
                                                    {this.state.tiposImpresora.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Protocolo</InputLabel>
                                                <Select
                                                    label="Protocolo"
                                                    value={this.state.cmbProtocolos}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbProtocolos}>
                                                    <MenuItem value={0}>-- Seleccione Protocolo --</MenuItem>
                                                    {this.state.protocolos.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Puerto"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtPuerto}
                                                onChange={this.onChangetxtPuerto}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Velocidad</InputLabel>
                                                <Select
                                                    label="Protocolo"
                                                    value={this.state.cmbVelocidades}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbVelocidades}>
                                                    <MenuItem value={0}>-- Seleccione Velocidad --</MenuItem>
                                                    {this.state.velocidades.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Archivo"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtArchivo}
                                                onChange={this.onChangetxtArchivo}
                                                fullWidth
                                                />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth component="fieldset">
                                                <InputLabel>Debug</InputLabel>
                                                <Select
                                                    label="Debug"
                                                    value={this.state.cmbDebugs}
                                                    defaultValue={0}
                                                    onChange={this.onChangecmbDebugs}>
                                                    <MenuItem value={0}>-- Seleccione Debug --</MenuItem>
                                                    {this.state.debugs.map(item => (
                                                    <MenuItem value={item}>{item}</MenuItem>
                                                    ))}
                                                </Select>
                                                </FormControl>
                                            </Grid>

                                        </Grid>
                                    </form>
                                </CardContent>
                                <CardActions>
                                        <Button  variant="contained" onClick={this.btnGuardarConfiguracionClick}>Guardar Configuración</Button>
                                        <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                                        </Snackbar>  
                                    </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Paper>
            

            </Box>
        )
    }
}


export default CajerosConfigurarImpresora