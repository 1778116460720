import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import localeEs from './assets/locales/es.json'
import localeEn from './assets/locales/en.json'


const fallbackLng = ['es'];
const availableLanguages = ['es', 'en'];

const resources = {
    es: {
      translation: localeEs
    },
    en: {
      translation: localeEn
    }
  };

  i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng,
    resources,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;