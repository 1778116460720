import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import EditarIcon from '@mui/icons-material/Edit';

const session = require('../../config/session')

function Row(props) {
 const {usuario} = props

 var entidades = []
 if(usuario.entidades != null) {
  /* usuario.entidades.split('**-**').map((entidad) => {
    entidades.push(
      <span>* {entidad}<br /></span>
    )
  })*/

  usuario.entidades.split('**-**').forEach((entidad) => {
    entidades.push(
      <span>* {entidad}<br /></span>
    )
  })
}

 var roles = []
 if(usuario.roles != null) {
  /* usuario.roles.split('**-**').map((rol) => {
    roles.push(
     <span>* {rol}<br /></span>
    )
  })*/

  usuario.roles.split('**-**').forEach((rol) => {
    roles.push(
     <span>* {rol}<br /></span>
    )
  })  
 }

 var estado = ""

 switch(usuario.estado) {
  case "A":
    estado = "Activo"
    break
  case "I":
    estado = "Inactivo"
    break
  case "W":
    estado = "Desactivado login fallido"
    break
  default:
    break
 }

 return (
   <TableRow>
     <TableCell>
       {usuario.fecha_registro}
     </TableCell>
     <TableCell>
        {entidades}
     </TableCell>
     <TableCell>
        {roles}
     </TableCell>
     <TableCell>
        {usuario.nombres}
     </TableCell>
     <TableCell>
       {usuario.apellidos}
     </TableCell>
     <TableCell>
       {usuario.cedula}
     </TableCell>
     <TableCell>
       {usuario.usuario}
    </TableCell>
    <TableCell>
      {usuario.email}
    </TableCell>
    <TableCell>
      {estado}
    </TableCell>
    <TableCell>
    {
      session.checkPermiso('acceder_usuarios_editar') && 
      <Link to={{pathname: '/dashboard/usuarios/editar', state: {id_usuario: usuario.id}}} style={{ textDecoration: 'none' }}><Button variant="contained" endIcon={<EditarIcon />} color="primary">Editar</Button></Link>
    }
    </TableCell>
  </TableRow>
 );
}


class UsuariosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      cajeros: []
    };
  }

  componentDidMount() {
  }

  render() {
    const usuarios = this.props.usuarios


    const columns = [
      {field: "fecha_registro", headerName: 'Fecha Registro', width: 250},
      {field: "entidades", headerName: 'Entidades', width: 250},
      {field: "roles", headerName: 'Roles', width: 250},
      {field: "nombres", headerName: 'Nombres', width: 220},
      {field: "apellidos", headerName: 'Apellidos', width: 170},
      {field: "cedula", headerName: 'Identificación', width: 170},
      {field: "usuario", headerName: 'Usuario', width: 150},
      {field: "email", headerName: 'Email', width: 150},
      {field: "estado", headerName: 'Estado', width: 100},
      {field: "", headerName: ''},
    ]

    return (
      <Box sx={{ maxWidth: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader class="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {usuarios.map((usuario) => {
                return(
                  <Row usuario={usuario} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default UsuariosTable;
