import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'



import EntidadesTable from './EntidadesTable'
import { Alert, AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class EntidadesPage extends Component {

  constructor(props) {
      super(props)

      this.state = {
          entidades: [],
          idEntidad: 0,
          txtNombre: "",
          txtTagURL: "",
          txtLogoWeb: "",
          txtLogoCajero: "",
          txtIPsAutorizadas: "",
          txtDenominacionesSobre: "",
          txtDenominacionesSoportadas: "",
          cmbModos: "",
          cmbUbicaciones: "",
          cmbSobres: "",
          cmbEstados: "I",
          accion: "Agregar",
          modos: [
            {nombre: 'Aceptación', id: 'Aceptacion'},
            {nombre: 'Dispensación', id: 'Dispensacion'},
            {nombre: 'Mixto', id: 'Mixto'}
          ],
          ubicaciones: [
            {nombre: 'Local', id: 'Local'},
            {nombre: 'Servidor', id: 'Servidor'},
            {nombre: 'Externo', id: 'Externo'}
          ],
          sobres: [
            {nombre: 'Activado', id: 'Activado'},
            {nombre: 'Desactivado', id: 'Desactivado'}
          ],
          estados: [
            {nombre: 'Desactivada', id: 'I'},
            {nombre: 'Activada', id: 'A'},
          ],

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
      }

    this.prepararNuevo = this.prepararNuevo.bind(this)
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }


  componentDidMount() {
      this.queryEntidades()
  }

  btnSeleccionarRegistro = (id) => {
    this.queryEntidad(id)
  }

  btnCancelarClick = () => {
    this.prepararNuevo()
  }
  //---LOGICA BOTON PARA ACTUALIZA R ENTIDAD ----

  btnGuardarClick = () => {
      console.log('Agregando entidad....')

      let continuar = true
      let mensajeError = "Error:\n"

      var nombre = this.state.txtNombre //
      console.log(typeof(nombre));
      console.log(typeof(this.state.txtNombre))
      
      var tagURL = this.state.txtTagURL //
      var logoWeb = this.state.txtLogoWeb //
      var logoCajero = this.state.txtLogoCajero //
      var denominacionesSobre = this.state.txtDenominacionesSobre  //
      var denominacionesSoportadas = this.state.txtDenominacionesSoportadas //
      var modo = this.state.cmbModos
      var ubicacion = this.state.cmbUbicaciones
      var ipsAutorizadas = this.state.txtIPsAutorizadas //
      var sobre = this.state.cmbSobres
      var estado = this.state.cmbEstados

      
      if(nombre === '') {
        continuar = false
        mensajeError += " * Debe ingresar el nombre.\n"
      }

      if(tagURL === '') {
        continuar = false
        mensajeError += " * Debe ingresar el Tag URL.\n"
      }

      if(logoWeb === '') {
        continuar = false
        mensajeError += " * Debe ingresar el logo web.\n"
      }

      if(logoCajero === '') {
        continuar = false
        mensajeError += " * Debe ingresar el logo cajero.\n"
      }

      if(denominacionesSoportadas === '') {
        continuar = false
        mensajeError += " * Debe ingresar las denominaciones soportadas.\n"
      }
  
      if(continuar) {
        if(this.state.accion === "Agregar") {
          var restArgs = {
            method: 'POST',
            headers: 'JSON',
            body: {
            nombre: nombre,
                  tag_url: tagURL,
                  logo_web: logoWeb,
                  logo_cajero: logoCajero,
                  modo: modo,
                  ubicacion: ubicacion,
                  ips_autorizadas: ipsAutorizadas,
                  sobre: sobre,
                  denominaciones_sobre: denominacionesSobre,
                  denominaciones_soportadas: denominacionesSoportadas,
                  estado: estado
            },
              url: process.env.REACT_APP_URL_BACKEND +  '/entidades/new'
          }
		
          RestTunel.makePetition(restArgs,(err, data) => {
            if(err) {
              // alert("Error creando entidad")
              // console.log('Error respuesta insertar entidad')
                  this.setState({
                    mensajeError: data.message,
                    mostrarAlerta: true,
                    mensajeAlerta: 'Error creando entidad',
                    severidadAlerta: 'error'
                  })
            } else {
              //console.log('Entidad insertada')
                  //alert("Nueva entidad creada")
                  this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Nueva entidad creada',
                    severidadAlerta: 'error'
                  })
                  this.prepararNuevo()
                  this.queryEntidades()
            }
          })

        } else {
            /* eslint-disable no-redeclare */    
          var restArgs = {
              method: 'PATCH',
              headers: 'JSON',
              body: {
              id_entidad: this.state.idEntidad,
                    nombre: nombre,
                    tag_url: tagURL,
                    logo_web: logoWeb,
                    logo_cajero: logoCajero,
                    modo: modo,
                    ubicacion: ubicacion,
                    ips_autorizadas: ipsAutorizadas,
                    sobre: sobre,
                    denominaciones_sobre: denominacionesSobre,
                    denominaciones_soportadas: denominacionesSoportadas,
                    estado: estado
            },
              url: process.env.REACT_APP_URL_BACKEND +  '/entidades/update'
            }
            /* eslint-enable no-redeclare */
		
          RestTunel.makePetition(restArgs,(err, data) => {
            if(err) {
              //alert("Error actualziando entidad")
              //console.log('Error respuesta actualizar entidad')
                  this.setState({
                    mensajeError: data.message,
                    mostrarAlerta: true,
                    mensajeAlerta: 'Error actualziando entidad',
                    severidadAlerta: 'error'
                  })
            } else {
              //console.log('Entidad actualizada')
                //  alert("Entidad actualizada")
                  this.setState({
                    mostrarAlerta: true,
                    mensajeAlerta: 'Entidad actualizada',
                    severidadAlerta: 'success'
                  })
                  this.prepararNuevo()
                  this.queryEntidades()
            }
          })
        }
      } else {

        //Esta callendo aca 
        mensajeError = 'Error en la solicitud' 
        //alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
      }
      
  }

  //---------------------

  prepararNuevo() {
    this.setState({
      txtNombre: "",
      txtTagURL: "",
      txtLogoWeb: "",
      txtLogoCajero: "",
      txtDenominacionesSobre: "",
      txtDenominacionesSoportadas: "",
      cmbModos: "",
      cmbUbicaciones: "",
      txtIPsAutorizadas: "",
      cmbSobres: "",
      cmbEstados: "I",
      accion: "Agregar"
    })
  }

  queryEntidades() {
    console.log('Consultando entidades....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/entidades/'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando entidades")
        // console.log('Error respuesta consultando entidades')
        this.setState({
          entidades: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando entidades',
          severidadAlerta: 'error'
        })
      } else {
		    //console.log('Entidades consultadas')
          this.setState({
            entidades: data
            })
      }
    })
    
  }

  queryEntidad(idEntidad) {
    console.log('Consultando entidad....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		  id_entidad: idEntidad
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/entidades/detalle'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando entidad")
        //console.log('Error respuesta consultando entidad')
          this.setState({
            entidad: {},
            idEntidad: 0,
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando entidad',
            severidadAlerta: 'error'
          })
      } else {
		    //console.log('Entidad consultada')
          this.setState({
            entidad: data
          })
          
          var entidad = data

          this.setState({
            idEntidad: entidad.id,
            txtNombre: entidad.nombre,
            txtTagURL: entidad.tag_url_login,
            txtLogoWeb: entidad.logo_web,
            txtLogoCajero: entidad.logo_cajero,
            txtDenominacionesSobre: entidad.denominaciones_sobre,
            txtDenominacionesSoportadas: entidad.denominaciones_soportadas,
            cmbModos: entidad.modo,
            cmbUbicaciones: entidad.ubicacion,
            txtIPsAutorizadas: entidad.allowed_ips,
            cmbSobres: entidad.sobre,
            cmbEstados: entidad.estado,
            accion: "Editar"
          })
      }
    })
  }

  onChangetxtNombre = (event) => {
    const nombre = event.target.value.toString()
    this.setState({txtNombre: nombre})
  }

  onChangetxtTagURL = (event) => {
    const tag = event.target.value.toString()
    this.setState({txtTagURL: tag})
  }

  onChangetxtLogoWeb = (event) => {
    const logoWeb = event.target.value.toString()
    this.setState({txtLogoWeb: logoWeb})
  }

  onChangetxtLogoCajero = (event) => {
    const logoCajero = event.target.value.toString()
    this.setState({txtLogoCajero: logoCajero})
  }
  
  onChangetxtIPsAutorizadas = (event) => {
    const ipsAutorizadas = event.target.value.toString()
    this.setState({txtIPsAutorizadas: ipsAutorizadas})
  }

  onChangecmbModos = (event) => {
    const modo = event.target.value
    this.setState({cmbModos: modo})
  }

  onChangetxtDenominacionesSobre = (event) => {
    const denominaciones = event.target.value
    this.setState({txtDenominacionesSobre: denominaciones})
  }

  onChangetxtDenominacionesSoportadas = (event) => {
    const denominaciones = event.target.value
    this.setState({txtDenominacionesSoportadas: denominaciones})
  }

  onChangecmbSobres = (event) => {
    const sobre = event.target.value
    this.setState({cmbSobres: sobre})
  }

  onChangecmbUbicaciones = (event) => {
    const ubicacion = event.target.value
    this.setState({cmbUbicaciones: ubicacion})
  }

  onChangecmbEstados = (event) => {
    const estado = event.target.value
    this.setState({cmbEstados: estado})
  }

  render() {
      return(
        <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Entidades
              </Typography>

              <Paper elevation={16}>
                <Card>
                    <CardContent>
                      <form>
                          <FormLabel>Información entidad:</FormLabel>
                          <Grid container xs={10} spacing={2} rowSpacing={2}>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Nombre"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtNombre}
                                onChange={this.onChangetxtNombre}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Tag URL"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtTagURL}
                                onChange={this.onChangetxtTagURL}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Logo Web"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtLogoWeb}
                                onChange={this.onChangetxtLogoWeb}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="Logo Cajeroooo"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtLogoCajero}
                                onChange={this.onChangetxtLogoCajero}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <InputLabel>Modo</InputLabel>
                              <Select
                                  label="Modo"
                                  value={this.state.cmbModos}
                                  defaultValue={''}
                                  onChange={this.onChangecmbModos}>
                                  <MenuItem value={''}>-- Seleccione Modo --</MenuItem>
                                  {this.state.modos.map(item => (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                  ))}
                              </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <InputLabel>Ubicación</InputLabel>
                              <Select
                                  label="Ubicación"
                                  value={this.state.cmbUbicaciones}
                                  defaultValue={''}
                                  onChange={this.onChangecmbUbicaciones}>
                                  <MenuItem value={''}>-- Seleccione Ubicación --</MenuItem>
                                  {this.state.ubicaciones.map(item => (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                  ))}
                              </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                label="IPs Autorizadas"
                                id="outlined-basic"
                                variant="outlined"
                                value={this.state.txtIPsAutorizadas}
                                onChange={this.onChangetxtIPsAutorizadas}
                                fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <InputLabel>Estado</InputLabel>
                              <Select
                                  label="Estado"
                                  value={this.state.cmbEstados}
                                  defaultValue={'I'}
                                  onChange={this.onChangecmbEstados}>
                                  {this.state.estados.map(item => (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                  ))}
                              </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <InputLabel>Sobre</InputLabel>
                              <Select
                                  label="Sobre"
                                  value={this.state.cmbSobres}
                                  defaultValue={''}
                                  onChange={this.onChangecmbSobres}>
                                  <MenuItem value={''}>-- Seleccione Sobre --</MenuItem>
                                  {this.state.sobres.map(item => (
                                  <MenuItem value={item.id}>{item.nombre}</MenuItem>
                                  ))}
                              </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <TextField
                              label="Denominaciones Sobres"
                              id="outlined-basic"
                              variant="outlined"
                              value={this.state.txtDenominacionesSobre}
                              multiline
                              rows={5}
                              onChange={this.onChangetxtDenominacionesSobre}
                              fullWidth
                              />
                              </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                              <FormControl fullWidth component="fieldset">
                              <TextField
                              label="Denominaciones Soportadas"
                              id="outlined-basic"
                              variant="outlined"
                              value={this.state.txtDenominacionesSoportadas}
                              multiline
                              rows={5}
                              onChange={this.onChangetxtDenominacionesSoportadas}
                              fullWidth
                              />
                              </FormControl>
                            </Grid>
                          </Grid>
                      </form>
                    </CardContent>
                    <CardActions>
                            <Button variant="contained" onClick={this.btnGuardarClick}>{this.state.accion === 'Agregar' ? "Agregar Entidad" : "Editar Entidad"}</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                            <Button  variant="contained" onClick={this.btnCancelarClick}>Cancelar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                        </CardActions>
                </Card>
              </Paper>
              <br />
              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Listado entidades:
                    </Typography>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <EntidadesTable entidades={this.state.entidades} eventoSeleccionar={this.btnSeleccionarRegistro} />
                    </Box>
                  </CardContent>
                  <CardActions>

                  </CardActions>
                </Card>

              </Paper>
          

        </Box>
      )
  }

}


export default EntidadesPage