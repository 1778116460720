import {Link} from 'react-router-dom'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

function ItemCategoria(props) {
	var categoria = props.categoria
	return(
		<tr>
			<th colSpan={2}>{categoria}</th>
		</tr>
	)
} 

function ItemEstado(props) {
	var estado = props.estado
	return(
		<tr>
			<td><img alt='cajero' src={'/estado_cajero/' + estado.archivo} width={20}/></td>
			<td>{estado.descripcion}</td>
		</tr>
	)
}

function ItemCajero (props) {
	var estadosCajero = props.estadosCajero
	var categoriasEstados = props.categoriasEstados

	var itemEstados = []
	
	categoriasEstados.forEach(categoria => {
		itemEstados.push(<ItemCategoria categoria={categoria.nombre} />)
		categoria.tipos.forEach(tipo => {
			
			//Buscar si existe un estado en el cajero para este tipo en especifico
			var itemEstado = null			
			var itemEstadoDesconocido = null
			
			tipo.estados.forEach(estado => {
				if(estado.codigo_estado === 0) {
					itemEstadoDesconocido = estado
				}
				//Buscar el estado del cajero
				estadosCajero.forEach(estadoCajero => {
					if(estado.id === estadoCajero) {
						itemEstado = estado	
					}
				})
			})
			
			if(itemEstado != null) {
				itemEstados.push(<ItemEstado estado={itemEstado} />)
			} else {
				if(itemEstadoDesconocido != null) {
					itemEstados.push(<ItemEstado estado={itemEstadoDesconocido} />)	
				}
			}
			
		})
	})
	
	
	return (
		<Card item xs sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 1}}>
			<CardContent>
				<table class="TablaEstados">
					{
						itemEstados
					}
				</table>
			</CardContent>
		</Card>
	)
}

export default ItemCajero