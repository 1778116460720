import React, {Component, useState} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import ClientesTable from './ClientesTable'
import { Alert, AlertTitle, Collapse, Snackbar } from '@mui/material'
const session = require('../../../config/session')
const RestTunel = require('../../../libs/rest_tunnel')

class ClientesPage extends Component {


    constructor(props) {
        super(props)

        this.state = {
            clientes: [],
            entidades: [],
            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: '',
        }


        this.cmbEntidades = React.createRef()
        this.txtIdentificacion = React.createRef()
        this.txtCodigo = React.createRef()


        this.btnBuscarClick = this.btnBuscarClick.bind(this)
    }

    showAlert = (mensaje, severidad) => {
      this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensaje,
          severidadAlerta: severidad,
      });
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }
    

    btnBuscarClick() {
      this.queryClientes()
    }
    
    

    queryEntidades() { 
      console.log('Consultando entidades....')
      
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        // console.log('Error respuesta consultando entidades')
	        //alert("Se produjo un error consultando entidades")

          this.showAlert('Se produjo un error consultando entidades', 'Error')
	        this.setState({
	          mensajeError: data.message
	        })
	      } else {
	        //console.log('Entidades consultadas')
	        this.setState({
	          entidades: data
	          })
	      }      
  		})
  
   }

    queryClientes() {
        var filtroIdEntidad = ''
        var filtroIdentificacion = ''
        var filtroCodigo = ''

        if(this.cmbEntidades.current.value !== 0){
          filtroIdEntidad = this.cmbEntidades.current.value
        }

        filtroIdentificacion = this.txtIdentificacion.current.value.toString()
        filtroCodigo = this.txtCodigo.current.value.toString()
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  filtro_id_entidad: filtroIdEntidad,
            filtro_identificacion: filtroIdentificacion,
            filtro_codigo: filtroCodigo
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/clientes/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //*//console.log('Error respuesta clientes')
	        //alert("Se produjo un error consultando los clientes")
          this.showAlert('Se produjo un error consultando los clientes', 'error')
              this.setState({
                clientes: [],
                mensajeError: data.message
              })
	      } else {
	        //console.log('Clientes consultadas')
	        this.setState({
                clientes: data
            })
	      }      
  		})
    }

    componentDidMount() {
      this.queryClientes()
      this.queryEntidades()
  }

    render() {
        return(
            <div>
              <h3>Clientes Billetera</h3>
              <Card>
                <CardContent>
                  <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                    <Grid container xs={10} spacing={2}>
                      <Grid item fullWidth>
                        <Typography gutterBottom variant="h6" component="div">
                          Buscar clientes:
                        </Typography>
                      </Grid>
                      <Grid item container fullWidth>
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <InputLabel>Entidad</InputLabel>
                            <Select
                              label="Entidad"
                              inputRef={this.cmbEntidades}
                              defaultValue={0}>
                              <MenuItem value={0}>-- Todas las entidades --</MenuItem>
                              {this.state.entidades.map(entidad => (
                                <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth>
                            <TextField
                              label="Identificación"
                              id="outlined-basic"
                              variant="outlined"
                              inputRef={this.txtIdentificacion}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl>
                            <TextField
                              label="Código"
                              id="outlined-basic"
                              variant="outlined"
                              inputRef={this.txtCodigo}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button variant="contained" onClick={this.btnBuscarClick}>Buscar</Button>
                  <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar>
                </CardActions>
              </Card>

              <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

              <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Listado clientes:
                    </Typography>
                      <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <ClientesTable clientes={this.state.clientes}  eventoSeleccionar={this.btnSeleccionarRegistro} />
                      </Box>
                  </CardContent>
              </Card>
            </div>
        )
    }

}

export default ClientesPage