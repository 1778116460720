import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Select from '@mui/material/Select'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'


const session = require('../../config/session')

function Row(props) {
 const {evento} = props;
 const [open, setOpen] = React.useState(false)
 let image = ""

  switch (evento.tipo_evento) {
    case "I":
      image = "/img_event_informativo.png";
      break;
    case "O":
      image = "/img_event_operativo.png";
      break;
    case "E":
      image = "/img_event_error.png"; 
      break;
    default:
      image = "/img_event_informativo.png";
      break;

  }

 var detalle = (evento.detalle !== '' ? JSON.parse(evento.detalle) : '')
 var filaDetalle = []

 for(var campo in detalle) {
  switch(campo) {
    case 'detalle_boveda':
      var detalleBovedaObj = detalle[campo]
      for(var detalleBoveda in detalleBovedaObj) {
        switch(detalleBoveda) {
          case 'automatico':
            var currencies = detalleBovedaObj[detalleBoveda].currencies
            var detalleFila = []
       
            // Recorrer las diferentes monedas(pais)
            // eslint-disable-next-line no-loop-func
            currencies.forEach((currency) => {
              detalleFila.push(
                <span>Moneda País: {currency.currency}<br /></span>
              )
       
              //Tomar los billetes y monedas de esa moneda
              var bills = currency.bills
              var coins = currency.coins
       
              //Recorrer todos los billetes
              detalleFila.push(
                <span><br />Billetes:<br />Total: ${currency.total_bills.toLocaleString()}<br />Detalle:<br /></span>
              )
              bills.forEach((bill) => {
                detalleFila.push(
                  <span>${bill.denomination + " x " + bill.amount}<br /></span>
                )
              })
       
              //Recorrer todas las monedas
              detalleFila.push(
                <span><br />Monedas:<br />Total: ${currency.total_coins.toLocaleString()}<br />Detalle:<br /></span>
              )
              coins.forEach((coin) => {
                  detalleFila.push(
                    <span>${coin.denomination + " x " + coin.amount}<br /></span>
                  )
              })
       
              detalleFila.push(<br />)
              
            })
       
            filaDetalle.push(
              <TableRow>
                <TableCell className="sub-detalle-titulo">Automático:</TableCell>
                <TableCell className="sub-detalle-valor">{detalleFila}</TableCell>
              </TableRow>
            )
            break
          case 'manual':
            /* eslint-disable no-redeclare */
            var currencies = detalleBovedaObj[detalleBoveda].currencies
            var detalleFila = []
            /* eslint-enable no-redeclare */
       
            // Recorrer las diferentes monedas(pais)
            // eslint-disable-next-line no-loop-func
            currencies.forEach((currency) => {
              detalleFila.push(
                <span>Moneda País: {currency.currency}<br /></span>
              )
       
              //Tomar los billetes y monedas de esa moneda
              var bills = currency.bills
              var coins = currency.coins
       
              //Recorrer todos los billetes
              detalleFila.push(
                <span><br />Billetes:<br />Total: ${currency.total_bills.toLocaleString()}<br />Detalle:<br /></span>
              )
              bills.forEach((bill) => {
                detalleFila.push(
                  <span>${bill.denomination + " x " + bill.amount}<br /></span>
                )
              })
       
              //Recorrer todas las monedas
              detalleFila.push(
                <span><br />Monedas:<br />Total: ${currency.total_coins.toLocaleString()}<br />Detalle:<br /></span>
              )
              coins.forEach((coin) => {
                  detalleFila.push(
                    <span>${coin.denomination + " x " + coin.amount}<br /></span>
                  )
              })
       
              detalleFila.push(<br />)
              
            })
       
            filaDetalle.push(
              <TableRow>
                <TableCell className="sub-detalle-titulo">Manual:</TableCell>
                <TableCell className="sub-detalle-valor">{detalleFila}</TableCell>
              </TableRow>
            )
            break
          default:
            filaDetalle.push(
              <TableRow>
               <TableCell className="sub-detalle-titulo">{campo}</TableCell>
               <TableCell className="sub-detalle-valor">{JSON.stringify(detalle[campo])}</TableCell>
              </TableRow>
            )
         }
      }
      break
    case 'uid_usuario_autorizo':
          	break
    default:
      filaDetalle.push(
        <TableRow>
         <TableCell className="sub-detalle-titulo">{campo}</TableCell>
         <TableCell className="sub-detalle-valor">{JSON.stringify(detalle[campo])}</TableCell>
        </TableRow>
      )
      
      
  }
 }

 return (
   <React.Fragment>
     <TableRow>
      <TableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
       <TableCell>
         <img alt='x' src={image} style={{width: 24}}/>
       </TableCell>
       <TableCell>
         {evento.fecha_registro} 
       </TableCell>
       <TableCell>
        {
          evento.codigo_cajero
        }
       </TableCell>
       <TableCell>
         {evento.alias_cajero}
       </TableCell>
       <TableCell>
         {evento.tipo_evento}
       </TableCell>
       <TableCell>
         {evento.id_evento}
       </TableCell>
       <TableCell>
         {evento.codigo_evento}
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
     <TableRow>
      <TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Table className="detalle">
            <TableRow>
              <TableCell className="sub-detalle-titulo">Fecha Dispositivo</TableCell>
              <TableCell className="sub-detalle-valor">{evento.fecha_dispositivo}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="detalle-titulo">
                Detalle
              </TableCell>
              <TableCell className="detalle-valor">
                <Table>
                  {filaDetalle}
                </Table>
              </TableCell>
            </TableRow>
          </Table>
        </Collapse>
      </TableCell>
     </TableRow>
   </React.Fragment>
 );
}

function RowBilletes(billetes) {
  /* eslint-disable no-redeclare */
  var billetes = billetes.split(',')
  /* eslint-enable no-redeclare */

  var filasBilletes = []
  for(var billete in billetes) {
    filasBilletes.push(
      <TableRow>
       <TableCell className="sub-detalle-valor">{billetes[billete]}</TableCell>
      </TableRow>
    )
  }

  return(
    <Table>
      {filasBilletes}
    </Table>
  )
}


class EventosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      eventos: []
    }



  }


  componentDidMount() {
  }

  render() {

    var eventos = this.props.eventos

    const columns = [
      {field: "desplegable", headerName: "", width: 10},
      {field: "tipo_icono", headerName: "", width: 10},
      {field: "fecha_hora", headerName: 'Fecha Registro', width: 150},
      {field: "codigo_cajero", headerName: 'Codigo Cajero', width: 150},
      {field: "alias_cajero", headerName: 'Alias Cajero', width: 170},
      {field: "tipo_evento", headerName: 'Tipo', width: 50},
      {field: "id_evento", headerName: 'ID Evento', width: 100},
      {field: "codigo_evento", headerName: 'Codigo Evento', width: 150},
    ]

    return (
      <Paper sx={{ width: 900, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {eventos.map((evento) => {
                return(
                  <Row evento={evento} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default EventosTable;
