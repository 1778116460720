import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CardActions from '@mui/material/CardActions'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class UsuariosEditarPage extends Component {

    constructor(props) {
        super(props)

        this.idUsuario = props.location.state.id_usuario
        this.entidadesSeleccionadas = []

        this.state = {
            usuario: {},
            txtNombres: "",
            txtApellidos: "",
            txtIdentificacion: "",
            txtEmail: "",
            txtUsuario: "",
            txtPasswordNueva: "",
            txtPasswordVerificacion: "",
            txtIPsAutorizadas: "",
            entidades: [],
            entidadesDisponibles: [],
            entidadesUsuario: [],
            roles: [],
            rolesDisponibles: [],
            rolesUsuario: [],
            cajeros: [],
            cajerosDisponibles: [],
            cajerosUsuario: [],
            gruposCajerosDisponibles: [],
            gruposCajerosUsuario: [],

            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }

    onChangetxtNombres = (event) => {
        const nombres = event.target.value.toString()
        this.setState({txtNombres: nombres})
    }

    onChangetxtApellidos = (event) => {
        const apellidos = event.target.value.toString()
        this.setState({txtApellidos: apellidos})
    }

    onChangetxtIdentificacion = (event) => {
        const identificacion = event.target.value.toString()
        this.setState({txtIdentificacion: identificacion})
    }

    onChangetxtEmail = (event) => {
        const email = event.target.value.toString()
        this.setState({txtEmail: email})
    }
    
  onChangetxtUsuario = (event) => {
    const usuario = event.target.value.toString()
    this.setState({txtUsuario: usuario})
  }

  onChangetxtPasswordNueva = (event) => {
    const password = event.target.value.toString()
    this.setState({txtPasswordNueva: password})
}

  onChangetxtPasswordVerificacion = (event) => {
    const password = event.target.value.toString()
    this.setState({txtPasswordVerificacion: password})
  }
  
  onChangetxtIPsAutorizadas = (event) => {
    const ipsAutorizadas = event.target.value.toString()
    this.setState({txtIPsAutorizadas: ipsAutorizadas})
  }
  
  actualizarEntidadesDisponibles() {
    var entidades = this.state.entidades
    
    var entidadesDisponibles = []
    var entidadesUsuario = this.state.entidadesUsuario

    /*entidades.map((entidad) => {
      var blnAgregar = true

      entidadesUsuario.map((entidadUsuario) => {
        if(entidadUsuario.id == entidad.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        entidadesDisponibles.push(entidad)
      }
    })*/
    
    entidades.forEach((entidad) => {
      var blnAgregar = true

      entidadesUsuario.forEach((entidadUsuario) => {
        if(entidadUsuario.id === entidad.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        entidadesDisponibles.push(entidad)
      }
    })

    this.setState({
      entidadesDisponibles: entidadesDisponibles
    })
  }

  agregarEntidad(idEntidad) {
    var entidadesDisponibles = this.state.entidadesDisponibles
    var entidadesUsuario = this.state.entidadesUsuario

    /*entidadesDisponibles.map((entidad) => {
      if(entidad.id == idEntidad) {
        entidadesUsuario.push(entidad)
      }
    })*/

    entidadesDisponibles.forEach((entidad) => {
      if(entidad.id === idEntidad) {
        entidadesUsuario.push(entidad)
      }
    })

    this.setState({
      entidadesUsuario: entidadesUsuario
    })

    this.actualizarEntidadesDisponibles()
  }

  removerEntidad(idEntidad) {
    var entidadesUsuario = this.state.entidadesUsuario
    for(var x = 0; x < entidadesUsuario.length; x++) {
      if(entidadesUsuario[x].id === idEntidad) {
        entidadesUsuario.splice(x, 1) 
        break
      }
    }

    this.setState({
      entidadesUsuario: entidadesUsuario
    })

    this.actualizarEntidadesDisponibles()
  }

  actualizarRolesDisponibles() {
    var roles = this.state.roles
    
    var rolesDisponibles = []
    var rolesUsuario = this.state.rolesUsuario

    /*roles.map((rol) => {
      var blnAgregar = true

      rolesUsuario.map((rolUsuario) => {
        if(rolUsuario.id == rol.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        rolesDisponibles.push(rol)
      }
    })*/

    roles.forEach((rol) => {
      var blnAgregar = true

      rolesUsuario.forEach((rolUsuario) => {
        if(rolUsuario.id === rol.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        rolesDisponibles.push(rol)
      }
    })
    
    this.setState({
      rolesDisponibles: rolesDisponibles
    })
  }

  agregarRol(idRol) {
    var rolesDisponibles = this.state.rolesDisponibles
    var rolesUsuario = this.state.rolesUsuario

    /* rolesDisponibles.map((rol) => {
      if(rol.id == idRol) {
        rolesUsuario.push(rol)
      }
    })*/

    rolesDisponibles.forEach((rol) => {
      if(rol.id === idRol) {
        rolesUsuario.push(rol)
      }
    })

    this.setState({
      rolesUsuario: rolesUsuario
    })

    this.actualizarRolesDisponibles()
  }

  removerRol(idRol) {
    var rolesUsuario = this.state.rolesUsuario
    for(var x = 0; x < rolesUsuario.length; x++) {
      if(rolesUsuario[x].id === idRol) {
        rolesUsuario.splice(x, 1) 
        break
      }
    }

    this.setState({
      rolesUsuario: rolesUsuario
    })

    this.actualizarRolesDisponibles()
  }


    componentDidMount() {
        this.queryUsuario()
        this.queryEntidades()
        this.queryEntidadesUsuario()
        this.queryRoles()
        this.queryRolesUsario()
        this.queryCajeros()
        this.queryCajerosUsuario()
    }

    btnActualizarUsuarioClick = () => {
        console.log('Actualizando usuario....')

        var continuar = true
        var mensajeError = "Error:\n"

        var nombres = this.state.txtNombres
        var apellidos = this.state.txtApellidos
        var identificacion = this.state.txtIdentificacion
        var usuario = this.state.txtUsuario
        var email = this.state.txtEmail
        var ipsAutorizadas = this.state.txtIPsAutorizadas
        

        nombres = nombres.trim()
        apellidos = apellidos.trim()
        identificacion = identificacion.trim()
        usuario = usuario.trim()
        email = email.trim()
        ipsAutorizadas = ipsAutorizadas.trim()
        
        if(nombres === '') {
          continuar = false
          mensajeError += " * Debe ingresar los nombres.\n"
        }

        if(apellidos === '') {
          continuar = false
          mensajeError += " * Debe ingresar los apellidos.\n"
        }

        if(identificacion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la identificación.\n"
        }

        if(usuario === '') {
          continuar = false
          mensajeError += " * Debe ingresar el usuario.\n"
        }

        if(email === '') {
          continuar = false
          mensajeError += " * Debe ingresar el email.\n"
        }
    
        if(continuar) {
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_usuario: this.state.usuario.id,
	                nombres: nombres,
	                apellidos: apellidos,
	                identificacion: identificacion,
	                usuario: usuario,
	                email: email,
	                ips_autorizadas: ipsAutorizadas
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/edit'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta actualizar usuario')
              //  alert("Se produjo un error actualizando el usuario")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Se produjo un error actualizando el usuario',
                  severidadAlerta: 'error'
                })
		      } else {
		        //console.log('Usuario actualizado')
              //  alert("Usuario Actualizado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Usuario Actualizado',
                  severidadAlerta: 'success'
                })
		      }
		    })
			
			
        } else {
          //alert(mensajeError)
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: mensajeError,
            severidadAlerta: 'error'
          })
        }
        
    }

    btnActualizarPasswordClick = () => {
      console.log('Actualizando password....')

      var continuar = true
      var mensajeError = "Error:\n"

      var passwordNueva = this.state.txtPasswordNueva.toString()
      var passwordVerifiacion = this.state.txtPasswordVerificacion.toString()

      passwordNueva = passwordNueva.trim()
      passwordVerifiacion = passwordVerifiacion.trim()
      
      if(passwordNueva === '') {
        continuar = false
        mensajeError += " * Debe ingresar la nueva contraseña.\n"
      }

      if(passwordVerifiacion === '') {
        continuar = false
        mensajeError += " * Debe ingresar la verificación de la contraseña.\n"
      }

      if(passwordNueva !== passwordVerifiacion) {
        continuar = false
        mensajeError += " * La contraseña nueva y la verificación no coinciden.\n"
      }
  
      if(continuar) {
		  var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
				  id_usuario: this.state.usuario.id,
	              usuario: this.state.usuario.usuario,
	              password: passwordNueva
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/passwordesp'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta actualizar contraseña')
              //alert("Se produjo un error actualizando la contraseña")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Se produjo un error actualizando la contraseña',
                  severidadAlerta: 'error'
                })
		      } else {
				  //alert("Contraseña actualziada")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Contraseña actualziada',
              severidadAlerta: 'success'
            })
		      }
		    })
		  
      } else {
        //alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
      }
      
  }

  btnGuardarEntidadesClick = () => {
    var entidadesUsuario = this.state.entidadesUsuario
    var entidades = []
    var mensajeError = "Error:\n"
    var continuar = true

    if(entidadesUsuario.length < 1) {
      mensajeError += "* El usuario debe pertenecer al menos a una entidad."
      continuar = false
    } else {
      /* entidadesUsuario.map((entidad) => {
        entidades.push(entidad.id)
      }) */

      entidadesUsuario.forEach((entidad) => {
        entidades.push(entidad.id)
      })
    }
    
    if(continuar) {
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.state.usuario.id,
            usuario: this.state.usuario.usuario,
            entidades: entidades
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/entidades'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar entidades')
            //alert("Se produjo un error actualizando entidades")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando entidades',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Entidades actualizadas')
            //alert("Entidades actualizadas")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Entidades actualizadas',
              severidadAlerta: 'success'
            })
	      }
	    })
		
    } else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  btnGuardarRolesClick = () => {
    var rolesUsuario = this.state.rolesUsuario
    var roles = []
    var mensajeError = "Error:\n"
    var continuar = true
    
    /* rolesUsuario.map((rol) => {
      roles.push(rol.id)
    })*/

    rolesUsuario.forEach((rol) => {
      roles.push(rol.id)
    })

    if(continuar) {
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.state.usuario.id,
            usuario: this.state.usuario.usuario,
            roles: roles
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/roles'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar roles')
            //alert("Se produjo un error actualizando roles usuario")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando roles usuario',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Roles actualizados')
            //alert("Roles actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Roles actualizados',
              severidadAlerta: 'success'
            })
	      }
	    })
		
    } else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  btnGuardarCajerosClick = () => {
    var cajerosUsuario = this.state.cajerosUsuario
    var cajeros = []
    var mensajeError = "Error:\n"
    var continuar = true

    
    /*cajerosUsuario.map((cajero) => {
        cajeros.push(
            {
              tipo: cajero.tipo,
              id: cajero.id
            }
          )
    }) */

    cajerosUsuario.forEach((cajero) => {
      cajeros.push(
          {
            tipo: cajero.tipo,
            id: cajero.id
          }
        )
    })

    if(continuar) {
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.state.usuario.id,
            usuario: this.state.usuario.usuario,
            cajeros: cajeros
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/cajeros'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar cajeros')
            //alert("Se produjo un error actualizando cajeros")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando cajeros',
              severidadAlerta: 'error'
            })
	      } else {
	          // console.log('Cajeros actualizados')
            // alert("Cajeros actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cajeros actualizados',
              severidadAlerta: 'success'
            })
            this.queryCajerosUsuario()
	      }
	    })
		
    } else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  cambiarEstado() {
      var estado = this.state.usuario.estado
      
      switch(estado) {
          case "I":
              estado = "A"
              break
          case "W":
              estado = "A"
              break
          case "A":
              estado = "I"
              break
          default:
            break
      }

      console.log('Actualizando usuario....')
      var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.idUsuario,
              usuario: this.state.usuario.usuario,
              estado: estado
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/usuario/estado/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error actualizando estado usuario")
	        //console.log('Error respuesta actualziando usuario')
              this.setState({
             	  mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error actualizando estado usuario',
                severidadAlerta: 'error'
              })
	      } else {
	            //console.log('Estado usuario actualizado')
              this.queryUsuario()
              //alert("Estado usario actualizado")
              this.setState({
                mostrarAlerta: true,
                mensajeAlerta: 'Estado usario actualizado',
                severidadAlerta: 'success'
             })
	      }
	    })
      
    }

    queryUsuario() {
        //console.log('Consultando usuario....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.idUsuario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/detalle'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando uusario")
	        //console.log('Error respuesta consultando usuario')
            this.setState({
           		  mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando uusario',
                severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Usuario consultado')
             
             var usuario = data
            this.setState({
                usuario: usuario,
                txtNombres: usuario.nombres,
                txtApellidos: usuario.apellidos,
                txtIdentificacion: usuario.cedula,
                txtUsuario: usuario.usuario,
                txtEmail: usuario.email,
                txtIPsAutorizadas: usuario.allowed_ips
            })
	      }
	    })
        
    }

    queryEntidades() {
        console.log('Consultando entidades....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidades")
	        //console.log('Error respuesta consultando entidades')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando entidades',
                severidadAlerta: 'error'
              })
	      } else {
	         console.log('Entidades consultadas')
              this.setState({
                entidades: data
                })
              this.actualizarEntidadesDisponibles()
	      }
	    })
        
      }

      queryEntidadesUsuario() {
        console.log('Consultando entidades usuario....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			   id_usuario: this.idUsuario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/entidades/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidades usuario")
	        //console.log('Error respuesta consultando entidades usuario')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando entidades',
                severidadAlerta: 'error'
              })
	      } else {
	        //console.log('Entidades usuario consultadas')
              this.setState({
                entidadesUsuario: data
                })
              this.actualizarEntidadesDisponibles()
	      }
	    })
    
      }
  
  
    queryRoles() {
      console.log('Consultando Roles....')
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/roles/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando roles")
	        //console.log('Error respuesta consultando roles')
            this.setState({
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando roles',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Roles consultados')
            this.setState({
              roles: data
              })
            this.actualizarRolesDisponibles()
	      }
	    })
      
    }

    queryCajeros() {
      console.log('Consultando cajeros....')
      
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/cajerosygrupos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajeros")
	        //console.log('Error respuesta consultando cajeros')
            this.setState({
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando cajeros',
              severidadAlerta: 'error'
            })
	      } else {
	          //console.log('Cajeros consultados')
            this.setState({
              cajeros: data
              })
            this.actualizarCajerosDisponibles()
	      }
	    })
    }

    queryRolesUsario() {
      console.log('Consultando Roles....')
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.idUsuario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/permisos/roles/usuario/list2'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando roles usuario")
	        //console.log('Error respuesta consultando roles usuario')
            this.setState({
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando roles usuario',
              severidadAlerta: 'error'
            })
	      } else {
	        // console.log('Roles usuario consultados')
            this.setState({
              rolesUsuario: data
              })
            this.actualizarRolesDisponibles()
	      }
	    })
      
    }

    queryCajerosUsuario() {
      console.log('Consultando cajeros usuario....')
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_usuario: this.idUsuario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosgrupos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajeros usuario")
	        //console.log('Error respuesta consultando cajeros usuario')
            this.setState({
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando cajeros usuario',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Cajeros usuario consultados')
            this.setState({
              cajerosUsuario: data
              })
            this.actualizarCajerosDisponibles()
	      }
	    })
      
    }


    actualizarCajerosDisponibles() {
      var cajeros = this.state.cajeros
      
      var cajerosDisponibles = []
      var gruposDisponibles = []
      var cajerosUsuario = this.state.cajerosUsuario
  
      /* cajeros.map((cajero) => {
        if(cajero.tipo == 1) {
          var blnAgregar = true
  
          cajerosUsuario.map((cajeroUsuario) => {
            if(cajeroUsuario.tipo == 1 && cajeroUsuario.id == cajero.id) {
              blnAgregar = false
            }
          })
    
          if(blnAgregar) {
            cajerosDisponibles.push(cajero)
          }
        } else {
          var blnAgregar = true
  
          cajerosUsuario.map((cajeroUsuario) => {
            if(cajeroUsuario.tipo == 2 && cajeroUsuario.id == cajero.id) {
              blnAgregar = false
            }
          })
  
          if(blnAgregar) {
            gruposDisponibles.push(cajero)
          }
        }
        
      })*/

      cajeros.forEach((cajero) => {
        if(cajero.tipo === 1) {
          var blnAgregar = true
  
          cajerosUsuario.forEach((cajeroUsuario) => {
            if(cajeroUsuario.tipo === 1 && cajeroUsuario.id === cajero.id) {
              blnAgregar = false
            }
          })
    
          if(blnAgregar) {
            cajerosDisponibles.push(cajero)
          }
        } else {
          blnAgregar = true
  
          cajerosUsuario.forEach((cajeroUsuario) => {
            if(cajeroUsuario.tipo === 2 && cajeroUsuario.id === cajero.id) {
              blnAgregar = false
            }
          })
  
          if(blnAgregar) {
            gruposDisponibles.push(cajero)
          }
        }
        
      })
      
      this.setState({
        cajerosDisponibles: cajerosDisponibles,
        gruposCajerosDisponibles:gruposDisponibles
      })
    }
  
    actualizarGruposCajerosDisponibles() {
      var grupos = this.state.gruposCajeros
      
      var gruposCajerosDisponibles = []
      var gruposCajerosUsuario = this.state.gruposCajerosUsuario
  
      /*grupos.map((grupo) => {
        var blnAgregar = true
  
        gruposCajerosUsuario.map((grupoUsuario) => {
          
          if(grupoUsuario.id == grupo.id) {
            blnAgregar = false
          }
        })
  
        if(blnAgregar) {
          gruposCajerosDisponibles.push(grupo)
        }
      })*/

      grupos.forEach((grupo) => {
        var blnAgregar = true
  
        gruposCajerosUsuario.forEach((grupoUsuario) => {
          
          if(grupoUsuario.id === grupo.id) {
            blnAgregar = false
          }
        })
  
        if(blnAgregar) {
          gruposCajerosDisponibles.push(grupo)
        }
      })
      
      this.setState({
        gruposCajerosDisponibles: gruposCajerosDisponibles
      })
    }
  
    agregarCajero(cajeroNuevo) {
      var cajerosDisponibles = this.state.cajerosDisponibles
      var gruposCajerosDisponibles = this.state.gruposCajerosDisponibles
      var cajerosUsuario = this.state.cajerosUsuario
  
      if(cajeroNuevo.tipo === 1) {
        /* cajerosDisponibles.map((cajero) => {
          if(cajero.tipo == cajeroNuevo.tipo && cajero.id == cajeroNuevo.id) {
            cajerosUsuario.push(cajero)
          }
        })*/

        cajerosDisponibles.forEach((cajero) => {
          if(cajero.tipo === cajeroNuevo.tipo && cajero.id === cajeroNuevo.id) {
            cajerosUsuario.push(cajero)
          }
        })
      } else {
        /*gruposCajerosDisponibles.forEach((cajero) => {
          if(cajero.tipo === cajeroNuevo.tipo && cajero.id === cajeroNuevo.id) {
            cajerosUsuario.push(cajero)
          }
        })*/
        gruposCajerosDisponibles.forEach((cajero) => {
          if(cajero.tipo === cajeroNuevo.tipo && cajero.id === cajeroNuevo.id) {
            cajerosUsuario.push(cajero)
          }
        })
      }
  
      this.setState({
        cajerosUsuario: cajerosUsuario
      })
  
      this.actualizarCajerosDisponibles()
    }
  
    removerCajero(cajeroRemover) {
      var cajerosUsuario = this.state.cajerosUsuario
  
      for(var x = 0; x < cajerosUsuario.length; x++) {
        if(cajerosUsuario[x].tipo === cajeroRemover.tipo && cajerosUsuario[x].id === cajeroRemover.id) {
          cajerosUsuario.splice(x, 1) 
          break
        }
      }
  
      this.setState({
        cajerosUsuario: cajerosUsuario
      })
  
      this.actualizarCajerosDisponibles()
    }


    render() {
        var usuario = this.state.usuario

        return(
          <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Editar Usuario
              </Typography>

              <Paper elevation={16}>
                <Grid container xs={8}>
                  <Card>
                      <CardContent>
                          <form>
                              <FormLabel>Información usuario</FormLabel>
                              <br />
                              <br />
                              <Grid container xs={12} spacing={2} rowSpacing={2}>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Nombres"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtNombres}
                                    onChange={this.onChangetxtNombres}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Apellidos"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtApellidos}
                                    onChange={this.onChangetxtApellidos}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>   
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Identificación"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtIdentificacion}
                                    onChange={this.onChangetxtIdentificacion}
                                    fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Email"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtEmail}
                                      onChange={this.onChangetxtEmail}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="Usuario"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtUsuario}
                                      onChange={this.onChangetxtUsuario}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                                <Grid item xs={4}>
                                  <FormControl fullWidth component="fieldset">
                                      <TextField
                                      label="IPs Autorizadas"
                                      id="outlined-basic"
                                      variant="outlined"
                                      value={this.state.txtIPsAutorizadas}
                                      onChange={this.onChangetxtIPsAutorizadas}
                                      fullWidth
                                    />
                                </FormControl>
                                </Grid> 
                              </Grid>                   
                          </form>
                      </CardContent>
                      <CardActions>
                              <Button  variant="contained" onClick={this.btnActualizarUsuarioClick}>Actualizar Informacion Usuario</Button>
                              <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                              </Snackbar> 
                          </CardActions>
                  </Card>
                </Grid>

              </Paper>

                <br />
              <Paper elevation={16}>
                <Grid container xs={4}>
                    <Card>
                        <CardContent>
                          <form>
                            <FormLabel>Cambiar Contraseña</FormLabel>
                            <br />
                            <br />
                              <Grid container xs={12} spacing={2} rowSpacing={2}>
                                <Grid item xs={6}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                      label="Nueva Contraseña"
                                      id="outlined-basic"
                                      type="password"
                                      variant="outlined"
                                      onChange={this.onChangetxtPasswordNueva}
                                      fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth component="fieldset">
                                    <TextField
                                      label="Verificar Contraseña"
                                      id="outlined-basic"
                                      type="password"
                                      variant="outlined"
                                      onChange={this.onChangetxtPasswordVerificacion}
                                      fullWidth
                                    />
                                  </FormControl>
                                </Grid>
                              </Grid>
                              </form>
                          </CardContent>
                          <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnActualizarPasswordClick}>Cambiar</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                          </CardActions>
                    </Card>
                </Grid>
              </Paper>
                <br />
              <Paper elevation={16}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Card>
                          <CardContent>
                            <Grid container xs={12} spacing={2} columnSpacing={2}>
                              <Grid item xs={6}>
                                <FormLabel>Entidades Disponibles</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.entidadesDisponibles.map((entidad) => {
                                        return(
                                          <ListItem button
                                            key={entidad.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarEntidad(entidad.id)}}>
                                              {entidad.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel>Entidades Usuario</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.entidadesUsuario.map((entidad) => {
                                        return(
                                          <ListItem button
                                            key={entidad.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerEntidad(entidad.id)}}>
                                            {entidad.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnGuardarEntidadesClick}>Guardar Entidades</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                          </CardActions>
                        </Card>
                      </Grid>
                </Grid>

              </Paper>
                <br />
              <Paper elevation={16}>
                <Grid container xs={12}>
                    <Grid item xs={6}>
                        <Card>
                          <CardContent>
                            <Grid container xs={12} spacing={2} columnSpacing={2}>
                              <Grid item xs={6}>
                                <FormLabel>Roles Disponibles</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.rolesDisponibles.map((rol) => {
                                        return(
                                          <ListItem button
                                            key={rol.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarRol(rol.id)}}>
                                              {rol.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel>Roles Usuario</FormLabel>
                                <List
                                  sx={{
                                    minHeight: 300,
                                    maxHeight: 300,
                                    overflow: 'auto'
                                  }}>
                                    {this.state.rolesUsuario.map((rol) => {
                                        return(
                                          <ListItem button
                                            key={rol.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerRol(rol.id)}}>
                                            {rol.nombre}
                                          </ListItem>
                                        )
                                      }
                                    )}
                                </List>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnGuardarRolesClick}>Guardar Roles</Button>
                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                          </CardActions>
                        </Card>
                      </Grid>
                </Grid>

              </Paper>

                <br />
              <Paper elevation={16}>
                <Grid container>
                    <Grid xs={8}>       
                      <Card>
                          <CardContent>
                            <Grid container xs={12} spacing={2}>
                              <Grid container item xs={6}>
                                <Grid item xs={12}>
                                  <FormLabel>Cajeros Disponibles</FormLabel>
                                    <List
                                      sx={{
                                        minHeight: 300,
                                        maxHeight: 300,
                                        overflow: 'auto'
                                      }}>
                                        {this.state.cajerosDisponibles.map((cajero) => {
                                            return(
                                              <ListItem button
                                                key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero)}}>
                                                  {cajero.alias}
                                              </ListItem>
                                            )
                                          }
                                        )}
                                    </List>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormLabel>Grupos Disponibles</FormLabel>
                                      <List
                                        sx={{
                                          minHeight: 300,
                                          maxHeight: 300,
                                          overflow: 'auto'
                                        }}>
                                          {this.state.gruposCajerosDisponibles.map((cajero) => {
                                              return(
                                                <ListItem button
                                                  key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero)}}>
                                                    {cajero.alias}
                                                </ListItem>
                                              )
                                            }
                                          )}
                                      </List>
                                </Grid>
                              </Grid>
                              <Grid item xs={6}>
                                <FormLabel>Cajeros/Grupos Usuario</FormLabel>
                                          <List
                                            sx={{
                                              minHeight: '100%',
                                              
                                              overflow: 'auto'
                                            }}>
                                              {this.state.cajerosUsuario.map((cajero) => {
                                                  return(
                                                    <ListItem button
                                                      key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerCajero(cajero)}}>
                                                      {cajero.tipo === 1 ? 'Cajero - ' + cajero.alias : 'Grupo - ' + cajero.alias}
                                                    </ListItem>
                                                  )
                                                }
                                              )}
                                          </List>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                              <Button variant="contained" fullWidth onClick={this.btnGuardarCajerosClick}>Guardar Cajeros</Button>
                              <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                            </CardActions>
                      </Card>
                    </Grid>
                </Grid>

              </Paper>

                <br />
                { session.checkPermiso('opcion_desactivar_usuario') &&
                    <Button variant="contained" onClick={() => {this.cambiarEstado()}}>{usuario.estado==="A" ? "Desactivar Usuario" : "Activar Usuario"}</Button>
                    
                }
                <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                </Snackbar> 
            

          </Box>
        )
    }

}


export default UsuariosEditarPage