import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import PagoAutomaticoIcon from '@mui/icons-material/Aod';
import PagoManualIcon from '@mui/icons-material/MailOutline';


function Row(props) {
  var flagWidth = 25
  const {registro} = props;
  const [open, setOpen] = React.useState(false) 

var detalleBoveda = JSON.parse(registro.detalle_boveda)

var detalleAutomatico = detalleBoveda.automatico.currencies
var filasBilletesAutomatico = []
var filasMonedasAutomatico = []

var detalleManual = detalleBoveda.manual.currencies
var filasBilletesManual= []
var filasMonedasManual = []

var tablaTotales = []

//Armar tabla de billetes y monedas de depositos automaticos
for(var pais of detalleAutomatico) {
  var billetesPais = pais.bills
  var monedasPais = pais.coins

  if(pais.total_bills > 0) {
    tablaTotales.push(
      <tr>
        <td><PagoAutomaticoIcon /></td>
        <td>${pais.total_bills.toLocaleString()}</td>
        <td><img alt='x' src={'/bill.png'} width={20}/></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} />{pais.currency}</td>
      </tr>
    )
  }
  if(pais.total_coins > 0) {
    tablaTotales.push(
      <tr>
        <td><PagoAutomaticoIcon /></td>
        <td>${pais.total_coins.toLocaleString()}</td>
        <td><img alt='x' src={'/coin.png'} width={20}/></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} />{pais.currency}</td>
      </tr>
    )
  }
  
  //Billetes
  for(var billete of billetesPais) {
    filasBilletesAutomatico.push(
      <tr>
        <td><PagoAutomaticoIcon /></td>
        <td><img alt='x' src='/bill.png' width={20} /></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} /></td>
        <td>${parseInt(billete.denomination).toLocaleString() + ' x ' + parseInt(billete.amount).toLocaleString()}</td>
      </tr>
    )
  }

  //Monedas
  for(var moneda of monedasPais) {
    filasMonedasAutomatico.push(
      <tr>
        <td><PagoAutomaticoIcon /></td>
        <td><img alt='x' src='/coin.png' width={20} /></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} /></td>
        <td>${parseInt(moneda.denomination).toLocaleString() + ' x ' + parseInt(moneda.amount).toLocaleString()}</td>
      </tr>
    )
  }
}

//Armar tabla de billetes y monedas de depositos manuales
/* eslint-disable no-redeclare */
for(var pais of detalleManual) {
  var billetesPais = pais.bills
  var monedasPais = pais.coins

  if(pais.total_bills > 0) {
    tablaTotales.push(
      <tr>
        <td><PagoManualIcon /></td>
        <td>${pais.total_bills.toLocaleString()}</td>
        <td><img alt='x' src={'/bill.png'} width={20}/></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} />{pais.currency}</td>
      </tr>
    )
  }
  if(pais.total_coins > 0) {
    tablaTotales.push(
      <tr>
        <td><PagoManualIcon /></td>
        <td>${pais.total_coins.toLocaleString()}</td>
        <td><img alt='x' src={'/coin.png'} width={20}/></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} />{pais.currency}</td>
      </tr>
    )
  }

  //Billetes
  for(var billete of billetesPais) {
    filasBilletesManual.push(
      <tr>
        <td><PagoManualIcon /></td>
        <td><img alt='x' src='/bill.png' width={20} /></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} /></td>
        <td>${parseInt(billete.denomination).toLocaleString() + ' x ' + parseInt(billete.amount).toLocaleString()}</td>
      </tr>
    )
  }

  //Monedas
  for(var moneda of monedasPais) {
    filasMonedasManual.push(
      <tr>
        <td><PagoManualIcon /></td>
        <td><img alt='x' src='/coin.png' width={20} /></td>
        <td><img alt='x' src={'/flags/' + pais.currency.toLowerCase() + '.png'} width={flagWidth} /></td>
        <td>${parseInt(moneda.denomination).toLocaleString() + ' x ' + parseInt(moneda.amount).toLocaleString()}</td>
      </tr>
    )
  }
}
/* eslint-enable no-redeclare */

var tablaDetalle = (
  <table>
    <tr>
      <td>
        {filasBilletesAutomatico}
      </td>
    </tr>
    <tr>
      <td>
        {filasMonedasAutomatico}
      </td>
    </tr>
    <tr>
      <td>
        {filasBilletesManual}
      </td>
    </tr>
    <tr>
      <td>
        {filasMonedasManual}
      </td>
    </tr>
  </table>
)


 return (
   <React.Fragment>
     <TableRow>
      <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon />:<KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
       <TableCell>
       {registro.fecha_recaudo}
       </TableCell>
       <TableCell>
         {registro.cajero_alias}
       </TableCell>
       <TableCell>
         {registro.cajero_codigo}
       </TableCell>
       <TableCell>
         {registro.pais}
       </TableCell>
       <TableCell>
         {registro.ciudad}
       </TableCell>
       <TableCell>
         <table>
          {tablaTotales}
         </table>
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
     <TableRow>
      <TableCell style={{paddingTop: 0, paddingBottom: 0}} colspan={10}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {tablaDetalle}
        </Collapse>
      </TableCell>
     </TableRow>
   </React.Fragment>
 );
}

class SaldoCajerosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      registros: []
    };
  }



  componentDidMount() {
  }

  render() {
    var flagWidth = 25
    const registros = this.props.registros
  

    var totales = []

    // Recorrer todos los registros y tomar el detalle de la boveda
    for(var registro of registros) {
      var detalleBoveda = JSON.parse(registro.detalle_boveda)
      
      // Recorrer las monedas de pais de automatico
      for(var registroMonedaPais of detalleBoveda.automatico.currencies) {
        var monedaPais = registroMonedaPais.currency
        var tipoTransaccion = 'A'
        var totalSumar = 0
        var tipoMoneda = 'B'
        //Buscar si ya existe un tipo de moneda y transaccion para ver si se agrega al total o si se suma a uno ya existente

        //Buscar por coincidencia de billetes
        var agregarTotal = true
        totalSumar = registroMonedaPais.total_bills
        for(var indiceTotal in totales) {
          var totalGuardado = totales[indiceTotal]

          // Mirar si el tipo de transaccion es automatico y si coincide la moneda y si es billete
          if(totalGuardado.tipoTransaccion === tipoTransaccion && totalGuardado.moneda === monedaPais && totalGuardado.tipoMoneda === tipoMoneda) {
            agregarTotal = false
            totalGuardado.total += totalSumar
            console.log(totalSumar)
            totales[indiceTotal] = totalGuardado
            break
          }
        }

        // Agregar el total por sino existia previamente
        if(agregarTotal) {
          totales.push(
            {
              tipoTransaccion: tipoTransaccion,
              moneda: registroMonedaPais.currency,
              total: totalSumar,
              tipoMoneda: tipoMoneda
            }
          )
        }

        //Buscar por coincidencia de monedas
        tipoMoneda = 'M'
        agregarTotal = true
        totalSumar = registroMonedaPais.total_coins

        /* eslint-disable no-redeclare */
        for(var indiceTotal in totales) {
          var totalGuardado = totales[indiceTotal]

          // Mirar si el tipo de transaccion es automatico y si coincide la moneda y si es billete
          if(totalGuardado.tipoTransaccion === tipoTransaccion && totalGuardado.moneda === monedaPais && totalGuardado.tipoMoneda === tipoMoneda) {
            agregarTotal = false
            totalGuardado.total += totalSumar
            console.log(totalSumar)
            totales[indiceTotal] = totalGuardado
            break
          }
        }
        /* eslint-enable no-redeclare */

        // Agregar el total por sino existia previamente
        if(agregarTotal) {
          totales.push(
            {
              tipoTransaccion: tipoTransaccion,
              moneda: registroMonedaPais.currency,
              total: totalSumar,
              tipoMoneda: tipoMoneda
            }
          )
        }


      }

      // Recorrer las monedas de pais de manual
      /* eslint-disable no-redeclare */
      for(var registroMonedaPais of detalleBoveda.manual.currencies) {
        var monedaPais = registroMonedaPais.currency
        var tipoTransaccion = 'M'
        var totalSumar = 0
        var tipoMoneda = 'B'
        //Buscar si ya existe un tipo de moneda y transaccion para ver si se agrega al total o si se suma a uno ya existente

        //Buscar por coincidencia de billetes
        var agregarTotal = true
        totalSumar = registroMonedaPais.total_bills
        for(var indiceTotal in totales) {
          var totalGuardado = totales[indiceTotal]

          // Mirar si el tipo de transaccion es automatico y si coincide la moneda y si es billete
          if(totalGuardado.tipoTransaccion === tipoTransaccion && totalGuardado.moneda === monedaPais && totalGuardado.tipoMoneda === tipoMoneda) {
            agregarTotal = false
            totalGuardado.total += totalSumar
            console.log(totalSumar)
            totales[indiceTotal] = totalGuardado
            break
          }
        }

        // Agregar el total por sino existia previamente
        if(agregarTotal) {
          totales.push(
            {
              tipoTransaccion: tipoTransaccion,
              moneda: registroMonedaPais.currency,
              total: totalSumar,
              tipoMoneda: tipoMoneda
            }
          )
        }

        //Buscar por coincidencia de monedas
        tipoMoneda = 'M'
        agregarTotal = true
        totalSumar = registroMonedaPais.total_coins
        for(var indiceTotal in totales) {
          var totalGuardado = totales[indiceTotal]

          // Mirar si el tipo de transaccion es automatico y si coincide la moneda y si es billete
          if(totalGuardado.tipoTransaccion === tipoTransaccion && totalGuardado.moneda === monedaPais && totalGuardado.tipoMoneda === tipoMoneda) {
            agregarTotal = false
            totalGuardado.total += totalSumar
            console.log(totalSumar)
            totales[indiceTotal] = totalGuardado
            break
          }
        }

        // Agregar el total por sino existia previamente
        if(agregarTotal) {
          totales.push(
            {
              tipoTransaccion: tipoTransaccion,
              moneda: registroMonedaPais.currency,
              total: totalSumar,
              tipoMoneda: tipoMoneda
            }
          )
        }

      }
      /* eslint-enable no-redeclare */
      

    }

    const columns = [
      {field: "desplegable", headerName: "", width: 10},
      {field: "fecha", headerName: 'Fecha Recaudo', width: 80},
      {field: "cajero_alias", headerName: 'Cajero-Alias', width: 150},
      {field: "cajero_codigo", headerName: 'Cajero-Codigo', width: 100},
      {field: "pais", headerName: 'País', width: 100},
      {field: "ciudad", headerName: 'Ciudad', width: 100},
      {field: "total", headerName: 'Total', width: 100}
    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {registros.map((registro) => {
                return(
                  <Row registro={registro} />
                )
              })}
            </TableBody>
          </Table>
          <Table sx={{width: '100%'}}>
            {totales.map((total) => {
              return(
                <TableRow sx={{margin: 1, padding: 1}}>
                  <TableCell sx={{width: '60%'}}></TableCell>
                  <TableCell></TableCell>
                  <TableCell>Total:</TableCell>
                  <TableCell></TableCell>
                  <TableCell align="right" sx={{width: 20}}>{total.tipoTransaccion === 'A' ? <PagoAutomaticoIcon /> : <PagoManualIcon />}</TableCell>
                  <TableCell align="right">${total.total.toLocaleString()}</TableCell>
                  <TableCell align="right" sx={{width: 20}}><img alt='x' src={total.tipoMoneda === 'B' ? '/bill.png' : '/coin.png'} width={20}/></TableCell>
                  <TableCell align="right">< img  alt='x' src={'/flags/' + total.moneda.toLowerCase() + '.png'} width={flagWidth} />{total.moneda}</TableCell>
                </TableRow>
              )
            })}
          </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default SaldoCajerosTable;
