import React, { Component } from 'react'
import {BrowserRouter as Router, Route, Redirect, Switch, Link} from 'react-router-dom'


import MainLayout from './components/MainLayout'
import LoginPage from './pages/login/LoginPage'
import LogoutPage from './pages/logout/LogoutPage'
import HomePage from './pages/home/HomePage'
import ReporteTotalesDiaPage from './pages/reporte_totales_dia/TotalesDiaPage'
import ReporteTotalesRecaudoPage from './pages/reporte_totales_recaudo/TotalesRecaudoPage'
import ReporteSaldoCajerosPage from './pages/reporte_saldo_cajeros/SaldoCajerosPage'
import ReporteTotalesCuadrePage from './pages/reporte_totales_cuadre/TotalesCuadrePage'
import ReporteCertificadoAvancePage from './pages/reporte_certificado_avance/CertificadoAvancePage'
import CajerosPage from './pages/cajeros/CajerosPage'
import CajerosCrearPage from './pages/cajeros_crear/CajerosCrearPage'
import CajerosEditarPage from './pages/cajeros_editar/CajerosEditarPage'
import CajerosResumenPage from './pages/cajeros_resumen/CajerosResumenPage'
import CajerosEstadosPage from './pages/cajeros_estado/CajeroEstadosPage'
import CajerosAccionesRemotasPage from './pages/cajeros_acciones_remotas/CajerosAccionesRemotas'
import CajerosConfigurarMoneyManager from './pages/cajeros_configurar_moneymanager/CajerosConfigurarMoneyManager'
import CajerosConfigurarBilletero from './pages/cajeros_configurar_billetero/CajerosConfigurarBilletero'
import CajerosConfigurarImpresora from './pages/cajeros_configurar_impresora/CajerosConfigurarImpresora'
import CajerosDispositivosComplementos from './pages/cajeros_dispositivos/CajerosDispositivosComplementosPage'
import CajerosGruposPage from './pages/cajeros_grupos/CajerosGruposPage'
import EventosPage from './pages/eventos/EventosPage'
import DepositosPage from './pages/depositos/DepositosPage'
import RetirosPage from './pages/retiros/RetirosPage'
import PermisosRolPage from './pages/permisos_rol/PermisosRolPage'
import EntidadesPage from './pages/entidades/EntidadesPage'
import UsuariosPage from './pages/usuarios/UsuariosPage'
import UsuariosEditarPage from './pages/usuarios_editar/UsuariosEditarPage'
import UsuariosCrearPage from './pages/usuarios_crear/UsuariosCrearPage'
import FuncionariosPage from './pages/funcionarios/FuncionariosPage'
import FuncionariosEditarPage from './pages/funcionarios_editar/FuncionariosEditarPage'
import FuncionariosCrearPage from './pages/funcionarios_crear/FuncionariosCrearPage'
import ClientesPage from './pages/clientes/ClientesPage'
import ClientesEditarPage from './pages/clientes_editar/ClientesEditarPage'
import ClientesCrearPage from './pages/clientes_crear/ClientesCrearPage'
import BilleteraClientesPage from './pages/billetera/clientes/ClientesPage'
import BilleteraClientesCrearPage from './pages/billetera/clientes_crear/ClientesCrearPage'
import BilleteraClientesEditarPage from './pages/billetera/clientes_editar/ClientesEditarPage'
import OpcionesCuentaPage from './pages/opciones_cuenta/OpcionesCuentaPage'
import ParametrosPage from './pages/parametros/ParametrosPage'
import LogPage from './pages/log/LogPage'
import MantenimientoPage from './pages/mantenimiento/MantenimientoPage'
import DashboardUnsupportedPage from './pages/dashboad_unsupported/DashboardUnsupportedPage'
import Page404 from './pages/404_page/404Page'
import SoportePage from './pages/soporte/SoportePage'


import Parametros from './config/parametros'
import WelcomePage from './pages/welcome/WelcomePage'

import './App.css';
const session = require('./config/session')

global.APP_Parametros = new Parametros()

function PrivateRoute ({component: Component, permiso, ...restOfProps}) {

  return(
    <Route
      {...restOfProps}
      render={(props) => session.isAuthorized(permiso) ? <Component {...props} /> : <Redirect to="/login" />
      }
    />

  )
}


function App() {

  return (
    <Router> 
        <Switch>
          <Route exact path="/" component={WelcomePage} />
          {/*<Route exact path="/login" component={LoginPage} />*/}
          <Route exact path="/logout/:tag" component={LogoutPage} />
          <Route exact path="/entidad/:tag" component={LoginPage} />
          <Route exact path="/mantenimiento" component={MantenimientoPage} />
	  <Route exact path="/soporte" component={SoportePage} />
          <Route exact path="/unsupported" component={DashboardUnsupportedPage} />
          {/* <Route exact path={["/dashboard", "/dashboard/cajeros", "/dashboard/cajeros/:id", "/dashboard/eventos", "/dashboard/depositos", "/dashboard/retiros"]}> */}
          <Route path={"/dashboard"}>
            <MainLayout>
              <Switch>
                {/*<PrivateRoute exact path="/dashboard" component={HomePage} permiso="acceder_dashboard"/>*/}
                <PrivateRoute exact path="/dashboard" component={HomePage} permiso="acceder_dashboard"/>
                {/* <PrivateRoute exact path="/dashboard" component={CajerosEstadosPage} permiso="acceder_cajeros_estados" /> */}
                <PrivateRoute exact path="/dashboard/totalesdia" component={ReporteTotalesDiaPage} permiso="acceder_reporte_totales_dia" />
                <PrivateRoute exact path="/dashboard/totalesrecaudo" component={ReporteTotalesRecaudoPage} permiso="acceder_reporte_totales_recaudo" />
                <PrivateRoute exact path="/dashboard/cajerossaldo" component={ReporteSaldoCajerosPage} permiso="acceder_reporte_cajeros_saldo" />
                <PrivateRoute exact path="/dashboard/totalescuadre" component={ReporteTotalesCuadrePage} permiso="acceder_reporte_totales_cuadre" />
                <PrivateRoute exact path="/dashboard/certificadoavance" component={ReporteCertificadoAvancePage} permiso="acceder_reporte_certificado_avance" />
                <PrivateRoute exact path="/dashboard/cajeros" component={CajerosPage} permiso="acceder_cajeros" />
                <PrivateRoute exact path="/dashboard/cajeros/crear" component={CajerosCrearPage} permiso="acceder_cajeros_crear" />
                <PrivateRoute exact path="/dashboard/cajeros/editar" component={CajerosEditarPage} permiso="acceder_cajeros_editar" />
                <PrivateRoute exact path="/dashboard/cajeros/resumen" component={CajerosResumenPage} permiso="acceder_cajeros_resumen" />
                <PrivateRoute exact path="/dashboard/eventos" component={EventosPage} permiso="acceder_eventos" />
                <PrivateRoute exact path="/dashboard/cajerosestados" component={CajerosEstadosPage} permiso="acceder_cajeros_estados" />
                <PrivateRoute exact path="/dashboard/cajeros/accionesremotas" component={CajerosAccionesRemotasPage} permiso="acceder_cajeros_acciones_remotas" />
                <PrivateRoute exact path="/dashboard/cajeros/configurarmoneymanager" component={CajerosConfigurarMoneyManager} permiso="acceder_cajeros_editar" />
                <PrivateRoute exact path="/dashboard/cajeros/configurarbilletero" component={CajerosConfigurarBilletero} permiso="acceder_cajeros_editar" />
                <PrivateRoute exact path="/dashboard/cajeros/configurarimpresora" component={CajerosConfigurarImpresora} permiso="acceder_cajeros_editar" />
                <PrivateRoute exact path="/dashboard/cajeros/dispositivoscomplementos" component={CajerosDispositivosComplementos} permiso="acceder_cajeros_editar" />
                <PrivateRoute exact path="/dashboard/cajeros/grupos" component={CajerosGruposPage} permiso="acceder_cajeros_grupos" />
                <PrivateRoute exact path="/dashboard/depositos" component={DepositosPage} permiso="acceder_depositos" />
                <PrivateRoute exact path="/dashboard/retiros" component={RetirosPage} permiso="acceder_retiros" />
                <PrivateRoute exact path="/dashboard/permisos" component={PermisosRolPage} permiso="acceder_permisos_rol" />
                <PrivateRoute exact path="/dashboard/usuarios" component={UsuariosPage} permiso="acceder_usuarios" />
                <PrivateRoute exact path="/dashboard/usuarios/crear" component={UsuariosCrearPage} permiso="acceder_usuarios_crear" />
                <PrivateRoute exact path="/dashboard/usuarios/editar" component={UsuariosEditarPage} permiso="acceder_usuarios_editar" />
                <PrivateRoute exact path="/dashboard/funcionarios" component={FuncionariosPage} permiso="acceder_funcionarios" />
                <PrivateRoute exact path="/dashboard/funcionarios/crear" component={FuncionariosCrearPage} permiso="acceder_funcionarios_crear" />
                <PrivateRoute exact path="/dashboard/funcionarios/editar" component={FuncionariosEditarPage} permiso="acceder_funcionarios_editar" />
                <PrivateRoute exact path="/dashboard/clientes" component={ClientesPage} permiso="acceder_clientes" />
                <PrivateRoute exact path="/dashboard/clientes/crear" component={ClientesCrearPage} permiso="acceder_clientes_crear" />
                <PrivateRoute exact path="/dashboard/clientes/editar" component={ClientesEditarPage} permiso="acceder_clientes_editar" />
                <PrivateRoute exact path="/dashboard/billetera/clientes" component={BilleteraClientesPage} permiso="acceder_billetera_clientes" />
                <PrivateRoute exact path="/dashboard/billetera/clientes/crear" component={BilleteraClientesCrearPage} permiso="acceder_billetera_clientes_crear" />
                <PrivateRoute exact path="/dashboard/billetera/clientes/editar" component={BilleteraClientesEditarPage} permiso="acceder_billetera_cliente_editars" />
                <PrivateRoute exact path="/dashboard/entidades" component={EntidadesPage} permiso="acceder_entidades" />
                <PrivateRoute exact path="/dashboard/opcionescuenta" component={OpcionesCuentaPage} permiso="acceder_opciones_cuenta" />
                <PrivateRoute exact path="/dashboard/parametros" component={ParametrosPage} permiso="acceder_parametros" />
                <PrivateRoute exact path="/dashboard/log" component={LogPage} permiso="acceder_log" />
		
                <Route>
                  <Redirect to="/error404" />
                </Route>
              </Switch>
            </MainLayout>
          </Route>
          <Route exact path="/error404" component={Page404} />
          <Route>
            <Redirect to="/error404" />
          </Route>
        </Switch>
    </Router>
  );
}

export default App;
