import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Alert, AlertTitle, Collapse, Grid, Paper, Snackbar } from '@mui/material'

const {DateTime} = require('luxon')
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class LogPage extends Component {

  constructor() {
    super()

    //Definir fechas de inicio para los filtros de fecha
    var fechaInicioTemp = DateTime.local().set({hour: 0, minute: 0, second: 0})
    var fechaFinTemp = DateTime.local().set({hour: 23, minute: 59, second: 59})

    this.state = {
      acciones: [],
      eventos: [],
      filtroFechaInicio: fechaInicioTemp,
      filtroFechaFin: fechaFinTemp,

      mostrarAlerta: false,
      mensajeAlerta: '',
      severidadAlerta: ''
    };

    this.filtroFechaInicio = ""
    this.filtroFechaFin = ""

    this.cmbOrigenes = React.createRef()
    this.cmbAcciones = React.createRef()
    this.btnBucarClick = this.btnBucarClick.bind(this)

    this.origenes = [
      {
        id: 1,
        nombre: "Dashboard"
      },
      {
        id: 2,
        nombre: "Dispositivos"
      },
      {
        id: 3,
        nombre: "Jobs"
      }
    ]
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }

  fechaFiltroChanged(origen, date) {
    if (date == null) return
    
    var formatoFechaInicio = "yyyy-LL-dd HH:mm:00"
    var formatoFechaFin = "yyyy-LL-dd HH:mm:59"

    switch (origen) {
      case 'inicio':
        this.setState({
          filtroFechaInicio: date
        })
        this.filtroFechaInicio = date.toFormat(formatoFechaInicio)
        break;
      case 'fin':
        this.setState({
          filtroFechaFin: date
        })
        this.filtroFechaFin = date.toFormat(formatoFechaFin)
        break;
      default:

    }
  }

  btnBucarClick() {
    this.queryLog()
  }

  componentDidMount() {
    this.queryAcciones()
    this.queryLog()
  }

  queryAcciones() {
    console.log('Consultando acciones....')
	var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/log/acciones/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
			      // alert("Error consultando acciones log")
	        	// console.log('Error respuesta consultando acciones')
	          this.setState({
	            mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando acciones log',
              severidadAlerta: 'error'
	          })
	      } else {
			  //console.log('Acciones consultadas')
	          this.setState({
				      acciones: data
	          })
	      }
	    })
  }

  queryLog() {
    console.log('Consultando log....')

    var filtroIdOrigen = 0
    var filtroIdAccion = 0

    if(this.cmbOrigenes.current.value > 0){
      filtroIdOrigen= this.cmbOrigenes.current.value
    }

    if(this.cmbAcciones.current.value > 0){
      filtroIdAccion = this.cmbAcciones.current.value
    }
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
		  filtro_id_origen: filtroIdOrigen,
        filtro_id_accion: filtroIdAccion,
        filtro_fecha_inicio: this.filtroFechaInicio,
        filtro_fecha_fin: this.filtroFechaFin
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/log/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
		  //alert("Error consultando eventos log")
        this.setState({
            eventos: [],
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando eventos log',
            severidadAlerta: 'error'
          })
      } else {
        //console.log('Log consultado')
          this.setState({
	        eventos: data
	      })
      }
    })
    
  }

  render() {
      return(
        <Box>
          <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
            Log
          </Typography>

          <Paper elevation={16}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                    Buscar log:
                </Typography>

                <Grid container spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                          label="Fecha inicio"
                          value={this.state.filtroFechaInicio}
                          onChange={date => this.fechaFiltroChanged('inicio', date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>

                    </Grid>

                    <Grid item>
                      <LocalizationProvider dateAdapter={AdapterLuxon}>
                        <DateTimePicker
                          label="Fecha fin"
                          value={this.state.filtroFechaFin}
                          onChange={date => this.fechaFiltroChanged('fin', date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                      
                    </Grid>

                  </Grid>

                  <Grid  item container  spacing={2}>
                    <Grid item>
                      <FormControl>
                        <InputLabel>Origen</InputLabel>
                        <Select style={{width: 200}}
                          label="Origen"
                          inputRef={this.cmbOrigenes}
                          defaultValue={0}>
                          <MenuItem value={0}>-- Todos los origenes --</MenuItem>
                          {this.origenes.map(origen => (
                            <MenuItem value={origen.id}>{origen.nombre}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item>
                      <FormControl>
                        <InputLabel>Acciones</InputLabel>
                        <Select style={{width: 200}}
                          label="Acciones"
                          inputRef={this.cmbAcciones}
                          defaultValue={0}>
                          <MenuItem value={0}>-- Todas las acciones --</MenuItem>
                          {this.state.acciones.map(accion => (
                            <MenuItem value={accion.id}>{accion.nombre}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                  </Grid>

                </Grid>


              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={this.btnBucarClick}>Buscar</Button>
                <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                </Snackbar>  
              </CardActions>
            </Card>
          </Paper>

            <Divider style={{"margin-top": 10, "margin-bottom": 10}} />
          
          <Paper elevation={16}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Listado Logs:
                </Typography>
                <Divider />
                <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                  <Table stickyHeader className="table-data">
                    <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Fecha</TableCell>
                          <TableCell>Origen</TableCell>
                          <TableCell>Usuario</TableCell>
                          <TableCell>Accion</TableCell>
                          <TableCell>Detalle</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                          this.state.eventos.map((eventoLog) => {
                              return(
                                  <TableRow>
                                      <TableCell>{eventoLog.id}</TableCell>
                                      <TableCell>{eventoLog.fecha}</TableCell>
                                      <TableCell>{eventoLog.origen}</TableCell>
                                      <TableCell>{eventoLog.usuario}</TableCell>
                                      <TableCell>{eventoLog.accion}</TableCell>
                                      <TableCell>{eventoLog.detalle}</TableCell>
                                  </TableRow>
                              )
                          })
                      }
                   </TableBody>
                  </Table>
                </Box>
              </CardContent>
            </Card>

          </Paper>
            Ver: {process.env.REACT_APP_VERSION}

        </Box>
      )
  }

}

export default LogPage