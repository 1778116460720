import {createTheme} from '@mui/material/styles'
import * as colors from '@mui/material/colors'
import { ListItem } from '@mui/material'

export default createTheme({
    palette: {
        primary: {
            main: '#0d47a1'
        },
        background: {
            default: '#cfd3c9'
        }
    }

})