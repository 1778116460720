import React, {Component, useState} from 'react'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import Alert from '@mui/lab/Alert'



import CajerosTable from './CajerosTable'
import { AlertTitle, Collapse, Paper, Snackbar } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CajerosPage extends Component {


  constructor(props) {
    super(props)

    this.state = {
      cajeros: [],
      grupos: [],
      estados: [
        {id: 'A', valor: 'Activo'},
        {id: 'I', valor: 'Inactivo'}
      ],

      mostrarAlerta: true,
      mensajeAlerta: '',
      severidadAlerta: '',
    }

    this.btnBuscarClick = this.btnBuscarClick.bind(this)

    this.cmbGrupos = React.createRef()
    this.txtCodigo = React.createRef()
    this.txtSerial = React.createRef()
    this.txtAlias = React.createRef()
    this.cmbEstados = React.createRef()
    
  }

  showAlert = (mensaje, severidad) => {
    this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensaje,
        severidadAlerta: severidad,
    });
  }

  handleCloseAlert= (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false })
  }


  btnBuscarClick() {
    this.queryCajeros()
  }

  queryGrupos() {
    console.log('Consultando grupos....')
    
    var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando grupos")
        //console.log('Error respuesta consultando grupos')
        this.showAlert('Error consultando grupos', 'error')
        this.setState({
			grupos: [],
			mensajeError: data.message
        })
      } else {
        //console.log('Grupos consultados')
      	this.setState({
        	grupos: data
        })
      }
    })
}

  queryCajeros() {
    var filtroIdGrupo = ''
    var filtroEstado = ''
    var filtroCodigo = ''
    var filtroSerial = ''
    var filtroAlias = ''

    if(this.cmbGrupos.current.value !== 0){
      filtroIdGrupo = this.cmbGrupos.current.value
    }

    if(this.cmbEstados.current.value !== 0){
      filtroEstado = this.cmbEstados.current.value
    }

    filtroCodigo = this.txtCodigo.current.value.toString()
    filtroSerial = this.txtSerial.current.value.toString()
    filtroAlias = this.txtAlias.current.value.toString()


	var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {
	  	filtro_id_grupo: filtroIdGrupo,
        filtro_estado: filtroEstado,
        filtro_codigo: filtroCodigo,
        filtro_serial: filtroSerial,
        filtro_alias: filtroAlias
	  },
      url: process.env.REACT_APP_URL_BACKEND + '/cajeros/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        //alert("Error consultando cajeros")
        //console.log('Error respuesta consultando cajeros')
        this.showAlert("Error consultando cajeros", 'error')

        this.setState({
          cajeros: [],
          mensajeError: data.message
        })
      } else {
        console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
  }

  componentDidMount() {
    this.queryGrupos()
    this.queryCajeros()
  }
  
  render() {
    return (

      <Box>
        <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
          Cajeros
        </Typography>

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <Grid container xs={10} spacing={2}>
                  <Grid item fullWidth>
                    <Typography gutterBottom variant="h6" component="div">
                      Buscar cajeros:
                    </Typography>
                  </Grid>
                  <Grid item container fullWidth spacing={2}>
                    <Grid item xs={3}>
                      <FormControl fullWidth>
                        <InputLabel>Grupo</InputLabel>
                        <Select
                          label="Grupo"
                          inputRef={this.cmbGrupos}
                          defaultValue={0}>
                          <MenuItem value={0}>-- Todos Cajeros/Grupos --</MenuItem>
                          {this.state.grupos.map(grupo => (
                            <MenuItem value={grupo.id}>{grupo.nombre}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <InputLabel>Estado</InputLabel>
                        <Select
                          label="Estado"
                          inputRef={this.cmbEstados}
                          defaultValue={0}>
                          <MenuItem value={0}>-- Todos los estados --</MenuItem>
                          {this.state.estados.map(estado => (
                            <MenuItem value={estado.id}>{estado.valor}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth>
                        <TextField
                          label="Codigo"
                          id="outlined-basic"
                          variant="outlined"
                          inputRef={this.txtCodigo}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          label="Serial"
                          id="outlined-basic"
                          variant="outlined"
                          inputRef={this.txtSerial}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          label="Alias"
                          id="outlined-basic"
                          variant="outlined"
                          inputRef={this.txtAlias}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardActions>
              <Button variant="contained" size="large" onClick={this.btnBuscarClick}>Buscar</Button>
              <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
              </Snackbar> 
            </CardActions>
          </Card>

        </Paper>

        <Divider style={{"margin-top": 10, "margin-bottom": 10}} />

        <Paper elevation={16}>
          <Card>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Listado cajeros:
              </Typography>
              <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                <CajerosTable cajeros={this.state.cajeros} />
              </Box>
            </CardContent>
            <CardActions>

            </CardActions>
          </Card>

        </Paper>
      </Box>
    )
  }
}

export default CajerosPage
