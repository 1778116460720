import React, {Component} from 'react'

const session = require('../../config/session')

class MantenimientoPage  extends Component {

    constructor(props) {
        super(props)
        
        session.endSession()
    }

    render() {
        return(
            <div style={{backgroundColor: '#ffffff'}}>
                <h2 style={{textAlign: 'center', color: '#000000'}}>Servidor en mantenimiento</h2>
                <div style={{textAlign: 'center'}}>
                    <img alt='mant' src='mantenimiento.png' width={250}/>
                </div>
            </div>
        )
    }

}

export default MantenimientoPage