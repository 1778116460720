import {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'

function Row(props) {
    var item = props.item
    var eventSelect = props.eventSelect
    var eventDelete = props.eventDelete

    return(
        <TableRow>
            <TableCell>{item.alias}</TableCell>
            <TableCell>{item.mecanismo}</TableCell>
            <TableCell>{item.estado}</TableCell>
            <TableCell>
                <Button variant="contained" size='small' fullWidth onClick={() => {eventSelect(item)}}>Seleccionar</Button>
                <Button variant="contained" size='small' color="error" fullWidth onClick={() => {eventDelete(item)}}>Borrar</Button>
            </TableCell>
        </TableRow>
    )
}


class TabCerraduras extends Component {
    constructor(props) {
        super(props)

        var tipoDispositivo = props.tipoDispositivo
        var dispositivos = props.dispositivos
        this.guardarEvent = props.event

        this.state = {
            tipoDispositivo: tipoDispositivo,
            dispositivos: dispositivos,
            txtAlias: "",
            txtId: "",
            cmbMecanismos: 0,
            cmbEstados: 0,
            mecanismos: [
                "Solenoide",
                "Lineal"
            ],
            modos: [
                "POLL",
                "EVENTO"
            ],
            estados: [
				{nombre: "Activo", valor: "A"},
				{nombre: "Inactivo", valor: "I"}
			],
            modoEdicion: false
        }
    }

    componentWillUnmount() {

    }

    onChangetxtAlias = (event) => {
        const alias = event.target.value.toString()
        this.setState({txtAlias: alias})
    }

    onChangetxtId = (event) => {
        const id = event.target.value.toString()
        this.setState({txtId: id})
    }

    

    onChangecmbMecanismos = (event) => {
        const mecanismo = event.target.value.toString()
        this.setState({cmbMecanismos: mecanismo})
    }
    
    onChangecmbEstados = (event) => {
        const estado = event.target.value
        this.setState({cmbEstados: estado})
    }

    btnSeleccionarClick = (item) => {
        this.setState({
            txtAlias: item.alias,
            txtId: item.id,
            cmbMecanismos: item.mecanismo,
            cmbEstados: item.estado,
            modoEdicion: true
        })
    }

    btnBorrarClick = (item) => {
        
        if(window.confirm("¿Borrar dispositivo?")) {
            this.pepararNuevo()
            var dispositivos = this.state.dispositivos

            for(var x = 0; x < dispositivos.length; x++) {
                if(dispositivos[x].alias === item.alias) {
                    dispositivos.splice(x, 1)
                    break
                }
            }


            this.setState({
                dispositivos: dispositivos
            })

            this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
        }

    }

    btnGuardarClick = () => {

        var dispositivos = this.state.dispositivos

        var item = {
            alias: this.state.txtAlias,
            id: this.state.txtId,
            mecanismo: this.state.cmbMecanismos,
            estado: this.state.cmbEstados
        }

        if(this.state.modoEdicion) {
            //Buscar el dispositivo por el alias
            for(var x = 0; dispositivos.length; x++) {
                if(dispositivos[x].alias === this.state.txtAlias) {
                    dispositivos[x] = item
                    break
                }
            }
        } else {
            dispositivos.push(item)
        }

        this.setState({
            dispositivos: dispositivos
        })

        this.guardarEvent(this.state.tipoDispositivo, this.state.dispositivos, this.confirmacionGuardado)
    }

    pepararNuevo = () => {
        this.setState({
            txtAlias: "",
            txtId: "",
            cmbMecanismos: 0,
            cmbEstados: 0,
            modoEdicion: false
        })
    }

    confirmacionGuardado = () => {
        this.pepararNuevo()
    }

    render() {
        const columns = [
            {field: 'alias', headerName: 'Alias', width: 150},
            {field: 'mecanismo', headerName: 'Mecanismo', width: 100},
            {field: 'Estado', headerName: 'Estado', width: 50},
            {field: '', headerName: '', width: 50}
          ]

        return(
            <Box>
                <Grid container xs={12}>
                    <Grid item xs={12}>
                        <Card fullWidth>
                            <CardContent>
                            	<h3>Cerraduras</h3>
                                <form>
                                    <FormLabel>Información Cerradura</FormLabel>
                                    <br />
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="Alias"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtAlias}
                                                onChange={this.onChangetxtAlias}
                                                inputProps={{disabled: this.state.modoEdicion}}
                                                disabled={this.state.modoEdicion}
                                                fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                                <TextField
                                                label="ID"
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={this.state.txtId}
                                                onChange={this.onChangetxtId}
                                                fullWidth
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Mecanismo</InputLabel>
                                            <Select
                                                label="Mecanismo"
                                                value={this.state.cmbMecanismos}
                                                defaultValue={0}
                                                onChange={this.onChangecmbMecanismos}>
                                                <MenuItem value={0}>-- Seleccione Mecanismo --</MenuItem>
                                                {this.state.mecanismos.map(item => (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth component="fieldset">
                                            <InputLabel>Estado</InputLabel>
                                            <Select
                                                label="Estado"
                                                value={this.state.cmbEstados}
                                                defaultValue={0}
                                                onChange={this.onChangecmbEstados}>
                                                <MenuItem value={0}>-- Seleccione estado --</MenuItem>
                                                {this.state.estados.map(item => (
                                                <MenuItem value={item.valor}>{item.nombre}</MenuItem>
                                                ))}
                                            </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            </CardContent>
                            <CardActions>
                                <Button  variant="contained" onClick={this.btnGuardarClick}>{ this.state.modoEdicion  === true ? "Modificar": "Agregar"}</Button>
                                <Button  variant="contained" onClick={this.pepararNuevo}>Cancelar</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <br />
                <Card xs={8}>
                    <CardContent xs={8}>
                        <TableContainer xs={8}>
                            <Table stickyHeader class="table-data" xs={8}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => {
                                                return(
                                                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                {
                                    this.state.dispositivos.map((dispositivo) => {
                                        return(<Row item = {dispositivo} eventSelect={this.btnSeleccionarClick} eventDelete={this.btnBorrarClick} />)
                                    })
                                }
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </Box>
        )
    }
}

export default TabCerraduras