import React, {Component} from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'

import CertificadoAvanceTable from './CertificadoAvanceTable'
import { Alert, AlertTitle, Collapse, FormLabel, Paper, Snackbar } from '@mui/material'
import { TransgenderTwoTone } from '@mui/icons-material'

const {DateTime} = require('luxon')
const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class CertificadoAvancePage extends Component {
    constructor() {
      super()

      //Definir fechas de inicio para los filtros de fecha
      var fechaInicioTemp = DateTime.local().set({hour: 0, minute: 0, second: 0})
      var fechaFinTemp = DateTime.local().set({hour: 23, minute: 59, second: 59})

      this.state = {
        grupos: [],
        cajeros: [],
        registros: [],
        filtroFechaInicio: fechaInicioTemp,
        filtroFechaFin: fechaFinTemp,
        tipoConsulta: "consolidado",
        entidadSeleccionada: null,

        mostrarAlerta: true,
        mensajeAlerta: '',
        severidadAlerta: '' 

      }

      this.btnBucarClick = this.btnBucarClick.bind(this)
      this.btnGenerarArchivoFTPClick = this.btnGenerarArchivoFTPClick.bind(this)
      this.exportExcel = this.exportExcel.bind(this)
      this.tipoConsultaChanged = this.tipoConsultaChanged.bind(this)

      this.cmbCajeros = React.createRef()
      this.cmbGrupos = React.createRef()
      this.rdgTipoConsulta = React.createRef()

      this.filtroFechaInicio = ""
      this.filtroFechaFin = ""

      this.fechaFiltroInicioChanged(fechaInicioTemp)
      this.fechaFiltroFinChanged(fechaFinTemp)
    }

    btnBucarClick() {
      this.queryTotales()
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }

	btnGenerarArchivoFTPClick() {
		
		var filtroIdGrupo = ''
		var fechaNegocio = ''
		var fechaInicio = ''
		var fechaFin = ''
		
		fechaNegocio = DateTime.fromFormat(this.filtroFechaInicio,  "yyyy-LL-dd HH:mm:ss").toFormat('yyyy-LL-dd')
		fechaInicio = fechaNegocio + " 00:00:00"
		fechaFin = fechaNegocio + " 23:59:59"
		
        if(this.cmbGrupos.current.value !== 0){
          filtroIdGrupo = this.cmbGrupos.current.value
        } else {
          //alert("Debe seleccionar un grupo")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Debe seleccionar un grupo',
            severidadAlerta: 'warning'
          })
          return
        }
		
      var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			filtro_id_grupo: filtroIdGrupo,
			fecha_negocio: fechaNegocio,
            filtro_fecha_inicio: fechaInicio,
            filtro_fecha_fin: fechaFin
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/archivoatlas/generar/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error generando archivo de FTP")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Error generando archivo de FTP',
            severidadAlerta: 'error'
          })
	      } else {
	        // alert("Archivo generado y subido al FTP.")
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Archivo generado y subido al FTP.',
            severidadAlerta: 'success'
          })
	      }
	    })
    }

    fechaFiltroInicioChanged(date) {
      if (date == null) return

      var formatoFecha = "yyyy-LL-dd HH:mm:00"

      this.setState({
        filtroFechaInicio: date
      })
      this.filtroFechaInicio = date.toFormat(formatoFecha)
    }

    fechaFiltroFinChanged(date) {
      if (date == null) return
      
      var formatoFecha = "yyyy-LL-dd HH:mm:59"

      this.setState({
        filtroFechaFin: date
      })
      this.filtroFechaFin = date.toFormat(formatoFecha)
    }

    tipoConsultaChanged(tipo) {
      this.setState({
        tipoConsulta: tipo.target.value
      })
    }
    
    queryGrupos() {
	    console.log('Consultando grupos....')
	        
	    var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando grupos")
	        //console.log('Error respuesta consultando grupos')
	        this.setState({
            grupos: [],
            mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando grupos',
            severidadAlerta: 'error'
	        })
	      } else {
	        //console.log('Grupos consultados')
	      	this.setState({
	        	grupos: data
	        })
	      }
	    })
	}

    queryCajeros() {

    console.log('Consultando cajeros....')
     var restArgs = {
      method: 'POST',
      headers: 'JSON',
      body: {},
      url: process.env.REACT_APP_URL_BACKEND + '/usuarios/cajerosconsultas/list'
    }

    RestTunel.makePetition(restArgs,(err, data) => {
      if(err) {
        // alert("Error consultando cajeros")
        // console.log('Error respuesta consultando cajeros')
        this.setState({
          cajeros: [],
          mensajeError: data.message,
          mostrarAlerta: true,
          mensajeAlerta: 'Error consultando cajera',
          severidadAlerta: 'error'
        })
      } else {
        console.log('Cajeros consultados')
      	this.setState({
        	cajeros: data
        })
      }
    })
    
   }

    queryTotales() {
        console.log('Consultando totales....')
    
        var filtroIdGrupo = ''
        var filtroIdCajero = ''

        if(this.cmbGrupos.current.value !== 0){
          filtroIdGrupo = this.cmbGrupos.current.value
          var entidades = this.state.entidades

		 /*
          for(var entidad in entidades) {
            if(entidades[entidad].id == filtroIdEntidad) {
              this.state.entidadSeleccionada = entidades[entidad]
            }   
          }
         */
        } else {
          //alert("Debe seleccionar un grupo")

          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: 'Debe seleccionar un grupo',
            severidadAlerta: 'warning'
          })
          return
        }

        if(this.cmbCajeros.current.value !== 0){
          filtroIdCajero = this.cmbCajeros.current.value
        }
        
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  filtro_id_grupo: filtroIdGrupo,
            filtro_id_cajero: filtroIdCajero,
            filtro_fecha_inicio: this.filtroFechaInicio,
            filtro_fecha_fin: this.filtroFechaFin
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/reportes/certificadoavance/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando información para certificados")
	        this.setState({
            registros: [],
              mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando información para certificados',
              severidadAlerta: 'error'
           })
	      } else {
	        console.log('Totales consultados')
            this.procesarDatos(data)
	      }
	    })
        
    }

    componentDidMount() {
      this.queryGrupos()
      this.queryCajeros()
      //this.queryTotales()
    }

    procesarDatos(registros) {    

      /*
      for(var x = 1; x < 1000; x++) {
        registros.push(registros[x])
      }
      */

      var certificadosAvance = []
      var consolidarTodasFechas = true

      var formatoFecha = "yyyy-LL-dd HH:mm:00"

      var fechaConsolidado = ''

      switch(this.state.tipoConsulta) {
        case 'consolidado':
          consolidarTodasFechas = true
          fechaConsolidado =  this.filtroFechaInicio + ' - ' + this.filtroFechaFin
          break
          case 'detallado':
            consolidarTodasFechas = false
            break
          default:
            break
      }

      // Recorrer todos los depositos y procesaros para obtener las denominaciones
      registros.forEach(registro => {


        // Parsear el detalle a JSON para procesarlo
        try {
          var tempJSON = JSON.parse(registro.detalle)
   
        // Tomar los depositos automaticos y manuales y unirlos en un solo arreglo
        for(var tipoTransaccion in tempJSON) {
          var nuevosDetalles = []

          // Tomar las monedas(currencies)
          // eslint-disable-next-line no-loop-func
          tempJSON[tipoTransaccion].currencies.forEach( currency => {

            switch(tipoTransaccion) {
              case 'automatico':
                tipoTransaccion = 'A'
                break;
              case 'manual':
                tipoTransaccion = 'M'
                break;
              default:
                break
            }

            //Temporalmente sobreescribo el tipo de transaccion para que no se discrimine por sobre u automatico, si algo despues se activa y se agrega el icono(actualmente lo remuevo)
            tipoTransaccion = ''

            // Tomar los billetes
            if(currency.bills != null) {
              currency.bills.forEach(bill => {
                nuevosDetalles.push([](tipoTransaccion, currency.currency,'B', bill.denomination, bill.amount))
              })
            }

            // Tomar las monedas
            if(currency.coins != null) {
              currency.coins.forEach(coin => {
                nuevosDetalles.push([](tipoTransaccion, currency.currency, 'M', coin.denomination, coin.amount))
              })                
            }

          })
        }

        // Buscar si ya existe un detalle de certificado para esa fecha, tipo transaccion, cajero y moneda
        var agregarDetalleCertificado = true
        certificadosAvance.forEach(tempCertificado => {
          // Comparar la fecha
          if(tempCertificado.fecha === registro.fecha || consolidarTodasFechas) {
            //Comparar cajero
            if(tempCertificado.cajero_id === registro.cajero_id) {
              //Comparar moneda
              if(tempCertificado.moneda === registro.moneda) {
                tempCertificado.valor_depositos += registro.valor
                if(registro.fecha_recaudo != null) {
                  tempCertificado.valor_recaudado += registro.valor
                }
                // Buscar entre todas las denominaciones nuevas si ya existia una entre las anteriores para sumar las cantidades, sino existe se agrega
                nuevosDetalles.forEach(nuevasCantidades => {
                  var agregarDenominacion = true
                  tempCertificado.detalle.forEach(cantidadesActuales => {
                    if(cantidadesActuales[0] === nuevasCantidades[0] && cantidadesActuales[1] === nuevasCantidades[1] && cantidadesActuales[2] === nuevasCantidades[2] && cantidadesActuales[3] === nuevasCantidades[3]) {
                      cantidadesActuales[4] += nuevasCantidades[4]
                      agregarDenominacion = false
                      return false
                    }
                  })

                  // No existia entonces se agrega la denominacion
                  if(agregarDenominacion) {
                    tempCertificado.detalle.push(nuevasCantidades)
                  }
                  
                })  
                
                agregarDetalleCertificado = false
                return false
              }
            }
          }
        })

        if(agregarDetalleCertificado) {
          var valor_recaudado = 0

          if(registro.fecha_recaudo != null) {
            valor_recaudado = registro.valor
          }

          var fecha = ''

          switch(this.state.tipoConsulta) {
            case 'consolidado' :
              fecha = fechaConsolidado
              break
            case 'detallado' :
              fecha = registro.fecha
              break
            default:
              break

          }

          certificadosAvance.push(
            {
              fecha: fecha,
              cajero_id: registro.cajero_id,
              cajero_alias: registro.cajero_alias,
              pais: registro.pais,
              ciudad: registro.ciudad,
              moneda: registro.moneda,
              valor_depositos: registro.valor,
              valor_recaudado:valor_recaudado,
              detalle: nuevosDetalles
            }
          )
        }
        } catch (error) {
          console.log("Error parseando datos")
        }


        
      })
      
      registros = null

      this.setState({
        registros: certificadosAvance
       })
       
       
    }

    exportExcel() {
     
      
      var workbook = new ExcelJS.Workbook()
      var sheet = workbook.addWorksheet("CertificadoAvance")
  
      var nombreArchivo = 'CertificadoAvance.xlsx'
  
      var headers = [
        {header: 'Fecha'},
        {header: 'Cajero'},
        {header: 'Pais'},
        {header: 'Ciudad'},
        {header: 'Moneda'},
        {header: 'Total Depositos'},
        {header: 'Total Recaudado'},
        {header: 'Total Remanente'}
      ]

      /* var listaDenominaciones = new Array('COP_B_1000', 'COP_B_2000', 'COP_B_5000', 'COP_B_10000', 'COP_B_20000', 'COP_B_50000', 'COP_B_100000',
                                            'COP_M_50', 'COP_M_100', 'COP_M_200', 'COP_M_500', 'COP_M_1000')
      */

      //var listaDenominaciones = this.state.entidadSeleccionada.denominaciones_soportadas.split(';')
      
      var listaDenominaciones = "COP_B_1000;COP_B_2000;COP_B_5000;COP_B_10000;COP_B_20000;COP_B_50000;COP_B_100000".split(";")

      listaDenominaciones.forEach(denominacion => {
        headers.push({header: denominacion})
      })
      

      sheet.columns = headers
  
  
      var registros = this.state.registros

      registros.forEach(registro => {
        var nuevoItem = [](
          registro.fecha,
          registro.cajero_alias,
          registro.pais,
          registro.ciudad,
          registro.moneda,
          registro.valor_depositos,
          registro.valor_recaudado,
          registro.valor_depositos - registro.valor_recaudado
          )

          // Preparar denominaciones
          var celdasDenominaciones = []
          for(var x in listaDenominaciones) {
            celdasDenominaciones.push(0)
          }
          
          // Buscar en que posicion va la denominacion del registro actual y sus denominaciones
          registro.detalle.forEach(itemDenominacion => {
            for(var x in listaDenominaciones) {
              if(listaDenominaciones[x] === (itemDenominacion[1] + '_' + itemDenominacion[2] + '_' + itemDenominacion[3])) {
                celdasDenominaciones[x] = itemDenominacion[4]
                continue
              }
            }
          })
          
        celdasDenominaciones.forEach(cantidad => {
          nuevoItem.push(cantidad)
        })
        

        sheet.addRow(nuevoItem)
        
      })

      sheet.columns.forEach(column => {
        column.width = 15
      })

      workbook.xlsx.writeBuffer().then(function(data) {
        FileSaver.saveAs(new Blob([data]), nombreArchivo)
      
        registros = null
        // alert("Archivo generado")
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: 'Archivo generado ',
          severidadAlerta: 'success'
        })
      })

    }

    render() {
        return(
            <Box>
              <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
                Certificado Avance 
              </Typography>

              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <Grid container xs={10} spacing={2}>
                        <Grid item fullWidth>
                          <Typography gutterBottom variant="h6" component="div">
                            Filtrar:
                          </Typography>
                        </Grid>
                        <Grid item container fullWidth spacing={2}>
                          <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DateTimePicker
                                label="Fecha/Hora Inicio"
                                value={this.state.filtroFechaInicio}
                                onChange={date => this.fechaFiltroInicioChanged(date)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={3}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                              <DateTimePicker
                                label="Fecha/Hora Fin"
                                value={this.state.filtroFechaFin}
                                onChange={date => this.fechaFiltroFinChanged(date)}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>

                        <Grid item container fullWidth spacing={2}>
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel>Grupos</InputLabel>
                              <Select
                                label="Grupos"
                                inputRef={this.cmbGrupos}
                                defaultValue={0}>
                                <MenuItem value={0}>-- Seleccione --</MenuItem>
                                {this.state.grupos.map(grupo => (
                                  <MenuItem value={grupo.id}>{grupo.nombre}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={3}>
                            <FormControl fullWidth>
                              <InputLabel>Cajero</InputLabel>
                              <Select
                                label="Cajero"
                                inputRef={this.cmbCajeros}
                                defaultValue={0}>
                                <MenuItem value={0}>-- Todos los cajeros --</MenuItem>
                                {this.state.cajeros.map(cajero => (
                                  <MenuItem value={cajero.id}>{cajero.alias}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item container fullWidth spacing={2}>
                          <Grid item xs={3}>
                            <FormLabel>Tipo consulta</FormLabel>
                            <RadioGroup
                              defaultValue="consolidado"
                              value = {this.state.tipoConsulta}
                              onChange={this.tipoConsultaChanged}
                            >
                              <FormControlLabel value ="consolidado" label="Consolidado" control={<Radio />} />
                              <FormControlLabel value ="detallado" label="Detallado" control={<Radio />} />
                            </RadioGroup>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Button variant="contained" onClick={this.btnBucarClick}>Buscar</Button>
                    <Snackbar
                                open={this.state.mostrarAlerta}
                                onClose={this.handleCloseAlert}
                                autoHideDuration={3000}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                              >
                                <Collapse in={this.state.mostrarAlerta}>
                                    <Alert
                                          severity={this.state.severidadAlerta}
                                          onClose={this.handleCloseAlert}
                                          sx={{ marginTop: 4, width:'100%'}}
                                              >
                                    <AlertTitle>
                                                  {this.state.severidadAlerta.toUpperCase()}
                                    </AlertTitle>
                                       {this.state.mensajeAlerta}
                                    </Alert>
                                </Collapse>
                    </Snackbar>

                  </CardActions>
                </Card>
              </Paper>
              <br />

              <Paper elevation={16}>
                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Totales:
                    </Typography>
                    { this.state.registros.length > 0 &&
                      <Button variant='contained' onClick={this.exportExcel}>Exportar</Button>
                    }
                    <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
                      <CertificadoAvanceTable registros={this.state.registros} />
                    </Box>
                  </CardContent>
                  <CardActions>
                    { this.state.registros.length > 0 &&
                      <Button variant='contained' onClick={this.exportExcel}>Exportar</Button>
                    }
                  </CardActions>
                </Card>

              </Paper>
             <br />
             <Button variant="contained" sx={{visibility: 'hidden'}} onClick={this.btnGenerarArchivoFTPClick}>Generar Archivo FTP</Button>
            </Box>
        )
    }

}

export default CertificadoAvancePage