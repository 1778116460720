import React, { Component } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Paper,
  Snackbar,
  Collapse,
  Alert,
  AlertTitle,
} from '@mui/material';
import { withTranslation } from 'react-i18next';

import '../login/LoginPageStyles.css';

require('dotenv').config();

const session = require('../../config/session');
const RestTunel = require('../../libs/rest_tunnel');

const baseUrl = process.env.REACT_APP_URL_FRONTEND;

class WelcomePage extends Component {
  constructor(props) {
    super(props);

    this.tagEntidad = '';

    this.state = {
      entidad: null,
      mensajeError: '',
      mostrarAlerta: false,
      mensajeAlerta: '',
      severidadAlerta: '',
    };

    // Aseguramos que el método login esté vinculado correctamente
    this.login = this.login.bind(this);

    // Inicializamos las referencias a los campos de texto
    this.txtUser = React.createRef();
    this.txtPassword = React.createRef();
  }

  showAlert = (mensaje, severidad) => {
    this.setState({
      mostrarAlerta: true,
      mensajeAlerta: mensaje,
      severidadAlerta: severidad,
    });
  };

  handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return; // Evitar que se cierre si el usuario hace clic fuera
    }
    this.setState({ mostrarAlerta: false });
  };

  componentDidMount() {
    session.endSession();
  }

  login() {
    // Implementa aquí tu lógica de autenticación
    const username = this.txtUser.current.value;
    const password = this.txtPassword.current.value;

    if (username === '' || password === '') {
      this.showAlert('Por favor ingrese usuario y contraseña', 'error');
      return;
    }

    // Ejemplo de llamada a una API de autenticación
    RestTunel.post('/login', { username, password })
      .then((response) => {
        // Maneja la respuesta exitosa
        this.showAlert('Inicio de sesión exitoso', 'success');
        // Redirige al usuario o actualiza el estado según sea necesario
      })
      .catch((error) => {
        // Maneja los errores de autenticación
        this.showAlert('Error en el inicio de sesión', 'error');
      });
  }

  render() {
    const { t } = this.props;

    const loginBox = () => {
      return (
        
          <div
            style={{
              minHeight: '100vh', 
              minWidth: '100vw', 
              backgroundColor: '#f5f5f5', 
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'space-between',

              //justifyContent: 'center',
            }}
          >
            {/* Contenedor de Imagen y Título */}
            <Box
              sx={{
                
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%', 
                boxShadow: '0px 0px 25px #4d4b4b',
                flexWrap: 'wrap',
                py: '10px'
                
              }}
            >
              {/* Imagen a la izquierda del título */}
              <a href="https://www.selfia.net/" >
                <Box
                  component="img"
                  src="/entidades/logo_selfia_classic.png"
                  alt="Logo"
                  href='https://www.selfia.net/'
                  sx={{
                    ////width: '50px', // Ajustar según el tamaño necesario
                    height: '30px', // Ajustar según el tamaño necesario
                    marginLeft: { xs: 1, sm: 2, md: 3 }, // Margen responsivo
                    marginRight: { xs: 1, sm: 2, md: 3 },

                  }}
                />
              </a>

              {/* Título */}
              <Typography
                variant="h4"
                sx={{
                  fontWeight: 'bold',
                  textAlign: 'center', // Alinear el texto del título a la izquierda
                }}
              >
                Sistema de información de depositarios
              </Typography>
            </Box>

            <Paper
              elevation={24}
              sx={{
                padding: 3, // Espaciado interno
                margin: 2,  // Espaciado externo
                boxShadow: '4px 12px 30px rgba(0, 128, 0, 0.4)',
                borderRadius: '10px',
                marginX: 10
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                {/* Descripción */}
                <Grid item xs={12} md={8} sx={{ textAlign: 'justify' }}>
                  <Typography
                    variant="body"
                    sx={{
                      fontSize: '1.2rem',
                      maxWidth: '800px',
                      //marginBottom: 4,
                      textAlign: 'justify',
                      paddingTop:10
                    }}
                  >
                    Nuestra familia de Smart boxes aseguran la mejor inversión costo resultado, para cubrir las necesidades de manera de efectivo, en cualquier tipo de ambientes, con el más alto nivel de rendimiento. Desarrollados sobre una arquitectura de Hardware y Software flexible, permite la ampliación de funciones utilizando la base de su configuración original. Gracias a su diseño simple y modular se facilita la integración de una variada gama de dispositivos que se adaptan a la presentación requerida, permitiendo distintas funcionalidades agregadas, para ampliar las posibilidad de su negocio.
                  </Typography>
                </Grid>

                {/* Imágenes */}
                
              </Grid>
            </Paper>

            <Box
                component="img"
                src="/kioskos.png"
                alt="Logo"
                sx={{
                  width: '100vw', // Ajustar según el tamaño necesario
                  height: 'auto', // Ajustar según el tamaño necesario
                  marginLeft: { xs: 1, sm: 2, md: 3 }, // Margen responsivo
                  marginRight: { xs: 1, sm: 2, md: 3 },
                  marginBottom: 5
                  
                }}
            />
            <Box
                sx={{
                  backgroundColor: '#353535',  
                  width: '100%',
                  textAlign: 'center',
                  position: 'fixed',
                  bottom:0,
                  
                  //padding: 1
                  //marginTop: 80,
                  //height: '50vh',
                  
                }}
                >
                  <Typography
                    variant="body4"
                    sx={{
                      textAlign: 'center',
                      color: '#FFFFFF',
                      //padding: 2,
                      //width: '100vw',
                      //height: '50vh',
                    }}
                  >
                    © 2024 Selfia. All rights reserved.
                  </Typography>

              </Box>
          </div>


      );
    };
    

    return (
      <>
      {loginBox()}
      </>
    )
  

    
  }
}

export default withTranslation()(WelcomePage);
