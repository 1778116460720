import React, {Component} from 'react'
import Grid from '@mui/material/Grid'
import { withTranslation } from 'react-i18next';
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos'

import ItemCajero from './ItemCajero'
import ItemCajeroIncidencia from './ItemCajeroIncidencia'
import { Alert, AlertTitle, Box, Collapse, Paper, Snackbar } from '@mui/material'
const session = require('../../config/session')

const RestTunel = require('../../libs/rest_tunnel')
var intervaloUpdateCajerosEstados = 10000

class HomePage extends Component {

  /*constructor(props) {
    super(props)
  }*/

    constructor(props) {
      super(props)

      this.state = {
          grupos: [],
          categoriasEstados: [], 
          cajeros: [],
          cajerosConIncidencia: [],

          mostrarAlerta: false,
          mensajeAlerta: '',
          severidadAlerta: ''
      }

      this.cmbGrupos = React.createRef()

      this.updateCajerosEstados = this.updateCajerosEstados.bind(this)
      this.btnFiltrarCajeros = this.btnFiltrarCajeros.bind(this)
  }

  componentDidMount() {
      this.timerRefrescarCajerosEstados = setInterval(this.updateCajerosEstados, intervaloUpdateCajerosEstados)


      this.queryGrupos()
      this.queryCategoriasEstados()
      this.updateCajerosEstados()
  }

  componentWillUnmount() {
      clearInterval(this.timerRefrescarCajerosEstados)
  }

  btnFiltrarCajeros() {
      this.updateCajerosEstados()
  }

  queryGrupos() {
      console.log('Consultando grupos....')
      
      var restArgs = {
        method: 'POST',
        headers: 'JSON',
        body: {},
        url: process.env.REACT_APP_URL_BACKEND + '/usuarios/grupos/list'
      }
  
      RestTunel.makePetition(restArgs,(err, data) => {
        if(err) {
          //alert("Error consultando grupos")
          //console.log('Error respuesta consultando grupos')
        this.setState({
      grupos: [],
      mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando grupos',
              severidadAlerta: 'error'
        })
      } else {
          //console.log('Grupos consultados')
          this.setState({
            grupos: data
          })
        }
      })
  }  
  
  queryCategoriasEstados() {
      console.log('Consultando lista estados....')
      
      var restArgs = {
        method: 'POST',
        headers: 'JSON',
        body: {},
        url: process.env.REACT_APP_URL_BACKEND + '/cajeroestados/listaestados'
      }
  
      RestTunel.makePetition(restArgs,(err, data) => {
        if(err) {
          //alert("Error consultando lista estados")
          //console.log('Error respuesta consultando lista estados')
        this.setState({
      categoriasEstados: [],
      mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando lista estados',
              severidadAlerta: 'error'
        })
      } else {
          //console.log('Estados consultados')
          this.setState({
            categoriasEstados: data.categoriasEstados
          })
        }
      })
  }

  updateCajerosEstados() {
      //console.log('Consultando estados cajeros')
      var filtroIdGrupo = ''

      if(this.cmbGrupos.current.value !== 0){
          filtroIdGrupo = this.cmbGrupos.current.value
      }
      
      var restArgs = {
        method: 'POST',
        headers: 'JSON',
        body: {filtro_id_grupo: filtroIdGrupo},
        url: process.env.REACT_APP_URL_BACKEND + '/cajeroestados/cajerosestados'
      }
  
      RestTunel.makePetition(restArgs,(err, data) => {
        if(err) {
          //alert("Error consultando grupos")
          //console.log('Error respuesta estado cajeros')
        this.setState({
      cajeros: [],
      mensajeError: data.message,
              mostrarAlerta: true,
              mensajeAlerta: 'Error consultando grupos',
              severidadAlerta: 'error'
        })
      } else {
          //console.log('Cajeros estados consultados')
          this.setState({
            cajeros: data.cajeros
          })
          this.buscarCajerosConIncidencias()
        }
      })
      
  }
  
  buscarCajerosConIncidencias() {
    var cajerosConIncidencias = []
    var cajeros = this.state.cajeros
    var cajerosEstados = this.state.cajerosEstados
    
    //Recorrer el listado de todos los cajeros 
    cajeros.forEach(cajero => {
      //Recorrer el listado de cajeros que tienen estados apra buscar si alguno de esos estados es de error
      cajero.estados.every(estado => {
        if(estado.codigo === 1) {
          cajerosConIncidencias.push(cajero)
          return false
        }
        return true
      })
    })
    
    this.setState({
      cajerosConIncidencia: cajerosConIncidencias
    })
}

  handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
  }

  render() {
    const {t} = this.props
    return(
      <>
        <Grid container justifyItems="center" direction="column" alignItems="center" justifyContent="center">
          <Grid container item alignItems="center" justifyContent="center">
            
          </Grid>
          {
        session.getItem('changePassword') && 
        <div class="divCambiarPassword">
            La contraseña ha expirado.
            <br />
            Debe cambiar su contraseña desde las opciones de la cuenta y volver a iniciar sesión.
        </div>
      }
        </Grid>


        <Box>
                <Paper elevation={16}>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom variant="h6" component="div" sx={{ marginBottom: 4 }}>
                                Filtrar cajeros:
                            </Typography>

                            <Grid container spacing={2}>

                                <Grid item ys={20}>
                                    <FormControl> 
                                        <InputLabel>Grupo</InputLabel>
                                        <Select style={{width: 300}}
                                            label="Grupo"
                                            inputRef={this.cmbGrupos}
                                            defaultValue={0}>
                                            <MenuItem value={0}>-- Todos los Cajeros/Grupos --</MenuItem>
                                            {this.state.grupos.map(grupo => (
                                            <MenuItem value={grupo.id}>Grupo - {grupo.nombre}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item ys={10}>
                                    <Button size="large" variant="contained" onClick={this.btnFiltrarCajeros}>Buscar</Button>
                                    <Snackbar
                                        open={this.state.mostrarAlerta}
                                        onClose={this.handleCloseAlert}
                                        autoHideDuration={3000}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                        >
                                        <Collapse in={this.state.mostrarAlerta}>
                                            <Alert
                                                severity={this.state.severidadAlerta}
                                                onClose={this.handleCloseAlert}
                                                sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                            <AlertTitle>
                                            {this.state.severidadAlerta.toUpperCase()}
                                            </AlertTitle>
                                            {this.state.mensajeAlerta}
                                            </Alert>
                                        </Collapse>
                                    </Snackbar>
                                </Grid>

                            </Grid>
                        </CardContent>
                    </Card>

                </Paper>

                    <Typography gutterBottom variant="h6" component="div" sx={{ marginTop: 2, marginBottom: 0 }}>
                        Cajeros con incidencias: {this.state.cajerosConIncidencia.length}
                    </Typography>
                    
                    <Grid container rowSpacing={1} columnSpacing={0} xs={12}>
                        {this.state.cajerosConIncidencia.map(cajero => {
                            return(
                                <ItemCajeroIncidencia cajero={cajero} categoriasEstados={this.state.categoriasEstados} />
                            )
                        })}
                    </Grid>

                    <Typography gutterBottom variant="h6" component="div" sx={{ marginTop: 2 , marginBottom: 0  }}>
                        Cajeros disponibles: {this.state.cajeros.length}
                    </Typography>

                    <Grid container rowSpacing={1} columnSpacing={0} xs={12}>
                        {this.state.cajeros.map(cajero => {
                            return(
                                <ItemCajero cajero={cajero} categoriasEstados={this.state.categoriasEstados} />
                            )
                        })}
                    </Grid>
                

            </Box>

      </>
    )
  }

}


export default withTranslation()(HomePage)
