import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';

import jawsThemeDesarrollo from './config/jaws-theme-desarrollo';
import jawsThemePruebas from './config/jaws-theme-pruebas';
import jawsThemeProduccion from './config/jaws-theme';

var customTheme = null


var entorno = process.env.REACT_APP_RAMA

//entorno = 'produccion'

switch(entorno) {
  case 'desarrollo':
    customTheme = jawsThemeDesarrollo
    break
  case 'pruebas':
    customTheme = jawsThemePruebas
    break
  case 'produccion':
    customTheme = jawsThemeProduccion
    break
  default:
    customTheme = jawsThemeProduccion
}


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={customTheme}>
      <App/>
    </ThemeProvider>
  </React.StrictMode>, 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
