import React, {Component} from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import FormGroup from '@mui/material/FormGroup'
import FormLabel from '@mui/material/FormLabel'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import CardActions from '@mui/material/CardActions'
import { Alert, AlertTitle, Collapse, Paper, Snackbar, Typography } from '@mui/material'

const session = require('../../config/session')
const RestTunel = require('../../libs/rest_tunnel')

class FuncionariosEditarPage extends Component {

    constructor(props) {
        super(props)

        this.idFuncionario = props.location.state.id_funcionario
        this.entidadesSeleccionadas = []

        this.state = {
            funcionario: {},
            txtNombre: "",
            txtIdentificacion: "",
            txtCodigo: "", 
            txtPasswordNueva: "",
            txtPasswordVerificacion: "",
            cmbEntidades: 0,
            entidades: [],
            cmbEstados: '',
            estados: [
              {id: "A", nombre: "Activo"},
              {id: "I", nombre: "Inactivo"},
              {id: "B", nombre: "Baja"}
            ],
            roles: [],
            rolesDisponibles: [],
            rolesFuncionario: [],
            permisos: [],
            permisosDisponibles: [],
            permisosFuncionario: [],
            cajeros: [],
            cajerosDisponibles: [],
            cajerosFuncionario: [],
            gruposCajerosDisponibles: [],
            gruposCajerosFuncionario: [],

            mostrarAlerta: false,
            mensajeAlerta: '',
            severidadAlerta: ''
        }
    }

    handleCloseAlert= (event, reason) => {
      if (reason === 'clickaway') {
        return; // Evitar que se cierre si el usuario hace clic fuera
      }
      this.setState({ mostrarAlerta: false })
    }


  onChangetxtNombre = (event) => {
      const nombre = event.target.value.toString()
      this.setState({txtNombre: nombre})
  }

  onChangetxtIdentificacion = (event) => {
      const identificacion = event.target.value.toString()
      this.setState({txtIdentificacion: identificacion})
  }

  onChangetxtCodigo= (event) => {
    const codigo = event.target.value.toString()
    this.setState({txtCodigo: codigo})
  }

  onChangetxtPasswordNueva = (event) => {
      const password = event.target.value.toString()
      this.setState({txtPasswordNueva: password})
  }

  onChangetxtPasswordVerificacion = (event) => {
    const password = event.target.value.toString()
    this.setState({txtPasswordVerificacion: password})
  }

  onChangecmbEntidades= (event) => {
    const entidad = event.target.value
    this.setState({cmbEntidades: entidad})
  }

  onChangecmbEstados= (event) => {
    const estado = event.target.value
    this.setState({cmbEstados: estado})
  }

  actualizarCajerosDisponibles() {
    var cajeros = this.state.cajeros
    
    var cajerosDisponibles = []
    var gruposDisponibles = []
    var cajerosFuncionario = this.state.cajerosFuncionario

    cajeros.forEach((cajero) => {
      if(cajero.tipo === 1) {
        var blnAgregar = true

        cajerosFuncionario.forEach((cajeroFuncionario) => {
          if(cajeroFuncionario.tipo === 1 && cajeroFuncionario.id === cajero.id) {
            blnAgregar = false
          }
        })
  
        if(blnAgregar) {
          cajerosDisponibles.push(cajero)
        }
      } else {
        /* eslint-disable no-redeclare */
        var blnAgregar = true
        /* eslint-enable no-redeclare */

        cajerosFuncionario.forEach((cajeroFuncionario) => {
          if(cajeroFuncionario.tipo === 2 && cajeroFuncionario.id === cajero.id) {
            blnAgregar = false
          }
        })

        if(blnAgregar) {
          gruposDisponibles.push(cajero)
        }
      }
      
    })
    
    this.setState({
      cajerosDisponibles: cajerosDisponibles,
      gruposCajerosDisponibles:gruposDisponibles
    })
  }

  actualizarGruposCajerosDisponibles() {
    var grupos = this.state.gruposCajeros
    
    var gruposCajerosDisponibles = []
    var gruposCajerosFuncionario = this.state.gruposCajerosFuncionario

    grupos.forEach((grupo) => {
      var blnAgregar = true

      gruposCajerosFuncionario.forEach((grupoFuncionario) => {
        
        if(grupoFuncionario.id === grupo.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        gruposCajerosDisponibles.push(grupo)
      }
    })
    
    this.setState({
      gruposCajerosDisponibles: gruposCajerosDisponibles
    })
  }

  agregarCajero(cajeroNuevo) {
    var cajerosDisponibles = this.state.cajerosDisponibles
    var gruposCajerosDisponibles = this.state.gruposCajerosDisponibles
    var cajerosFuncionario = this.state.cajerosFuncionario

    if(cajeroNuevo.tipo === 1) {
      cajerosDisponibles.forEach((cajero) => {
        if(cajero.tipo === cajeroNuevo.tipo && cajero.id === cajeroNuevo.id) {
          cajerosFuncionario.push(cajero)
        }
      })
    } else {
      gruposCajerosDisponibles.forEach((cajero) => {
        if(cajero.tipo === cajeroNuevo.tipo && cajero.id === cajeroNuevo.id) {
          cajerosFuncionario.push(cajero)
        }
      })
    }

    this.setState({
      cajerosFuncionario: cajerosFuncionario
    })

    this.actualizarCajerosDisponibles()
  }

  removerCajero(cajeroRemover) {
    var cajerosFuncionario = this.state.cajerosFuncionario

    for(var x = 0; x < cajerosFuncionario.length; x++) {
      if(cajerosFuncionario[x].tipo === cajeroRemover.tipo && cajerosFuncionario[x].id === cajeroRemover.id) {
        cajerosFuncionario.splice(x, 1) 
        break
      }
    }

    this.setState({
      cajerosFuncionario: cajerosFuncionario
    })

    this.actualizarCajerosDisponibles()
  }

  formatearRoles(strRoles) {
    strRoles = JSON.parse(strRoles)
    this.setState({
      roles: strRoles.roles
    })
  }

  formatearPermisos(strPermisos) {
    strPermisos = JSON.parse(strPermisos)
    this.setState({
      permisos: strPermisos.permisos
    })
  }

  actualizarRolesDisponibles() {
    var roles = this.state.roles
    
    var rolesDisponibles = []
    var rolesFuncionario = this.state.rolesFuncionario

    roles.forEach((rol) => {
      var blnAgregar = true

      rolesFuncionario.forEach((rolFuncionario) => {
        if(rolFuncionario.id === rol.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        rolesDisponibles.push(rol)
      }
    })
    
    this.setState({
      rolesDisponibles: rolesDisponibles
    })
  }

  actualizarPermisosDisponibles() {
    var permisos = this.state.permisos
    
    var permisosDisponibles = []
    var permisosFuncionario = this.state.permisosFuncionario

    permisos.forEach((rol) => {
      var blnAgregar = true

      permisosFuncionario.forEach((permisoFuncionario) => {
        if(permisoFuncionario.id === rol.id) {
          blnAgregar = false
        }
      })

      if(blnAgregar) {
        permisosDisponibles.push(rol)
      }
    })
    
    this.setState({
      permisosDisponibles: permisosDisponibles
    })
  }

  agregarRol(idRol) {
    var rolesDisponibles = this.state.rolesDisponibles
    var rolesFuncionario = this.state.rolesFuncionario

    rolesDisponibles.forEach((rol) => {
      if(rol.id === idRol) {
        rolesFuncionario.push(rol)
      }
    })

    this.setState({
      rolesFuncionario: rolesFuncionario
    })

    this.actualizarRolesDisponibles()
  }

  removerRol(idRol) {
    var rolesFuncionario = this.state.rolesFuncionario
    for(var x = 0; x < rolesFuncionario.length; x++) {
      if(rolesFuncionario[x].id === idRol) {
        rolesFuncionario.splice(x, 1) 
        break
      }
    }

    this.setState({
      rolesFuncionario: rolesFuncionario
    })

    this.actualizarRolesDisponibles()
  }

  agregarPermiso(idPermiso) {
    var permisosDisponibles = this.state.permisosDisponibles
    var permisosFuncionario = this.state.permisosFuncionario

    permisosDisponibles.forEach((permiso) => {
      if(permiso.id === idPermiso) {
        permisosFuncionario.push(permiso)
      }
    })

    this.setState({
      permisosFuncionario: permisosFuncionario
    })

    this.actualizarPermisosDisponibles()
  }

  removerPermiso(idPermiso) {
    var permisosFuncionario = this.state.permisosFuncionario
    for(var x = 0; x < permisosFuncionario.length; x++) {
      if(permisosFuncionario[x].id === idPermiso) {
        permisosFuncionario.splice(x, 1) 
        break
      }
    }

    this.setState({
      permisosFuncionario: permisosFuncionario
    })

    this.actualizarPermisosDisponibles()
  }


    componentDidMount() {
        this.queryFuncionario()
        this.queryEntidades()
        this.queryRoles()
        this.queryPermisos()
        this.queryCajeros()
        this.queryCajerosFuncionario()
    }

    btnActualizarFuncionarioClick = () => {
        console.log('Actualizando funcionario....')

        var continuar = true
        var mensajeError = "Error:\n"

        var nombre = this.state.txtNombre
        var identificacion = this.state.txtIdentificacion
        var codigo = this.state.txtCodigo
        var idEntidad = this.state.cmbEntidades
        var idEstado = this.state.cmbEstados

        nombre = nombre.trim()
        identificacion = identificacion.trim()
        codigo = codigo.trim()
        
        if(nombre === '') {
          continuar = false
          mensajeError += " * Debe ingresar el nombre.\n"
        }

        if(identificacion === '') {
          continuar = false
          mensajeError += " * Debe ingresar la identificación.\n"
        }

        if(codigo === '') {
          continuar = false
          mensajeError += " * Debe ingresar el codigo.\n"
        }

        if(idEntidad === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar una entidad.\n"
        }

        if(idEstado === 0) {
          continuar = false
          mensajeError += " * Debe seleccionar un estado.\n"
        }
        
        if(continuar) {
			var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	 id_funcionario: this.state.funcionario.id,
                nombre: nombre,
                identificacion: identificacion,
                codigo: codigo,
                id_entidad: idEntidad,
                id_estado: idEstado
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/funcionario/edit'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta actualizar funcionario')
                //alert("Se produjo un error actualizando el funcionario")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Se produjo un error actualizando el funcionario',
                  severidadAlerta: 'error'
                })
		      } else {
                //console.log('Funcionario actualizado')
                //alert("Funcionario Actualizado")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Funcionario Actualizado',
                  severidadAlerta: 'success'
                })
		      }
		    })
			
        } else {
          //alert(mensajeError)
          this.setState({
            mostrarAlerta: true,
            mensajeAlerta: mensajeError,
            severidadAlerta: 'error'
          })
        }
        
    }

    btnActualizarPasswordClick = () => {
      console.log('Actualizando password....')

      var continuar = true
      var mensajeError = "Error:\n"

      var passwordNueva = this.state.txtPasswordNueva.toString()
      var passwordVerifiacion = this.state.txtPasswordVerificacion.toString()

      passwordNueva = passwordNueva.trim()
      passwordVerifiacion = passwordVerifiacion.trim()
      
      if(passwordNueva === '') {
        continuar = false
        mensajeError += " * Debe ingresar la nueva contraseña.\n"
      }

      if(passwordVerifiacion === '') {
        continuar = false
        mensajeError += " * Debe ingresar la verificación de la contraseña.\n"
      }

      if(passwordNueva !== passwordVerifiacion) {
        continuar = false
        mensajeError += " * La contraseña nueva y la verificación no coinciden.\n"
      }
  
      if(continuar) {
		  var restArgs = {
		      method: 'PATCH',
		      headers: 'JSON',
		      body: {
			  	id_funcionario: this.state.funcionario.id,
	              funcionario: this.state.funcionario.identificacion + ' ' + this.state.funcionario.codigo,
	              password: passwordNueva
			  },
		      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/funcionario/password'
		    }
		
		    RestTunel.makePetition(restArgs,(err, data) => {
		      if(err) {
		        //console.log('Error respuesta actualizar contraseña')
             	// alert("Se produjo un error actualizando la contraseña")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Se produjo un error actualizando la contraseña',
                  severidadAlerta: 'error'
                })
		      } else {
		            //console.log('Contraseña actualizada')
              	//alert("Contraseña actualizada")
                this.setState({
                  mostrarAlerta: true,
                  mensajeAlerta: 'Contraseña actualizada',
                  severidadAlerta: 'success'
                })
		      }
		    })
		
      } else {
        //alert(mensajeError)
        this.setState({
          mostrarAlerta: true,
          mensajeAlerta: mensajeError,
          severidadAlerta: 'error'
        })
      }
      
  }

  btnGuardarCajerosClick = () => {
    var cajerosFuncionario = this.state.cajerosFuncionario
    var cajeros = []
    var mensajeError = "Error:\n"
    var continuar = true

    
    cajerosFuncionario.forEach((cajero) => {
        cajeros.push(
            {
              tipo: cajero.tipo,
              id: cajero.id
            }
          )
    })
    
    
    if(continuar) {
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
		  	id_funcionario: this.state.funcionario.id,
            funcionario: this.state.funcionario.identificacion + ' ' + this.state.funcionario.codigo,
            cajeros: cajeros
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/funcionario/cajeros'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar cajeros')
            //alert("Se produjo un error actualizando cajeros")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando cajeros',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Cajeros actualizados')
            //alert("Cajeros actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Cajeros actualizadas',
              severidadAlerta: 'success'
            })
            this.queryCajerosFuncionario()
	      }
	    })
		
    } else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

  btnGuardarPermisosClick = () => {
    var rolesFuncionario = this.state.rolesFuncionario
    var permisosFuncionario = this.state.permisosFuncionario
    var roles = []
    var permisos = []
    var mensajeError = "Error:\n"
    var continuar = true
    
    rolesFuncionario.forEach((rol) => {
      roles.push(rol)
    })

    permisosFuncionario.forEach((permiso) => {
      permisos.push(permiso)
    })


    if(continuar) {
		var restArgs = {
	      method: 'PATCH',
	      headers: 'JSON',
	      body: {
		  	id_funcionario: this.state.funcionario.id,
            funcionario: this.state.funcionario.identificacion + ' ' + this.state.funcionario.codigo,
            roles: roles,
            permisos: permisos
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/funcionario/permisos'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //console.log('Error respuesta actualizar roles')
            //alert("Se produjo un error actualizando roles y permisos")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Se produjo un error actualizando roles y permisos',
              severidadAlerta: 'error'
            })
	      } else {
	        //console.log('Roles actualizados')
            //alert("Roles y Permisos actualizados")
            this.setState({
              mostrarAlerta: true,
              mensajeAlerta: 'Roles y Permisos actualizados',
              severidadAlerta: 'success'
            })
	      }
	    })
		
    } else {
      //alert(mensajeError)
      this.setState({
        mostrarAlerta: true,
        mensajeAlerta: mensajeError,
        severidadAlerta: 'error'
      })
    }
  }

    queryFuncionario() {
        //console.log('Consultando funcionario....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
			  id_funcionario: this.idFuncionario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/detalle'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando funcionario")
	         //console.log('Error respuesta consultando funcionario')
	        this.setState({
	       		mensajeError: data.message,
            mostrarAlerta: true,
            mensajeAlerta: 'Error consultando funcionario',
            severidadAlerta: 'error'
	        })
	      } else {
	        // console.log('Funcionario consultado')
	        
	        var roles = []
	        var permisos = []
	
	        try {
	          var rolesPermisos = JSON.parse(data.permisos)
	          roles = rolesPermisos.roles
	          permisos = rolesPermisos.permisos
	        } catch {
	
	        }
	        
	        var funcionario = data
	
	        this.setState({
	            funcionario: funcionario,
	            txtNombre: funcionario.nombre,
	            txtIdentificacion: funcionario.identificacion,
	            txtCodigo: funcionario.codigo,
	            cmbEntidades: funcionario.id_entidad,
	            cmbEstados: funcionario.estado,
	            rolesFuncionario: roles,
	            permisosFuncionario: permisos,
	        })
	        this.actualizarRolesDisponibles()
	        this.actualizarPermisosDisponibles()
	      }
	    })
        
   }

    queryEntidades() {
        console.log('Consultando entidades....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/entidades/usuario/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando entidades")
	        //console.log('Error respuesta consultando entidades')
              this.setState({
				        entidades: [],
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando entidades',
                severidadAlerta: 'error'
              })
	      } else {
	        //console.log('Entidades consultadas')
              this.setState({
                entidades: data
                })
	      }
	    })
        
      }

      queryCajeros() {
        //console.log('Consultando cajeros....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {},
	      url: process.env.REACT_APP_URL_BACKEND + '/cajerosgrupos/cajerosygrupos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajeros")
	        //console.log('Error respuesta consultando cajeros')
	          this.setState({
				        cajeros: [],
	              mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajeros',
                severidadAlerta: 'error'
	          })
	      } else {
	      	  // console.log('Cajeros consultados')
              this.setState({
                cajeros: data,
                mostrarAlerta: true,
                mensajeAlerta: 'Cajeros consultados',
                severidadAlerta: 'success'
              })
             this.actualizarCajerosDisponibles()
	      }
	    })
        
      }

      queryRoles() {
        console.log('Consultando Roles....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	codigo: 'dashboard_funcionarios_roles'
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/parametros/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //lert("Error consultando roles")
	        //console.log('Error respuesta consultando roles')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando roles',
                severidadAlerta: 'error'
              })
	      } else {
	          //console.log('Roles consultados')
              this.formatearRoles(data.valor1)
              this.actualizarRolesDisponibles()
	      }
	    })
        
      }

      queryPermisos() {
        //console.log('Consultando Permisos....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	codigo: 'dashboard_funcionarios_permisos'
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/parametros/'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando permisos")
	        //console.log('Error respuesta consultando permisos')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando permisos',
                severidadAlerta: 'error'
              })
	      } else {
	        //console.log('Permisos consultados')
              this.formatearPermisos(data.valor1)
              this.actualizarPermisosDisponibles()
	      }
	    })
    
      }

      queryCajerosFuncionario() {
        // console.log('Consultando cajeros usuario....')
        var restArgs = {
	      method: 'POST',
	      headers: 'JSON',
	      body: {
		  	id_funcionario: this.idFuncionario
		  },
	      url: process.env.REACT_APP_URL_BACKEND + '/funcionarios/cajerosgrupos/list'
	    }
	
	    RestTunel.makePetition(restArgs,(err, data) => {
	      if(err) {
	        //alert("Error consultando cajeros funcionario")
	        //console.log('Error respuesta consultando cajeros funcionario')
              this.setState({
                mensajeError: data.message,
                mostrarAlerta: true,
                mensajeAlerta: 'Error consultando cajeros funcionario',
                severidadAlerta: 'error'
              })
	      } else {
	        //console.log('Cajeros funcionario consultados')
              this.setState({
                cajerosFuncionario: data
                })
              this.actualizarCajerosDisponibles()
	      }
	    })
   
      }


    render() {
        var funcionario = this.state.funcionario

        return(
          <Box>
            
            <Typography gutterBottom variant="h5" component="div" sx={{ marginBottom: 2 }}>
              Editar Funcionario

            </Typography> 

              <Paper elevation={16}>
   
                <Card>
                    <CardContent>
                        <form>
                            <FormLabel sx={{marginBottom: 2}}>Información Funcionario</FormLabel>
                            <Grid container xs={12} spacing={2} rowSpacing={2}>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Nombre"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtNombre}
                                  onChange={this.onChangetxtNombre}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>  
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <TextField
                                  label="Identificación"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={this.state.txtIdentificacion}
                                  onChange={this.onChangetxtIdentificacion}
                                  fullWidth
                                  />
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                    <TextField
                                    label="Código"
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={this.state.txtCodigo}
                                    onChange={this.onChangetxtCodigo}
                                    fullWidth
                                  />
                               </FormControl>
                              </Grid> 
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <InputLabel>Entidad</InputLabel>
                                  <Select
                                    label="Entidad"
                                    value={this.state.cmbEntidades}
                                    defaultValue={0}
                                    onChange={this.onChangecmbEntidades}>
                                    <MenuItem value={0}>-- Seleccione entidad --</MenuItem>
                                    {this.state.entidades.map(entidad => (
                                      <MenuItem value={entidad.id}>{entidad.nombre}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                <FormControl fullWidth component="fieldset">
                                  <InputLabel>Estado</InputLabel>
                                  <Select
                                    label="Estado"
                                    value={this.state.cmbEstados}
                                    defaultValue={0}
                                    onChange={this.onChangecmbEstados}>
                                    <MenuItem value={0}>-- Seleccione estado --</MenuItem>
                                    {this.state.estados.map(estado => (
                                      <MenuItem value={estado.id}>{estado.nombre}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            </Grid>                   
                        </form>
                    </CardContent>
                    <CardActions>
                      <Button  variant="contained" onClick={this.btnActualizarFuncionarioClick}>Actualizar Informacion Funcionario</Button>
                      <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                      </Snackbar> 
                    </CardActions>
                  </Card>
                
              </Paper>
                <br />
              <Paper elevation={16}>
                
                  <Card>
                      <CardContent>
                        <form>
                         <FormLabel>Cambiar Contraseña</FormLabel>
                          <Grid container xs={12} spacing={2} rowSpacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                  label="Nueva Contraseña"
                                  id="outlined-basic"
                                  type="password"
                                  variant="outlined"
                                  onChange={this.onChangetxtPasswordNueva}
                                  fullWidth
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth component="fieldset">
                                <TextField
                                  label="Verificar Contraseña"
                                  id="outlined-basic"
                                  type="password"
                                  variant="outlined"
                                  onChange={this.onChangetxtPasswordVerificacion}
                                  fullWidth
                                />
                              </FormControl>
                            </Grid>
                           </Grid>
                          </form>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" fullWidth onClick={this.btnActualizarPasswordClick}>Cambiar</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                        </CardActions>
                  </Card>
                
              </Paper>
                <br />
              <Paper elevation={16}>
                   <Grid item xs={8}>
                      <Card>
                        <CardContent>
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                              <FormLabel>Roles Disponibles</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.rolesDisponibles.map((rol) => {
                                      return(
                                        <ListItem button
                                          key={rol.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarRol(rol.id)}}>
                                            {rol.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Roles Funcionario</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.rolesFuncionario.map((rol) => {
                                      return(
                                        <ListItem button
                                          key={rol.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerRol(rol.id)}}>
                                          {rol.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                          </Grid>
                          <br />
                          <Grid container xs={12} spacing={2} columnSpacing={2}>
                            <Grid item xs={6}>
                              <FormLabel>Permisos Disponibles</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.permisosDisponibles.map((permiso) => {
                                      return(
                                        <ListItem button
                                          key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarPermiso(permiso.id)}}>
                                            {permiso.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Permisos Funcionario</FormLabel>
                              <List
                                sx={{
                                  minHeight: 300,
                                  maxHeight: 300,
                                  overflow: 'auto'
                                }}>
                                  {this.state.permisosFuncionario.map((permiso) => {
                                      return(
                                        <ListItem button
                                          key={permiso.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerPermiso(permiso.id)}}>
                                          {permiso.nombre}
                                        </ListItem>
                                      )
                                    }
                                  )}
                              </List>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                          <Button variant="contained" fullWidth onClick={this.btnGuardarPermisosClick}>Guardar Roles y Permisos</Button>
                          <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                          </Snackbar> 
                        </CardActions>
                      </Card>
                    </Grid>
                
              </Paper>
                <br />
              <Paper elevation={16}>
                  <Grid xs={8}>       
                    <Card>
                        <CardContent>
                          <Grid container xs={12} spacing={2}>
                            <Grid container item xs={6}>
                              <Grid item xs={12}>
                                <FormLabel>Cajeros Disponibles</FormLabel>
                                  <List
                                    sx={{
                                      minHeight: 300,
                                      maxHeight: 300,
                                      overflow: 'auto'
                                    }}>
                                      {this.state.cajerosDisponibles.map((cajero) => {
                                          return(
                                            <ListItem button
                                              key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero)}}>
                                                {cajero.alias}
                                            </ListItem>
                                          )
                                        }
                                      )}
                                  </List>
                              </Grid>
                              <Grid item xs={12}>
                                <FormLabel>Grupos Disponibles</FormLabel>
                                    <List
                                      sx={{
                                        minHeight: 300,
                                        maxHeight: 300,
                                        overflow: 'auto'
                                      }}>
                                        {this.state.gruposCajerosDisponibles.map((cajero) => {
                                            return(
                                              <ListItem button
                                                key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.agregarCajero(cajero)}}>
                                                  {cajero.alias}
                                              </ListItem>
                                            )
                                          }
                                        )}
                                    </List>
                              </Grid>
                            </Grid>
                            <Grid item xs={6}>
                              <FormLabel>Cajeros/Grupos Funcionario</FormLabel>
                                        <List
                                          sx={{
                                            minHeight: '100%',
                                            
                                            overflow: 'auto'
                                          }}>
                                            {this.state.cajerosFuncionario.map((cajero) => {
                                                return(
                                                  <ListItem button
                                                    key={cajero.id} classes={{root: "ItemLista"}} onClick={() =>{this.removerCajero(cajero)}}>
                                                    {cajero.tipo === 1 ? 'Cajero - ' + cajero.alias : 'Grupo - ' + cajero.alias}
                                                  </ListItem>
                                                )
                                              }
                                            )}
                                        </List>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" fullWidth onClick={this.btnGuardarCajerosClick}>Guardar Cajeros</Button>

                            <Snackbar
                                            open={this.state.mostrarAlerta}
                                            onClose={this.handleCloseAlert}
                                            autoHideDuration={3000}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                                            >
                                            <Collapse in={this.state.mostrarAlerta}>
                                                <Alert
                                                    severity={this.state.severidadAlerta}
                                                    onClose={this.handleCloseAlert}
                                                    sx={{ marginTop: 4, width:'100%'}}
                                                    >
                                                <AlertTitle>
                                                    {this.state.severidadAlerta.toUpperCase()}
                                                </AlertTitle>
                                                    {this.state.mensajeAlerta}
                                                </Alert>
                                            </Collapse>
                            </Snackbar> 
                          </CardActions>
                    </Card>
                  </Grid>

              </Paper>
            

          </Box>
        )
    }

}


export default FuncionariosEditarPage