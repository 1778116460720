import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import EditarIcon from '@mui/icons-material/Edit';

const session = require('../../config/session')

function Row(props) {
 const {funcionario} = props

 var estado = ""

 switch(funcionario.estado) {
  case "A":
    estado = "Activo"
    break
  case "I":
    estado = "Inactivo"
    break
  case "B":
    estado = "Baja"
    break
  default:
    break 
 }

 return (
   <TableRow>
     <TableCell>
       {funcionario.fecha_registro}
     </TableCell>
     <TableCell>
        {funcionario.nombre}
     </TableCell>
     <TableCell>
       {funcionario.identificacion}
     </TableCell>
     <TableCell>
       {funcionario.codigo}
    </TableCell>
    <TableCell>
       {funcionario.entidad}
    </TableCell>
    <TableCell>
      {estado}
    </TableCell>
    <TableCell>
    {
      session.checkPermiso('acceder_funcionarios_editar') && 
      <Link to={{pathname: '/dashboard/funcionarios/editar', state: {id_funcionario: funcionario.id}}} style={{ textDecoration: 'none' }}><Button variant="contained" endIcon={<EditarIcon />} color="primary">Editar</Button></Link>
    }
    </TableCell>
  </TableRow>
 );
}


class FuncionariosTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      cajeros: []
    };
  }

  componentDidMount() {
  }

  render() {
    const funcionarios = this.props.funcionarios


    const columns = [
      {field: "fecha_registro", headerName: 'Fecha Registro', width: 250},
      {field: "nombre", headerName: 'Nombre', width: 220},
      {field: "identificacion", headerName: 'Identificación', width: 170},
      {field: "codigo", headerName: 'Código', width: 170},
      {field: "entidad", headerName: 'Entidad', width: 250},
      {field: "estado", headerName: 'Estado', width: 100},
      {field: "", headerName: ''},
    ]

    return (
      <Box sx={{ maxWidth: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader class="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {funcionarios.map((funcionario) => {
                return(
                  <Row funcionario={funcionario} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
}

export default FuncionariosTable;
