import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import TableContainer from '@mui/material/TableContainer'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

function Row(props) {
 const {registro} = props;

  var diferenciaStyle = ""

  if(registro.total_maquina !== registro.total_servidor || registro.total_maquina !== registro.total_transportadora) {
    diferenciaStyle = "recaudo-diferencia"
  }

 return (
   <React.Fragment>
     <TableRow>
     <TableCell>
         {registro.cajero_codigo}
       </TableCell>
       <TableCell>
         {registro.cajero_alias}
       </TableCell>
       <TableCell>
         {registro.fecha_inicio}
       </TableCell>
       <TableCell>
         {registro.fecha_cierre}
       </TableCell>
       <TableCell>
         {registro.num_transacciones}
       </TableCell>
       <TableCell className={diferenciaStyle}>
        {registro.total_maquina.toLocaleString()}
       </TableCell>
       <TableCell className={diferenciaStyle}>
        {registro.total_servidor.toLocaleString()}
       </TableCell>
       <TableCell className={diferenciaStyle}>
        {registro.total_transportadora.toLocaleString()}
       </TableCell>
       <TableCell>
        <img alt='x' src={'/flags/' + registro.moneda.toLowerCase() + '.png'} width="20" /> {registro.moneda}
       </TableCell>       
       <TableCell>
         {registro.pais}
       </TableCell>
       <TableCell>
         {registro.ciudad}
       </TableCell>
     </TableRow>
     <TableRow>
     </TableRow>
   </React.Fragment>
 );
}

class TotalesCuadreTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      registros: []
    };
  }



  componentDidMount() {
  }

  render() {

    const registros = this.props.registros

    var totalRecaudos= []
    
    //Sumar los totales de depositos dependiendo la moneda. Se almacena en un arreglo cada tipo de moneda encontrado y se va sumando el todal de cada una
    registros.forEach((registro) => {
      
      var blnAgregarNuevaMoneda = true

      var valorTemporal = registro.total_maquina
      for(var x = 0; x < totalRecaudos.length; x++) {
        
        if(totalRecaudos[x].moneda === registro.moneda) {
          totalRecaudos[x].total += valorTemporal
          blnAgregarNuevaMoneda = false
          break
        }
      }

      if(blnAgregarNuevaMoneda) {
        totalRecaudos.push({
          moneda: registro.moneda,
          total: valorTemporal
        })
      }

    })

    const columns = [
      {field: "cajero_codigo", headerName: 'Cajero-Codigo', width: 100},
      {field: "cajero_alias", headerName: 'Cajero-Alias', width: 150},
      {field: "fechainicio", headerName: 'Fecha Inicio', width: 80},
      {field: "fechafin", headerName: 'Fecha Fin', width: 80},
      {field: "num_transacciones", headerName: '# Trans', width: 60},
      {field: "total_maquina", headerName: 'Maquina', width: 100},
      {field: "total_servidor", headerName: 'Servidor', width: 100},
      {field: "total_transportadora", headerName: 'Transportadora', width: 100},
      {field: "moneda", headerName: 'Moneda', width: 30},      
      {field: "pais", headerName: 'País', width: 100},
      {field: "ciudad", headerName: 'Ciudad', width: 100}
    ]

    return (
      <Paper sx={{ width: 1200, overflow: 'hidden' }}>
        <TableContainer>
          <Table stickyHeader className="table-data">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return(
                    <TableCell style={{width: column.width}} key={column.field}>{column.headerName}</TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {registros.map((registro) => {
                return(
                  <Row registro={registro} />
                )
              })}
            </TableBody>
          </Table>
          <Table sx={{width: '100%'}}>
              {totalRecaudos.map((total) => {
                return(
                  <TableRow>
                    <TableCell sx={{width: '70%'}}></TableCell>
                    <TableCell></TableCell>
                    <TableCell>Total:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="right">{total.total.toLocaleString()}</TableCell>
                    <TableCell align="right"><img alt='x' src={'/flags/' + total.moneda.toLowerCase() + '.png'} width="20" />{total.moneda}</TableCell>
                  </TableRow>
                )
              })}
              </Table>
        </TableContainer>
      </Paper>
    )
  }
}

export default TotalesCuadreTable;
